import React, { useState, useEffect } from "react";
import "./MobileNotificationComponent.scss";
import flagLogo from "../../assets/images/ionic-ios-flag.svg";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import moment from "moment-timezone";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Button from "@material-ui/core/Button";
//FeedBack Screen
import feedback1 from "../../assets/images/FeedbackPopUpImg2.svg";
import feedback2 from "../../assets/images/feedbackPopUpImg1.svg";
import {
  Snackbar,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  IconButton,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Popover,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import axios from "axios";
import { readAllData } from "../../indexdb_utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { requestActions } from "../../store/request";

const MobileNotification = () => {
  // const [showNotificationPopUp, setShowNotificationPopUp] = useState(false);
  const [notificationStatus, setnotificationStatus] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationSeeMore, setNotificationSeeMore] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const APIFETCHLIMIT = "ALL";
  const APISEEMORELIMIT = "ALL";

  //FeedBack Start

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const [openfeedpack1Popup, setopenfeedpack1Popup] = useState(false);
  const [isSubmitFeedbackDisabled, setisSubmitFeedbackDisabled] =
    useState(true);
  const [userfeedback, setUserfeedback] = useState({
    servicerequestno: "",
    lstResponses: [
      {
        Question: "",
        Response: "",
        OrderNo: "",
      },
    ],
  });
  const [starvalue, setStarValue] = useState();
  const [openfeedpack2Popup, setopenfeedpack2Popup] = useState(false);
  const [isSubmitFeedbackErr, setisSubmitFeedbackErr] = useState(false);
  const starRating = ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"];
  const [feedBackOptionFiltered, setFeedBackOptionFiltered] = useState<any[]>(
    []
  );

  //FeedBack End

  useEffect(() => {
    fetchNotification(APIFETCHLIMIT);
  }, []);

  const fetchNotification = async (APILIMIT: any) => {
    try {
      //let response =  await makePostRequest(`https://sprinngboot-event.herokuapp.com/eventRest/eventDetails/${APILIMIT}`);

      //console.log(response);
      const userDetails: any = JSON.parse(
        localStorage.getItem("userDetails") as any
      );
      let legacycode = userDetails ? userDetails.legacycode : "";
      const notificationURLBaseService =
        process.env.REACT_APP_ENV == "QA"
          ? `${process.env.REACT_APP_NOTIFICATION_QA}`
          : process.env.REACT_APP_ENV == "UAT"
          ? `${process.env.REACT_APP_NOTIFICATION_UAT}`
          : process.env.REACT_APP_ENV == "PROD"
          ? `${process.env.REACT_APP_NOTIFICATION_DEV}`
          : `${process.env.REACT_APP_NOTIFICATION_DEV}`;
      let notificationURL = `${notificationURLBaseService}/${APILIMIT}/${legacycode}`;
      let response = await makePostRequest(notificationURL);
      let notificationListArray = response.data.notifications.filter(
        (notification: any, index: any) => {
          return notification.usv_Notification_Text__c !== null;
        }
      );
      //console.log(notificationListArray);
      if (notificationListArray && notificationListArray.length > 0) {
        setNotificationList(notificationListArray);
      }
    } catch (error: any) {
      console.log(error.message);
      let response: any;
      if ("indexedDB" in window && error.message == "Network Error") {
        //console.log("in indexedDB responseIndexedDB")
        response = await readAllData(
          "posts",
          `https://sprinngboot-event.herokuapp.com/eventRest/eventDetails/${APILIMIT}`
        );
        //console.log("in indexedDB responseIndexedDB",response)
        let notificationListArray = response.data.notifications.filter(
          (notification: any, index: any) => {
            return notification.usv_Notification_Text__c !== null;
          }
        );
        //console.log(notificationListArray);
        if (notificationListArray && notificationListArray.length > 0) {
          setNotificationList(notificationListArray);
        }
      }
    }
  };
  const handleShowMoreNotification = () => {
    fetchNotification(APISEEMORELIMIT);
    setNotificationSeeMore(false);
  };
  const getReqDate = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dayMonth = moment(reqDate).format("HH:MM | DDMMM ");
      return dayMonth;
    }
  };

  //FeedBack Pop Up
  const showFeedbackPopUp = async (event: any) => {
    event.stopPropagation();
    //let reqId = event.currentTarget.getAttribute('data-reqid');
    //setCurrentReqId(reqId);
    const feedbackUrl = `${process.env.REACT_APP_FETCH_FEEDBACK}`;
    const feedbackQuestionUrl = baseServiceUrl + feedbackUrl;
    let params: any = {
      servicerequestno: "00002314",
    };
    let serviceAccessibleData = await makePostRequest(
      feedbackQuestionUrl,
      new RequestParams(params)
    );

    // console.log('serviceAccessibleData serviceAccessibleData',serviceAccessibleData);
    setFeedBackOptionFiltered(serviceAccessibleData.data.lstQuestions);
    setopenfeedpack1Popup(true);
  };
  function closeFeedback1PopUp() {
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  }

  function handleChangeUserFeedback(currentSelction: any) {
    let reqId = "00002314";
    let starValue = currentSelction.value;
    let quesName = currentSelction.getAttribute("name");
    const indexOfQues = feedBackOptionFiltered.findIndex(
      (element: any, index: number) => {
        if (element.Questions === quesName) {
          return true;
        }
      }
    );
    // console.log(feedBackOptionFiltered,'feedBackOptionFiltered',indexOfQues);

    let otherQuesName =
      feedBackOptionFiltered[feedBackOptionFiltered.length - 1].Questions;

    let response = quesName == otherQuesName ? "" : starRating[starValue - 1];
    let copyOfcurrentFeedback = userfeedback.lstResponses
      ? userfeedback.lstResponses
      : [];
    // check if this question already exists
    let nonEmptyFeedback = copyOfcurrentFeedback.filter(
      (item) => item.Question !== ""
    );
    let filteredFeedback = nonEmptyFeedback.filter(
      (item) => item.Question !== quesName
    );

    filteredFeedback.push({
      Question: quesName,
      Response: quesName == otherQuesName ? starValue : response,
      OrderNo: indexOfQues.toString(),
    });

    const responseVal = filteredFeedback.find(
      ({ Response }) => Response.length > 0
    );

    if (responseVal !== undefined && responseVal.Response.length > 0) {
      setisSubmitFeedbackDisabled(false);
    } else {
      setisSubmitFeedbackDisabled(true);
    }

    setUserfeedback({
      servicerequestno: reqId,
      lstResponses: filteredFeedback,
    });
  }

  const sendFeedBack = async () => {
    const submitfeedbackUrl =
      baseServiceUrl + `${process.env.REACT_APP_SUBMIT_FEEDBACK}`;
    let serviceSubmitFeedback = await makePostRequest(
      submitfeedbackUrl,
      new RequestParams(userfeedback)
    );
    //  console.log('serviceSubmitFeedback',serviceSubmitFeedback);

    if (serviceSubmitFeedback.data.status == "Success") {
      setopenfeedpack2Popup(true);
      setopenfeedpack1Popup(false);
      //   fetchHomePageData();
    } else {
      setisSubmitFeedbackErr(true);
    }
  };
  function closeFeedback2PopUp() {
    setopenfeedpack2Popup(false);
  }

  const closeSubmitFeedbackErr = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackErr(false);
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  };

  const setReadMoreStatus = async () => {
    let notificationStatusCheck = notificationList.map(
      (readStatusList: any) => {
        if (
          readStatusList.usv_Notification_Subject__c ===
          "Event Reminder Notification"
        ) {
          return {
            status: "read",
            replyId: readStatusList.replyId,
          };
        } else {
          return {
            status: readStatusList.readStatus,
            replyId: readStatusList.replyId,
          };
        }
      }
    );
    setReadUnreadStatus(notificationStatusCheck);
  };
  const handleCheckStatus = async (e: any, replyId: any) => {
    let notificationStatusCheck = [
      {
        status: "read",
        replyId: replyId,
      },
    ];
    setReadUnreadStatus(notificationStatusCheck);
    showFeedbackPopUp(e);
  };
  const handleViewStatus = async (e: any) => {
    history.push("/request");
    dispatch(requestActions.setrequestTabIndex(1));
  };
  const setReadUnreadStatus = async (notificationStatusData: any) => {
    try {
      await axios({
        url: "https://sprinngboot-event.herokuapp.com/eventRest/readStatus",
        method: "post",
        data: notificationStatusData,
      });
      checkIfNotificationUnread(notificationList);
      notificationSeeMore
        ? fetchNotification(APIFETCHLIMIT)
        : fetchNotification(APISEEMORELIMIT);
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfNotificationUnread = (notificationData: any) => {
    let notificationIconFlag = notificationData.every((list: any) => {
      return list.readStatus === "read";
    });
    setnotificationStatus(!notificationIconFlag);
  };

  //FeedbackPop Up End

  return (
    <div>
      <React.Fragment>
        <section className="notification-mainContainer-mobile mobileWrapperContentPages">
          <div className="notification-list mt-2">
            {notificationList &&
              notificationList.length > 0 &&
              notificationList.map((item: any, index: any) => {
                if (item.usv_Notification_Text__c !== null) {
                  switch (item.usv_Notification_Subject__c) {
                    case "Event Reminder Notification":
                      return (
                        <div key={index}>
                          <article
                            className={`notificationListContainer ${
                              item.readStatus == "read"
                                ? "notificationListContainerRead"
                                : ""
                            }`}
                          >
                            <section className="notificationLogo">
                              <div className="reqNotification orange mt-2">
                                <img src={flagLogo} alt="img"></img>
                              </div>
                            </section>
                            <section className="reqNotificationtText ms-3 mt-1">
                              <div
                                className="notificationText"
                                title={item.usv_Notification_Subject__c}
                              >
                                <label>{item.usv_Notification_Text__c}</label>
                                <div className="Date-Time-Details">
                                  {getReqDate(item.createdDate)}
                                </div>
                                {/* <label>{formatString(item.categoryText, 90)}</label> */}
                              </div>
                            </section>
                          </article>
                        </div>
                      );
                    case "Service Request Completed":
                      return (
                        <div key={index}>
                          <article
                            className={`notificationListContainer ${
                              item.readStatus == "read"
                                ? "notificationListContainerRead"
                                : null
                            }`}
                          >
                            <section className="notificationLogo">
                              <div className="reqNotification  mt-2">
                                <FeedbackIcon
                                  style={{ color: "#09577a", fontSize: "24px" }}
                                />
                              </div>
                            </section>
                            <section className="reqNotificationtText ms-3 mt-1">
                              <div
                                className="notificationText"
                                title={item.usv_Notification_Subject__c}
                              >
                                <label>{item.usv_Notification_Text__c}</label>
                                <div className="Date-Time-Details">
                                  {getReqDate(item.createdDate)}
                                </div>
                                {/* <label>{formatString(item.categoryText, 90)}</label> */}
                              </div>
                              {item.usv_Feedback_Recieved__c === "false" ? (
                                <div className="submitFeedback">
                                  <Button
                                    variant="contained"
                                    onClick={(e) =>
                                      handleCheckStatus(
                                        e,
                                        item.usv_Case_Number__c
                                      )
                                    }
                                    className="mt-2"
                                  >
                                    Submit Feedback
                                  </Button>
                                </div>
                              ) : null}
                            </section>
                          </article>
                        </div>
                      );
                    default:
                      return (
                        <div key={index}>
                          <article
                            className={`notificationListContainer ${
                              item.readStatus == "read"
                                ? "notificationListContainerRead"
                                : null
                            }`}
                          >
                            <section className="notificationLogo">
                              <div className="reqNotification orange mt-2">
                                <img src={flagLogo} alt="img"></img>
                              </div>
                            </section>
                            <section className="reqNotificationtText ms-3 mt-1">
                              <div
                                className="notificationText"
                                title={item.usv_Notification_Subject__c}
                              >
                                {/* <label>{formatString(item.categoryText, 90)}</label> */}
                                <label>{item.usv_Notification_Text__c}</label>
                                <div className="Date-Time-Details">
                                  {getReqDate(item.createdDate)}
                                </div>
                              </div>
                              <div className="submitFeedback">
                                <Button
                                  variant="contained"
                                  onClick={(e) => handleViewStatus(e)}
                                  className="mt-2"
                                >
                                  View Status
                                </Button>
                              </div>
                            </section>
                          </article>
                        </div>
                      );
                  }
                }
              })}
          </div>
        </section>
        {/* Feedback Dialog PopUp1 */}
        <Dialog className="feedBack-mainPopup-mobile" open={openfeedpack1Popup}>
          <DialogContent>
            <div className="closeFeedbackIcon">
              <Close
                className="closeFeedBackDialog"
                onClick={closeFeedback1PopUp}
              />
            </div>
            <section className="feedback-mainContainer row">
              <section className="leftContainer col-lg-6  col-md-6 col-12">
                <h2 id="simple-dialog-title">We value your feedback</h2>
                <div className="feedbackImg">
                  <img src={feedback1} alt="img"></img>
                </div>
              </section>
              <section className="rightContainer col-lg-6  col-md-6 col-12">
                <h4 id="simple-dialog-title">
                  How satisfied are you with USV service ?
                </h4>
                {feedBackOptionFiltered &&
                  feedBackOptionFiltered.map((fitem: any, findex: number) => {
                    return findex !== feedBackOptionFiltered.length - 1 ? (
                      // (!(fitem.inclDudes('Any other'))) ? (
                      <div>
                        <Box
                          component="fieldset"
                          mb={3}
                          borderColor="transparent"
                        >
                          <Typography component="legend">
                            {fitem.Questions}
                          </Typography>
                          <Rating
                            name={fitem.Questions}
                            value={starvalue}
                            onChange={(event: any) => {
                              handleChangeUserFeedback(event.target);
                            }}
                          />
                        </Box>
                      </div>
                    ) : (
                      <section className="otherFeedback mt-2">
                        <TextField
                          //id="standard-basic"
                          id="outlined-multiline-static"
                          placeholder={fitem.Questions}
                          name={fitem.Questions}
                          type="text"
                          multiline
                          rows={3}
                          variant="outlined"
                          value={starvalue}
                          onChange={(event: any) => {
                            handleChangeUserFeedback(event.target);
                          }}
                          autoComplete="off"
                          fullWidth
                        />
                      </section>
                    );
                  })}
                <Button
                  variant="contained"
                  disabled={isSubmitFeedbackDisabled}
                  className={
                    "sendFeedBackBtn " +
                    (isSubmitFeedbackDisabled
                      ? "disabledFeedbackBtn"
                      : "enablefeedBackBtn")
                  }
                  onClick={sendFeedBack}
                >
                  Send Feedback
                </Button>
              </section>
            </section>
          </DialogContent>
          <Snackbar
            open={isSubmitFeedbackErr}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
            onClose={closeSubmitFeedbackErr}
          >
            <Alert severity="warning">
              Error Occurred in Submitting The Request.\n Please email us on
              info@usv.in or call us on 1800-123-4567
            </Alert>
          </Snackbar>
        </Dialog>

        {/* FEEDBACK POPUP 2 */}
        <Dialog
          className="feedBack-ConfirmPopup"
          onClose={closeFeedback2PopUp}
          open={openfeedpack2Popup}
        >
          <div>
            <Close
              className="closeFeedBackDialog"
              onClick={closeFeedback2PopUp}
            />
          </div>
          <DialogContent>
            <div className="confirmFeedBackImg">
              <img src={feedback2} alt="confirmFeedback"></img>
            </div>
          </DialogContent>
          <h2 className="feedback-thankyou">Thank you!</h2>
          <DialogContentText className="confirmFeedBackText">
            Thank you for sharing your thoughts.
            <br /> We appreciate your feedback!
          </DialogContentText>
          <Button
            variant="contained"
            className="feedBackDoneBtn"
            onClick={() => {
              setopenfeedpack2Popup(false);
            }}
          >
            Done
          </Button>
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default MobileNotification;
