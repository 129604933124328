import ReactGA from "react-ga";

export const initGA = (trackingID:string) => {           
    ReactGA.initialize(trackingID,{ testMode: process.env.NODE_ENV === 'test' }); 
 }

export const PageViewGA = () => {  
    ReactGA.pageview(window.location.pathname +  
                     window.location.search); 
}

export const EventGA = (category:string, action:string, label:any) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };