import { openDB } from 'idb';

let dbPromise = openDB('posts-store',1,{
    upgrade(db:any){
        if(!db.objectStoreNames.contains('posts')){
            db.createObjectStore('posts',{autoincrement:false})
        }
    }
})

const writeData = (st:any, data:any,key:string) => {
    return dbPromise
    .then((db:any)=>{
        let tx = db.transaction(st,'readwrite');
        let store:any = tx.objectStore(st);
        store.put(data,key);
        return tx.done;
    })
}
const readAllData = (st:any,key:any) => {
    return dbPromise
    .then((db:any)=>{
        let tx = db.transaction(st,'readonly');
        let store:any = tx.objectStore(st);
        return store.get(key)
    })
}
const clearAllData = (st:any,key:any) => {
    return dbPromise
    .then((db:any)=>{
        let tx = db.transaction(st,'readwrite');
        let store:any = tx.objectStore(st);
        store.delete(key);
        return tx.done;
    })
}

export {dbPromise, writeData, readAllData, clearAllData}