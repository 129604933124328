import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./Logout.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { loginActions } from "../../store/login";
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { clearSessionData } from "../../utils/home_axios";

const Logout = () => {
  const history = useHistory();
  const open = useSelector((state: RootState) => state.login.showLogOutPopUp);

  const dispatch = useDispatch();

  const setOpen = (params: any) => {
    dispatch(loginActions.setshowLogoutPopUp(params));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlelogOut = () => {
    setOpen(false);
    clearSessionData();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="logOut_popup"
      >
        <DialogTitle id="alert-dialog-title">
          <ErrorOutlineIcon />{" "}
          <label>
            <strong>{"Log Out From USV Connect"}</strong>
          </label>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <label>
              {"Are you sure you would like to log out from your USV account?"}
            </label>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancelBtn">
            Cancel
          </Button>
          <Button onClick={handlelogOut} className="logoutBtn">
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Logout;
