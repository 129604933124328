import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./CreateNewRequest.scss";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import {
  makePostRequest,
  makeGetRequest,
} from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import { APiUrls } from "../../../HcpHelper";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import { shippingAddressAction } from "../../../store/shippingAddress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import shippingAddress from "../../../store/shippingAddress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
const PatientCareRequest = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [optionVal, setoptionVal] = useState<string[]>([]);
  const serviceName = "Patient Care";
  const dispatch = useDispatch();
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const [duplicateSampleFound, setduplicateSampleFound] = useState(false);
  const [listOfService, setListOfService] = useState([]);
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;
  const [submitSampleEnabled, setSubmitSampleEnabled] = useState(true);
  const [submitSafetyKitEnabled, setSubmitSafetyKitEnabled] = useState(true);
  const [submitExpertDocEnabled, setSubmitExpertDocEnabled] = useState(true);
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [checkedStatusErr, setCheckedStatusErr] = useState("UD");

  // const [safetykitcheckedStatus, setsafetykitCheckedStatus] = useState(false);
  // const [safetykitcheckedStatusErr, setsafetykitCheckedStatusErr] =
  //   useState("UD");
  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  // const serviceList = useSelector((state: RootState) => state.request.serviceInfo.subTabs);
  // const patientCareRequestTypeArray = serviceList.filter(function (item: any) {
  //     return item.subTab == 'Patient Care';
  // })

  const editDraft = useSelector((state: RootState) => state.request.editDraft);
  const caseNumber = useSelector(
    (state: RootState) => state.request.caseNumber
  );

  const shipToAddressRecordID = useSelector(
    (state: RootState) => state.request.shipToAddressRecordID
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 18;

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [brandName, setBrandName] = useState([]);
  // const brandName = [
  //   "Amlopin 10 mg",
  //   "Amlopin 2.5 mg",
  //   "Amlopin 5 mg",
  //   "Amlopin AT",
  //   "Amlopin M",
  //   "Amlopin M 5 & 25 mg",
  //   "Angispan TR 2.5 mg",
  //   "Angispan TR 6.5 mg",
  //   "Canzap Capsules 100 mg",
  //   "Canzap Capsules 200 mg",
  //   "Doxy-1 L-DR Forte Capsules",
  //   "D-Rise 60k Caps -4s",
  //   "D-Rise Caps",
  //   "D-Rise Sachet",
  //   "Duotrol",
  //   "Duotrol SR",
  //   "Ecosprin 150 mg",
  //   "Ecosprin 325 mg",
  //   "Ecosprin 75 mg",
  //   "Ecosprin AV 150 - 15's",
  //   "Ecosprin AV 150/20",
  //   "Ecosprin AV 75 - 15's",
  //   "Ecosprin AV 75/20",
  //   "Ecosprin Gold 10 - 15's",
  //   "Ecosprin Gold 10 Forte",
  //   "Ecosprin Gold 20 - 15's",
  //   "Ericlear Cream 30gm",
  //   "Erytop Gel 20gm",
  //   "Erytop Lotion 25ml (Clindamycin)",
  //   "Erytop-N Gel 15gm",
  //   "Folcure 5 Tablets",
  //   "Folcure 5D Tablets",
  //   "Folcure Tablets",
  //   "Glycomet 1 gm - 15's",
  //   "Glycomet 250 mg",
  //   "Glycomet 500 mg",
  //   "Glycomet 500 mg SR 20's",
  //   "Glycomet 850 mg",
  //   "Glycomet 850 mg SR",
  //   "Glycomet GP 0.5",
  //   "Glycomet GP 0.5 Forte",
  //   "Glycomet GP 1/850 Tab",
  //   "Glycomet GP 2/850 Tab",
  //   "Glycomet GP 3 Forte",
  //   "Glycomet GP1 - 15's",
  //   "Glycomet GP1 Forte",
  //   "Glycomet GP2 - 15's",
  //   "Glycomet GP2 Forte",
  //   "Glycomet GP3/850 Tab",
  //   "Glycomet GP4 Forte",
  //   "Glycomet Trio 1 /0.3",
  //   "Glycomet Trio 1 mg",
  //   "Glycomet Trio 2 mg",
  //   "Glycomet Trio 2/0.3",
  //   "Glycomet Trio Forte 1",
  //   "Glycomet Trio Forte 2",
  //   "Glynase 5 mg",
  //   "Glynase MF",
  //   "Glynase XL 10 Tabs",
  //   "Glynase XL 5 Tabs",
  //   "GP 0.5 mg",
  //   "GP 1 Tabs",
  //   "GP 2 Tabs",
  //   "GP 3 Tabs",
  //   "GP 4 Tabs",
  //   "Jalra 100 mg OD",
  //   "Jalra 50 mg",
  //   "Jalra M 50/1000 tabs",
  //   "Jalra M 50/500 tabs",
  //   "Jalra M/850 tabs",
  //   "Lipicard 160 mg",
  //   "Lipicard AV",
  //   "Lipicard Caps 200 mg",
  //   "Mashyne 60K Capsules",
  //   "Metzok 100 mg",
  //   "Metzok 12.5 mg",
  //   "Metzok 25 mg",
  //   "Metzok 50 mg",
  //   "Mychiro Tablets",
  //   "Olmetrack 20 mg",
  //   "Olmetrack 40 mg",
  //   "Olmetrack CT 12.5",
  //   "Olmetrack CT 6.25",
  //   "Olmetrack-AM",
  //   "Olmetrack-CT 40/12.5 Tab 10S",
  //   "Olmetrack-H",
  //   "Olmetrack-H 40",
  //   "Pevesca Plus",
  //   "Pioz 15 mg",
  //   "Pioz 30 mg",
  //   "Pioz 7.5 mg",
  //   "Pioz MF 15",
  //   "Pioz MFG 1",
  //   "Pioz MFG 2",
  //   "Ricosprin 15 mg",
  //   "Ricosprin 2.5 mg",
  //   "Ricosprin 20 mg",
  //   "Roseday 10 mg",
  //   "Roseday 20 mg",
  //   "Roseday 40 mg",
  //   "Roseday 5 mg",
  //   "Roseday A 10",
  //   "Roseday A 20",
  //   "Roseday EZ 10mg",
  //   "Roseday F",
  //   "Roseday F 10 mg",
  //   "Roseday F 20 mg",
  //   "Roseday Gold",
  //   "Roseday Gold 20",
  //   "SR Pevesca Plus 75",
  //   "Sulisent 100 mg Tabs",
  //   "Tazloc 20 mg",
  //   "Tazloc 40 mg",
  //   "Tazloc 80 H",
  //   "Tazloc 80 mg",
  //   "Tazloc AM",
  //   "Tazloc AM 80",
  //   "Tazloc Beta 25",
  //   "Tazloc Beta 50",
  //   "Tazloc CT 40 Mg",
  //   "Tazloc CT 6.25 Mg",
  //   "Tazloc CT 80 Mg",
  //   "Tazloc H",
  //   "Tazloc Trio 40 mg",
  //   "Tazloc Trio 80 mg",
  //   "The TriGlynase 2 Forte",
  //   "Ticaspan 60 mg tab 10's",
  //   "Ticaspan 90 mg tab 10's",
  //   "Triglycomet",
  //   "Triglynase 1",
  //   "Triglynase 2",
  //   "Triple A Cal - D Tablets",
  //   "Triple A Cal FD Tablets",
  //   "Triple A Cal Forte Tablets",
  //   "Triple A Cal Tablets",
  //   "Udapa 10 mg",
  //   "Udapa 5 mg",
  //   "Udapa M 1000 mg",
  //   "Udapa M 500 mg",
  //   "USV-Golite 15gm",
  //   "Vibact Capsules",
  //   "Vibact DS capsules",
  //   "Vobose 0.2 mg",
  //   "Vobose 0.3 mg",
  //   "Vobose M 0.2 mg",
  //   "Vobose M 0.3 mg",
  //   "Zabesta tabs",
  // ];
  const selectedPatientCareReqRef = useRef("");
  const selectedPatientCareReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );
  const setselectedPatientCareReq = (params: any) => {
    selectedPatientCareReqRef.current = params;
    dispatch(requestActions.setSelectedReq(params));
  };

  const setErrorToastMsg = (params: any) => {
    dispatch(requestActions.setErrorToastMsg(params));
  };

  const setSuccessToastMsg = (params: any) => {
    dispatch(requestActions.setSuccessToastMsg(params));
  };

  useEffect(() => {
    fetchListOfAccessbileServices();
    loadUSVBrandDropdownOptions();
    // resetPrevSelections(selectedPatientCareReq);
    resetPrevSelectedFieldValues(selectedPatientCareReq);
    resetFieldErrors(selectedPatientCareReq);
    resetSubmitButtons(selectedPatientCareReq);

    return () => {
      //  console.log("cleanup")
      debouncedChangeHandler();
    };
  }, []);

  function debounce(callback: any, delay: any) {
    //console.log("debounce")
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }
  const debouncedChangeHandler = () => {
    //console.log("debouncedChangeHandler")
    let text = selectedPatientCareReqRef.current; //(localStorage.getItem('selectedService'));
    debounce(() => saveAsDraftOnTabChange(text), 1000);
  };

  const saveAsDraftOnTabChange = (selectedPatientCareReq1: any) => {
    //console.log(selectedPatientCareReq1, 'selectedAcademicReq')
    switch (selectedPatientCareReq1) {
      case "Samples":
        submitSampleReq(true, true);
        break;
      case "Safety Kit":
        submitSafetyKitReq(true, true);
        break;
      case "Expert Doctor Connect":
        submitExpertDocConnectReq(true, true);
        break;
    }
  };

  const patientCareRequestTypeArray = listOfService.filter(function (
    item: any
  ) {
    return item.serviceName == "Patient Care";
  });

  patientCareRequestTypeArray.map((item1: any, index1: number) => {
    const sortedlist = item1.lstRequestType.sort((a: any, b: any) =>
      a.requestName < b.requestName ? -1 : a.requestName > b.requestName ? 1 : 0
    );
    item1.lstRequestType = sortedlist;
  });

  const fetchListOfAccessbileServices = async () => {
    const serviceUrl1 = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
    const srviceAccUrl1 = baseServiceUrl + serviceUrl1;
    let params: any = {
      speciality: userInfo ? userInfo.speciality : "",
      category: userInfo ? userInfo.category : "",
    };
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl1,
      new RequestParams(params)
    );
    setListOfService(serviceAccessibleData.data.lstServiceType);
  };

  // const usvBrandNameRef = useRef(new Array());
  // const usvBrandName = useSelector((state: RootState) => state.request.patientCareReq.samples.usvBrandName);
  // const setUsvBrandName = (params: any) => {
  //     usvBrandNameRef.current = params;
  //     dispatch(requestActions.setUsvBrandName(params));
  // };
  const usvBrandNameRef = useRef("");
  const usvBrandName: any = useSelector(
    (state: RootState) => state.request.patientCareReq.samples.usvBrandName
  );
  const setUsvBrandName = (params: any) => {
    usvBrandNameRef.current = params;
    dispatch(requestActions.setUsvBrandName(params));
  };

  const SKU = useSelector(
    (state: RootState) => state.request.patientCareReq.samples.sku
  );
  const setSKU = (params: any) => {
    dispatch(requestActions.setSKU(params));
  };

  const sampleTabletCountRef = useRef("");
  const sampleTabletCount = useSelector(
    (state: RootState) => state.request.patientCareReq.samples.noOfTabletMonth
  );
  const setSampleTabletCount = (params: any) => {
    sampleTabletCountRef.current = params;
    dispatch(requestActions.setSampleTabletCount(params));
  };

  // const sampleDescRef = useRef("");
  // const sampleDesc = useSelector(
  //   (state: RootState) => state.request.patientCareReq.samples.sampleDesc
  // );
  // const setSampleDesc = (params: any) => {
  //   sampleDescRef.current = params;
  //   dispatch(requestActions.setSampleDesc(params));
  // };

  const sampleShipToAddress = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.samples.sampleshipToAddress
  );

  const [usvBrandNameErr, setusvBrandNameErr] = useState("UD");
  // const [sampleDescErr, setsampleDescErr] = useState("UD");
  // const [skuErr, setSKUErr] = useState('UD');
  const [tabletCountErr, setTabletCountErr] = useState("UD");
  const [sampleshipToAddressErr, setsampleshipToAddressErr] = useState("UD");
  const [sampleReqClicked, setSampleReqClicked] = useState(false);

  useEffect(() => {
    if (sampleReqClicked) {
      validateSampleReq();
    }
  }, [sampleReqClicked]);

  useEffect(() => {
    if (
      usvBrandNameErr === "N" &&
      tabletCountErr === "N" &&
      sampleshipToAddressErr === "N" &&
      // sampleDescErr === "N" &&
      checkedStatusErr === "N"
    ) {
      submitSampleReq();
    } else if (
      usvBrandNameErr === "Y" ||
      tabletCountErr === "Y" ||
      sampleshipToAddressErr === "Y" ||
      // sampleDescErr === "Y" ||
      checkedStatusErr === "Y"
    ) {
      setSampleReqClicked(false);
    }
  }, [
    usvBrandNameErr,
    tabletCountErr,
    // sampleDescErr,
    sampleshipToAddressErr,
    checkedStatusErr,
  ]);

  const validateSampleReq = () => {
    setusvBrandNameErr(usvBrandName.length == 0 ? "Y" : "N");
    setTabletCountErr(sampleTabletCount.length ? "N" : "Y");
    // setsampleDescErr(sampleDesc.length === 0 ? "Y" : "N");
    setsampleshipToAddressErr(sampleShipToAddress.length === 0 ? "Y" : "N");
    setCheckedStatusErr(!checkedStatus ? "Y" : "N");
  };

  const submitSampleReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitSampleEnabled(false);
    let reqObject = {
      servicetype: serviceName,
      requesttype: unmounted
        ? selectedPatientCareReqRef.current
        : selectedPatientCareReq,
      legacycode: userInfo ? userInfo.legacycode : "",
      usvBrandName: optionVal.join(";"),
      // "sku": SKU,
      noOfMonth: unmounted ? sampleTabletCountRef.current : sampleTabletCount,
      PhysicianSampleNotForSale: checkedStatus,
      // description: unmounted ? sampleDescRef.current : sampleDesc,
      shipToAddress: sampleShipToAddress,
      status: fromSaveAsDraft ? "draft" : "Submitted",
      caseNumber: editDraft ? caseNumber : "",
      addressRecordId: shipToAddressRecordID,
    };
    if (
      fromSaveAsDraft &&
      (sampleTabletCountRef.current.length > 0 ||
        usvBrandNameRef.current.length > 0 ||
        sampleShipToAddress.length > 0)
    ) {
      sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
    } else if (sampleReqClicked) {
      sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
    }
    setSampleReqClicked(false);
    setSubmitSampleEnabled(true);
  };

  const [safetyReqClicked, setSafetyReqClicked] = useState(false);
  const nameOfSafetyGear = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.safetyKit.nameOfSafetyGear
  );

  // const safetyKitDescRef = useRef("");
  // const safetyKitDesc = useSelector(
  //   (state: RootState) =>
  //     state.request.patientCareReq.safetyKit.descriptionSafetyKit
  // );
  // const setSafetyKitDesc = (params: any) => {
  //   safetyKitDescRef.current = params;
  //   dispatch(requestActions.setSafetyKitDesc(params));
  // };

  const safetyKitshipToAddress = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.safetyKit.safetyKitShipToAddress
  );
  const safetyKitShipToAddressRef = useRef("");
  const setsafetyKitShipToAddress = (params: any) => {
    safetyKitShipToAddressRef.current = params;
    dispatch(requestActions.setsafetyKitShipToAddress(params));
  };

  // const [safetyKitDescErr, setSafetyKitDescErr] = useState("UD");
  const [safetyKitshipToAddressErr, setsafetyKitshipToAddressErr] =
    useState("UD");
  useEffect(() => {
    if (safetyReqClicked) validateSafetyKitReq();
  }, [safetyReqClicked]);

  useEffect(() => {
    if (safetyReqClicked && safetyKitshipToAddressErr === "N") {
      submitSafetyKitReq();
    } else if (safetyKitshipToAddressErr === "Y") {
      setSafetyReqClicked(false);
    }
  }, [safetyKitshipToAddressErr]);

  const validateSafetyKitReq = () => {
    // setSafetyKitDescErr(safetyKitDesc.length == 0 ? "Y" : "N");
    setsafetyKitshipToAddressErr(
      safetyKitshipToAddress.length == 0 ? "Y" : "N"
    );
  };

  const submitSafetyKitReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitSafetyKitEnabled(false);
    try {
      //if (safetyReqClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPatientCareReqRef.current
          : selectedPatientCareReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        nameOfSafetyGear: nameOfSafetyGear,
        // description: unmounted ? safetyKitDescRef.current : safetyKitDesc,
        shipToAddress: unmounted
          ? safetyKitShipToAddressRef.current
          : safetyKitshipToAddress,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
        addressRecordId: shipToAddressRecordID,
      };
      if (fromSaveAsDraft) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (safetyReqClicked) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSafetyReqClicked(false);
      setSubmitSafetyKitEnabled(true);
    }
  };

  const expertDocConnectSpecialityRef = useRef("");
  const expertDocConnectSpeciality = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.expertDoctorConnect.specialty
  );
  const setExpertDocConnectSpeciality = (params: any) => {
    expertDocConnectSpecialityRef.current = params;
    dispatch(requestActions.setExpertDocConnectSpeciality(params));
  };

  const expertDocConnectBriefDescRef = useRef("");
  const expertDocConnectBriefDesc = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.expertDoctorConnect.briefCaseDesc
  );
  const setExpertDocConnectBriefDesc = (params: any) => {
    expertDocConnectBriefDescRef.current = params;
    dispatch(requestActions.setExpertDocConnectBriefDesc(params));
  };

  const expertDocConnectDescRef = useRef("");
  const expertDocConnectDesc = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.expertDoctorConnect.descriptionEDC
  );
  const setExpertDocConnectDesc = (params: any) => {
    expertDocConnectDescRef.current = params;
    dispatch(requestActions.setExpertDocConnectDesc(params));
  };

  const [expertDocConnectDescErr, setExpertDocConnectDescErr] = useState("UD");
  const [expertDocConnectBriefDescErr, setExpertDocConnectBriefDescErr] =
    useState("UD");
  const [expertDocConnectSpecialityErr, setExpertDocConnectSpecialityErr] =
    useState("UD");
  const [expertConnectReqClicked, setExpertConnectReqClicked] = useState(false);

  useEffect(() => {
    if (expertConnectReqClicked) {
      validateExpertDocConnectReq();
    }
  }, [expertConnectReqClicked]);

  useEffect(() => {
    if (
      expertDocConnectDescErr === "N" &&
      expertDocConnectBriefDescErr === "N" &&
      expertDocConnectSpecialityErr === "N"
    ) {
      submitExpertDocConnectReq();
    } else if (
      expertDocConnectDescErr === "Y" ||
      expertDocConnectBriefDescErr === "Y" ||
      expertDocConnectSpecialityErr === "Y"
    ) {
      setExpertConnectReqClicked(false);
    }
  }, [
    expertDocConnectDescErr,
    expertDocConnectBriefDescErr,
    expertDocConnectSpecialityErr,
  ]);

  const validateExpertDocConnectReq = () => {
    setExpertDocConnectDescErr(
      expertDocConnectDesc.trim().length == 0 ? "Y" : "N"
    );
    setExpertDocConnectBriefDescErr(
      expertDocConnectBriefDesc.trim().length == 0 ? "Y" : "N"
    );
    setExpertDocConnectSpecialityErr(
      expertDocConnectSpeciality.length == 0 ? "Y" : "N"
    );
  };

  const submitExpertDocConnectReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitExpertDocEnabled(false);

    //if (expertConnectReqClicked || fromSaveAsDraft) {
    let reqObject = {
      servicetype: serviceName,
      requesttype: unmounted
        ? selectedPatientCareReqRef.current
        : selectedPatientCareReq,
      legacycode: userInfo ? userInfo.legacycode : "",
      speciality: unmounted
        ? expertDocConnectSpecialityRef.current
        : expertDocConnectSpeciality,
      description: unmounted
        ? expertDocConnectDescRef.current
        : expertDocConnectDesc,
      briefCaseDesc: unmounted
        ? expertDocConnectBriefDescRef.current
        : expertDocConnectBriefDesc,
      status: fromSaveAsDraft ? "draft" : "Submitted",
      caseNumber: editDraft ? caseNumber : "",
    };
    if (
      fromSaveAsDraft &&
      (expertDocConnectBriefDescRef.current.length > 0 ||
        expertDocConnectDescRef.current.length > 0 ||
        expertDocConnectSpecialityRef.current.length > 0)
    ) {
      sendReqObjectForNewReq(reqObject, unmounted);
    } else if (expertConnectReqClicked) {
      sendReqObjectForNewReq(reqObject, unmounted);
    }
    setExpertConnectReqClicked(false);
    setSubmitExpertDocEnabled(true);
  };

  const sendReqObjectForNewReq = async (
    reqObject: any,
    unmounted?: boolean,
    saveAsDraft?: boolean
  ) => {
    if (!editDraft) {
      delete (reqObject as any).caseNumber;
    }
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      // console.log(output.data);
      dispatch(requestActions.seteditDraft(false));
      if (output.data.status == "failure") {
        if (!unmounted) {
          setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
          // resetPrevSelectionsOnErr(selectedPatientCareReq);
          //resetPrevSelectedFieldValues(selectedPatientCareReq);
          resetFieldErrors(selectedPatientCareReq);
        }
      } else {
        if (!unmounted) {
          // if (
          //   selectedPatientCareReq == "Samples" &&
          //   output.data.samplerequestmessage == "RBM will connect  you soon" &&
          //   !saveAsDraft
          // ) {
          //   setduplicateSampleFound(true);
          // } else
          {
            setSuccessToastMsg({
              isSuccess: true,
              newReqId: output.data.requestnumber,
            });
          }

          resetPrevSelectedFieldValues(selectedPatientCareReq);
          resetFieldErrors(selectedPatientCareReq);
        }
        if (
          caseNumber !== undefined &&
          caseNumber.trim() == "" &&
          (saveAsDraft || unmounted)
        ) {
          setbadgeCount(badgeCount + 1);
        }
      }
      //resetSubmitButtons(selectedPatientCareReq);
    } catch (e) {
      console.log("send Req Msg");
    }
    resetSubmitButtons(selectedPatientCareReq);
  };

  function resetPrevSelectedFieldValues(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Samples":
            setUsvBrandName([]);
            setSKU("");
            setSampleTabletCount("");
            // setSampleDesc("");
            setoptionVal([]);
            setCheckedStatus(false);
            setCheckedStatus(false);
            dispatch(requestActions.setSampleShipToAddress(""));

            // sampleDescRef.current = "";
            usvBrandNameRef.current = "";
            break;

          case "Safety Kit":
            // setSafetyKitDesc("");
            setsafetyKitShipToAddress("");

            // safetyKitDescRef.current = "";
            safetyKitShipToAddressRef.current = "";
            break;

          case "Expert Doctor Connect":
            setExpertDocConnectSpeciality("");
            setExpertDocConnectBriefDesc("");
            setExpertDocConnectDesc("");

            expertDocConnectSpecialityRef.current = "";
            expertDocConnectDescRef.current = "";
            expertDocConnectBriefDescRef.current = "";
            break;
        }
      })();
    }
  }

  function resetFieldErrors(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Samples":
            setusvBrandNameErr("UD");
            // setSKUErr('UD');
            setTabletCountErr("UD");
            // setsampleDescErr("UD");
            setCheckedStatusErr("UD");
            setsampleshipToAddressErr("UD");
            break;

          case "Safety Kit":
            // setSafetyKitDescErr("UD");
            setsafetyKitshipToAddressErr("UD");
            break;

          case "Expert Doctor Connect":
            setExpertDocConnectSpecialityErr("UD");
            setExpertDocConnectBriefDescErr("UD");
            setExpertDocConnectDescErr("UD");
            break;
        }
      })();
    }
  }

  function resetSubmitButtons(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Samples":
            setSubmitSampleEnabled(true);
            break;

          case "Safety Kit":
            setSubmitSafetyKitEnabled(true);
            break;

          case "Expert Doctor Connect":
            setSubmitExpertDocEnabled(true);
            break;
        }
      })();
    }
  }

  useEffect(() => {}, [selectedPatientCareReq]);

  const handleReqTypeChange = (clickedElement: any) => {
    let isSponsoredService =
      clickedElement.currentTarget.getAttribute("data-issponsored");
    dispatch(requestActions.seteditDraft(false));
    if (isSponsoredService == "true") {
      dispatch(
        requestActions.setSponsoredPopup1({
          showPopup: true,
          fromHome: false,
          clickedSponsoredReq: clickedElement.target.value,
          clickedSponsoredService: serviceName,
        })
      );
      setselectedPatientCareReq("");
    } else {
      setselectedPatientCareReq(clickedElement.target.value);
      resetPrevSelectedFieldValues(clickedElement.target.value);
      resetFieldErrors(clickedElement.target.value);
      if (clickedElement.target.value == "Samples") {
        loadUSVBrandDropdownOptions();
      }
    }
  };

  const loadUSVBrandDropdownOptions = async () => {
    const sampleOptionApi =
      baseServiceUrl + `${process.env.REACT_APP_SAMPLEOPTION}`;
    let brandOptionvalue = await makeGetRequest(sampleOptionApi);
    const arr = brandOptionvalue.data;
    // arr.length = 10;
    setBrandName(arr);
  };

  // const setSampleValues = (e: any) => {
  //   let arr: any = optionVal;
  //   if (optionVal.includes(e.currentTarget.getAttribute("data-productid"))) {
  //     arr = arr.filter(
  //       (obj: any) => obj != e.currentTarget.getAttribute("data-productid")
  //     );
  //   } else {
  //     arr.push(e.currentTarget.getAttribute("data-productid"));
  //   }

  //   setUsvBrandName(e.target.value);
  //   setoptionVal(arr);
  // };
  const [abc, setabc] = useState([]);

  const setSampleValues = (e: any, val: any) => {
    let arr: any = optionVal;
    let productIdArray =
      val &&
      val.map((item: any) => {
        return item.productid;
      });
    let uniqueArr: any = Array.from(new Set(productIdArray));
    const selectedDDVal =
      val &&
      val.map((item: any) => {
        return `${val[0].ProductName}(${val[0].Quantity} strips)`;
      });
    // if (optionVal.includes(selectedProductId)) {
    //   arr = arr.filter((obj: any) => obj != selectedProductId);
    // } else {
    //   arr.push(selectedProductId);
    // }
    setUsvBrandName(val);
    setabc(val);
    console.log(uniqueArr, "uniqueArr");
    setoptionVal(uniqueArr);
  };

  const setCheckedStatusForSamples = (checkboxStatus: boolean) => {
    setCheckedStatus(checkboxStatus);
  };

  function displayShippingAddress(params: any) {
    dispatch(shippingAddressAction.setshowShippingAddressPopup(params));
  }

  return (
    <section className="root-container container-fluid mt-3 reqCategoryContentContainer">
      <section className="userFilledEntity RequestSubtypeDD">
        <FormControl className="requestEntityContainer">
          <InputLabel id="demo-simple-select-required-label">
            Request Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedPatientCareReq}
            onChange={(e: any) => {
              handleReqTypeChange(e);
            }}
            className="selectReqDD-Container createReqDropdown selectRequestTypeBucket"
            MenuProps={MenuProps}
          >
            <MenuItem value="" disabled>
              Select Request
            </MenuItem>
            {patientCareRequestTypeArray.map((item: any, index: number) =>
              item.lstRequestType.map((item1: any, index1: number) => (
                <MenuItem
                  key={index1}
                  value={item1.requestName}
                  data-issponsored={item1.sponsored}
                >
                  {item1.requestName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </section>

      {(() => {
        switch (selectedPatientCareReq) {
          case "Samples":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity sampleBrandNameDropdown">
                      <FormControl>
                        <Autocomplete
                          // open={true}
                          disablePortal
                          multiple
                          className={
                            (usvBrandName && usvBrandName.length > 0
                              ? "hidePaddingAutoCompReq "
                              : "") + "createReqDropdown"
                          }
                          limitTags={1}
                          id="multiple-limit-tags"
                          value={usvBrandName}
                          onChange={(e: any, val: any) => {
                            setSampleValues(e, val);
                          }}
                          getOptionDisabled={(option: any) => {
                            const a = optionVal.length > 4 ? true : false;
                            return a;
                          }}
                          options={brandName}
                          disableCloseOnSelect
                          getOptionLabel={(option: any) =>
                            `${option.ProductName}(${option.Quantity} strips)`
                          }
                          renderOption={(option: any, { selected }) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                              data-productid={option.productid}
                            >
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{
                                  marginRight: 8,
                                  width: 20,
                                  paddingTop: 2,
                                }}
                                checked={selected}
                              />
                              {`${option.ProductName}(${option.Quantity} strips)`}
                            </div>
                          )}
                          // style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              required
                              error={usvBrandNameErr === "Y" ? true : false}
                              {...params}
                              label=" USV Brand Name (max 5 selection allowed)"
                              helperText={
                                usvBrandNameErr === "Y"
                                  ? "Please enter a valid value"
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </section>

                    {/* <section className="userFilledEntity" >
                                        <FormControl className="requestEntityContainer" required error={skuErr === 'Y' ? true : false}>
                                            <InputLabel id="demo-simple-select-required-label">SKU</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={SKU}
                                                onChange={(e: any) => setSKU(e.target.value)}
                                                className="selectReqDD-Container createReqDropdown"
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="" disabled>Select SKU </MenuItem>
                                                <MenuItem value='Censpram Plus' key='sku1'>Censpram Plus </MenuItem>
                                                <MenuItem value='Censpram 10 tabs' key='sku2'>Censpram 10 tabs </MenuItem>
                                            </Select>
                                            <FormHelperText>{skuErr === 'Y' ? "Please enter a valid value" : ""}</FormHelperText>
                                        </FormControl>
                                    </section> */}

                    {/* <section className="userFilledEntity disclaimerContainer addressCheckbox mt-5" >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className='sampleDisclaimerText'
                                                        value={addressCheckedStatus}
                                                        size="small"
                                                        checked={addressCheckedStatus}
                                                        onChange={(e: any) => setCheckedStatusForAddress(e.target.checked)}
                                                    />
                                                }
                                                label={`Same as present address`}
                                            />
                                        </section> */}
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={tabletCountErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Number Of Months
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={sampleTabletCount}
                          onChange={(e: any) =>
                            setSampleTabletCount(e.target.value)
                          }
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Number of Month
                          </MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                        </Select>
                        <FormHelperText>
                          {tabletCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={sampleshipToAddressErr === "Y"}
                        helperText={
                          sampleshipToAddressErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Ship to Address"
                        type="text"
                        title={sampleShipToAddress}
                        value={sampleShipToAddress}
                        onClick={() => displayShippingAddress(true)}
                      />
                    </section>
                    {/* <section className="userFilledEntity">
                      <TextField
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={sampleDesc}
                        onChange={(e: any) => setSampleDesc(e.target.value)}
                        error={sampleDescErr === "Y"}
                        helperText={
                          sampleDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                      />
                    </section> */}

                    <section className="userFilledEntity disclaimerContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="sampleDisclaimerText"
                            required
                            value={checkedStatus}
                            size="small"
                            checked={checkedStatus}
                            onChange={(e: any) =>
                              setCheckedStatusForSamples(e.target.checked)
                            }
                          />
                        }
                        label={`Please send sample for my patient. Physician Sample. Not for sale`}
                      />
                      <label className="mandatoryCheckbox">*</label>
                      <FormHelperText className="disclaimerNote">
                        {checkedStatusErr == "Y"
                          ? "Please select the checkbox"
                          : ""}
                      </FormHelperText>
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setSampleReqClicked(true);
                    }}
                    disabled={submitSampleEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitSampleReq(true);
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Safety Kit":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl className="requestEntityContainer" required>
                        <InputLabel id="demo-simple-select-required-label">
                          Name of Safety Kit
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={nameOfSafetyGear}
                          // onChange={(e: any) => setSafetyKit(e.target.value)}
                          className="selectReqDD-Container createReqDropdown"
                          title={nameOfSafetyGear}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Safety Kit
                          </MenuItem>
                          <MenuItem value="N95 mask, Hand Sanitizer, Three Ply Mask, Surface Disinfectant">
                            N95 mask, Hand Sanitizer, Three Ply Mask, Surface
                            Disinfectant
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </section>
                  </div>

                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={safetyKitshipToAddressErr === "Y"}
                        helperText={
                          safetyKitshipToAddressErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Ship to Address"
                        type="text"
                        title={safetyKitshipToAddress}
                        value={safetyKitshipToAddress}
                        // multiline
                        // rows={1}
                        // rowsMax={5}
                        // onChange={(e: any) =>
                        //   setsafetyKitShipToAddress(e.target.value)
                        // }
                        onClick={() => displayShippingAddress(true)}
                      />
                    </section>

                    {/* <section className="userFilledEntity">
                      <TextField
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={safetyKitDesc}
                        onChange={(e: any) => setSafetyKitDesc(e.target.value)}
                        error={safetyKitDescErr === "Y"}
                        helperText={
                          safetyKitDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                      />
                    </section>
                  */}
                  </div>
                </div>

                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={(e: any) => setSafetyReqClicked(true)}
                    disabled={submitSafetyKitEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitSafetyKitReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitSafetyKitReq(true);
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Expert Doctor Connect":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          expertDocConnectSpecialityErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Speciality
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={expertDocConnectSpeciality}
                          onChange={(e: any) =>
                            setExpertDocConnectSpeciality(e.target.value)
                          }
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Speciality
                          </MenuItem>
                          <MenuItem value="Clinical Cardiologist">
                            Clinical Cardiologist
                          </MenuItem>
                          <MenuItem value="Interventional Cardiologist">
                            Interventional Cardiologist
                          </MenuItem>
                          <MenuItem value="Endocrinologist">
                            Endocrinologist
                          </MenuItem>
                          <MenuItem value="Diabetologist">
                            Diabetologist
                          </MenuItem>
                          <MenuItem value="Gynaecologist">
                            Gynaecologist
                          </MenuItem>
                          <MenuItem value="Infertility Specialist">
                            Infertility Specialist
                          </MenuItem>
                          <MenuItem value="Dermatologist">
                            Dermatologist
                          </MenuItem>
                          <MenuItem value="Cosmetologist">
                            Cosmetologist
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {expertDocConnectSpecialityErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <TextField
                        required
                        autoComplete="off"
                        error={expertDocConnectBriefDescErr === "Y"}
                        helperText={
                          expertDocConnectBriefDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Describe The Case Briefly"
                        type="text"
                        value={expertDocConnectBriefDesc}
                        onChange={(e: any) =>
                          setExpertDocConnectBriefDesc(e.target.value)
                        }
                      />
                    </section>

                    <section className="userFilledEntity">
                      <TextField
                        id="standard-basic"
                        required
                        autoComplete="off"
                        error={expertDocConnectDescErr === "Y"}
                        helperText={
                          expertDocConnectDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        label="Description"
                        type="text"
                        value={expertDocConnectDesc}
                        onChange={(e: any) =>
                          setExpertDocConnectDesc(e.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setExpertConnectReqClicked(true);
                    }}
                    disabled={submitExpertDocEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitExpertDocConnectReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitExpertDocConnectReq(true);
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
        }
      })()}

      <Dialog
        open={duplicateSampleFound}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="MobileHomePage-popover"
      >
        <section className="sponsoredPopupDetails sponsoredPopup2">
          <h5>Thank you for your interest</h5>
          <div>
            <p className="sampleTextMsg">
              {"USV representative will connect with you shortly"}
            </p>
          </div>
        </section>
        <DialogActions className="btnContainer_sponsored">
          <Button
            onClick={() => setduplicateSampleFound(false)}
            className="confirmBtn"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default PatientCareRequest;
