import { useState, ChangeEvent, useEffect } from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CreateReq from "./CreateRequest";
import ViewRequest from "./ViewRequest";
import DraftRequest from "./DraftRequestComponent/DraftRequest";
import Badge from "@material-ui/core/Badge";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Request.scss";
import "./mobileRequest.scss";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { requestActions } from "../../store/request";
import { useLocation } from "react-router-dom";
import {
  homeEvents,
  homeRequests,
  homeServices,
  getHomeJsonData,
  fetchDraftDetails,
} from "../../utils/home_axios";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../..//Class-Interfaces/httpRequestParams";
import IntersectionLeft from "../../assets/images/Intersection2.svg";
import IntersectionRight from "../../assets/images/Intersection1.svg";
import { readAllData } from "../../indexdb_utils";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { PageViewGA, EventGA } from "../../utils/googleAnalyticsTracking";
import SupportTicket from "./SupportTicket/SupportTicket";
import ShippingAddressPopup from "./ShippingAddressPopup/ShippingAddressPopup";
function TabPanel(props: any) {
  const { children, value, index } = props;
  return <div role="tabpanel">{value === index && <Box>{children}</Box>}</div>;
}

export default function Request() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [serviceName, setServiceName] = useState("");
  const [masterData, setMasterData] = useState([]);
  // const [showReqSubmitStatus, setShowReqSubmitStatus] = useState(false);
  const [listOfReqType, setListOfReqType] = useState<any[]>([]);
  const location = useLocation();
  const homeSelection = location.state as any;
  //console.log(homeSelection);
  const isSelectedFromHome = homeSelection ? homeSelection.fromHome : "";
  const isSelectedFromProfile = homeSelection
    ? homeSelection.fromProfilePopup
    : "";
  const isSelectedFromNotification = homeSelection
    ? homeSelection.fromNotification
    : "";

  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const requestTabs = useSelector(
    (state: RootState) => state.request.requestTab
  );
  const requestTabIndex = useSelector(
    (state: RootState) => state.request.requestTabIndex
  );

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const fetchServiceApi = baseServiceUrl + serviceUrl;

  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const viewDetailScreenClicked = useSelector(
    (state: RootState) => state.request.viewDetailScreenClicked
  );

  const fetchDraftData = async () => {
    try {
      let response = await fetchDraftDetails();
      let lstServiceRequests = response.data.lstServiceRequest;
      // console.log(lstServiceRequests);
      let draftDataCount =
        lstServiceRequests !== null && lstServiceRequests !== undefined
          ? lstServiceRequests.length
          : 0;
      setbadgeCount(draftDataCount);
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedReq = (params: any) => {
    dispatch(requestActions.setSelectedReq(params));
  };

  const selectService = (event: any, serviceNameParam: string) => {
    setServiceName(serviceNameParam);
  };

  const setshowBucketLoader = (params: any) => {
    dispatch(requestActions.setshowBucketLoader(params));
  };

  const [value, setValue] = useState(0);

  const handleTabs = (event: ChangeEvent<{}>, val: number) => {
    event.preventDefault();
    setValue(val);
    setMobileTabs(val);
    if (val !== 0) setServiceName(""); //reset selected service in tab navigation
    setSelectedReq("");
    history.replace({
      pathname: "/request",
      state: { selectedServiceHome: "", selectedReqHome: "" },
    });
  };

  const setMobileTabs = (tabVlaue: any) => {
    if (matchMedia("screen and (max-width: 1024px)").matches) {
      tabVlaue === 0
        ? dispatch(requestActions.setrequestTab(true))
        : dispatch(requestActions.setrequestTab(false));
      dispatch(requestActions.setrequestTabIndex(tabVlaue));
    }
  };

  const handleTabsOnDraftClick = (val: number) => {
    setValue(val);
  };

  const theme = useTheme();

  const handleChangeIndex = (index: number, e?: any) => {
    setValue(index);
  };

  useEffect(() => {
    PageViewGA();
    if (
      isSelectedFromHome ||
      isSelectedFromProfile ||
      isSelectedFromNotification
    ) {
      handleChangeIndex(1);
    }
    if (matchMedia("screen and (max-width: 1024px)").matches) {
      requestTabIndex === 0
        ? handleChangeIndex(0)
        : requestTabIndex === 1
        ? handleChangeIndex(1)
        : requestTabIndex === 3
        ? handleChangeIndex(3)
        : handleChangeIndex(2);
    }
  }, [
    isSelectedFromHome,
    isSelectedFromProfile,
    isSelectedFromNotification,
    requestTabIndex,
  ]);
  const isSitedown = `${process.env.REACT_APP_SITEDOWN}`;
  useEffect(() => {
    setshowBucketLoader("true");
    if (
      localStorage.getItem("authHeader") &&
      localStorage.getItem("authHeader") != "" &&
      isSitedown == "false"
    ) {
      setTimeout(() => {
        fetchHomePageData();
        fetchDraftData();
      }, 2000);
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {}, [masterData]);

  const fetchHomePageData = async () => {
    let homeApiData = await homeServices();
    let arr = Array<string>();
    if (homeApiData !== null) {
      setMasterData(homeApiData.data.lstServiceType);
      homeApiData.data.lstServiceType.map((item: any, index: number) => {
        arr.push(item.serviceName);
      });
    }
    setListOfReqType(arr);
    setshowBucketLoader("false");
  };

  const showShippingAddressPopup = useSelector(
    (state: RootState) => state.shippingAddress.showShippingAddressPopup
  );

  return (
    <div className="req-mainContainer mobileWrapperContentPages">
      {/* <div >
                <Snackbar
                    open={showReqSubmitStatus}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    message="Error Occured"
                />
            </div> */}
      {
        <>
          {viewDetailScreenClicked ? null : (
            <AppBar position="static" color="default">
              <Tabs value={value} centered={false} onChange={handleTabs}>
                <Tab
                  label="Raise a Request"
                  className={`${
                    requestTabs ? "showRequestTab" : "hideRequestTab"
                  }`}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="My Requests"
                  className={`${
                    requestTabs
                      ? "hideRequestTab"
                      : requestTabIndex == 3
                      ? "hideRequestTab"
                      : "showRequestTab"
                  }`}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Draft Requests"
                  className={`${
                    requestTabs
                      ? "hideRequestTab"
                      : requestTabIndex == 3
                      ? "hideRequestTab"
                      : "showRequestTab"
                  }`}
                  style={{ textTransform: "none" }}
                >
                  {" "}
                </Tab>

                {/* {badgeCount !== "0" ? (
                  <Badge
                    badgeContent={badgeCount}
                    className={`${
                      requestTabs ? "hideRequestTab" : "showRequestTab"
                    }`}
                    color="error"
                  ></Badge>
                ) : (
                  <></>
                )} */}
                <Tab
                  label="Support Tickets"
                  className={`${
                    requestTabs
                      ? "hideRequestTab"
                      : requestTabIndex == 3
                      ? "showRequestTab"
                      : " hideRequestTab"
                  }`}
                  style={{ textTransform: "none" }}
                />
                {badgeCount !== "0" ? (
                  <Badge
                    badgeContent={badgeCount}
                    className={`draftBadgeCount ${
                      requestTabs
                        ? " hideRequestTab"
                        : requestTabIndex == 3
                        ? "hideRequestTab"
                        : "showRequestTab"
                    }`}
                    color="error"
                  ></Badge>
                ) : null}
              </Tabs>
            </AppBar>
          )}
          {mobileScreen() ? (
            <div>
              <TabPanel value={value} index={0} classes={"createReqTabPanel"}>
                {masterData ? (
                  <CreateReq
                    masterData={masterData}
                    serviceName={serviceName}
                    selectService={selectService}
                    listOfReqType={listOfReqType}
                  ></CreateReq>
                ) : null}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {" "}
                <ViewRequest></ViewRequest>{" "}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {" "}
                <DraftRequest handleTabs={handleTabsOnDraftClick} />{" "}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <SupportTicket></SupportTicket>{" "}
              </TabPanel>
            </div>
          ) : (
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={(e) => {
                handleChangeIndex(e);
              }}
            >
              <TabPanel value={value} index={0} classes={"createReqTabPanel"}>
                {masterData ? (
                  <CreateReq
                    masterData={masterData}
                    serviceName={serviceName}
                    selectService={selectService}
                    listOfReqType={listOfReqType}
                  ></CreateReq>
                ) : null}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {" "}
                <ViewRequest></ViewRequest>{" "}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {" "}
                <DraftRequest handleTabs={handleTabsOnDraftClick} />{" "}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <SupportTicket />
              </TabPanel>
            </SwipeableViews>
          )}
        </>
      }

      {mobileScreen() ? null : (
        <>
          <div className="right-intersection">
            <img src={IntersectionRight} alt="rightBGImage" />
          </div>
          <div className="left-intersection">
            <img src={IntersectionLeft} alt="leftBGImage" />
          </div>
        </>
      )}
      {showShippingAddressPopup ? <ShippingAddressPopup /> : null}
    </div>
  );
}
