import React, { Component, useState, useEffect } from "react";
import "./Disclaimer.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { useHistory } from "react-router-dom";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { PageViewGA } from "../../utils/googleAnalyticsTracking";
import { useLocation } from "react-router-dom";
const Disclaimer = () => {
  //   useEffect(() => {
  //     PageViewGA();
  //     if (
  //       localStorage.getItem("authHeader") &&
  //       localStorage.getItem("authHeader") != ""
  //     ) {
  //       // loadUSVBrandDropdownOptions();
  //     } else {
  //       history.push("/login");
  //     }
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="disclaimer-rootContainer">
      {mobileScreen() ? (
        <h3 className="mobile-FAQHeader"> Disclaimer</h3>
      ) : (
        <section className="titleSection">
          <section className="col-lg-6 col-md-6 col-12 headingPage">
            <h2>Disclaimer</h2>
          </section>
        </section>
      )}
      <div className="disclaimer-textContainer mt-4">
        <div>
          <h5>1. DISCLAIMERS AND LIMITATION OF LIABILITY</h5>
          <p>
            USV means USV Private Limited and any subsidiaries, affiliates,
            related companies, suppliers, licensors and partners and the
            officers, directors, employees, agents and representatives of each
            of them.
          </p>
          <ol type="a">
            <li>
              USV are providing You this platform to connect with other peer
              members, on an “as is” and “as available” basis, without warranty
              of any kind, express or implied, without limiting the foregoing,
              USV expressly disclaim any and all warranties and conditions of
              merchantability, title, accuracy and completeness, uninterrupted
              or error-free service, fitness for a particular purpose, quiet
              enjoyment, non-infringement, and any warranties arising out of
              course of dealing or trade usage.
            </li>
            <li>
              The content / video / presentation / article posted on this
              Platform is not own by USV and is for general information,
              experience shared by respective Peer members / Healthcare
              Professionals (HCPs) and educational purposes only and is not
              complete or exhaustive. The content / video / presentation /
              article on this Platform is not intended or implied to provide or
              substitute for medical or other professional health care advice,
              be used to assess health conditions, or used or relied upon for
              diagnosis or treatment. The content / video / presentation /
              article do not constitute an endorsement or an approval of any of
              the products, services, contents or opinions by USV. USV makes no
              representations as to accuracy, completeness, correctness,
              suitability or validity of any information / post contained in the
              content / video / presentation / article posted uploaded on this
              Platform and will not be liable for any losses, injuries or
              damages arising from its use.
            </li>
            <li>
              You understand and agree that any interactions and associated
              issues with others on the website / platform, is strictly between
              You and the Peer members / HCPs. You shall not hold USV
              responsible for any such interactions and associated issues. USV
              is not involved in the providing any healthcare or medical advice
              or diagnosis and hence is not responsible for any outcome between
              You and the other Peer members / HCPs You interact with.
            </li>
            <li>
              USV makes no promises with respect to, and expressly disclaims all
              liability for: (i) content posted by any user or third party; (ii)
              any third-party website / platform, third-party product, or
              third-party service listed on or accessible to you through this
              platform, including an integrated service provider or professional
              contributor; or (iii) unauthorized access, use or alteration of
              your content.
            </li>
            <li>
              USV makes no warranty that: (a) the USV platform will meet your
              requirements; (b) the USV platform will be uninterrupted, timely,
              secure, or error-free; (c) the results or information that you may
              obtain from the use of the USV platform, a professional
              contributor, or any other user will be accurate or reliable.
            </li>
            <li>
              You agree that to the maximum extent permitted by law, USV will
              not be liable to you under any theory of liability, without
              limiting the foregoing, you agree that, to the maximum extent
              permitted by law, USV specifically will not be liable for any
              indirect, incidental, consequential, special, or exemplary
              damages, loss of profits, business interruption, reputational
              harm, or loss of data (even if we have been advised of the
              possibility of such damages or such damages are foreseeable)
              arising out of or in any way connected with your use of, or
              inability to use, the USV platform.
            </li>
            <li>
              Your sole remedy for dissatisfaction with the USV platform is to
              stop using this platform
            </li>
          </ol>
        </div>

        <br />
        <div>
          <h5>2.Indemnification</h5>
          <p>
            You agree to release, indemnify, and defend USV from all third-party
            claims and costs (including reasonable attorneys’ fees) arising out
            of or related to: i) your use of the USV Platform, ii) Your Content,
            iii) Your post, iv) your conduct or interactions with other users of
            the USV Platform, or v) your breach of any part of this Agreement.
          </p>
        </div>
        <br />
        <div>
          <h5>3. Dispute Resolution</h5>
          <p>
            This Agreement and any action arising out of your use of the USV
            Platform will be governed by the laws of India and to the exclusive
            jurisdiction of Mumbai Courts.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
