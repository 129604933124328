

export class RequestParams {
  requestObject: any;
  uname?: string;
  mobilePhone?: string;
  email?: string;
  legacycode?:string;


  servicetype?: string;
  requesttype?:string;


  topicName?: string;
  talkDuration?:string;
  targetAudience?:string;
  meetingType?:string;
  status?:string;

  articleTitle?:string;
  journalName?:string;
  articleDesc?:string;


  digitalMeetingName?:string;
    digitalMeetingType?:string;
    participantCount?:string;
    loginRequirement?:string;
    digitalMeetingDateTime?:string;
    optionalRequirement?:string;
    digitalAudienceType?:string;
    digitalReqDesc?:string;


    trainingName?:string;
    trainingDesc?:string;
    trainingStaffCount?:string;


  constructor(valueParams: any) {
    if (valueParams !== undefined) {
      for (let item in valueParams) {
        this[item as keyof RequestParams] = valueParams[item]
      }
    }
  }

}