import { createSlice } from "@reduxjs/toolkit";

const initialRequestState = {
  errorToastMsg: {
    isError: false,
    errMsg: "",
  },
  selectedReq: "",
  errCreatingSponsoredService: {
    isError: false,
    errMsg: "",
  },
  showBucketLoader: "UD",
  successToastMsg: {
    isSuccess: false,
    newReqId: "",
  },
  userReq: {
    lstServiceRequest: [
      {
        createdon: "",
        closedon: "",
        inprogresson: "",
        requestnumber: "",
        requeststatus: "",
        requesttype: "",
        servicetype: "",
        feedbackgiven: false,
      },
    ],
  },

  serviceInfo: {
    tab: "SERVICES",
    subTabs: [
      {
        id: "",
        subTab: "",
        requestTypes: [
          {
            id: "",
            requestType: "",
          },
        ],
      },
    ],
  },

  serviceAccessList: [],

  editDraft: false,
  caseNumber: "",
  badgeCount: "0",
  requestTab: true,
  requestTabIndex: 0,
  otpFocusInput: false,

  showSponsoredPopup1: {
    showPopup: false,
    fromHome: false,
    clickedSponsoredReq: "",
    clickedSponsoredService: "",
  },
  showSponsoredPopup2: false,

  selectedServiceName: "",
  selectedSponsoredRequestName: "",

  viewDetailScreenClicked: false,
  mobileRequestSource: "",
  //request bucket --- start
  createAcademicRequest: {
    slidesPresentation: {
      topicName: "",
      duration: "",
      // topicDesc:'',
      requesttype: "",
      targetAudience: Array<string>(),
      meetingType: "",
      specialComments: "",
      otherTargetAudience: "",
    },

    articleReference: {
      articleTitle: "",
      journalName: "",
      articleDesc: "",
    },
    digitalMeeting: {
      digitalMeetingName: "",
      digitalMeetingType: "",
      participantCount: "",
      loginRequirement: "",
      digitalMeetingDateTime: "",
      optionalRequirement: "",
      digitalAudienceType: "",
      digitalReqDesc: "",
      otherSpecificReq: "",
    },
    staffCertification: {
      trainingName: "",
      trainingDesc: "",
      trainingStaffCount: "",
    },
  },
  createPracticeEnhancmentRequest: {
    SkillDevelopmentprogramme: {
      sessionType: Array<string>(),
      skillDevelopmentDesc: "",
      programtype: "",
    },

    VirtualConsultation: {
      virtualConsultationDesc: "",
    },

    SocialMediaReq: {
      reqOfService: "",
      reqOfOtherService: "",
      socialMediaReqDesc: "",
    },
  },
  patientAwarenessSupportReq: {
    patientAwarenessMaterial: {
      selectTopicTherapy: "",
      inputType: "",
      noOfCopies: "",
      deliveryAddr: "",
      language: "",
      selectTopicOther: "",
      descriptionPatientAwareness: "",
      email: "",
      papersize: "",
    },
    patientScreening: {
      selectTopicTherapyPS: Array<string>(),
      selectTopicOtherPS: "",
      supportScreeningPS: Array<string>(),
      noOfInputPS: Array<string>(),
      patientEduMaterialPS: "",
      patientEduTypePS: "",
      deliveryAddrPS: "",
      languagePS: "",
      serviceDescPS: "",
      emailPS: "",
      copiesCountPS: "",
    },
    patientCounselling: {
      selectServicePC: "",
      noOfPatientMonthPC: "",
      mode: "",
      freqPerMonth: "",
      serviceDescPC: "",
      counsellingMode: "Virtual",
    },
    patientSupport: {
      programType: "",
      descriptionPatientSupport: "",
    },
  },
  patientCareReq: {
    samples: {
      usvBrandName: Array<string>(),
      sku: "",
      noOfTabletMonth: "",
      sampleDesc: "",
      sampleshipToAddress: "",
    },

    safetyKit: {
      nameOfSafetyGear:
        "N95 mask, Hand Sanitizer, Three Ply Mask, Surface Disinfectant",
      descriptionSafetyKit: "",
      safetyKitShipToAddress: "",
    },

    expertDoctorConnect: {
      specialty: "",
      descriptionEDC: "",
      briefCaseDesc: "",
    },
  },
  researchPublicationReq: {
    posterPreparation: {
      posterTitle: "",
      conferenceName: "",
      conferenceMonth: "",
      sampleDesc: "",
    },

    publicationMedicalWriting: {
      researchTitle: "",
      requiredResearchService: "",
      otherResearchService: "",
    },
  },

  shipToAddressRecordID: "",
  shippingAddressMaster: Array<string>(),
};

const requestSlice = createSlice({
  name: "request",
  initialState: initialRequestState,
  reducers: {
    setServiceInfo(state, action) {
      state.serviceInfo = action.payload;
    },
    setshowBucketLoader(state, action) {
      state.showBucketLoader = action.payload;
    },

    setServiceAccessList(state, action) {
      state.serviceAccessList = action.payload;
    },

    setUserRequestList(state, action) {
      state.userReq = action.payload;
    },

    setErrorToastMsg(state, action) {
      state.errorToastMsg = action.payload;
    },
    setSuccessToastMsg(state, action) {
      state.successToastMsg = action.payload;
    },
    setSelectedReq(state, action) {
      state.selectedReq = action.payload;
    },

    setTopicName(state, action) {
      state.createAcademicRequest.slidesPresentation.topicName = action.payload;
    },
    setDuration(state, action) {
      state.createAcademicRequest.slidesPresentation.duration = action.payload;
    },

    setTargetAudience(state, action) {
      state.createAcademicRequest.slidesPresentation.targetAudience =
        action.payload;
    },
    setMeetingType(state, action) {
      state.createAcademicRequest.slidesPresentation.meetingType =
        action.payload;
    },
    setSpecialComments(state, action) {
      state.createAcademicRequest.slidesPresentation.specialComments =
        action.payload;
    },
    setOtherTargetAudience(state, action) {
      state.createAcademicRequest.slidesPresentation.otherTargetAudience =
        action.payload;
    },
    setArticleTitle(state, action) {
      state.createAcademicRequest.articleReference.articleTitle =
        action.payload;
    },
    setJournalName(state, action) {
      state.createAcademicRequest.articleReference.journalName = action.payload;
    },
    setArticleDesc(state, action) {
      state.createAcademicRequest.articleReference.articleDesc = action.payload;
    },

    // DigitalMeeting--- start

    setDigitalMeetingName(state, action) {
      state.createAcademicRequest.digitalMeeting.digitalMeetingName =
        action.payload;
    },
    setDigitalMeetingType(state, action) {
      state.createAcademicRequest.digitalMeeting.digitalMeetingType =
        action.payload;
    },
    setParticipantCount(state, action) {
      state.createAcademicRequest.digitalMeeting.participantCount =
        action.payload;
    },

    setLoginRequirement(state, action) {
      state.createAcademicRequest.digitalMeeting.loginRequirement =
        action.payload;
    },
    setDigitalMeetingDateTime(state, action) {
      state.createAcademicRequest.digitalMeeting.digitalMeetingDateTime =
        action.payload;
    },
    setOptionalRequirement(state, action) {
      state.createAcademicRequest.digitalMeeting.optionalRequirement =
        action.payload;
    },
    setDigitalAudienceType(state, action) {
      state.createAcademicRequest.digitalMeeting.digitalAudienceType =
        action.payload;
    },
    setDigitalReqDesc(state, action) {
      state.createAcademicRequest.digitalMeeting.digitalReqDesc =
        action.payload;
    },
    setotherSpecificReq(state, action) {
      state.createAcademicRequest.digitalMeeting.otherSpecificReq =
        action.payload;
    },

    // DigitalMeeting--- end
    setTrainingName(state, action) {
      state.createAcademicRequest.staffCertification.trainingName =
        action.payload;
    },
    setTrainingDesc(state, action) {
      state.createAcademicRequest.staffCertification.trainingDesc =
        action.payload;
    },
    setTrainingStaffCount(state, action) {
      state.createAcademicRequest.staffCertification.trainingStaffCount =
        action.payload;
    },

    setSessionType(state, action) {
      state.createPracticeEnhancmentRequest.SkillDevelopmentprogramme.sessionType =
        action.payload;
    },
    setskillDevelopmentDesc(state, action) {
      state.createPracticeEnhancmentRequest.SkillDevelopmentprogramme.skillDevelopmentDesc =
        action.payload;
    },
    setskillDevelopmentProgramType(state, action) {
      state.createPracticeEnhancmentRequest.SkillDevelopmentprogramme.programtype =
        action.payload;
    },

    setVirtualConsultationDesc(state, action) {
      state.createPracticeEnhancmentRequest.VirtualConsultation.virtualConsultationDesc =
        action.payload;
    },

    setReqOfService(state, action) {
      state.createPracticeEnhancmentRequest.SocialMediaReq.reqOfService =
        action.payload;
    },
    setReqOfOtherService(state, action) {
      state.createPracticeEnhancmentRequest.SocialMediaReq.reqOfOtherService =
        action.payload;
    },
    setSocialMediaReqDesc(state, action) {
      state.createPracticeEnhancmentRequest.SocialMediaReq.socialMediaReqDesc =
        action.payload;
    },

    /******************** SAMPLE REQ */
    setUsvBrandName(state, action) {
      state.patientCareReq.samples.usvBrandName = action.payload;
    },
    setSKU(state, action) {
      state.patientCareReq.samples.sku = action.payload;
    },

    setSampleTabletCount(state, action) {
      state.patientCareReq.samples.noOfTabletMonth = action.payload;
    },
    setSampleDesc(state, action) {
      state.patientCareReq.samples.sampleDesc = action.payload;
    },
    setSampleShipToAddress(state, action) {
      state.patientCareReq.samples.sampleshipToAddress = action.payload;
    },
    setSafetyKitDesc(state, action) {
      state.patientCareReq.safetyKit.descriptionSafetyKit = action.payload;
    },
    setsafetyKitShipToAddress(state, action) {
      state.patientCareReq.safetyKit.safetyKitShipToAddress = action.payload;
    },
    setSafetyKitName(state, action) {
      state.patientCareReq.safetyKit.nameOfSafetyGear = action.payload;
    },

    setExpertDocConnectDesc(state, action) {
      state.patientCareReq.expertDoctorConnect.descriptionEDC = action.payload;
    },
    setExpertDocConnectBriefDesc(state, action) {
      state.patientCareReq.expertDoctorConnect.briefCaseDesc = action.payload;
    },
    setExpertDocConnectSpeciality(state, action) {
      state.patientCareReq.expertDoctorConnect.specialty = action.payload;
    },

    setProgramType(state, action) {
      state.patientAwarenessSupportReq.patientSupport.programType =
        action.payload;
    },
    setPatientSupportDesc(state, action) {
      state.patientAwarenessSupportReq.patientSupport.descriptionPatientSupport =
        action.payload;
    },

    setServiceForCounselling(state, action) {
      state.patientAwarenessSupportReq.patientCounselling.selectServicePC =
        action.payload;
    },

    setModeForCounselling(state, action) {
      state.patientAwarenessSupportReq.patientCounselling.counsellingMode =
        action.payload;
    },

    setCounsellingFreqPerMonth(state, action) {
      state.patientAwarenessSupportReq.patientCounselling.freqPerMonth =
        action.payload;
    },

    setCounsellingPatientCount(state, action) {
      state.patientAwarenessSupportReq.patientCounselling.noOfPatientMonthPC =
        action.payload;
    },
    setCounsellingDesc(state, action) {
      state.patientAwarenessSupportReq.patientCounselling.serviceDescPC =
        action.payload;
    },

    /***********PATIENT SCREENING************/
    setscreeningTherapyTopic(state, action) {
      state.patientAwarenessSupportReq.patientScreening.selectTopicTherapyPS =
        action.payload;
    },
    setScreeningTherapyTopicOther(state, action) {
      state.patientAwarenessSupportReq.patientScreening.selectTopicOtherPS =
        action.payload;
    },
    setScreeningSupport(state, action) {
      state.patientAwarenessSupportReq.patientScreening.supportScreeningPS =
        action.payload;
    },
    setScreeningInputCount(state, action) {
      state.patientAwarenessSupportReq.patientScreening.noOfInputPS =
        action.payload;
    },
    setEducationMaterialNeeded(state, action) {
      state.patientAwarenessSupportReq.patientScreening.patientEduMaterialPS =
        action.payload;
    },
    setpatientEducationType(state, action) {
      state.patientAwarenessSupportReq.patientScreening.patientEduTypePS =
        action.payload;
    },

    setScreeningLanguage(state, action) {
      state.patientAwarenessSupportReq.patientScreening.languagePS =
        action.payload;
    },

    setScreeningDeliveryAddress(state, action) {
      state.patientAwarenessSupportReq.patientScreening.deliveryAddrPS =
        action.payload;
    },

    setScreeningCopiesCount(state, action) {
      state.patientAwarenessSupportReq.patientScreening.copiesCountPS =
        action.payload;
    },

    setScreeningDesc(state, action) {
      state.patientAwarenessSupportReq.patientScreening.serviceDescPS =
        action.payload;
    },

    setScreeningEmail(state, action) {
      state.patientAwarenessSupportReq.patientScreening.emailPS =
        action.payload;
    },

    setTherapyTopicSelection(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.selectTopicTherapy =
        action.payload;
    },
    setOtherTherapyTopic(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.selectTopicOther =
        action.payload;
    },
    setPaperSize(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.papersize =
        action.payload;
    },
    setpatientAwarenessDeliveryAddress(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.deliveryAddr =
        action.payload;
    },
    setpatientAwarenessInputType(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.inputType =
        action.payload;
    },
    setpatientAwarenessDesc(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.descriptionPatientAwareness =
        action.payload;
    },
    setpatientAwarenessLang(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.language =
        action.payload;
    },
    setpatientAwarenessCopiesCount(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.noOfCopies =
        action.payload;
    },
    setpatientAwarenessEmail(state, action) {
      state.patientAwarenessSupportReq.patientAwarenessMaterial.email =
        action.payload;
    },

    setResearchTitle(state, action) {
      state.researchPublicationReq.publicationMedicalWriting.researchTitle =
        action.payload;
    },
    setRequiredResearchService(state, action) {
      state.researchPublicationReq.publicationMedicalWriting.requiredResearchService =
        action.payload;
    },
    setOtherResearchService(state, action) {
      state.researchPublicationReq.publicationMedicalWriting.otherResearchService =
        action.payload;
    },

    setPosterTitle(state, action) {
      state.researchPublicationReq.posterPreparation.posterTitle =
        action.payload;
    },
    setConferenceName(state, action) {
      state.researchPublicationReq.posterPreparation.conferenceName =
        action.payload;
    },
    setConferenceDate(state, action) {
      state.researchPublicationReq.posterPreparation.conferenceMonth =
        action.payload;
    },

    setSponsoredPopup1(state, action) {
      state.showSponsoredPopup1 = action.payload;
    },
    setSponsoredPopup2(state, action) {
      state.showSponsoredPopup2 = action.payload;
    },

    seteditDraft(state, action) {
      state.editDraft = action.payload;
    },
    setCaseNumber(state, action) {
      state.caseNumber = action.payload;
    },
    setOTPFocus(state, action) {
      state.otpFocusInput = action.payload;
    },

    setSelectedServiceName(state, action) {
      state.selectedServiceName = action.payload;
    },
    setSelectedSponsoredRequestName(state, action) {
      state.selectedSponsoredRequestName = action.payload;
    },
    seterrCreatingSponsoredService(state, action) {
      state.errCreatingSponsoredService = action.payload;
    },

    setbadgeCount(state, action) {
      state.badgeCount = action.payload;
    },
    setrequestTab(state, action) {
      state.requestTab = action.payload;
    },
    setrequestTabIndex(state, action) {
      state.requestTabIndex = action.payload;
    },

    setIsViewDetailScreenClicked(state, action) {
      state.viewDetailScreenClicked = action.payload;
    },

    setMobileRequestSource(state, action) {
      state.mobileRequestSource = action.payload;
    },
    setShipToAddressRecordId(state, action) {
      state.shipToAddressRecordID = action.payload;
    },
    setShippingAddressMaster(state, action) {
      state.shippingAddressMaster = action.payload;
    },
  },
});

export const requestActions = requestSlice.actions;

export default requestSlice.reducer;
