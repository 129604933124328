import "bootstrap/dist/css/bootstrap.min.css";
import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./CreateNewRequest.scss";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CircularProgress } from "@material-ui/core";

const AcademicRequest = () => {
  const dispatch = useDispatch();
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const [listOfService, setListOfService] = useState([]);
  const serviceName = "Academic & Education";

  const [submitArticleEnabled, setSubmitArticleEnabled] = useState(true);
  const [submitDigitalMeetEnabled, setSubmitDigitalMEnabled] = useState(true);
  const [submitSlideEnabled, setSubmitSlideEnabled] = useState(true);
  const [submitTrainingEnabled, setSubmitTrainingEnabled] = useState(true);

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  // const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
  const editDraft = useSelector((state: RootState) => state.request.editDraft);
  const caseNumber = useSelector(
    (state: RootState) => state.request.caseNumber
  );

  const selectedAcademicReqRef = useRef("");
  //refs for slide presentation

  const names = [
    "Cardiologist",
    "Diabetologist",
    "Endocrinologist",
    "Gynecologists",
    "GP",
    "PG",
    "Patients",
    "Dermatologists",
    "Chest Physician",
    "Orthopedition",
    "Family Physicians",
    "Nephrologists",
    "Neurologists",
    "Consulting Physicians",
    "Other",
  ];

  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const setErrorToastMsg = (params: any) =>
    dispatch(requestActions.setErrorToastMsg(params));
  const setSuccessToastMsg = (params: any) =>
    dispatch(requestActions.setSuccessToastMsg(params));

  const selectedAcademicReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );

  const handleReqTypeChange = (clickedElement: any) => {
    let isSponsoredService =
      clickedElement.currentTarget.getAttribute("data-issponsored");
    dispatch(requestActions.seteditDraft(false));
    if (isSponsoredService == "true") {
      dispatch(
        requestActions.setSponsoredPopup1({
          showPopup: true,
          fromHome: false,
          clickedSponsoredReq: clickedElement.target.value,
          clickedSponsoredService: serviceName,
        })
      );
      setSelectedReq("");
    } else {
      setSelectedReq(clickedElement.target.value);
      //resetPrevSelections(clickedElement.target.value);
      resetPrevSelectedFieldValues(clickedElement.target.value);
      resetFieldErrors(clickedElement.target.value);
    }
  };

  const setSelectedReq = (params: any) => {
    dispatch(requestActions.setSelectedReq(params));
    selectedAcademicReqRef.current = params;
    //localStorage.setItem('selectedService', params.toString());
  };

  useEffect(() => {
    fetchListOfAccessbileServices();
    //resetPrevSelections(selectedAcademicReq);
    resetPrevSelectedFieldValues(selectedAcademicReq);
    resetFieldErrors(selectedAcademicReq);
    resetSubmitButtons(selectedAcademicReq);
    return () => {
      //  console.log("cleanup")
      debouncedChangeHandler();
    };
  }, []);

  function debounce(callback: any, delay: any) {
    // console.log("debounce")
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }
  const debouncedChangeHandler = () => {
    // console.log("debouncedChangeHandler")
    let text = selectedAcademicReqRef.current; //(localStorage.getItem('selectedService'));
    debounce(() => saveAsDraftOnTabChange(text), 1000);
  };

  const saveAsDraftOnTabChange = (selectedAcademicReq1: any) => {
    // console.log(selectedAcademicReq1, 'selectedAcademicReq')
    switch (selectedAcademicReq1) {
      case "Slides Presentation":
        submitSlidePresentationReq(true, true);
        break;

      case "Article/ Reference":
        submitArticleReq(true, true);
        break;

      case "Digital Meeting Platform":
        submitDigitalMeetingReq(true, true);
        break;

      case "Training Required For Staff":
        submitTrainingReq(true, true);
        break;
    }
  };

  const academicRequestTypeArray1 = listOfService.filter(function (item: any) {
    return item.serviceName == serviceName;
  });

  academicRequestTypeArray1.map((item1: any, index1: number) => {
    const sortedlist = item1.lstRequestType.sort((a: any, b: any) =>
      a.requestName < b.requestName ? -1 : a.requestName > b.requestName ? 1 : 0
    );
    item1.lstRequestType = sortedlist;
  });
  const fetchListOfAccessbileServices = async () => {
    const serviceUrl1 = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
    const srviceAccUrl1 = baseServiceUrl + serviceUrl1;
    let params: any = {
      speciality: userInfo ? userInfo.speciality : "",
      category: userInfo ? userInfo.category : "",
    };
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl1,
      new RequestParams(params)
    );
    setListOfService(serviceAccessibleData.data.lstServiceType);
  };

  const topicNameRef = useRef("");
  const topicName = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.topicName
  );
  const setTopicName = (params: any) => {
    topicNameRef.current = params;
    dispatch(requestActions.setTopicName(params));
  };
  const durationRef = useRef("");
  const duration = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.duration
  );
  const setDuration = (params: any) => {
    durationRef.current = params;
    dispatch(requestActions.setDuration(params));
  };

  const targetAudienceRef: any = useRef([]);
  const targetAudience = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.targetAudience
  );
  const setTargetAudience = (params: any) => {
    targetAudienceRef.current = params;
    dispatch(requestActions.setTargetAudience(params));
  };

  const meetingTypeRef = useRef("");
  const meetingType = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.meetingType
  );
  const setMeetingType = (params: any) => {
    meetingTypeRef.current = params;
    dispatch(requestActions.setMeetingType(params));
  };

  const specialCommentsRef = useRef("");
  const specialComments = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.specialComments
  );
  const setSpecialComments = (params: any) => {
    specialCommentsRef.current = params;
    dispatch(requestActions.setSpecialComments(params));
  };

  const otherTargetAudienceRef = useRef("");
  const otherTargetAudience = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.slidesPresentation.otherTargetAudience
  );
  const setOtherTargetAudience = (params: any) => {
    otherTargetAudienceRef.current = params;
    dispatch(requestActions.setOtherTargetAudience(params));
  };

  const handleTargetAudienceChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTargetAudience(event.target.value as string[]);
    if (!targetAudience.includes("Other")) {
      setOtherTargetAudience("");
    }
  };

  const [topicNameErr, setTopicNameErr] = useState("UD");
  const [durationErr, setDurationErr] = useState("UD");
  const [targetAudienceErr, setTargetAudienceErr] = useState("UD");
  const [meetingTypeErr, setMeetingTypeErr] = useState("UD");
  const [specialCommentsErr, setspecialCommentsErr] = useState("UD");
  const [slideReqSubmitClicked, setslideReqSubmitClicked] = useState(false);

  useEffect(() => {
    if (slideReqSubmitClicked) {
      validateSlideReq();
    }
  }, [slideReqSubmitClicked]);

  useEffect(() => {
    if (
      topicNameErr === "N" &&
      durationErr === "N" &&
      targetAudienceErr === "N" &&
      meetingTypeErr === "N" &&
      specialCommentsErr === "N"
    ) {
      submitSlidePresentationReq();
    } else if (
      topicNameErr === "Y" ||
      durationErr === "Y" ||
      targetAudienceErr === "Y" ||
      meetingTypeErr === "Y" ||
      specialCommentsErr === "Y"
    ) {
      setslideReqSubmitClicked(false);
    }

    // setSubmitBtnLoader(false);
  }, [
    topicNameErr,
    durationErr,
    targetAudienceErr,
    meetingTypeErr,
    specialCommentsErr,
  ]);

  const validateSlideReq = () => {
    setTopicNameErr(topicName.trim().length == 0 ? "Y" : "N");
    setDurationErr(duration.length == 0 ? "Y" : "N");
    setTargetAudienceErr(targetAudience.length == 0 ? "Y" : "N");
    setMeetingTypeErr(meetingType.length == 0 ? "Y" : "N");
    setspecialCommentsErr(specialComments.trim().length == 0 ? "Y" : "N");
  };

  const submitSlidePresentationReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitSlideEnabled(false);
    try {
      // if (slideReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedAcademicReqRef.current
          : selectedAcademicReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        topicName: unmounted ? topicNameRef.current : topicName,
        talkDuration: unmounted ? durationRef.current : duration,
        targetAudience: unmounted
          ? targetAudienceRef.current.join(";")
          : targetAudience.join(";"),
        meetingType: unmounted ? meetingTypeRef.current : meetingType,
        specialComments: unmounted
          ? specialCommentsRef.current
          : specialComments,
        targetaudienceother: unmounted
          ? otherTargetAudienceRef.current
          : otherTargetAudience,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };

      // console.log('reqObject', reqObject)
      if (
        fromSaveAsDraft &&
        (specialCommentsRef.current.length > 0 ||
          meetingTypeRef.current.length > 0 ||
          targetAudienceRef.current.length > 0 ||
          durationRef.current.length > 0 ||
          topicNameRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (slideReqSubmitClicked) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
      // else {
      //     setErrorToastMsg({ 'isError': true, 'errMsg': 'Error' });
      // }
    } catch (err) {
      console.log(err);
    } finally {
      //if (!unmounted) {
      setslideReqSubmitClicked(false);
      //}
      setSubmitSlideEnabled(true);
    }
  };

  const articleTitleRef = useRef("");
  const articleTitle = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.articleReference.articleTitle
  );
  const setArticleTitle = (params: any) => {
    articleTitleRef.current = params;
    dispatch(requestActions.setArticleTitle(params));
  };
  const journalNameRef = useRef("");
  const journalName = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.articleReference.journalName
  );
  const setJournalName = (params: any) => {
    journalNameRef.current = params;
    dispatch(requestActions.setJournalName(params));
  };

  const articleDescRef = useRef("");
  const articleDesc = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.articleReference.articleDesc
  );
  const setArticleDesc = (params: any) => {
    articleDescRef.current = params;
    dispatch(requestActions.setArticleDesc(params));
  };

  const [articleTitleErr, setArticleTitleErr] = useState("UD");
  const [journalNameErr, setJournalNameErr] = useState("UD");
  const [articleDescErr, setarticleDescErr] = useState("UD");
  const [articleReqSubmitClicked, setArticleReqSubmitClicked] = useState(false);

  const validateArticleReq = () => {
    setArticleTitleErr(articleTitle.trim().length == 0 ? "Y" : "N");
    setarticleDescErr(articleDesc.trim().length == 0 ? "Y" : "N");
    setJournalNameErr(journalName.trim().length == 0 ? "Y" : "N");
  };

  useEffect(() => {
    if (articleReqSubmitClicked) {
      validateArticleReq();
    }
  }, [articleReqSubmitClicked]);

  useEffect(() => {
    if (
      articleDescErr === "N" &&
      articleTitleErr === "N" &&
      journalNameErr == "N"
    ) {
      submitArticleReq();
    } else if (
      articleDescErr === "Y" ||
      (articleTitleErr === "Y" && journalNameErr == "Y")
    ) {
      setArticleReqSubmitClicked(false);
    }
  }, [articleDescErr, articleTitleErr, journalNameErr]);

  const submitArticleReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitArticleEnabled(false);
    try {
      //if (articleReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedAcademicReqRef.current
          : selectedAcademicReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        articleTitle: unmounted ? articleTitleRef.current : articleTitle,
        journalname: unmounted ? journalNameRef.current : journalName,
        description: unmounted ? articleDescRef.current : articleDesc,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };

      if (
        fromSaveAsDraft &&
        (articleDescRef.current.length > 0 ||
          articleTitleRef.current.length > 0 ||
          journalNameRef.current.length > 0)
      ) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (articleReqSubmitClicked) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // if (!unmounted) {
      setArticleReqSubmitClicked(false);
      setSubmitArticleEnabled(true);
      //}
    }
  };

  const digitalMeetingNameRef = useRef("");
  const digitalMeetingName = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.digitalMeetingName
  );
  const setDigitalMeetingName = (params: any) => {
    digitalMeetingNameRef.current = params;
    dispatch(requestActions.setDigitalMeetingName(params));
  };

  const digitalMeetingTypeRef = useRef("");
  const digitalMeetingType = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.digitalMeetingType
  );
  const setDigitalMeetingType = (params: any) => {
    digitalMeetingTypeRef.current = params;
    dispatch(requestActions.setDigitalMeetingType(params));
  };

  const participantCountRef = useRef("");
  const participantCount = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.participantCount
  );
  const setParticipantCount = (params: any) => {
    participantCountRef.current = params;
    dispatch(requestActions.setParticipantCount(params));
  };

  const loginRequirementRef = useRef("");
  const loginRequirement = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.loginRequirement
  );
  const setLoginRequirement = (params: any) => {
    loginRequirementRef.current = params;
    dispatch(requestActions.setLoginRequirement(params));
  };

  const digitalMeetingDateTimeRef = useRef("");
  const digitalMeetingDateTime = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.digitalMeetingDateTime
  );
  const setDigitalMeetingDateTime = (params: any) => {
    digitalMeetingDateTimeRef.current = params;
    dispatch(requestActions.setDigitalMeetingDateTime(params));
  };

  const optionalRequirementRef = useRef("");
  const optionalRequirement = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.optionalRequirement
  );
  const setOptionalRequirement = (params: any) => {
    optionalRequirementRef.current = params;
    dispatch(requestActions.setOptionalRequirement(params));
  };

  const digitalAudienceTypeRef = useRef("");
  const digitalAudienceType = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.digitalAudienceType
  );
  const setDigitalAudienceType = (params: any) => {
    digitalAudienceTypeRef.current = params;
    dispatch(requestActions.setDigitalAudienceType(params));
  };

  const digitalReqDescRef = useRef("");
  const digitalReqDesc = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.digitalMeeting.digitalReqDesc
  );
  const setDigitalReqDesc = (params: any) => {
    digitalReqDescRef.current = params;
    dispatch(requestActions.setDigitalReqDesc(params));
  };

  const [digitalMeetingNameErr, setDigitalMeetingNameErr] = useState("UD");
  const [digitalReqDescErr, setdigitalReqDescErr] = useState("UD");
  const [digitalMeetingTypeErr, setDigitalMeetingTypeErr] = useState("UD");
  const [participantCountErr, setParticipantCountErr] = useState("UD");
  const [loginRequirementErr, setLoginRequirementErr] = useState("UD");
  const [digitalMeetingDateTimeErr, setDigitalMeetingDateTimeErr] =
    useState("UD");
  const [optionalRequirementErr, setOptionalRequirementErr] = useState("UD");
  const [digitalAudienceTypeErr, setdigitalAudienceTypeErr] = useState("UD");
  const [digitalMeetingReqSubmitClicked, setDigitalMeetingReqSubmitClicked] =
    useState(false);

  useEffect(() => {
    if (digitalMeetingReqSubmitClicked) validateDigitalMeetingReq();
  }, [digitalMeetingReqSubmitClicked]);

  useEffect(() => {
    if (
      digitalMeetingNameErr === "N" &&
      digitalReqDescErr === "N" &&
      digitalMeetingTypeErr === "N" &&
      participantCountErr === "N" &&
      loginRequirementErr === "N" &&
      digitalMeetingDateTimeErr === "N" &&
      optionalRequirementErr === "N" &&
      loginRequirementErr === "N" &&
      digitalAudienceTypeErr === "N"
    ) {
      submitDigitalMeetingReq();
    } else if (
      digitalMeetingNameErr === "Y" ||
      digitalReqDescErr === "Y" ||
      digitalMeetingTypeErr === "Y" ||
      participantCountErr === "Y" ||
      loginRequirementErr === "Y" ||
      digitalMeetingDateTimeErr === "Y" ||
      optionalRequirementErr === "Y" ||
      loginRequirementErr === "Y" ||
      digitalAudienceTypeErr === "Y"
    ) {
      setDigitalMeetingReqSubmitClicked(false);
    }
  }, [
    digitalMeetingNameErr,
    digitalReqDescErr,
    digitalMeetingTypeErr,
    participantCountErr,
    loginRequirementErr,
    digitalMeetingDateTimeErr,
    optionalRequirementErr,
    digitalAudienceTypeErr,
  ]);

  const validateDigitalMeetingReq = () => {
    setDigitalMeetingNameErr(digitalMeetingName.length == 0 ? "Y" : "N");
    setdigitalReqDescErr(digitalReqDesc.trim().length == 0 ? "Y" : "N");
    setDigitalMeetingTypeErr(digitalMeetingType.length == 0 ? "Y" : "N");
    setParticipantCountErr(participantCount.length == 0 ? "Y" : "N");
    setLoginRequirementErr(loginRequirement.length == 0 ? "Y" : "N");
    setDigitalMeetingDateTimeErr(
      digitalMeetingDateTime.length == 0 ? "Y" : "N"
    );
    setOptionalRequirementErr(optionalRequirement.length == 0 ? "Y" : "N");
    setdigitalAudienceTypeErr(digitalAudienceType.length == 0 ? "Y" : "N");
  };

  const submitDigitalMeetingReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitDigitalMEnabled(false);
    try {
      //if (digitalMeetingReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedAcademicReqRef.current
          : selectedAcademicReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        meetingName: unmounted
          ? digitalMeetingNameRef.current
          : digitalMeetingName,
        noOfParticipant: unmounted
          ? participantCountRef.current
          : participantCount,
        preRegLoginReq: unmounted
          ? loginRequirementRef.current
          : loginRequirement,
        meetingDateTime: unmounted
          ? digitalMeetingDateTimeRef.current.length > 0
            ? new Date(digitalMeetingDateTimeRef.current)
                .toISOString()
                .slice(0, -5) + "Z"
            : ""
          : digitalMeetingDateTime.length > 0
          ? new Date(digitalMeetingDateTime).toISOString().slice(0, -5) + "Z"
          : "",
        otherSpecificReg: unmounted
          ? optionalRequirementRef.current
          : optionalRequirement,
        audience: unmounted
          ? digitalAudienceTypeRef.current
          : digitalAudienceType,
        description: unmounted ? digitalReqDescRef.current : digitalReqDesc,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (digitalReqDescRef.current.length > 0 ||
          digitalAudienceTypeRef.current.length > 0 ||
          optionalRequirementRef.current.length > 0 ||
          digitalMeetingDateTimeRef.current.length > 0 ||
          loginRequirementRef.current.length > 0 ||
          participantCountRef.current.length > 0 ||
          digitalMeetingNameRef.current.length > 0)
      ) {
        sendReqObjectForNewReq(reqObject);
      } else if (digitalMeetingReqSubmitClicked) {
        sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // if (!unmounted) {
      setDigitalMeetingReqSubmitClicked(false);
      setSubmitDigitalMEnabled(true);
      //}
    }
  };

  const trainingNameRef = useRef("");
  const trainingName = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.staffCertification.trainingName
  );
  const setTrainingName = (params: any) => {
    trainingNameRef.current = params;
    dispatch(requestActions.setTrainingName(params));
  };

  const trainingDescRef = useRef("");
  const trainingDesc = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.staffCertification.trainingDesc
  );
  const setTrainingDesc = (params: any) => {
    trainingDescRef.current = params;
    dispatch(requestActions.setTrainingDesc(params));
  };

  const trainingStaffCountRef = useRef("");
  const trainingStaffCount = useSelector(
    (state: RootState) =>
      state.request.createAcademicRequest.staffCertification.trainingStaffCount
  );
  const setTrainingStaffCount = (params: any) => {
    trainingStaffCountRef.current = params;
    dispatch(requestActions.setTrainingStaffCount(params));
  };

  const [trainingNameErr, setTrainingNameErr] = useState("UD");
  const [trainingDescErr, setTrainingDescErr] = useState("UD");
  const [trainingStaffCountErr, setTrainingStaffCountErr] = useState("UD");
  const [trainingReqSubmitClicked, setTrainingReqSubmitClicked] =
    useState(false);

  useEffect(() => {
    if (trainingReqSubmitClicked) validateTrainingReq();
  }, [trainingReqSubmitClicked]);

  useEffect(() => {
    if (
      trainingNameErr === "N" &&
      trainingDescErr === "N" &&
      trainingStaffCountErr === "N"
    ) {
      submitTrainingReq();
    } else if (
      trainingNameErr === "Y" ||
      trainingDescErr === "Y" ||
      trainingStaffCountErr === "Y"
    ) {
      setTrainingReqSubmitClicked(false);
    }
  }, [trainingNameErr, trainingDescErr, trainingStaffCountErr]);

  const validateTrainingReq = () => {
    setTrainingNameErr(trainingName.length == 0 ? "Y" : "N");
    setTrainingDescErr(trainingDesc.trim().length == 0 ? "Y" : "N");
    setTrainingStaffCountErr(trainingStaffCount.length == 0 ? "Y" : "N");
    //return (trainingDescErr && trainingNameErr && trainingStaffCountErr);
  };

  const submitTrainingReq = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitTrainingEnabled(false);
    try {
      // if (trainingReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedAcademicReqRef.current
          : selectedAcademicReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        trainingName: unmounted ? trainingNameRef.current : trainingName,
        trainingDesc: unmounted ? trainingDescRef.current : trainingDesc,
        noOfStaffForTraining: unmounted
          ? trainingStaffCountRef.current
          : trainingStaffCount,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };

      if (
        fromSaveAsDraft &&
        (trainingNameRef.current.length > 0 ||
          trainingDescRef.current.length > 0 ||
          trainingStaffCountRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (trainingReqSubmitClicked) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTrainingReqSubmitClicked(false);
      setSubmitTrainingEnabled(true);
    }
  };

  function resetPrevSelectedFieldValues(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Slides Presentation":
            setTopicName("");
            setDuration("");
            setMeetingType("");
            setSpecialComments("");
            setTargetAudience([]);
            setOtherTargetAudience("");
            // setTargetAudienceErr('UD');
            // setTopicNameErr('UD');
            // setMeetingTypeErr('UD');
            // setDurationErr('UD');

            topicNameRef.current = "";
            durationRef.current = "";
            targetAudienceRef.current = new Array();
            meetingTypeRef.current = "";
            specialCommentsRef.current = "";
            otherTargetAudienceRef.current = "";
            break;

          case "Article/ Reference":
            setArticleDesc("");
            setArticleTitle("");
            setJournalName("");
            setArticleDesc("");
            // setArticleTitleErr('UD');
            // setJournalNameErr('UD');
            //setarticleDescErr('UD');

            articleTitleRef.current = "";
            journalNameRef.current = "";
            articleDescRef.current = "";
            break;

          case "Digital Meeting Platform":
            setDigitalMeetingName("");
            setDigitalMeetingType("");
            setParticipantCount("");
            setLoginRequirement("");
            setDigitalReqDesc("");
            setDigitalAudienceType("");
            setDigitalMeetingDateTime("");
            setOptionalRequirement("");

            digitalMeetingNameRef.current = "";
            digitalMeetingTypeRef.current = "";
            participantCountRef.current = "";
            loginRequirementRef.current = "";
            digitalMeetingDateTimeRef.current = "";
            optionalRequirementRef.current = "";
            digitalAudienceTypeRef.current = "";
            digitalReqDescRef.current = "";
            break;

          case "Training Required For Staff":
            setTrainingDesc("");
            setTrainingName("");
            setTrainingStaffCount("");

            // setTrainingDescErr('UD');
            // setTrainingNameErr('UD');
            // setTrainingStaffCountErr('UD');

            trainingNameRef.current = "";
            trainingDescRef.current = "";
            trainingStaffCountRef.current = "";
            break;
        }
      })();
    }
  }

  function resetFieldErrors(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Slides Presentation":
            setTargetAudienceErr("UD");
            setTopicNameErr("UD");
            setMeetingTypeErr("UD");
            setDurationErr("UD");
            setspecialCommentsErr("UD");
            break;

          case "Article/ Reference":
            setArticleTitleErr("UD");
            setJournalNameErr("UD");
            setarticleDescErr("UD");
            break;

          case "Digital Meeting Platform":
            setLoginRequirementErr("UD");
            setDigitalMeetingTypeErr("UD");
            setDigitalMeetingNameErr("UD");
            setParticipantCountErr("UD");
            setdigitalAudienceTypeErr("UD");
            setdigitalReqDescErr("UD");
            break;

          case "Training Required For Staff":
            setTrainingDescErr("UD");
            setTrainingNameErr("UD");
            setTrainingStaffCountErr("UD");
            break;
        }
      })();
    }
  }

  function resetSubmitButtons(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Slides Presentation":
            setSubmitSlideEnabled(true);
            break;

          case "Article/ Reference":
            setSubmitArticleEnabled(true);
            break;

          case "Digital Meeting Platform":
            setSubmitDigitalMEnabled(true);
            break;

          case "Training Required For Staff":
            setSubmitTrainingEnabled(true);
            break;
        }
      })();
    }
  }

  const sendReqObjectForNewReq = async (
    reqObject: any,
    unmounted?: boolean,
    saveAsDraft?: boolean
  ) => {
    if (!editDraft) {
      delete (reqObject as any).caseNumber;
    }
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      // console.log(output.data);
      dispatch(requestActions.seteditDraft(false));
      if (output.data.status == "failure") {
        if (!unmounted) {
          setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
          resetFieldErrors(selectedAcademicReq);
        }
      } else {
        if (!unmounted) {
          setSuccessToastMsg({
            isSuccess: true,
            newReqId: output.data.requestnumber,
          });
          //resetPrevSelections(selectedAcademicReq);
          resetPrevSelectedFieldValues(selectedAcademicReq);
          resetFieldErrors(selectedAcademicReq);
        }
        if (
          caseNumber !== undefined &&
          caseNumber.trim() == "" &&
          (saveAsDraft || unmounted)
        ) {
          setbadgeCount(badgeCount + 1);
        }
      }
    } catch (e: any) {
      console.log("send Req Msg");
    }
    resetSubmitButtons(selectedAcademicReq);
  };

  return (
    <section className="root-container container-fluid mt-3 reqCategoryContentContainer">
      <section className="userFilledEntity RequestSubtypeDD">
        <FormControl className="requestEntityContainer">
          <InputLabel id="demo-simple-select-required-label">
            Request Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={selectedAcademicReq}
            onChange={(e: ChangeEvent<{}>) => {
              handleReqTypeChange(e);
            }}
            className="selectReqDD-Container createReqDropdown selectRequestTypeBucket"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value="" disabled>
              Select Request
            </MenuItem>
            {academicRequestTypeArray1.map((item: any, index: number) =>
              item.lstRequestType.map((item1: any, index1: number) => (
                <MenuItem
                  key={index1}
                  value={item1.requestName}
                  data-issponsored={item1.sponsored}
                >
                  {item1.requestName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </section>
      {(() => {
        switch (selectedAcademicReq) {
          case "Slides Presentation":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2 ar_TopicName">
                      <TextField
                        id="standard-basic"
                        label="Topic Name"
                        type="text"
                        value={topicName}
                        onChange={(event) => setTopicName(event.target.value)}
                        error={topicNameErr === "Y"}
                        helperText={
                          topicNameErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                      />
                    </section>

                    <section className="userFilledEntity mt-2 ar_typeOfMeeting">
                      <FormControl
                        required
                        className="requestEntityContainer required"
                        error={meetingTypeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Type Of Meeting
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={meetingType}
                          onChange={(e: any) => setMeetingType(e.target.value)}
                          className="createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Meeting Type
                          </MenuItem>
                          <MenuItem value="District">District</MenuItem>
                          <MenuItem value="Regional">Regional</MenuItem>
                          <MenuItem value="National">National</MenuItem>
                          <MenuItem value="International">
                            International
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {meetingTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        id="standard-basic"
                        label="Special Comments"
                        type="text"
                        value={specialComments}
                        error={specialCommentsErr === "Y"}
                        helperText={
                          specialCommentsErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        onChange={(event) =>
                          setSpecialComments(event.target.value)
                        }
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2 ar_chatDurationContainer">
                      <TextField
                        required
                        error={durationErr === "Y"}
                        helperText={
                          durationErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Duration (in mins)"
                        type="number"
                        value={duration}
                        onChange={(event: any) =>
                          setDuration(event.target.value)
                        }
                        autoComplete="off"
                      />
                    </section>

                    <section className="userFilledEntity mt-2 ar_targetAudience">
                      <FormControl
                        required
                        className="requestEntityContainer"
                        error={targetAudienceErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Target Audience
                        </InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={targetAudience}
                          onChange={handleTargetAudienceChange}
                          className="audienceTypeDD createReqDropdown"
                          renderValue={(selected) => {
                            if ((selected as string[]).length === 0) {
                              return <>Select Target Audience</>;
                            }
                            return (selected as string[]).join(",");
                          }}
                          MenuProps={MenuProps}
                        >
                          <MenuItem disabled value="">
                            Select Target Audience
                          </MenuItem>
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              className="audienceType"
                            >
                              <Checkbox
                                checked={targetAudience.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText>
                          {targetAudienceErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display: targetAudience.includes("Other")
                          ? "block"
                          : "none",
                      }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Other"
                        type="text"
                        value={otherTargetAudience}
                        onChange={(event: any) =>
                          setOtherTargetAudience(event.target.value)
                        }
                        autoComplete="off"
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setslideReqSubmitClicked(true);
                    }}
                    disabled={submitSlideEnabled ? false : true}
                  >
                    Submit{" "}
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitSlidePresentationReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitSlidePresentationReq(true);
                    }}
                    disabled={submitSlideEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Article/ Reference":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        autoComplete="off"
                        error={articleTitleErr == "Y"}
                        helperText={
                          articleTitleErr == "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        required
                        id="standard-basic"
                        label="Title Of The Article"
                        type="text"
                        value={articleTitle}
                        onChange={(event: any) =>
                          setArticleTitle(event.target.value)
                        }
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        autoComplete="off"
                        id="standard-basic"
                        label="Description"
                        type="text"
                        required
                        error={articleDescErr == "Y"}
                        helperText={
                          articleDescErr == "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        value={articleDesc}
                        onChange={(event) => setArticleDesc(event.target.value)}
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Journal Name"
                        type="text"
                        value={journalName}
                        error={journalNameErr === "Y"}
                        helperText={
                          journalNameErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        onChange={(event) => setJournalName(event.target.value)}
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => setArticleReqSubmitClicked(true)}
                    disabled={submitArticleEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitArticleReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitArticleReq(true);
                    }}
                    disabled={submitArticleEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Digital Meeting Platform":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        required
                        id="standard-basic"
                        label="Name Of Meeting"
                        type="text"
                        value={digitalMeetingName}
                        onChange={(event) =>
                          setDigitalMeetingName(event.target.value)
                        }
                        error={digitalMeetingNameErr === "Y"}
                        helperText={
                          digitalMeetingNameErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                      />
                    </section>
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        required
                        className="requestEntityContainer"
                        error={digitalMeetingTypeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Type Of Meeting
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={digitalMeetingType}
                          onChange={(e: any) =>
                            setDigitalMeetingType(e.target.value)
                          }
                          className="meetingTypeReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Meeting Type
                          </MenuItem>
                          <MenuItem value="Webinar">Webinar</MenuItem>
                          <MenuItem value="RTM(Round Table Meeting)">
                            RTM(Round Table Meeting)
                          </MenuItem>
                          <MenuItem value="Conference">Conference</MenuItem>
                          <MenuItem value="Customized Platform">
                            Customized Platform
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {digitalAudienceTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                    <section className="userFilledEntity mt-2">
                      <TextField
                        required
                        error={participantCountErr === "Y"}
                        helperText={
                          participantCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Number Of Participant"
                        type="number"
                        value={participantCount}
                        onChange={(event) =>
                          setParticipantCount(event.target.value)
                        }
                      />
                    </section>
                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={optionalRequirementErr === "Y"}
                        helperText={
                          optionalRequirementErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Any Other Specific Requirement"
                        type="text"
                        value={optionalRequirement}
                        onChange={(event) =>
                          setOptionalRequirement(event.target.value)
                        }
                      />
                    </section>
                  </div>

                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        required
                        className="requestEntityContainer"
                        error={loginRequirementErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Pre-Registration Login Requirement
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={loginRequirement}
                          onChange={(e: any) =>
                            setLoginRequirement(e.target.value)
                          }
                          className="meetingTypeReqDD-Container createReqDropdown"
                        >
                          <MenuItem value=" " disabled>
                            Select Pre-Registration Login requirement
                          </MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        <FormHelperText>
                          {digitalMeetingTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        required
                        id="datetime-local"
                        label="Date Time"
                        type="datetime-local"
                        value={digitalMeetingDateTime}
                        onChange={(event) =>
                          setDigitalMeetingDateTime(event.target.value)
                        }
                        error={digitalMeetingDateTimeErr === "Y"}
                        helperText={
                          digitalMeetingDateTimeErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        required
                        className="requestEntityContainer"
                        error={digitalAudienceTypeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Audience
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={digitalAudienceType}
                          onChange={(e: any) =>
                            setDigitalAudienceType(e.target.value)
                          }
                          className="meetingTypeReqDD-Container createReqDropdown"
                        >
                          <MenuItem value="" disabled>
                            Select Audience
                          </MenuItem>
                          <MenuItem value="Patient">Patient</MenuItem>
                          <MenuItem value="HCPs">HCPSs</MenuItem>
                        </Select>
                        <FormHelperText>
                          {digitalAudienceTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        id="standard-basic"
                        required
                        label="Description"
                        type="text"
                        value={digitalReqDesc}
                        onChange={(event) =>
                          setDigitalReqDesc(event.target.value)
                        }
                        error={digitalReqDescErr === "Y"}
                        helperText={
                          digitalReqDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => setDigitalMeetingReqSubmitClicked(true)}
                    disabled={submitDigitalMeetEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitDigitalMeetingReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitDigitalMeetingReq(true);
                    }}
                    disabled={submitDigitalMeetEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Training Required For Staff":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={trainingNameErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Name Of Training Program
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={trainingName}
                          onChange={(e: any) => {
                            setTrainingName(e.target.value);
                            setTrainingDesc(
                              "NDEP (National Diabetes Educator Programme) is a course designed to become certified diabetes educators."
                            );
                          }}
                          className="selectReqDD-Container createReqDropdown"
                        >
                          <MenuItem value="">Select Training Program</MenuItem>
                          <MenuItem value="National Diabetes Education Program">
                            National Diabetes Education Program
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {trainingNameErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                    <section className="userFilledEntity mt-2">
                      <TextField
                        multiline
                        rows={1}
                        rowsMax={5}
                        disabled
                        error={trainingDescErr === "Y" ? true : false}
                        helperText={
                          trainingDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Training Program Description"
                        type="text"
                        value={trainingDesc}
                        onChange={(e: any) => setTrainingDesc(e.target.value)}
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={trainingStaffCountErr === "Y" ? true : false}
                        helperText={
                          trainingStaffCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Number Of Staff Members For Training Program"
                        type="number"
                        value={trainingStaffCount}
                        onChange={(e: any) =>
                          setTrainingStaffCount(e.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => setTrainingReqSubmitClicked(true)}
                    disabled={submitTrainingEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitTrainingReq(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitTrainingReq(true);
                    }}
                    disabled={submitTrainingEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
        }
      })()}
    </section>
  );
};

export default AcademicRequest;
