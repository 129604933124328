import { RequestParams } from "../Class-Interfaces/httpRequestParams";
import { makeGetRequest, makePostRequest } from "../services/httpServices";
import { readAllData } from "../indexdb_utils";

export const baseServiceUrl =
  process.env.REACT_APP_ENV == "QA"
    ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
    : process.env.REACT_APP_ENV == "UAT"
    ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
    : process.env.REACT_APP_ENV == "PROD"
    ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
    : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;

export const homeServices = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const serviceUrl = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
  const srviceAccUrl = baseServiceUrl + serviceUrl;
  let params: any = {
    speciality: userDetails ? userDetails.speciality : "",
    category: userDetails ? userDetails.category : "",
  };
  try {
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl,
      new RequestParams(params)
    );

    if (serviceAccessibleData && serviceAccessibleData.status == 200) {
      return serviceAccessibleData;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      let serviceAccessibleData = await readAllData("posts", srviceAccUrl);
      let serviceAccessibleDataIndexedDB: any = { data: null };
      serviceAccessibleDataIndexedDB.data = serviceAccessibleData;
      return serviceAccessibleDataIndexedDB;
    }
  }
};
export const homeRequests = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const requestUrl = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const requestFullUrl = baseServiceUrl + requestUrl;

  try {
    let requestFullData = await makePostRequest(
      requestFullUrl,
      new RequestParams({
        legacycode: userDetails ? userDetails.legacycode : "",
        draftonly: false,
        supportTicketOnly: false,
      })
    );
    if (requestFullData && requestFullData.status == 200) {
      return requestFullData;
    } else {
      clearSessionData();
    }
    //console.log("requestFullData result", requestFullData)
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB request")
      let requestFullData = await readAllData("posts", requestFullUrl);
      //console.log("in indexedDB request", requestFullData)
      let requestFullDataIndexedDB: any = { data: null };
      requestFullDataIndexedDB.data = requestFullData;
      return requestFullDataIndexedDB;
    }
  }
};
export const homeEvents = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const eventUrl = `${process.env.REACT_APP_FETCH_EVENTS}`;
  const eventFullUrl = baseServiceUrl + eventUrl;
  try {
    let eventsFullData = await makePostRequest(
      eventFullUrl,
      new RequestParams({
        legacycode: userDetails ? userDetails.legacycode : "",
      })
    );

    if (eventsFullData && eventsFullData.status == 200) {
      return eventsFullData;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB events")
      let eventsFullData = await readAllData("posts", eventFullUrl);
      //console.log("in indexedDB events", eventsFullData)
      let eventsFullDataIndexedDB: any = { data: null };
      eventsFullDataIndexedDB.data = eventsFullData;
      return eventsFullDataIndexedDB;
    }
  }
};

export const fetchDraftDetails = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const draftUrl = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const draftFullUrl = baseServiceUrl + draftUrl;
  try {
    let draftDetails = await makePostRequest(
      draftFullUrl,
      new RequestParams({
        legacycode: userDetails ? userDetails.legacycode : "",
        draftonly: true,
        supportTicketOnly: false,
      })
    );

    if (draftDetails && draftDetails.status == 200) {
      return draftDetails;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB events")
      let draftFullData = await readAllData("posts", draftFullUrl);
      //console.log("in indexedDB events", draftFullData)
      let draftFullDataIndexedDB: any = { data: null };
      draftFullDataIndexedDB.data = draftFullData;
      return draftFullDataIndexedDB;
    }
  }
};
// const requestReducer = async (requestFullUrl: any, userDetails: any) => {
//     let requestFullData = await homeRequests()
//     let requestParsedData = homeRequestReducer(requestFullData)
//     return requestParsedData
//   }
//   const eventReducer = async (eventFullUrl: any, userDetails: any) => {
//     let eventsFullData = await homeEvents();
//     let eventsParsedData = homeEventReducer(eventsFullData)
//     return eventsParsedData
//   }

export const getHomeJsonData = async () => {
  try {
    let homeData = await makeGetRequest("home.json");
    return homeData;
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB")
      let homeDataIndexedDb = await readAllData(
        "posts",
        "http://localhost:3000/home.json"
      );
      //console.log("in indexedDB", homeDataIndexedDb)
      let homeData: any = { data: null, statusText: "" };
      homeData.data = homeDataIndexedDb;
      homeData.statusText = "OK";
      return homeData;
    }
  }
};

export const fetchTopPickContent = async () => {
  const topPicksUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS}`;
  const topPicksFullUrl = baseServiceUrl + topPicksUrl;
  try {
    let topPicks = await makePostRequest(
      topPicksFullUrl,
      new RequestParams({ type: "Top Picks Banner" })
    );

    if (topPicks && topPicks.status == 200) {
      return topPicks;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB fetchTopPickContent")
      let topPicks = await readAllData("posts", topPicksFullUrl);
      //console.log("in indexedDB fetchTopPickContent", topPicks)
      let topPicksIndexedDb: any = { data: null };
      topPicksIndexedDb.data = topPicks;
      return topPicksIndexedDb;
    }
  }
};
export const fetchPushMarketingContent = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const pushMarketingUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS}`;
  const pushMarketingFullUrl = baseServiceUrl + pushMarketingUrl;
  try {
    let pushMarketingContent = await makePostRequest(
      pushMarketingFullUrl,
      new RequestParams({
        type: "Push Marketing Initiative Banner",
        Speciality: userDetails ? userDetails.speciality : "",
        legacycode: userDetails ? userDetails.legacycode : "",
      })
    );

    if (pushMarketingContent && pushMarketingContent.status == 200) {
      return pushMarketingContent;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB fetchPushMarketingContent")
      let pushMarketingContent = await readAllData(
        "posts",
        pushMarketingFullUrl
      );
      //console.log("in indexedDB fetchPushMarketingContent", pushMarketingContent)
      let pushMarketingContentIndexedDb: any = { data: null };
      pushMarketingContentIndexedDb.data = pushMarketingContent;
      return pushMarketingContentIndexedDb;
    }
  }
};

export const fetchTop10RatedArticles = (data: any) => {
  const data1 = data;
};

export const fetchSupportTicketDetails = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const Url = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const fullUrl = baseServiceUrl + Url;
  try {
    let supportticketDetails = await makePostRequest(
      fullUrl,
      new RequestParams({
        legacycode: userDetails ? userDetails.legacycode : "",
        draftonly: false,
        supportTicketOnly: true,
      })
    );

    if (supportticketDetails && supportticketDetails.status == 200) {
      return supportticketDetails;
    } else clearSessionData();
  } catch (error: any) {
    if ("indexedDB" in window && error.message === "Network Error") {
      //console.log("in indexedDB events")
      let supportticketURL = await readAllData("posts", fullUrl);
      //console.log("in indexedDB events", draftFullData)
      let supportTicketIndexedDB: any = { data: null };
      supportTicketIndexedDB.data = supportticketURL;
      return supportTicketIndexedDB;
    }
  }
};

export const getShippingAddressMasterData = async () => {
  const userDetails: any = JSON.parse(
    localStorage.getItem("userDetails") as any
  );
  const fetchDropdownMasterData =
    baseServiceUrl + `${process.env.REACT_APP_SHIPPINGADDRESS_DROPDOWNDATA}`;
  try {
    let shippingAddressDropdownData = await makeGetRequest(
      fetchDropdownMasterData
    );
    if (
      shippingAddressDropdownData &&
      shippingAddressDropdownData.status == 200
    ) {
      if (
        shippingAddressDropdownData &&
        shippingAddressDropdownData.data &&
        // shippingAddressDropdownData.data.isSuccess &&
        shippingAddressDropdownData.data.reqWrap
        //  &&
        // shippingAddressDropdownData.data.reqWrap.length
      ) {
        const data = shippingAddressDropdownData.data.reqWrap;
        return data;
        // setshippingAddressDropdownDataVal(
        //   shippingAddressDropdownData.data.reqWrap
        // );
      }
    } else clearSessionData();
  } catch (e: any) {
    //localStorage.removeItem('authHeader');
    console.log(e);
    // pwa-code-start
    if ("indexedDB" in window && e.message === "Network Error") {
      let userReqListIndexedDB = await readAllData(
        "posts",
        fetchDropdownMasterData
      );
      // setshippingAddressDropdownDataVal(userReqListIndexedDB);
    }
    // pwa-code-end
  }
};

export function clearSessionData() {
  localStorage.removeItem("authHeader");
  localStorage.removeItem("_expiredTime");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("uname");
  window.location.href = "/login";
}
