//Function to convert blob type for download
export const handleDownloadType = (downloadType: any) => {
  switch (downloadType) {
    case "PDF":
      return "application/pdf";
    case "POWER_POINT_X":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "MP4":
      return "video/mp4";
    case "PNG":
      return "image/png";
    case "JPG":
      return "image/jpg";
    case "JPEG":
      return "image/jpeg";
    case "EXCEL_X":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "WORD_X":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "CSV":
      return "text/csv";
  }
};
