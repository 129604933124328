import "./Footer.scss";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import usvLogo from "../../../assets/images/USV_Blue_Logo.svg";
import { useState, useEffect } from "react";
import { readAllData } from "../../../indexdb_utils";
// import Disclaimer from '../../../files/disclaimer.pdf'

const Footer = () => {
  const [footerLink, setFooterLink] = useState([]);

  useEffect(() => {
    fetchFooterLink();
  }, []);

  const fetchFooterLink = async () => {
    fetch("footerLink.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        setFooterLink(myJson.footerLink);
      });
    if ("indexedDB" in window) {
      //console.log("in indexedDB setFooterLink")
      let myJson = await readAllData(
        "posts",
        "http://localhost:3000/footerLink.json"
      );
      //console.log("in indexedDB myJson",myJson)
      // setFooterLink(myJson.footerLink)
    }
  };

  return (
    <div className="footer-section--full container-fluid">
      <div className="footer-directory row pt-4">
        {footerLink.map((item: any, index: number) => {
          return (
            <>
              <div className="footer-col-leftConatiner" key={index}>
                <div>
                  <img src={usvLogo} alt="logo" />
                </div>
                <div className="footercolumn footer-col-left">
                  <p className="address">{item.address}</p>
                  {/* <ul className="footerlist">
              <li className="footerlist-item">
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
              </li>
              <li className="footerlist-item">
                59 Street, 1200 Techpark
              </li>
              <li className="footerlist-item">
                0123456789
              </li>
              <li className="footerlist-item">
                8:00 am - 17:00 pm, Monday - Friday
              </li>
            </ul> */}
                </div>
              </div>
              <div className="footerlists row footer-col-right">
                <div className="footercolumn">
                  <h3 className="footerlist-item footerlist-item--heading">
                    Quick Links
                  </h3>
                  <ul className="footerlist">
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href={item.aboutUs}
                        target="_blank"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href={item.mission}
                        target="_blank"
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href={item.vision}
                        target="_blank"
                      >
                        Our Vision
                      </a>
                    </li>
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href={item.awards}
                        target="_blank"
                      >
                        Awards
                      </a>
                    </li>
                    {/* <li className="footerlist-item">
                <a className="footerlink" href="https://www.usvindia.com/">
                  Success Story
                </a>
              </li> */}
                  </ul>
                </div>
                <div className="footercolumn">
                  <h3 className="footerlist-item footerlist-item--heading">
                    Others
                  </h3>
                  <ul className="footerlist">
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href={item.termsAndCondition}
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="footerlist-item">
                      <a
                        className="footerlink"
                        href="https://www.usvindia.com/"
                        target="_blank"
                      >
                        Cookies Policy
                      </a>
                    </li>

                    <li className="footerlist-item">
                      <Link
                        to="/disclaimer"
                        className="footerlink"
                        target="_blank"
                      >
                        Disclaimer
                      </Link>
                      {/* <a
                        className="footerlink"
                        href={
                          process.env.PUBLIC_URL + "/disclaimer.pdf#toolbar=0"
                        }
                        target="_blank"
                      >
                        Disclaimer
                      </a> */}
                    </li>

                    {/* <li className="footerlist-item">
                <a className="footerlink">
                  Copyright information
                  {item.copyrightInfo}
                </a>
              </li> */}
                    {/* <li className="footerlist-item">
                <a className="footerlink" href="https://www.usvindia.com/" >
                  Terms of Use
                </a>
              </li> */}
                  </ul>
                </div>
              </div>
              <p className="copyright">{item.copyrightInfo}</p>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
