import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./CreateNewRequest.scss";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import { DatePicker } from "@material-ui/pickers";

const ResearchPublicationRequest = () => {
  const [listOfService, setListOfService] = useState([]);
  const selectedResearchReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );

  const selectedResearchReqRef = useRef("");
  const setSelectedResearchReq = (params: any) => {
    selectedResearchReqRef.current = params;
    dispatch(requestActions.setSelectedReq(params));
  };
  const serviceName = "Research Publication";
  const dispatch = useDispatch();
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);

  const editDraft = useSelector((state: RootState) => state.request.editDraft);
  const caseNumber = useSelector(
    (state: RootState) => state.request.caseNumber
  );

  const [submitPublicationEnabled, setSubmitPublicationEnabled] =
    useState(true);
  const [submitPosterEnabled, setSubmitPosterEnabled] = useState(true);

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const setErrorToastMsg = (params: any) => {
    dispatch(requestActions.setErrorToastMsg(params));
  };
  const setSuccessToastMsg = (params: any) => {
    dispatch(requestActions.setSuccessToastMsg(params));
  };

  useEffect(() => {
    // console.log('draft',selectedResearchReq);
    // return (()=>{saveAsDraft()});
  }, [selectedResearchReq]);

  // function saveAsDraft(){
  //     console.log(selectedResearchReq,'12345','unmounting');
  // }

  useEffect(() => {
    fetchListOfAccessbileServices();
    //resetPrevSelections(selectedResearchReq);
    resetPrevSelectedFieldValues(selectedResearchReq);
    resetFieldErrors(selectedResearchReq);
    resetSubmitButtons(selectedResearchReq);
    return () => {
      //  console.log("cleanup")
      debouncedChangeHandler();
    };
  }, []);

  function debounce(callback: any, delay: any) {
    // console.log("debounce")
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }
  const debouncedChangeHandler = () => {
    // console.log("debouncedChangeHandler")
    let text = selectedResearchReqRef.current; //(localStorage.getItem('selectedService'));
    debounce(() => saveAsDraftOnTabChange(text), 1000);
  };

  const saveAsDraftOnTabChange = (currentSelectedReq: any) => {
    // console.log(selectedAcademicReq1, 'selectedAcademicReq')
    switch (currentSelectedReq) {
      case "Poster Preparation Support":
        submitPosterPrepRequest(true, true);
        break;

      case "Publication/ Medical Writing":
        submitPublicationWritingRequest(true, true);
        break;
    }
  };

  const researchPublicationRequestArray = listOfService.filter(function (
    item: any
  ) {
    return item.serviceName == serviceName;
  });

  researchPublicationRequestArray.map((item1: any, index1: number) => {
    const sortedlist = item1.lstRequestType.sort((a: any, b: any) =>
      a.requestName < b.requestName ? -1 : a.requestName > b.requestName ? 1 : 0
    );
    item1.lstRequestType = sortedlist;
  });

  const fetchListOfAccessbileServices = async () => {
    const serviceUrl1 = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
    const srviceAccUrl1 = baseServiceUrl + serviceUrl1;
    let params: any = {
      speciality: userInfo ? userInfo.speciality : "",
      category: userInfo ? userInfo.category : "",
    };
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl1,
      new RequestParams(params)
    );
    // console.log(serviceAccessibleData,'serviceAccessibleData');
    setListOfService(serviceAccessibleData.data.lstServiceType);
    // console.log(serviceAccessibleData.data.lstServiceType,'serviceAccessibleData.data.lstServiceType');
  };

  const handleReqTypeChange = (clickedElement: any) => {
    dispatch(requestActions.seteditDraft(false));
    let isSponsoredService =
      clickedElement.currentTarget.getAttribute("data-issponsored");
    if (isSponsoredService == "true") {
      dispatch(
        requestActions.setSponsoredPopup1({
          showPopup: true,
          fromHome: false,
          clickedSponsoredReq: clickedElement.target.value,
          clickedSponsoredService: serviceName,
        })
      );
      setSelectedResearchReq("");
    } else {
      setSelectedResearchReq(clickedElement.target.value);
      //resetPrevSelections(clickedElement.target.value);
      resetPrevSelectedFieldValues(clickedElement.target.value);
      resetFieldErrors(clickedElement.target.value);
    }
  };

  const researchTitleRef = useRef("");
  const researchTitle = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.publicationMedicalWriting
        .researchTitle
  );
  const setResearchTitle = (params: any) => {
    researchTitleRef.current = params;
    dispatch(requestActions.setResearchTitle(params));
  };

  const requiredResearchServiceRef = useRef("");
  const requiredResearchService = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.publicationMedicalWriting
        .requiredResearchService
  );
  const setRequiredResearchService = (params: any) => {
    requiredResearchServiceRef.current = params;
    dispatch(requestActions.setRequiredResearchService(params));
  };

  const otherResearchServiceRef = useRef("");
  const otherResearchService = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.publicationMedicalWriting
        .otherResearchService
  );
  const setOtherResearchService = (params: any) => {
    otherResearchServiceRef.current = params;
    dispatch(requestActions.setOtherResearchService(params));
  };

  const [researchTitleErr, setResearchTitleErr] = useState("UD");
  const [requiredResearchServiceErr, setRequiredResearchServiceErr] =
    useState("UD");
  const [otherResearchErr, setotherResearchErr] = useState("UD");

  const [publicationRequestClicked, setPublicationRequestClicked] =
    useState(false);

  useEffect(() => {
    if (publicationRequestClicked) {
      validatePublicationRequest();
    }
  }, [publicationRequestClicked]);

  useEffect(() => {
    if (researchTitleErr === "N" && requiredResearchServiceErr === "N") {
      if (
        requiredResearchService == "Others(Please Specify)" &&
        otherResearchErr === "N"
      ) {
        submitPublicationWritingRequest();
      } else submitPublicationWritingRequest();
    } else if (researchTitleErr === "Y" || requiredResearchServiceErr === "Y") {
      if (
        requiredResearchService == "Others(Please Specify)" &&
        otherResearchErr === "Y"
      ) {
        setPublicationRequestClicked(false);
      } else setPublicationRequestClicked(false);
    }
  }, [researchTitleErr, requiredResearchServiceErr, otherResearchErr]);

  const validatePublicationRequest = () => {
    setResearchTitleErr(researchTitle.length == 0 ? "Y" : "N");
    setRequiredResearchServiceErr(
      requiredResearchService.length == 0 ? "Y" : "N"
    );

    if (requiredResearchService == "Others(Please Specify)") {
      setotherResearchErr(otherResearchService.length == 0 ? "Y" : "N");
    }
  };

  const submitPublicationWritingRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPublicationEnabled(false);
    try {
      //if (publicationRequestClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedResearchReqRef.current
          : selectedResearchReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        researchTitle: unmounted ? researchTitleRef : researchTitle,
        servicesRequired: unmounted
          ? requiredResearchServiceRef.current
          : requiredResearchService,
        reqOfServiceOther: unmounted
          ? otherResearchServiceRef.current
          : otherResearchService,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (researchTitleRef.current.length > 0 ||
          requiredResearchServiceRef.current.length > 0)
      ) {
        sendReqObjectForNewReq(reqObject);
      } else if (publicationRequestClicked) {
        sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPublicationRequestClicked(false);
      setSubmitPublicationEnabled(true);
    }
  };

  const posterTitleRef = useRef("");
  const posterTitle = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.posterPreparation.posterTitle
  );
  const setPosterTitle = (params: any) => {
    posterTitleRef.current = params;
    dispatch(requestActions.setPosterTitle(params));
  };

  const conferenceNameRef = useRef("");
  const conferenceName = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.posterPreparation.conferenceName
  );
  const setConferenceName = (params: any) => {
    conferenceNameRef.current = params;
    dispatch(requestActions.setConferenceName(params));
  };

  const conferenceDateRef = useRef("");
  const conferenceDate = useSelector(
    (state: RootState) =>
      state.request.researchPublicationReq.posterPreparation.conferenceMonth
  );
  const setConferenceDate = (params: any) => {
    conferenceDateRef.current = params;
    dispatch(requestActions.setConferenceDate(params));
  };

  const [posterTitleErr, setPosterTitleErr] = useState("UD");
  const [conferenceNameErr, setConferenceNameErr] = useState("UD");
  const [posterRequestClicked, setPosterRequestClicked] = useState(false);

  useEffect(() => {
    if (posterRequestClicked) {
      validatePosterRequest();
    }
  }, [posterRequestClicked]);

  useEffect(() => {
    if (posterTitleErr === "N" && conferenceNameErr === "N") {
      submitPosterPrepRequest();
    } else if (posterTitleErr === "Y" || conferenceNameErr === "Y") {
      setPosterRequestClicked(false);
    }
  }, [posterTitleErr, conferenceNameErr, posterRequestClicked]);

  const validatePosterRequest = () => {
    setPosterTitleErr(posterTitle.length == 0 ? "Y" : "N");
    setConferenceNameErr(conferenceName.length == 0 ? "Y" : "N");
  };

  const submitPosterPrepRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPosterEnabled(false);
    try {
      //if (posterRequestClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedResearchReqRef.current
          : selectedResearchReq,
        legacycode: userInfo.legacycode,
        posterTitle: unmounted ? posterTitleRef.current : posterTitle,
        conferenceName: unmounted ? conferenceNameRef.current : conferenceName,
        conferenceMonth: unmounted ? conferenceDateRef.current : conferenceDate,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (posterTitleRef.current.length > 0 ||
          conferenceNameRef.current.length > 0 ||
          conferenceDateRef.current.length > 0)
      ) {
        sendReqObjectForNewReq(reqObject);
      } else if (posterRequestClicked) {
        sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPosterRequestClicked(false);
      setSubmitPosterEnabled(true);
    }
  };

  const sendReqObjectForNewReq = async (
    reqObject: any,
    unmounted?: boolean,
    saveAsDraft?: boolean
  ) => {
    if (!editDraft) {
      delete (reqObject as any).caseNumber;
    }
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      // console.log(output.data);
      dispatch(requestActions.seteditDraft(false));
      if (output.data.status == "failure") {
        if (!unmounted) {
          setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
          resetFieldErrors(selectedResearchReq);
        }
      } else {
        if (!unmounted) {
          setSuccessToastMsg({
            isSuccess: true,
            newReqId: output.data.requestnumber,
          });
          //resetPrevSelections(selectedResearchReq);
          resetPrevSelectedFieldValues(selectedResearchReq);
          resetFieldErrors(selectedResearchReq);
        }
        if (
          caseNumber !== undefined &&
          caseNumber.trim() == "" &&
          (saveAsDraft || unmounted)
        ) {
          setbadgeCount(badgeCount + 1);
        }
      }
    } catch (e) {
      console.log("send Req Msg");
    }
    resetSubmitButtons(selectedResearchReq);
  };

  function resetPrevSelectedFieldValues(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Publication/ Medical Writing":
            setResearchTitle("");
            setRequiredResearchService("");
            setOtherResearchService("");

            researchTitleRef.current = "";
            requiredResearchServiceRef.current = "";
            otherResearchServiceRef.current = "";
            break;

          case "Poster Preparation Support":
            setPosterTitle("");
            setConferenceName("");
            setConferenceDate("");

            posterTitleRef.current = "";
            conferenceNameRef.current = "";
            conferenceDateRef.current = "";
            break;
        }
      })();
    }
  }

  function resetFieldErrors(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Publication/ Medical Writing":
            setRequiredResearchServiceErr("UD");
            setotherResearchErr("UD");
            break;

          case "Poster Preparation Support":
            setPosterTitleErr("UD");
            setConferenceNameErr("UD");
            break;
        }
      })();
    }
  }

  function resetSubmitButtons(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Publication/ Medical Writing":
            setSubmitPublicationEnabled(true);
            break;

          case "Poster Preparation Support":
            setSubmitPosterEnabled(true);
            break;
        }
      })();
    }
  }
  return (
    <section className="root-container container-fluid mt-3 reqCategoryContentContainer">
      <section className="userFilledEntity RequestSubtypeDD">
        <FormControl className="requestEntityContainer">
          <InputLabel id="demo-simple-select-required-label">
            Request Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedResearchReq}
            onChange={(e: any) => {
              handleReqTypeChange(e);
            }}
            className="selectReqDD-Container createReqDropdown selectRequestTypeBucket"
            MenuProps={MenuProps}
          >
            {researchPublicationRequestArray.map((item: any, index: number) =>
              item.lstRequestType.map((item1: any, index1: number) => (
                <MenuItem
                  key={index1}
                  value={item1.requestName}
                  data-issponsored={item1.sponsored}
                >
                  {item1.requestName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </section>

      {(() => {
        switch (selectedResearchReq) {
          case "Publication/ Medical Writing":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        required
                        id="standard-basic"
                        label="Title of Research"
                        type="text"
                        value={researchTitle}
                        onChange={(event) =>
                          setResearchTitle(event.target.value)
                        }
                        autoComplete="off"
                        error={researchTitleErr === "Y"}
                        helperText={
                          researchTitleErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                      />
                    </section>
                  </div>

                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          requiredResearchServiceErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Services required
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={requiredResearchService}
                          onChange={(e: any) =>
                            setRequiredResearchService(e.target.value)
                          }
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Services
                          </MenuItem>
                          <MenuItem value="Medical Writing">
                            Medical Writing
                          </MenuItem>
                          <MenuItem value="Statistical Analysis">
                            Statistical Analysis
                          </MenuItem>
                          <MenuItem value="Data Management">
                            {" "}
                            Data Management
                          </MenuItem>
                          <MenuItem value="Publication Support">
                            {" "}
                            Publication Support
                          </MenuItem>
                          <MenuItem value="Others (Please Specify) ">
                            Others (Please Specify){" "}
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {requiredResearchServiceErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          requiredResearchService == "Others(Please Specify)"
                            ? "block"
                            : "none",
                      }}
                    >
                      <TextField
                        required
                        autoComplete="off"
                        error={otherResearchErr === "Y"}
                        helperText={
                          otherResearchErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Other Service"
                        type="text"
                        value={otherResearchService}
                        onChange={(event) =>
                          setOtherResearchService(event.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setPublicationRequestClicked(true);
                    }}
                    disabled={submitPublicationEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    className="saveAsDraft mt-2"
                    onClick={() => submitPublicationWritingRequest(true)}
                    disabled={submitPublicationEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Poster Preparation Support":
            return (
              <>
                <div className="row d-flex justify-content-center text-center ">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <TextField
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Title of Poster"
                        type="text"
                        value={posterTitle}
                        onChange={(event) => setPosterTitle(event.target.value)}
                        error={posterTitleErr === "Y"}
                        helperText={
                          posterTitleErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <TextField
                        autoComplete="off"
                        required
                        error={conferenceNameErr === "Y"}
                        helperText={
                          conferenceNameErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Name of Conference"
                        type="text"
                        value={conferenceName}
                        onChange={(event) =>
                          setConferenceName(event.target.value)
                        }
                      />
                    </section>

                    <section className="userFilledEntity">
                      <FormControl className="requestEntityContainer">
                        <InputLabel id="demo-simple-select--label">
                          Month of Conference
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={conferenceDate}
                          onChange={(event) =>
                            setConferenceDate(event.target.value)
                          }
                          className="createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Month Of Conference
                          </MenuItem>
                          {months &&
                            months.map((monthName) => (
                              <MenuItem key={monthName} value={monthName}>
                                {monthName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setPosterRequestClicked(true);
                    }}
                    disabled={submitPosterEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  <Button
                    className="saveAsDraft mt-2"
                    onClick={() => submitPosterPrepRequest(true)}
                    disabled={submitPosterEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
        }
      })()}
    </section>
  );
};

export default ResearchPublicationRequest;
