import "./Sitedown.scss";

const Sitedown = () => {
  return (
    <div className="siteDown-Container"></div>
    // <section>
    //   <div className="siteDown-Container"></div>
    //   <div className="siteDown-text">
    //     <h5>We are under maintenance from 8pm to 9pm</h5>
    //     <h5>Will be back soon!!</h5>
    //   </div>
    // </section>
  );
};

export default Sitedown;
