import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    otp: "",
    legacycode: "",
    hcpname: "",
    mobilenumber: "",
    speciality: "",
    email: "",
    profileimage: "",
    category: "",
  },
  showMyProfilePopUp: false,
  showLogOutPopUp: false,
  profileImg: "",
  loginStatus: false,
  top10Article: [],
};

const slice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setshowMyProfilePopUp(state, action) {
      state.showMyProfilePopUp = action.payload;
    },
    setProfileImg(state, action) {
      state.profileImg = action.payload;
    },
    setloginStatus(state, action) {
      state.loginStatus = action.payload;
    },
    setshowLogoutPopUp(state, action) {
      state.showLogOutPopUp = action.payload;
    },
    settop10Article(state, action) {
      state.top10Article = action.payload;
    },
  },
});

export const loginActions = slice.actions;

export default slice.reducer;
