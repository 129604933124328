
import moment from 'moment-timezone';
import { readAllData } from '../indexdb_utils';
import { homeEvents, homeRequests, homeServices,getHomeJsonData } from "./home_axios";



export const homeRequestReducer = (requestFullData: any) => {
  let requestParsedData = requestFullData["data"].lstServiceRequest.sort((a:any,b:any)=>+new Date(b.createdon) - +new Date(a.createdon)).reduce((reqParsedArray: any[], reqObj: any, index: number) => {
    if (!reqParsedArray.find((obj: any) => {
      if ((new Date(parseInt(moment(obj.date).format('YYYY')), parseInt(moment(obj.date).format('MM')) - 1, parseInt(moment(obj.date).format('DD')))).getTime() == (new Date(parseInt(moment(reqObj.createdon).format('YYYY')), parseInt(moment(reqObj.createdon).format('MM')) - 1, parseInt(moment(reqObj.createdon).format('DD')))).getTime()) {
        return obj
      }
    })) 

    {
      if (reqParsedArray.length <= 2) {
        let newReqObj: any = {}
        newReqObj.date = new Date(parseInt(moment(reqObj.createdon).format('YYYY')), parseInt(moment(reqObj.createdon).format('MM')) - 1, parseInt(moment(reqObj.createdon).format('DD')))
        let requestArray = [];
        let subRequestObj: any = {};
        subRequestObj.requestId = reqObj.requestnumber
        subRequestObj.requestCat = reqObj.requesttype
        subRequestObj.status = reqObj.requeststatus
        requestArray.push({ ...subRequestObj })
        newReqObj.requestArray = [...requestArray]
        reqParsedArray.push({ ...newReqObj })
      }
    } else {
      let existingReqObj = reqParsedArray.find((obj: any) => {
        if ((new Date(parseInt(moment(obj.date).format('YYYY')), parseInt(moment(obj.date).format('MM')) - 1, parseInt(moment(obj.date).format('DD')))).getTime() == (new Date(parseInt(moment(reqObj.createdon).format('YYYY')), parseInt(moment(reqObj.createdon).format('MM')) - 1, parseInt(moment(reqObj.createdon).format('DD')))).getTime()) {
          return obj
        }
      })
      if (existingReqObj.requestArray.length <=2) {
        let subRequestObj: any = {};
        subRequestObj.requestId = reqObj.requestnumber
        subRequestObj.requestCat = reqObj.requesttype
        subRequestObj.status = reqObj.requeststatus
        existingReqObj.requestArray.push({ ...subRequestObj })
      }
    }
    return reqParsedArray;
  }, [])
  return requestParsedData
}

export const homeEventReducer = (eventsFullData: any) => {

  //console.log(eventsFullData,'eventsFullData');

  var newArray = eventsFullData["data"].lstEventDetails.filter(function (el:any)
{
  return new Date(el.starttime) > new Date();
}
);
//console.log(newArray,' newArraynewArraynewArraynewArraynewArray');







//let sample = eventsFullData["data"].lstEventDetails.filter((a:any,b:any)=>+new Date(b.starttime) - +new Date(a.starttime))

  let eventsParsedData = newArray && newArray.sort((a:any,b:any)=>+new Date(a.starttime) - +new Date(b.starttime)).reduce((eventsParsedArray: any[], evObj: any, index: number) => {
    if (!eventsParsedArray.find((obj: any) => {
      if ((new Date(parseInt(moment(obj.date).format('YYYY')), parseInt(moment(obj.date).format('MM')) - 1, parseInt(moment(obj.date).format('DD')))).getTime() == (new Date(parseInt(moment(evObj.starttime).format('YYYY')), parseInt(moment(evObj.starttime).format('MM')) - 1, parseInt(moment(evObj.starttime).format('DD')))).getTime()) {
        return obj
      }
    })) 
    
    {
      if (eventsParsedArray.length <= 2) {
        let newEvObj: any = {}
        let eventsArray: any = [];
        newEvObj.date = new Date(parseInt(moment(evObj.starttime).format('YYYY')), parseInt(moment(evObj.starttime).format('MM')) - 1, parseInt(moment(evObj.starttime).format('DD')))
        let subEvObj: any = {};
        subEvObj.eventName = evObj.subject ? evObj.subject : "No subject"
        subEvObj.time = moment(evObj.starttime).tz(moment.tz.guess()).format('hh:mm:ss A')
        subEvObj.speaker = evObj.speakername ? evObj.speakername : "Not Available"
        subEvObj.venue = evObj.venue ? evObj.venue : 'Not Available'
        eventsArray.push({ ...subEvObj })
        newEvObj.eventsArray = [...eventsArray]
        eventsParsedArray.push({ ...newEvObj })
      }
    } else {

      let existingEvObj = eventsParsedArray.find((obj: any) => {
        if ((new Date(parseInt(moment(obj.date).format('YYYY')), parseInt(moment(obj.date).format('MM')) - 1, parseInt(moment(obj.date).format('DD')))).getTime() == (new Date(parseInt(moment(evObj.starttime).format('YYYY')), parseInt(moment(evObj.starttime).format('MM')) - 1, parseInt(moment(evObj.starttime).format('DD')))).getTime()) {
          return obj
        }
      })
      if (existingEvObj.eventsArray.length <= 3) {
        let subEvObj: any = {};
        subEvObj.eventName = evObj.subject ? evObj.subject : "No subject"
        subEvObj.time = moment(evObj.starttime).tz(moment.tz.guess()).format('hh:mm:ss A')
        subEvObj.speaker = evObj.speakername ? evObj.speakername : "Not Available"
        subEvObj.venue = evObj.venue ? evObj.venue : 'Not Available'
        existingEvObj.eventsArray.push({ ...subEvObj })
      }
    }
    return eventsParsedArray
  }, [])
  return eventsParsedData;
}

export const homePageData = async () => {
  let homeApiData = [];
  try {
    let serviceAccessibleData = await homeServices()

    // let requestFullData = await homeRequests()
 
    // let requestParsedData = homeRequestReducer(requestFullData)

    // let eventsFullData = await homeEvents();

    // let eventParsedData = homeEventReducer(eventsFullData)

    let homeData = await getHomeJsonData();

   
    //console.log("in home utils params",serviceAccessibleData,requestParsedData,eventParsedData,homeData)
    //homeApiData = await formatHomePageData(serviceAccessibleData, requestParsedData, eventParsedData,homeData)
    homeApiData = await formatHomePageData(serviceAccessibleData,homeData)
    //console.log("in home utils ",homeApiData)
   
  } catch (error:any) {
    //console.log(error)
  }
  return homeApiData;
}

//export const formatHomePageData = async (serviceAccessibleData: any, requestParsedData: any, eventParsedData: any,homeData:any) => {
export const formatHomePageData = async (serviceAccessibleData: any,homeData:any) => {
  let homeApiData = [];
 
  if (homeData.statusText === "OK") {
    homeApiData = homeData.data;
    let serviceObj = homeApiData.tabs.find((obj: any) => obj.tab === 'SERVICES')
    let subTabs = [];
    subTabs = serviceAccessibleData.data.lstServiceType.map((obj: any) => {
      let freshSubTab: any = {};
      freshSubTab.subTab = obj.serviceName;
      let freshReqList = []
      freshReqList = obj.lstRequestType.map((subObj: any) => {
        let freshReqObj: any = {};
        freshReqObj.requestType = subObj.requestName;
        freshReqObj.id = subObj.requestId;
        freshReqObj.description = subObj.description;
        freshReqObj.sponsored = subObj.sponsored;
        return { ...freshReqObj };
      })
      freshSubTab.requestTypes = [...freshReqList];
      return freshSubTab;
    })
    // subTabs.sort((a: any, b: any) => a.subTab < b.subTab ? -1 : a.subTab > b.subTab ? 1 : 0);

    subTabs.map((item: any, index: number) => {
      const sortedReqTypeList = (item.requestTypes).sort((a: any, b: any) => a.requestType < b.requestType ? -1 : a.requestType > b.requestType ? 1 : 0);
      (item.requestTypes) = sortedReqTypeList;

    })

    serviceObj.subTabs = subTabs;
    // let requestObj = homeApiData.tabs.find((obj: any) => obj.tab === 'MY REQUESTS')
    // requestObj.subTabs = []
    // requestObj.subTabs = [...requestParsedData]
    // let eventObj = homeApiData.tabs.find((obj: any) => obj.tab === 'UPCOMING EVENTS')
    // eventObj.subTabs = [];
    // eventObj.subTabs = [...eventParsedData]
  }
  return homeApiData;
}




