import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import "./UserProfile.scss";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import doctor from "../../assets/images/maleDoctor.jpg";
import { loginActions } from "../../store/login";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { PageViewGA, EventGA } from "../../utils/googleAnalyticsTracking";
import { clearSessionData } from "../../utils/home_axios";

const UserProfile = () => {
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);

  if (!userInfo) {
    clearSessionData();
  }
  const dispatch = useDispatch();
  const profileImg = useSelector((state: RootState) => state.login.profileImg);
  const setProfileImg = (params: any) => {
    dispatch(loginActions.setProfileImg(params));
  };

  useEffect(() => {
    PageViewGA();
    var userDetails: any = JSON.parse(
      localStorage.getItem("userDetails") as any
    );

    if (userDetails) {
      userDetails &&
      userDetails.profileimage != null &&
      userDetails.profileimage != ""
        ? setProfileImg(`data:image/png;base64,${userDetails.profileimage}`)
        : setProfileImg(`${doctor}`);
    } else {
      clearSessionData();
    }
  }, []);

  const setshowMyProfilePopUp = (params: any) => {
    dispatch(loginActions.setshowMyProfilePopUp(params));
  };
  setshowMyProfilePopUp(false);

  const imageHandler = (e: any) => {
    console.log(e);
    handleUpload(e);
  };

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const updateProfileImgApi =
    baseServiceUrl + "/services/apexrest/updateprofile";

  const handleUpload = async (e: any) => {
    // e.preventDefault();
    let blob = new Blob(
      [new Uint8Array(await e.target.files[0].arrayBuffer())],
      { type: e.target.files[0].type }
    );
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      var base64String = reader.result;
      let reqObject = {
        legacycode: userInfo ? userInfo.legacycode : "",
        profileimage: base64String
          ? (base64String as string).substring(
              (base64String as string).indexOf(",") + 1
            )
          : "",
      };

      let output = await makePostRequest(
        updateProfileImgApi,
        new RequestParams(reqObject)
      );

      if (output.data.status == "success") {
        userInfo.profileimage = base64String
          ? (base64String as string).substring(
              (base64String as string).indexOf(",") + 1
            )
          : "";
        localStorage.setItem("userDetails", JSON.stringify(userInfo));

        if (reqObject.profileimage !== "") {
          setProfileImg(`data:image/png;base64,${reqObject.profileimage}`);
        } else {
          setProfileImg(`${doctor}`);
        }
      }
    };
  };

  function truncateAddress(address: string) {
    const add = `${address}`;
    var returnedValue = add;
    if (returnedValue.length > 120) {
      returnedValue = returnedValue.substring(0, 120) + "...";
    }
    return returnedValue;
  }

  return (
    <div className="mobileWrapperContentPages">
      <section className="userProfile-rootContainer d-flex justify-content-center text-center ">
        <div className="leftCol col=lg-6 col-md-6 col-12">
          <div className="doctorProfileImg mt-4">
            <div
              className="profileImgDivContainer"
              style={{
                backgroundImage:
                  profileImg == "" || userInfo.profileimage == null
                    ? "url(" + doctor + ")"
                    : "url(" + profileImg + ")",
                backgroundSize: "cover",
              }}
            ></div>
            {/* {profileImg!='' && <img src={profileImg} alt='Please add an image'/>} 
            {profileImg==''  && <img src={doctor} alt='Please add an image'/>} */}

            <label className="cameraImg" htmlFor="upload-button">
              <CameraAltIcon />
            </label>
            <input
              type="file"
              id="upload-button"
              style={{ display: "none" }}
              onChange={imageHandler}
            />
          </div>
          <div className="hcpProfileDesc mt-5">
            <div className="hcpName">
              <h4>Dr. {userInfo.hcpname}</h4>
            </div>
            <div className="hcpSpecs">{userInfo.speciality}</div>
            <div className="hcpLegacyCode">
              USV Registration Number : {userInfo.legacycode}
            </div>
          </div>
        </div>
        <div className="rightCol col-lg-6 col-md-6 col-12">
          <div className="hcpContactDetails mt-2 ps-5">
            {userInfo.address ? (
              <>
                <div className="hcpContactField mb-4">
                  <div>
                    <AddLocationIcon></AddLocationIcon>
                  </div>
                  <div className="hcpContactText">
                    <div className="hcpContactTextTitle">Address</div>
                    <div
                      className="hcpContactTextValue hcpAddress"
                      title={userInfo.address}
                    >
                      {/* {userInfo.address} */}

                      {mobileScreen()
                        ? `${userInfo.address}`
                        : truncateAddress(`${userInfo.address}`)}
                    </div>
                    {userInfo.pinCode ? (
                      <div className="hcpContactTextValue">
                        <span>{`Pincode : ${userInfo.pinCode}`}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="hcpContactField mb-3">
              <div>
                <MailIcon></MailIcon>
              </div>
              <div className="hcpContactText">
                <div className="hcpContactTextTitle">Email</div>
                <div className="hcpContactTextValue">{userInfo.email}</div>
              </div>
            </div>
            {userInfo.mobilenumber ? (
              <>
                <div className="hcpContactField mb-3">
                  <div>
                    <CallIcon></CallIcon>
                  </div>
                  <div className="hcpContactText">
                    <div className="hcpContactTextTitle">Contact Number</div>
                    <div className="hcpContactTextValue">
                      {userInfo.mobilenumber}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="hcpContactField mb-3">
              {/* <div><CallIcon></CallIcon></div>
              <div className="hcpContactText">
                <div className="hcpContactTextTitle">Secondary Contact Number</div>
                <div className="hcpContactTextValue">{userInfo.mobilenumber}</div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserProfile;
