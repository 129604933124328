import React, { useState, useEffect } from "react";
import "./Otp.scss";
import "../Login/Login.scss";
import { Link } from "react-router-dom";
import OTPInput from "../OTPInput";
import usvLogo from "../../assets/images/USV_Blue_Logo.svg";
import doctorImg1 from "../../assets/images/loginImg1.svg";
import doctorImg2 from "../../assets/images/loginImg2.svg";
import { useHistory } from "react-router-dom";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import { RootState } from "../../store";
import { requestActions } from "../../store/request";
import { useSelector, useDispatch } from "react-redux";
import MobileWrapper from "../../mobile-Components/MobileWrapperComponent/MobileWrapperComponent";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { Button } from "@material-ui/core";
import { PageViewGA, EventGA } from "../../utils/googleAnalyticsTracking";
import axios from "axios";
import { loginActions } from "../../store/login";

const Otp = () => {
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const dispatch = useDispatch();

  const [otpEntered, setOTPEntered] = useState("");
  const [isOTPResend, setIsOTPResend] = useState(false);
  const [isInCorrectOTP, setisInCorrectOTP] = useState(false);

  const history = useHistory();
  const focusEnalbed = useSelector(
    (state: RootState) => state.request.otpFocusInput
  );

  //Middleware Integration
  //const baseServiceUrlMiddleware = (`${process.env.REACT_APP_BASE_SERVICEURL_DEV_MIDDLEWARE}`);
  const baseServiceUrlMiddleware =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA_MIDDLEWARE}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT_MIDDLEWARE}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD_MIDDLEWARE}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV_MIDDLEWARE}`;
  const otpUrl = `${process.env.REACT_APP_OTP_MIDDLEWARE}`;
  const verifyOTPApi = baseServiceUrlMiddleware + otpUrl;
  const serviceUrlMiddleware = `${process.env.REACT_APP_LOGIN_MIDDLEWARE}`;
  const loginApiMiddleware = baseServiceUrlMiddleware + serviceUrlMiddleware;

  const setUserInfo = (params: any) => {
    dispatch(loginActions.setUserInfo(params));
  };

  async function verifyOTPEntered(event: any) {
    try {
      let OTPURLAPI = `${verifyOTPApi}${otpEntered}`;
      let verifyOTP = await axios.post(OTPURLAPI);
      if (verifyOTP.data && verifyOTP.data.accessToken !== "") {
        setUserInfo(verifyOTP.data.loginResponse);
        let authHeader = `Bearer ${verifyOTP.data.accessToken}`;
        // let abc= `00D0p0000000Nkp!AQsAQHAETsmSYN5SnoepaDAUdj0cBbx8U7meLk5YDekZfFX0jUbcYtDc2.lsO8qjOU44SqDGWXXOXFCZAnTI9Z5SJMs7H3XA`
        // let devAuthHeader = `Bearer ${abc}`;
        //  localStorage.setItem('authHeader',devAuthHeader);
        localStorage.setItem("authHeader", authHeader);
        localStorage.setItem(
          "userDetails",
          JSON.stringify(verifyOTP.data.loginResponse)
        );
        setIsOTPResend(false);
        setisInCorrectOTP(false);
        setTimeout(() => {
          history.push("/home");
        }, 500);
      } else {
        setisInCorrectOTP(true);
        event.preventDefault();
      }
    } catch {
      setisInCorrectOTP(true);
      event.preventDefault();
    }
  }

  async function autologinToHome(enterdOTP: any) {
    if (enterdOTP.length === 4) {
      try {
        let OTPURLAPI = `${verifyOTPApi}${enterdOTP}`;
        let verifyOTP = await axios.post(OTPURLAPI);
        if (verifyOTP.data && verifyOTP.data.accessToken !== "") {
          setUserInfo(verifyOTP.data.loginResponse);

          let authHeader = `Bearer ${verifyOTP.data.accessToken}`;
          // let abc= `00D0p0000000Nkp!AQsAQHAETsmSYN5SnoepaDAUdj0cBbx8U7meLk5YDekZfFX0jUbcYtDc2.lsO8qjOU44SqDGWXXOXFCZAnTI9Z5SJMs7H3XA`
          // let devAuthHeader = `Bearer ${abc}`;
          // localStorage.setItem('authHeader',devAuthHeader);
          localStorage.setItem("authHeader", authHeader);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(verifyOTP.data.loginResponse)
          );
          setIsOTPResend(false);
          setisInCorrectOTP(false);
          setTimeout(() => {
            history.push("/home");
          }, 500);
        } else {
          setisInCorrectOTP(true);
        }
      } catch {
        setisInCorrectOTP(true);
      }
    }
  }

  async function checkResendOTP() {
    EventGA("OTP", "Click to generate new OTP", "OTP Page");
    let output;
    const phoneNoPattern = /\d{9}/;
    const loginId: any = localStorage.getItem("uname");
    if (loginId && loginId.length > 0) {
      if (loginId.match(phoneNoPattern)) {
        output = await makePostRequest(
          loginApiMiddleware,
          new RequestParams({
            emailId: "",
            phoneNumber: loginId,
          })
        );
      } else {
        output = await makePostRequest(
          loginApiMiddleware,
          new RequestParams({
            emailId: loginId,
            phoneNumber: "",
          })
        );
      }
    }

    if (
      output.data.trim().includes("HCP OTP Send") ||
      output.data.trim().includes("HCP OTP Sent")
    ) {
      setIsOTPResend(true);
      localStorage.removeItem("userDetails");
      localStorage.setItem("userDetails", JSON.stringify(output.data));
    }
  }

  return mobileScreen() ? (
    <MobileWrapper>
      <div className="background-mobile">
        {/* <img  src={bgImg2}  alt={bgImg2}/> */}
      </div>
      <div id="login-container-mobile " className="login-container-mobile">
        <div className="mobile-image-wrapper">
          <div className="mobile-left-docter">
            {" "}
            <img src={doctorImg2} alt={doctorImg2} />
          </div>
          <div className="mobile-right-docter">
            {" "}
            <img src={doctorImg1} alt={doctorImg1} />
          </div>
        </div>
        <div className="mobile-login-wrapper">
          <div className="mobile-login">
            <div id="mobile-logo" className="mobile-logo">
              <img src={usvLogo} alt="usvlogo"></img>
            </div>
            <h1>OTP</h1>
            <div className="otpCredential-mobile">
              <OTPInput
                autoFocus
                isNumberInput
                length={4}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(otp) => {
                  setOTPEntered(otp);
                  setIsOTPResend(false);
                  setisInCorrectOTP(false);
                  autologinToHome(otp);
                }}
                handleEnterKey={(event) => verifyOTPEntered(event)}
              />
              <div className="resendOtpSec">
                {" "}
                <span
                  className="resendOTPLink"
                  onClick={() => checkResendOTP()}
                >
                  <strong>Resend </strong>
                </span>
                <span
                  className="incorrectOTPMsg hidden"
                  style={
                    isInCorrectOTP ? { display: "block" } : { display: "none" }
                  }
                >
                  You have entered incorrect OTP
                </span>
                <span
                  className="newOTPSendMsg"
                  style={{ display: isOTPResend ? "block" : "none" }}
                >
                  New OTP has been sent
                </span>
              </div>
            </div>
          </div>
          <div className="mobile-button">
            <Button
              className={`submit-button ${
                focusEnalbed ? "submit-botton-enabled" : null
              }`}
              onClick={(e) => {
                verifyOTPEntered(e);
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </MobileWrapper>
  ) : (
    <div id="otp-container" className=" container-fluid">
      <div className="row">
        <div className="left-container col-lg-6 col-md-6 col-12">
          <section className="doctorImgContainer">
            <div className="loginimagecontainer col-lg-4 col-md-4 col-12">
              <div className="loginImg1">
                <img src={doctorImg2} />
              </div>
            </div>
            <div className="loginimagecontainer col-lg-8 col-md-8 col-12">
              <div className="loginImg2">
                <img src={doctorImg1} />
              </div>
            </div>
          </section>
        </div>

        <div className="right-container col-lg-6 col-md-6 col-12 pe-0">
          {/* <div className="login-imgContainer">
                        <section>
                            <div></div>
                        </section>
                        <img src={login_main_icon} alt="loginImg" />
                    </div> */}

          <div className="signInContainer mt-5">
            <div id="logo">
              <img src={usvLogo} alt="usvlogo"></img>
            </div>
            <div id="verifyDetails" className="mt-3">
              <h3>OTP</h3>
              <section className="loginCredentialContainer">
                <OTPInput
                  autoFocus
                  isNumberInput
                  length={4}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={(otp) => {
                    setOTPEntered(otp);
                    setIsOTPResend(false);
                    setisInCorrectOTP(false);
                    autologinToHome(otp);
                  }}
                  handleEnterKey={(event) => verifyOTPEntered(event)}
                />
                <div className="resendOtpSec">
                  If you do not receive the OTP in 1 minute,
                  <br />
                  click to{" "}
                  <span
                    className="resendOTPLink"
                    onClick={() => checkResendOTP()}
                  >
                    <strong>RESEND OTP?</strong>
                  </span>
                  <span
                    className="incorrectOTPMsg hidden"
                    style={
                      isInCorrectOTP
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    You have entered incorrect OTP
                  </span>
                  <span
                    className="newOTPSendMsg"
                    style={{ display: isOTPResend ? "block" : "none" }}
                  >
                    New OTP has been sent
                  </span>
                </div>
              </section>
              <section className="otpCredentialContainer">
                <div className="verifyUserLink">
                  <Link
                    id="goToOTP"
                    to="#"
                    onClick={(e) => {
                      verifyOTPEntered(e);
                    }}
                  >
                    <span>&#xBB;</span>
                  </Link>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="dottedBlocksLogin">
            <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
        <section></section>
       
            </div> */}
      {/* <section className="ringSection">
                <div></div>
            </section> */}
      <div className="semiCircle"></div>
      {/* <section className="ringSection-sec">
                <div></div>
            </section> */}
    </div>
  );
};

export default Otp;
