import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store/index";
import { Provider } from "react-redux";

import axios from "axios";

if (process.env.REACT_APP_ENV == "PROD" || process.env.REACT_APP_ENV == "UAT") {
  console.log = function () {};
}

try {
  axios.interceptors.request.use(
    (request: any) => {
      if (!request.url.includes("token")) {
        request.headers["Authorization"] = localStorage.getItem("authHeader");
      }
      // console.log(request)
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
} catch (e) {
  console.log("accessToken ", e);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
