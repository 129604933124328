import React, { useEffect, useState, useRef } from "react";
import "./ShippingAddressPopup.scss";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import { shippingAddressAction } from "../../../store/shippingAddress";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import {
  makeGetRequest,
  makeDeleteRequest,
  makePostRequest,
} from "../../../services/httpServices";
import { Divider, IconButton } from "@material-ui/core";
import { readAllData } from "../../../indexdb_utils";
import { TextField } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getShippingAddressMasterData } from "../../../utils/home_axios";
const ShippingAddressPopup = () => {
  const [showLoader, setShowLoader] = useState(false);
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const dispatch = useDispatch();
  const selectedReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const getAdrressListUrl = `${process.env.REACT_APP_SHIPPINGADDRESS_LIST}`;
  const fetchShippingAddressListAPI =
    baseServiceUrl + getAdrressListUrl + userInfo.legacycode;
  const addShippingAddressAPI =
    baseServiceUrl + `${process.env.REACT_APP_ADD_SHIPPINGADDRESS}`;
  const fetchDropdownMasterData =
    baseServiceUrl + `${process.env.REACT_APP_SHIPPINGADDRESS_DROPDOWNDATA}`;
  const deleteShippingAddressUrl = `${baseServiceUrl}${process.env.REACT_APP_DELETE_SHIPPINGADDRESS}`;
  const [shippingAddressList, setShippingAddressList] = useState([]);
  // const [shippingAddressDropdownDataVal, setshippingAddressDropdownDataVal] =
  //   useState([]);

  const [pinCodeDropdownVal, setPinCodeDropdownVal] = useState([]);
  const [stateDropdownVal, setStateDropdownVal] = useState([]);
  const [cityDropdownVal, setCityDropdownVal] = useState([]);
  const [townDropdownVal, setTownDropdownVal] = useState([]);

  const shippingAddressDropdownDataVal = useSelector(
    (state: RootState) => state.request.shippingAddressMaster
  );
  const showShippingAddressPopup = useSelector(
    (state: RootState) => state.shippingAddress.showShippingAddressPopup
  );

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    dispatch(shippingAddressAction.setshowShippingAddressPopup(false));
  };

  const [showAddNewAddress, setshowAddNewAddress] = useState(false);
  const handleAddNewShippingAddressClose = () => {
    resetFormFields();
    setshowAddNewAddress(false);
  };

  const setShipToAddressRecordId = (params: any) => {
    dispatch(requestActions.setShipToAddressRecordId(params));
  };

  useEffect(() => {
    fetchShippingAddressList();
    console.log(
      shippingAddressDropdownDataVal,
      "shippingAddressDropdownDataValshippingAddressDropdownDataValshippingAddressDropdownDataVal"
    );
    if (shippingAddressDropdownDataVal.length == 0) {
      fetchMasterShippingAddress();
    }
    //fetchMasterDataDropdownList();
  }, []);

  const fetchShippingAddressList = async () => {
    setShowLoader(true);
    try {
      let shippingAddressList = await makeGetRequest(
        fetchShippingAddressListAPI
      );
      console.log(
        shippingAddressList,
        "shippingAddressListshippingAddressList"
      );
      if (
        shippingAddressList &&
        shippingAddressList.data &&
        // shippingAddressList.data.isSuccess &&
        shippingAddressList.data.reqWrap
        // &&
        // shippingAddressList.data.reqWrap.length
      ) {
        setShippingAddressList(shippingAddressList.data.reqWrap);
        setShowLoader(false);
      }
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      // pwa-code-start
      if ("indexedDB" in window && e.message === "Network Error") {
        let userReqListIndexedDB = await readAllData(
          "posts",
          fetchShippingAddressListAPI
        );
        setShippingAddressList(userReqListIndexedDB);
      }
      // pwa-code-end
    }
  };
  async function fetchMasterShippingAddress() {
    const response: any = await getShippingAddressMasterData();
    if (response && response.length) {
      dispatch(requestActions.setShippingAddressMaster(response));
    }
  }
  // const fetchMasterDataDropdownList = async () => {
  //   try {
  //     let shippingAddressDropdownData = await makeGetRequest(
  //       fetchDropdownMasterData
  //     );
  //     console.log(
  //       shippingAddressDropdownData,
  //       "shippingAddressListshippingAddressList"
  //     );
  //     if (
  //       shippingAddressDropdownData &&
  //       shippingAddressDropdownData.data &&
  //       // shippingAddressDropdownData.data.isSuccess &&
  //       shippingAddressDropdownData.data.reqWrap
  //       //  &&
  //       // shippingAddressDropdownData.data.reqWrap.length
  //     ) {
  //       setshippingAddressDropdownDataVal(
  //         shippingAddressDropdownData.data.reqWrap
  //       );
  //     }
  //   } catch (e: any) {
  //     //localStorage.removeItem('authHeader');
  //     console.log(e);
  //     // pwa-code-start
  //     if ("indexedDB" in window && e.message === "Network Error") {
  //       let userReqListIndexedDB = await readAllData(
  //         "posts",
  //         fetchDropdownMasterData
  //       );
  //       setshippingAddressDropdownDataVal(userReqListIndexedDB);
  //     }
  //     // pwa-code-end
  //   }
  // };

  const fetchPinCodeDropdownValues = () => {
    if (
      shippingAddressDropdownDataVal &&
      shippingAddressDropdownDataVal.length
    ) {
      const pincodelist: any = shippingAddressDropdownDataVal.map(
        (x: any) => x.pinCode
      );
      const uniqPincodeList: any = Array.from(new Set(pincodelist));
      setPinCodeDropdownVal(uniqPincodeList);
    }
  };

  const fetchStateDropdownValues = (selectedPincode: string) => {
    if (
      shippingAddressDropdownDataVal &&
      shippingAddressDropdownDataVal.length
    ) {
      var statelist = shippingAddressDropdownDataVal
        .filter(function (el: any) {
          return el.pinCode == selectedPincode;
        })
        .map((x: any) => x.state);
      const uniqStateList: any = Array.from(new Set(statelist));
      setStateDropdownVal(uniqStateList);
    }
  };

  const fetchCityDropdownValue = (selectedState: string) => {
    if (
      shippingAddressDropdownDataVal &&
      shippingAddressDropdownDataVal.length
    ) {
      var citylist = shippingAddressDropdownDataVal
        .filter(function (el: any) {
          return el.state == selectedState;
        })
        .map((x: any) => x.city);
      const uniqCityList: any = Array.from(new Set(citylist));
      setCityDropdownVal(uniqCityList);
    }
  };

  const fetchTownDropdownValue = (selectedCity: any) => {
    if (
      shippingAddressDropdownDataVal &&
      shippingAddressDropdownDataVal.length
    ) {
      var townlist = shippingAddressDropdownDataVal
        .filter(function (el: any) {
          return el.state == state && el.city == selectedCity;
        })
        .map((x: any) => x.town);
      const uniqTownList: any = Array.from(new Set(townlist));
      setTownDropdownVal(uniqTownList);
    }
  };

  function setShippingAddressForRequest(e: any) {
    const selectedAddress: string =
      e.currentTarget.getAttribute("data-address");

    switch (selectedReq) {
      case "Samples":
        dispatch(requestActions.setSampleShipToAddress(selectedAddress));
        break;
      case "Safety Kit":
        dispatch(requestActions.setsafetyKitShipToAddress(selectedAddress));
        break;

      case "Patient Screening":
        dispatch(requestActions.setScreeningDeliveryAddress(selectedAddress));
        break;
      case "Patient Awareness Material":
        dispatch(
          requestActions.setpatientAwarenessDeliveryAddress(selectedAddress)
        );
        break;
      default:
        break;
    }
    handleClose();
  }

  async function deleteSelectedAddress(event: any) {
    const deleteAPI = `${deleteShippingAddressUrl}`;
    const recordId = event.currentTarget.getAttribute("data-id");
    const selectedAddrReqForm =
      event.currentTarget.getAttribute("data-address");

    event.stopPropagation();
    try {
      let deleteShippingAddressAPI = await makeDeleteRequest(
        `${deleteAPI}${recordId}`
      );
      if (deleteShippingAddressAPI.data.status == "failure") {
        setShowErrorToast({
          from: "Delete",
          isSuccess: false,
        });
      } else {
        setShowSuccessToast({
          from: "Delete",
          isSuccess: true,
        });
        removeValuefromRequestForm(selectedAddrReqForm);
        fetchShippingAddressList();
      }
    } catch (e: any) {
      if ("indexedDB" in window && e.message === "Network Error") {
        let userReqListIndexedDB = await readAllData(
          "posts",
          `${deleteAPI}${recordId}`
        );
        setShippingAddressList(userReqListIndexedDB);
      }
    }
  }
  const sampleShipToAddress = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.samples.sampleshipToAddress
  );
  const safetyKitshipToAddress = useSelector(
    (state: RootState) =>
      state.request.patientCareReq.safetyKit.safetyKitShipToAddress
  );
  const patientAwarenessDeliveryAddress = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .deliveryAddr
  );
  const screeningDeliveryAddress = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.deliveryAddrPS
  );

  function removeValuefromRequestForm(selectedAddrReqForm: any) {
    console.log(selectedReq, "currently Selected Request");

    switch (selectedReq) {
      case "Samples":
        sampleShipToAddress === selectedAddrReqForm
          ? dispatch(requestActions.setSampleShipToAddress(""))
          : console.log("Sample delete failed");

        break;
      case "Safety Kit":
        safetyKitshipToAddress === selectedAddrReqForm
          ? dispatch(requestActions.setsafetyKitShipToAddress(""))
          : console.log("Safety Kit delete failed");

        break;

      case "Patient Screening":
        screeningDeliveryAddress === selectedAddrReqForm
          ? dispatch(requestActions.setScreeningDeliveryAddress(""))
          : console.log("Patient Screening delete failed");
        break;
      case "Patient Awareness Material":
        patientAwarenessDeliveryAddress === selectedAddrReqForm
          ? dispatch(requestActions.setpatientAwarenessDeliveryAddress(""))
          : console.log("Patient Awareness Material delete failed");

        break;
      default:
        break;
    }
  }
  function displayShippingAddressForHCP(obj: any) {
    const address = obj.address ? `${obj.address},` : "";
    const town = obj.town ? `${obj.town},` : "";
    const city = obj.city ? `${obj.city},` : "";
    const state = obj.state ? `${obj.state},` : "";
    const pincode = obj.pincode ? `${obj.pincode}` : "";
    const hcpShippingTown = `${address}${town}`;
    const hcpShippingCityState = `${city}${state}${pincode}`;
    const completeAddress = `${hcpShippingTown}${hcpShippingCityState}`;
    const addressRecordId = obj.recordId ? `${obj.recordId}` : "";
    return (
      <ListItem
        button
        key={obj.recordId}
        className="hcpAddressContainer"
        data-address={completeAddress}
        divider={true}
        onClick={(e: any) => {
          setShippingAddressForRequest(e);
          setShipToAddressRecordId(addressRecordId);
        }}
      >
        <IconButton className="deleteShippingAddress">
          <DeleteIcon
            fontSize="small"
            data-pincode={obj.pincode}
            data-id={obj.recordId}
            data-address={completeAddress}
            onClick={(e: any) => deleteSelectedAddress(e)}
          ></DeleteIcon>
        </IconButton>
        <ListItemText primary={hcpShippingTown} />
        <ListItemText secondary={hcpShippingCityState} />
      </ListItem>
    );
  }

  const [address, setaddress] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [town, setTown] = useState("");
  const [recordIdNewAddress, setrecordIdNewAddress] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handlePincodeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    newValue: any
  ) => {
    const selectedPincode: any = newValue;
    setPincode(newValue as string);
    console.log(
      shippingAddressDropdownDataVal,
      "shippingAddressDropdownDataVal"
    );
    const filteredAddress: any = shippingAddressDropdownDataVal.filter(
      (el: any) => el.pinCode == newValue
    );
    // console.log(filteredAddress, "filteredAddress");
    if (filteredAddress && filteredAddress.length) {
      setState(filteredAddress[0].state);
      setCity(filteredAddress[0].city);
      setTown(filteredAddress[0].town);
      setrecordIdNewAddress(filteredAddress[0].recordId);
    }

    //fetchStateDropdownValues(selectedPincode);
  };

  const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedState: any = event.target.value;
    setState(event.target.value as string);
    fetchCityDropdownValue(selectedState);
  };

  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCity(event.target.value as string);
    fetchTownDropdownValue(event.target.value);
  };

  const handleTownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTown(event.target.value as string);
  };

  useEffect(() => {
    disableSubmitBtn();
  }, [address, city, town, state, pincode]);

  function disableSubmitBtn() {
    address.length > 0 &&
    city.length > 0 &&
    town.length > 0 &&
    state.length > 0 &&
    pincode.length > 0
      ? setDisableSubmit(false)
      : setDisableSubmit(true);
  }

  const submitNewShippingAddress = async () => {
    let reqObject = {
      HCP__r: {
        USV_Legacy_Code__c: userInfo ? userInfo.legacycode : "",
      },
      USV_Town__c: town,
      USV_State__c: state,
      USV_City__c: city,
      USV_Pincode__c: pincode,
      USV_Address__c: address,
      USV_City_Master__c: recordIdNewAddress,
    };
    try {
      const output = await makePostRequest(
        addShippingAddressAPI,
        new RequestParams(reqObject)
      );

      if (output.data.status == "failure") {
        setShowErrorToast({
          from: "AddNewAddress",
          isSuccess: false,
        });
      } else {
        setShowSuccessToast({
          from: "AddNewAddress",
          isSuccess: true,
        });
        resetFormFields();
        fetchShippingAddressList();
        handleAddNewShippingAddressClose();
      }
    } catch (e: any) {
      console.log("send Req Msg");
    }
  };

  function resetFormFields() {
    setaddress("");
    setCity("");
    setTown("");
    setPincode("");
    setState("");
  }

  const [showSuccessToast, setShowSuccessToast] = useState({
    from: "",
    isSuccess: false,
  });
  const [showErrorToast, setShowErrorToast] = useState({
    from: "",
    isSuccess: false,
  });
  const hideSuccessToast = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessToast({
      from: "",
      isSuccess: false,
    });
  };
  const hideErrorToast = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorToast({
      from: "",
      isSuccess: false,
    });
  };
  return (
    <>
      <Snackbar
        open={showSuccessToast.isSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={hideSuccessToast}
      >
        <Alert onClose={hideSuccessToast} severity="success">
          {showSuccessToast.from == "Delete"
            ? "Address Deleted Successfully"
            : showSuccessToast.from == "AddNewAddress"
            ? "Address Added Successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorToast.isSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={hideErrorToast}
      >
        <Alert onClose={hideErrorToast} severity="error">
          {showErrorToast.from == "Delete"
            ? "Failed to Delete Address"
            : showErrorToast.from == "AddNewAddress"
            ? "Failed to Add Address"
            : ""}
        </Alert>
      </Snackbar>

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={showShippingAddressPopup}
      >
        <DialogTitle id="simple-dialog-title" className="dialogTitle">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{"Your Address"}</Box>
            <Box className="CloseDialog">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <label className="addressNotification">
          *Only 5 address can be linked to an account
        </label>
        <Divider />
        <List>
          {showLoader ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <>
              {shippingAddressList &&
                shippingAddressList.map((item: any) =>
                  displayShippingAddressForHCP(item)
                )}
            </>
          )}

          {shippingAddressList && shippingAddressList.length < 5 ? (
            <ListItem
              autoFocus
              button
              onClick={() => {
                setshowAddNewAddress(true);
                fetchPinCodeDropdownValues();
                //fetchStateDropdownValues();
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add Address" />
            </ListItem>
          ) : null}
        </List>
      </Dialog>

      {/* Add a new Shipping Address popup */}
      <Dialog aria-labelledby="simple-dialog-title" open={showAddNewAddress}>
        <DialogTitle id="simple-dialog-title" className="dialogTitle">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{"Add a New Address"}</Box>
            <Box className="CloseDialog">
              <IconButton onClick={handleAddNewShippingAddressClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <section className="addNewAdrressForm">
          <section className="addNewAdrressFormField mt-2">
            <TextField
              id="standard-basic"
              // label="House number,Apartment"
              label="Enter your complete address"
              type="text"
              value={address}
              onChange={(event) => {
                setaddress(event.target.value);
              }}
              autoComplete="off"
              required
            />
          </section>

          <section className="addNewAdrressFormField mt-4">
            {/* <TextField
              id="standard-basic"
              label="Pincode"
              type="number"
              value={pincode}
              onChange={(event) => {
                setPincode(event.target.value);
              }}
              autoComplete="off"
              required
            /> */}
            <Autocomplete
              id="combo-box-demo"
              options={pinCodeDropdownVal}
              onChange={(event: any, newValue: any) => {
                handlePincodeChange(event, newValue);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField required {...params} label="Select Pincode" />
              )}
            />
          </section>

          <section className="addNewAdrressFormField mt-4">
            <TextField
              disabled
              className="addNewAdrressState"
              id="standard-basic"
              label="State"
              type="text"
              value={state}
              autoComplete="off"
            />

            {/* <FormControl required>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                onChange={handleStateChange}
              >
                {stateDropdownVal.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
           */}
          </section>

          <section className="addNewAdrressFormField mt-4">
            <TextField
              disabled
              className="addNewAdrressState"
              id="standard-basic"
              label="City"
              type="text"
              value={city}
              autoComplete="off"
            />
            {/* <FormControl required>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={city}
                onChange={handleCityChange}
              >
                {cityDropdownVal.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
         */}
          </section>

          <section className="addNewAdrressFormField mt-4">
            <TextField
              disabled
              className="addNewAdrressState"
              id="standard-basic"
              label="Town"
              type="text"
              value={town}
              autoComplete="off"
            />
            {/* <FormControl required disabled>
              <InputLabel id="demo-simple-select-label">Town</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={town}
                onChange={handleTownChange}
              >
                {townDropdownVal.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          */}
          </section>

          <div className="submitNewAddress">
            <Button
              disabled={disableSubmit}
              variant="contained"
              onClick={(e) => submitNewShippingAddress()}
              className="submitNewShippingaddDetails mt-3 mb-3"
            >
              Add New Address
            </Button>
          </div>
        </section>
      </Dialog>
    </>
  );
};

export default ShippingAddressPopup;
