import React, { Component, useEffect, useState } from "react";
import "./Events.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import { PageViewGA, EventGA } from "../../utils/googleAnalyticsTracking";
import { readAllData } from "../../indexdb_utils";
import { clearSessionData } from "../../utils/home_axios";
const localizer = momentLocalizer(moment);

const Events = () => {
  const history = useHistory();
  const serviceUrl = `${process.env.REACT_APP_FETCH_EVENTS}`;
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const fetchEventApi = baseServiceUrl + serviceUrl;
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [focusedEvent, setFocusedEvent] = useState({
    title: "",
    venue: "",
    start: "",
    end: "",
    description: "",
    hconame: "",
    noofparticipants: "",
    noofextparticipants: "",
    speakername: "",
    hcpname: "",
    location: "",
  });
  const [modEventDtls, setModEventDtls] = useState([
    {
      start: new Date(),
      end: new Date(),
      title: "",
      allDay: false,
      hexColor: "",
      color: "",
    },
  ]);
  const hexCodeArray = [
    {
      hexColor: "#ffe1de",
      color: "#EC5241",
    },
    {
      hexColor: "#e4e4e4",
      color: "#419268",
    },
    {
      hexColor: "#e3dfe6",
      color: "#803BC1",
    },
    {
      hexColor: "#efefef",
      color: "#2E53C9",
    },
  ];
  const isSitedown = `${process.env.REACT_APP_SITEDOWN}`;
  const fetchEventsData = async () => {
    try {
      let userDetails: any = localStorage.getItem("userDetails")
        ? localStorage.getItem("userDetails")
        : "";
      let legacycode = JSON.parse(userDetails).legacycode;
      let payload = { legacycode: legacycode };
      let output: any;

      output = await makePostRequest(
        fetchEventApi + "",
        new RequestParams(payload)
      );
      if (output && output.status == 200) {
        let modEventDtlsTemp: any = (
          output.data ? output["data"].lstEventDetails : modEventDtls
        ).map((elem: any, index: number) => {
          let startDate = new Date(elem.starttime);
          let endDate = new Date(elem.endtime);

          return {
            start: startDate,
            end: endDate,
            title: elem.subject ? elem.subject : "No subject",
            allDay: false,
            hexColor: hexCodeArray[index % hexCodeArray.length].hexColor,
            color: hexCodeArray[index % hexCodeArray.length].color,
            venue: elem.venue ? elem.venue : "Not Available",
            description: elem.description ? elem.description : "Not Available",
            hconame: elem.hconame ? elem.hconame : "Not Available",
            noofparticipants: elem.noofparticipants
              ? elem.noofparticipants
              : "0",
            noofextparticipants: elem.noofextparticipants
              ? elem.noofextparticipants
              : "0",
            speakername: elem.speakername ? elem.speakername : "Not available",
            hcpname: elem.hcpname ? elem.hcpname : "Not Available",
            location: elem.location ? elem.location : "Not Available",
          };
        });
        setModEventDtls(modEventDtlsTemp);
      } else {
        clearSessionData();
      }
    } catch (e: any) {
      console.log(e);
      // pwa-code-start
      if ("indexedDB" in window && e.message === "Network Error") {
        //console.log("in indexedDB")
        let eventDataIndexedDb = await readAllData("posts", fetchEventApi);
        //console.log("Store latest Events in indexedDB", eventDataIndexedDb);
        let modEventDtlsTemp: any = (
          eventDataIndexedDb.lstEventDetails
            ? eventDataIndexedDb.lstEventDetails
            : modEventDtls
        ).map((elem: any, index: number) => {
          let startDate = new Date(elem.starttime);
          let endDate = new Date(elem.endtime);
          return {
            start: startDate,
            end: endDate,
            title: elem.subject ? elem.subject : "No subject",
            allDay: false,
            hexColor: hexCodeArray[index % hexCodeArray.length].hexColor,
            color: hexCodeArray[index % hexCodeArray.length].color,
            venue: elem.venue ? elem.venue : "Not Available",
            description: elem.description ? elem.description : "Not Available",
            hconame: elem.hconame ? elem.hconame : "Not Available",
            noofparticipants: elem.noofparticipants
              ? elem.noofparticipants
              : "0",
            noofextparticipants: elem.noofextparticipants
              ? elem.noofextparticipants
              : "0",
            speakername: elem.speakername ? elem.speakername : "Not available",
            hcpname: elem.hcpname ? elem.hcpname : "Not Available",
            location: elem.location ? elem.location : "Not Available",
          };
        });
        setModEventDtls(modEventDtlsTemp);
      }
      // pwa-code-end
    }
  };

  useEffect(() => {
    PageViewGA();
    if (
      localStorage.getItem("authHeader") &&
      localStorage.getItem("authHeader") != "" &&
      isSitedown == "false"
    ) {
      // console.log("in events")
      fetchEventsData();
    } else {
      history.push("/login");
    }
  }, []);

  const eventStyleGetter = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    // console.log(event);
    let backgroundColor = event.hexColor;
    // console.log(backgroundColor)
    let style = {
      backgroundColor: backgroundColor,
      color: event.color,
    };
    return {
      style: style,
    };
  };

  const handleClose = () => {
    setOpenEventDialog(false);
  };
  const selectedEvent = (event: any, e: any) => {
    // console.log(event, e)
    setFocusedEvent(event);
    setOpenEventDialog(true);
    EventGA("EVENTS", "Click to View Event Detail", "Events Page");
  };

  const isURL = (eventLocation: string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(eventLocation);
  };
  return (
    <div className="eventWrapper mt-4">
      <Dialog
        className="eventDialogWrapper"
        aria-labelledby="simple-dialog-title"
        open={openEventDialog}
      >
        <DialogTitle className="eventDialogTitle">
          {focusedEvent.title}
          <Close className="closeEventDialog" onClick={handleClose} />
        </DialogTitle>
        <div className="eventDialogContent">
          <article className="mt-2">
            <LocationOnIcon />
            <span>
              {focusedEvent.location ? (
                isURL(focusedEvent.location) ? (
                  <a href={focusedEvent.location} target="blank">
                    {focusedEvent.location}
                  </a>
                ) : (
                  focusedEvent.location
                )
              ) : isURL(focusedEvent.venue) ? (
                <a href={focusedEvent.venue} target="blank">
                  {focusedEvent.venue}
                </a>
              ) : (
                focusedEvent.venue
              )}
            </span>
          </article>
          <article className="eventStartEndTime">
            <section>
              <WatchLaterIcon />
              <div>
                <label>Start Time</label>
                <article>
                  {moment(focusedEvent.start).format("hh:mm A")}
                </article>
              </div>
            </section>
            <section>
              <WatchLaterIcon />
              <div>
                <label>End Time</label>
                <article>{moment(focusedEvent.end).format("hh:mm A")}</article>
              </div>
            </section>
          </article>
          <article>
            <label>Description</label>
            <p>{focusedEvent.description}</p>
          </article>
          <article className="eventHcoName">
            <NoteAddIcon />
            <div>
              <label>HCP Name</label>
              <p>
                {focusedEvent.hcpname
                  ? focusedEvent.hcpname
                  : focusedEvent.hconame}
              </p>
            </div>
          </article>
          <article className="eventHcoName">
            <PeopleIcon />
            <div>
              <label>Number Of Participants</label>
              <p>{focusedEvent.noofparticipants}</p>
            </div>
          </article>
          <article className="eventHcoName">
            <PeopleIcon />
            <div>
              <label>Number Of External Participants</label>
              <p>{focusedEvent.noofextparticipants}</p>
            </div>
          </article>
          <article className="eventHcoName">
            <PersonIcon />
            <div>
              <label>Speaker Name</label>
              <p>{focusedEvent.speakername}</p>
            </div>
          </article>
        </div>
      </Dialog>
      <Calendar
        messages={{ next: ">", previous: "<" }}
        localizer={localizer}
        events={modEventDtls}
        startAccessor="start"
        endAccessor="end"
        views={["month", "day", "week"]}
        onSelectEvent={selectedEvent}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default Events;
