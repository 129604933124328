import React from "react";
import "./MobileToggleComponent.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import usvLogo from "../../assets/images/USV_Blue_Logo.svg";
import { NavLink } from "react-router-dom";
import AboutUs from "../../assets/images/MobileAssets/Icon-material-people.svg";
import MyAccount from "../../assets/images/MobileAssets/Icon-feather-user.svg";
import Faq from "../../assets/images/MobileAssets/Icon-metro-question.svg";
import Logout from "../../assets/images/MobileAssets/mobileLogout.svg";
import { loginActions } from "../../store/login";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import doctor from "../../assets/images/maleDoctor.jpg";

import { requestActions } from "../../store/request";
const MobileToggle = () => {
  const dispatch = useDispatch();
  const ToggleListItem = [
    "My Account",
    "Support Tickets",
    "FAQs",
    "About Us",
    "Terms and Conditions",
    "Our Vision",
    "Our Mission",
    "Disclaimer",
  ];
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const profileImg = useSelector((state: RootState) => state.login.profileImg);

  // const renderIcon=(renderText:any)=>{
  //     switch (renderText) {
  //         case  'Logout' :
  //             return  <img src={Logout} alt='Logout'/>
  //       }
  // }

  const [value, setValue] = React.useState("home");
  const [active, setActive] = React.useState(false);

  const goToSupportTickets = (event: any, newValue: any) => {
    setValue(newValue);
    dispatch(requestActions.setrequestTab(false));
    dispatch(requestActions.setrequestTabIndex(3));
    setActive(false);
    setValue("request");
  };

  const renderLink = (renderText: any) => {
    switch (renderText) {
      case "My Account":
        return (
          <NavLink to="/userProfile" activeClassName="ToggleLinkActive">
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "Support Tickets":
        return (
          <NavLink
            to="/request"
            activeClassName="ToggleLinkActive"
            onClick={(e: any) => {
              goToSupportTickets(e, "supportTicket");
            }}
          >
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "FAQs":
        return (
          <NavLink to="/faq" activeClassName="ToggleLinkActive">
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "About Us":
        return (
          <NavLink
            activeClassName="ToggleLinkActive"
            to={{ pathname: "https://www.usvindia.com/aboutus.php" }}
            target="_blank"
          >
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "Terms and Conditions":
        return (
          <NavLink
            activeClassName="ToggleLinkActive"
            to={{ pathname: "https://www.usvindia.com/terms-condition.php" }}
            target="_blank"
          >
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "Our Vision":
        return (
          <NavLink
            activeClassName="ToggleLinkActive"
            to={{ pathname: "https://www.usvindia.com/vision.php" }}
            target="_blank"
          >
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "Our Mission":
        return (
          <NavLink
            activeClassName="ToggleLinkActive"
            to={{ pathname: "https://www.usvindia.com/mission.php" }}
            target="_blank"
          >
            <ListItemText primary={renderText} />
          </NavLink>
        );
      case "Disclaimer":
        return (
          <NavLink to="/disclaimer" activeClassName="ToggleLinkActive">
            <ListItemText primary={renderText} />
          </NavLink>
        );
      // case  'Logout' :
      //     return <NavLink className='logout-mobile' to="#" onClick={(e: any) => setshowLogoutPopUp(true)}><ListItemText primary={renderText} /></NavLink>
    }
  };

  const setshowLogoutPopUp = (params: any) => {
    dispatch(loginActions.setshowLogoutPopUp(params));
  };

  return (
    <>
      <div className="mobileToggleWrapper">
        <div className="usvIconDivMobile">
          <div className="doctorProfileImgtoggle">
            <div
              className="profileImgDivContainer"
              style={{
                background:
                  profileImg == "" || userInfo.profileimage == null
                    ? "url(" + doctor + ")"
                    : "url(" + profileImg + ")",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
          <div className="hcpProfileDesctoggle">
            <div className="hcpNametoggle">
              <h2>Welcome</h2>
              <h4>Dr. {userInfo.hcpname}</h4>
            </div>
            <div className="hcpSpecstoggle">{userInfo.speciality}</div>
          </div>
        </div>
        <List className="toggle-list">
          {ToggleListItem.map((text, index) => (
            <ListItem button key={text}>
              {/* {text === 'Logout' ? <ListItemIcon>{(()=> renderIcon(text))()}</ListItemIcon>: null} */}
              {(() => renderLink(text))()}
            </ListItem>
          ))}
        </List>
        <div className="logoutMobileText">
          <img src={Logout} alt="Logout" />
          <span
            className="logout-mobile"
            onClick={(e: any) => setshowLogoutPopUp(true)}
          >
            Logout
          </span>
        </div>
        {window.innerWidth > window.innerHeight ? (
          <div className="copyrightslandscape">
            <p>©2021 USV Private Limited. All Rights Reserved.</p>
          </div>
        ) : (
          <div className="copyrights">
            <p>©2021 USV Private Limited. All Rights Reserved.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileToggle;
