import { useState, useEffect } from "react";
import "./Login.scss";
import "./LoginMobile.scss";
import usvLogo from "../../assets/images/USV_Blue_Logo.svg";
import doctorImg1 from "../../assets/images/loginImg1.svg";
import doctorImg2 from "../../assets/images/loginImg2.svg";
import TextField from "@material-ui/core/TextField";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import { useDispatch } from "react-redux";
import { loginActions } from "../../store/login";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import MobileWrapper from "../../mobile-Components/MobileWrapperComponent/MobileWrapperComponent";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import bgImg2 from "../../assets/images/background_mobile.png";

const Login = () => {
  const [showErrorMesage, setErrorMsgStatus] = useState(false);
  const [focusEnalbed, setfocusEnalbed] = useState(false);
  const [uname, setUname] = useState("");
  const history = useHistory();
  const ErrorMessage = "Incorrect Credentials";
  const [enableLoginBtn, setEnableLoginBtn] = useState(true);
  // Middle ware Integration
  const baseServiceUrlMiddleware =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA_MIDDLEWARE}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT_MIDDLEWARE}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD_MIDDLEWARE}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV_MIDDLEWARE}`;
  //const baseServiceUrlMiddleware = (`${process.env.REACT_APP_BASE_SERVICEURL_DEV_MIDDLEWARE}`);
  const serviceUrlMiddleware = `${process.env.REACT_APP_LOGIN_MIDDLEWARE}`;
  const loginApiMiddleware = baseServiceUrlMiddleware + serviceUrlMiddleware;

  useEffect(() => {
    if (uname.length === 0) {
      setErrorMsgStatus(false);
    }
  }, [uname]);

  const checkInputFocus = (e: any) => {
    setfocusEnalbed(true);
  };
  const checkInputBlur = (e: any) => {
    setfocusEnalbed(false);
  };

  async function verifyUserDetails(event: any, fromEnterKey: boolean) {
    clearCache();
    fromEnterKey ? setEnableLoginBtn(false) : setEnableLoginBtn(true);
    let phoneNoPattern = /\d{9}/;
    let outputMiddleware;
    if (uname.length > 0) {
      localStorage.setItem("uname", uname);
      try {
        if (uname.match(phoneNoPattern)) {
          outputMiddleware = await makePostRequest(
            loginApiMiddleware,
            new RequestParams({ phoneNumber: uname })
          );
          //console.log(outputMiddleware);
        } else {
          outputMiddleware = await makePostRequest(
            loginApiMiddleware,
            new RequestParams({ emailId: uname })
          );
          //console.log(outputMiddleware);
        }

        if (
          outputMiddleware.data.trim().includes("HCP OTP Send") ||
          outputMiddleware.data.trim().includes("HCP OTP Sent")
        ) {
          history.push("/verifyUser");
        } else {
          setErrorMsgStatus(true);
          event.preventDefault();
        }
      } catch (e) {
        localStorage.removeItem("authHeader");
        console.log(e);
      }
    } else {
      setErrorMsgStatus(true);
      event.preventDefault();
    }
    setEnableLoginBtn(true);
  }

  const clearCache = () => {
    if (window.caches !== undefined) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
  };

  return mobileScreen() ? (
    <MobileWrapper>
      <div id="login-container-mobile " className="login-container-mobile">
        <div className="mobile-image-wrapper">
          <div className="mobile-left-docter">
            {" "}
            <img src={doctorImg2} alt={doctorImg2} />
          </div>
          <div className="mobile-right-docter">
            {" "}
            <img src={doctorImg1} alt={doctorImg1} />
          </div>
        </div>
        <div className="mobile-login-wrapper">
          <div className="mobile-login">
            <div id="mobile-logo" className="mobile-logo">
              <img src={usvLogo} alt="usvlogo"></img>
            </div>
            <h1>Login</h1>
            <div className="loginCredential-mobile">
              <PermIdentityOutlinedIcon />
              <TextField
                placeholder="Enter Phone Number or Email Id"
                type="email"
                onFocus={(e) => {
                  checkInputFocus(e);
                }}
                onBlur={(e) => {
                  checkInputBlur(e);
                }}
                onChange={(e) => setUname(e.target.value.toLowerCase())}
                value={uname}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && enableLoginBtn)
                    verifyUserDetails(e, true);
                }}
              ></TextField>
            </div>
            <p
              className="validateUname hidden mt-1 "
              style={
                showErrorMesage ? { display: "block" } : { display: "none" }
              }
            >
              {ErrorMessage}
            </p>
          </div>
          <div className="mobile-button">
            <Button
              className={`submit-button ${
                focusEnalbed ? "submit-botton-enabled" : null
              }`}
              onClick={(e) => {
                verifyUserDetails(e, false);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </MobileWrapper>
  ) : (
    <div id="login-container" className="container-fluid">
      <div className="row">
        <div className="left-container col-lg-6 col-md-6 col-12">
          <section className="doctorImgContainer">
            <div className="loginimagecontainer col-lg-4 col-md-4 col-12">
              <div className="loginImg1">
                <img src={doctorImg2} />
              </div>
            </div>
            <div className="loginimagecontainer col-lg-8 col-md-8 col-12">
              <div className="loginImg2">
                <img src={doctorImg1} />
              </div>
            </div>
          </section>
        </div>
        <div className="right-container col-lg-6 col-md-6 col-12 pe-0">
          <div className="signInContainer mt-3">
            <div id="logo">
              <img src={usvLogo} alt="usvlogo"></img>
            </div>
            <div id="loginDetails" className="mt-5">
              <h3>Login</h3>
              <div className="credential-Entered">Phone Number or Email Id</div>
              <section className="loginCredentialContainer">
                <TextField
                  placeholder="Enter Phone Number or Email Id"
                  type="text"
                  onChange={(e) => setUname(e.target.value)}
                  value={uname}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && enableLoginBtn)
                      verifyUserDetails(e, true);
                  }}
                />
                <p
                  className="validateUname hidden"
                  style={
                    showErrorMesage ? { display: "block" } : { display: "none" }
                  }
                >
                  {ErrorMessage}
                </p>
              </section>
              <div
                className={
                  (enableLoginBtn ? "" : "disableLoginBtn ") + "verifyUserLink"
                }
                id="goToHome"
                onClick={(e) => {
                  verifyUserDetails(e, false);
                }}
              >
                <span>&#xBB;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dottedBlocksLogin"></div>
      <div className="semiCircle"></div>
      <section className="ringSection-sec">
        <div></div>
      </section>
    </div>
  );
};

export default Login;
