import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import "./PushMarketing.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchTop10RatedArticles } from "../../utils/home_axios";
import { loginActions } from "../../store/login";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { makePatchRequest, makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../..//Class-Interfaces/httpRequestParams";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import TextField from "@material-ui/core/TextField";
import GetAppIcon from "@material-ui/icons/GetApp";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";

const PushMarketing = () => {
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);

  const testdata: any = JSON.parse(
    localStorage.getItem("topratedcontent") || "{}"
  );

  const top10data = useSelector((state: RootState) => state.login.top10Article);
  const [data, setdata] = useState(testdata ? testdata : {});

  const [showErrorToast, setErrorToastMsg] = useState({
    isError: false,
    errMsg: "",
  });
  const [showSuccessToast, setSuccessToastMsg] = useState({
    isSuccess: false,
    newReqId: "",
  });
  const [successfeedbackToast, setsuccessfeedbackToast] = useState(false);

  const [likeBtnClicked, setlikeBtnClicked] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0] &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Like__c
      ? data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Like__c
      : false
  );
  const [dislikeBtnClicked, setdislikeBtnClicked] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0] &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Dislike__c
      ? data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Dislike__c
      : false
  );

  const [disableLikeBtn, setdisableLikeBtn] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0] &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Like__c
      ? data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Like__c
      : false
  );
  const [disabledisLikeBtn, setdisabledisLikeBtn] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0] &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord &&
      data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Dislike__c
      ? data.ContentAnalyticsData[0].ContentAnalyticsrecord.USV_Dislike__c
      : false
  );
  const [scrollVal, setscrollVal] = useState(0);
  const [feedbacktext, setfeedbacktext] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0].Feedback &&
      data.ContentAnalyticsData[0].Feedback.length
      ? data.ContentAnalyticsData[0].Feedback
      : ""
  );
  const [feedbacktextSession, setfeedbacktextSession] = useState(
    data &&
      data.ContentAnalyticsData &&
      data.ContentAnalyticsData.length &&
      data.ContentAnalyticsData[0].Feedback &&
      data.ContentAnalyticsData[0].Feedback.length
      ? data.ContentAnalyticsData[0].Feedback
      : ""
  );
  const pushMarketingRef = useRef(null);

  const handleClickReqFullText = async (e: any) => {
    const articlRefNumber = e.currentTarget.getAttribute("data-refnumber");
    let reqObject = {
      servicetype: "Push Marketing",
      requesttype: "Top rated article - Full text Article",
      legacycode: userInfo ? userInfo.legacycode : "",
      speciality: userInfo ? userInfo.speciality : "",
      articleRefNo: articlRefNumber,
      monthOfArticle: getCurrentDate(),
      status: "Submitted",
    };
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      if (output.data.status === "failure") {
        setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
      } else {
        // sendContentAnalyticsData("requestfulltext", articlRefNumber);
        setSuccessToastMsg({
          isSuccess: true,
          newReqId: output.data.requestnumber,
        });
      }
    } catch (e) {
      console.log(e, "error in submitting request");
    }
  };

  function getCurrentDate() {
    var today: any = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setsuccessfeedbackToast(false);
    setErrorToastMsg({ isError: false, errMsg: "" });
    setSuccessToastMsg({ isSuccess: false, newReqId: "" });
  };

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;

  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  // useEffect(() => {
  //   const unloadCallback = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  const sendContentAnalyticsData = async (
    triggerdfrom?: any,
    articleId?: any
  ) => {
    let payload = {
      USV_Content_PDF_Name__c: data.title,
      USV_HCP__r: { USV_Legacy_Code__c: userInfo ? userInfo.legacycode : "" },
      USV_View_Timestamp__c: new Date().toISOString(),
      USV_Month__c: `${new Date().getFullYear()}-${String(
        new Date().getMonth() + 1
      ).padStart(2, "0")}-01`,
      USV_Like__c: triggerdfrom == "likebtn" ? true : likeBtnClicked,
      USV_Dislike__c: triggerdfrom == "dislikebtn" ? true : dislikeBtnClicked,
      USV_Feedback__c: feedbacktext,
      USV_Scrolled_Percentage__c: scrollVal,
      USV_Content_Download__c: triggerdfrom == "download" ? true : false,
      USV_Content_Type__c: "content",
      // ,
      // USV_Article_Id__c: articleId,
    };
    const dynamicdata =
      payload.USV_HCP__r.USV_Legacy_Code__c +
      "_" +
      payload.USV_Content_PDF_Name__c +
      "_" +
      payload.USV_Month__c +
      "_content";

    const url = `${baseServiceUrl}${process.env.REACT_APP_TOP10RATEDARTICLE_CONTENTANALYTICS}${dynamicdata}`;
    const response = await makePatchRequest(url, new RequestParams(payload));

    if (response.data.success) {
      if (triggerdfrom == "likebtn") {
        setdisableLikeBtn(true);
        setdisabledisLikeBtn(true);
      }
      if (triggerdfrom == "dislikebtn") {
        setdisableLikeBtn(true);
        setdisabledisLikeBtn(true);
      }
      if (triggerdfrom == "feedback") {
        setfeedbacktextSession(feedbacktext);
      }
      if (
        triggerdfrom &&
        triggerdfrom.length > 0 &&
        !["accessfulltext", "download"].includes(triggerdfrom)
      ) {
        setsuccessfeedbackToast(
          response && response.data && response.data.success ? true : false
        );
      }
    }
  };

  const handleScrollEvent = (event: any) => {
    let containerHeight = event.currentTarget.clientHeight;
    let scrollHeight = event.currentTarget.scrollHeight;
    let scrollTop = event.currentTarget.scrollTop;
    // console.log(((scrollTop + containerHeight) / scrollHeight) * 100);
    const scrolledPercentage = Math.floor(
      ((scrollTop + containerHeight) / scrollHeight) * 100
    );
    setscrollVal(scrolledPercentage);
  };

  const sendActionButtonClickDetails = (event: any, isAccessText: boolean) => {
    const articleId = event.currentTarget.getAttribute("data-articleId");
    let payload = {
      USV_Content_PDF_Name__c: data.title,
      USV_HCP__r: { USV_Legacy_Code__c: userInfo ? userInfo.legacycode : "" },
      USV_View_Timestamp__c: new Date().toISOString(),
      USV_Month__c: `${new Date().getFullYear()}-${String(
        new Date().getMonth() + 1
      ).padStart(2, "0")}-01`,
      USV_ServiceRaised_ArticleName__c:
        event.currentTarget.getAttribute("data-name"),
      USV_Fulltext_Actiontype__c: isAccessText
        ? "Access Full Text"
        : "Request Full Text",
      USV_Content_Type__c: "request full text",
      USV_Article_Id__c: articleId,
    };

    const dynamicdata =
      payload.USV_HCP__r.USV_Legacy_Code__c +
      "_" +
      payload.USV_Content_PDF_Name__c +
      "_" +
      payload.USV_Month__c +
      "_" +
      payload.USV_Content_Type__c +
      "_" +
      payload.USV_ServiceRaised_ArticleName__c;

    const url = `${baseServiceUrl}${process.env.REACT_APP_TOP10RATEDARTICLE_CONTENTANALYTICS}${dynamicdata}`;
    const response = makePatchRequest(url, new RequestParams(payload));
    sendContentAnalyticsData("accessfulltext", articleId);
    setsuccessfeedbackToast(
      response && response.data && response.data.success ? true : false
    );
  };

  const sendLikeFeedback = () => {
    setlikeBtnClicked(true);
    setdisableLikeBtn(true);
    setdisabledisLikeBtn(true);
    sendContentAnalyticsData("likebtn");
  };

  const senddisLikeFeedback = () => {
    setdislikeBtnClicked(true);
    setdisableLikeBtn(true);
    setdisabledisLikeBtn(true);
    sendContentAnalyticsData("dislikebtn");
  };

  const submitFeedback = (e: any) => {
    sendContentAnalyticsData("feedback");
  };
  const downloadPdf = () => {
    // let w = window.open("", "print", "width=1366,height=760");
    sendContentAnalyticsData("download");
    window.print();
  };

  return (
    <>
      {data && data.Images ? (
        <div
          ref={pushMarketingRef}
          className={
            (mobileScreen() ? "pushMarketingMobile" : "") +
            " pushMarketingContainer"
          }
          onScroll={handleScrollEvent}
        >
          <header>{data.title}</header>
          <section className="images-container">
            {data.Images.map((obj: any, index: number) => (
              <>
                <hr />
                <div key={obj.alt}>
                  <section>
                    <label className="articleIndex">Article {index + 1}</label>
                    <img className="imgSrc" src={obj.src} alt={obj.alt} />
                  </section>
                  <section className="actionButton">
                    {obj.accessText ? (
                      <a href={obj.Url} target="_blank">
                        <button
                          data-name={obj.alt}
                          data-articleId={obj.ArticleId}
                          data-refnumber={obj.ArticleRefNo}
                          onClick={(e: any) =>
                            sendActionButtonClickDetails(e, true)
                          }
                        >
                          Access Full Text
                        </button>
                      </a>
                    ) : (
                      <button
                        data-name={obj.alt}
                        data-articleId={obj.ArticleId}
                        data-refnumber={obj.ArticleRefNo}
                        onClick={(e: any) => {
                          sendActionButtonClickDetails(e, false);
                          handleClickReqFullText(e);
                        }}
                      >
                        Request Full Text
                      </button>
                    )}
                  </section>
                </div>
              </>
            ))}
          </section>
          <section className="userinputcontainer container-fluid">
            <section className="firstcontainer">
              <button
                className="votebutton"
                disabled={disableLikeBtn}
                onClick={() => sendLikeFeedback()}
              >
                <ThumbUpIcon />
              </button>
              <button
                className="votebutton"
                disabled={disabledisLikeBtn}
                onClick={() => senddisLikeFeedback()}
              >
                <ThumbDownIcon />
              </button>
              {/* <button className="votebutton" onClick={downloadPdf}>
                <GetAppIcon />
              </button> */}
            </section>
            <section
              className={`second-container ${
                mobileScreen() ? " mobileSubmitFeedbackBanner" : ""
              }`}
            >
              <TextField
                disabled={feedbacktextSession.length > 0 ? true : false}
                className="userfeedback"
                aria-label="minimum height"
                multiline
                value={feedbacktext}
                minRows={2}
                onChange={(event) => setfeedbacktext(event.target.value)}
                placeholder="Submit Feedback"
              />
              <button
                disabled={feedbacktextSession.length > 0 ? true : false}
                className="submitFeedback"
                onClick={(e) => submitFeedback(e)}
              >
                Submit
              </button>
            </section>
          </section>
          <Snackbar
            open={showErrorToast.isError}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert variant="filled" severity="warning">
              {showErrorToast.isError
                ? showErrorToast.errMsg.includes("Found Similar Record")
                  ? "Please wait same service type request is under process."
                  : "Error Occurred in Submitting The Request.\nPlease email us on info@usv.in or call us on 1800-123-4567"
                : ""}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showSuccessToast.isSuccess}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert variant="filled" severity="success">
              {showSuccessToast.newReqId.length > 0
                ? " Request " +
                  showSuccessToast.newReqId +
                  " has been created successfully"
                : " Request has been created successfully"}
            </Alert>
          </Snackbar>
          <Snackbar
            open={successfeedbackToast}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert variant="filled" severity="success">
              Thankyou For Your Feedback
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>No Content Found</div>
      )}
    </>
  );
};

export default PushMarketing;
