import { useEffect, useState } from "react";
import "./Home.scss";
import "./HomeMobile.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Footer from "../shared-component/Footer/Footer";
import { CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import UserServicePanel from "../UserServicePanel/UserServicePanel";
import { makeGetRequest, makePostRequest } from "../../services/httpServices";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { requestActions } from "../../store/request";
import { RootState } from "../../store";
import moment from "moment-timezone";
import top_picks_3 from "../../assets/images/top_picks_3.png";
import top_picks_2 from "../../assets/images/top_picks_2.png";
import top_picks_1 from "../../assets/images/top_picks_1.png";
import home_academics from "../../assets/images/home_academics.svg";
import home_research from "../../assets/images/home_research.svg";
import home_patient_care from "../../assets/images/home_patient_care.svg";
import home_patient_awareness from "../../assets/images/home_patient_awareness.svg";
import home_practice from "../../assets/images/home_practice.svg";
import { homePageData } from "../../utils/home-utils";
import TopPicksAnimation from "./TopPicksAnimation";
import Snackbar from "@material-ui/core/Snackbar";

import {
  fetchTopPickContent,
  fetchPushMarketingContent,
  getShippingAddressMasterData,
  clearSessionData,
} from "../../utils/home_axios";
import { RequestParams } from "../..//Class-Interfaces/httpRequestParams";
import { handleDownloadType } from "../../utils/globalUtils";
import axios from "axios";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Popover,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import MobileWrapper from "../../mobile-Components/MobileWrapperComponent/MobileWrapperComponent";
import MobileTopNavigation from "../../mobile-Components/MobileTopMenu/MobileTopNavigationComponent";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import MobileBottomNavigation from "../../mobile-Components/MobileBottomMenu/MobileButtomMenuComponent";
import { PageViewGA, EventGA } from "../../utils/googleAnalyticsTracking";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { loginActions } from "../../store/login";
const Home = () => {
  const [openChat, setOpenChat] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [requestData, setRequestData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [topPicksCounter, setTopPicksCounter] = useState(0);
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const dispatch = useDispatch();
  const [showLoaderService, setShowLoaderService] = useState(false);
  const [showLoaderBanner, setShowLoaderBanner] = useState(false);
  const history = useHistory();
  const [listOfReqType, setListOfReqType] = useState<any[]>([]);

  const [openTopPicksPopup, setOpenTopPicksPopup] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const [createReqTopPick, setcreateReqTopPick] = useState(false);

  const [readMoreDownload, setReadMoreDownload] = useState("");
  const [readMoreDownloadType, setReadMoreDownloadType] = useState("");
  const [readMoreFileName, setreadMoreFileName] = useState("");
  const [contentAnalyticsFileName, setContentAnalyticsFileName] = useState("");
  const [topPicks, settopPicks] = useState([]);
  const [bannerContent, setbannerContent] = useState([]);

  const [selectedTopPickProgramType, setSelectedTopPickProgramType] =
    useState("");

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;

  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  const errCreatingSponsoredService = useSelector(
    (state: RootState) => state.request.errCreatingSponsoredService
  );
  const errCreatingSponsoredServiceHome = useSelector(
    (state: RootState) => state.request.errorToastMsg
  );

  const seterrCreatingSponsoredService = (params: any) =>
    dispatch(requestActions.seterrCreatingSponsoredService(params));
  const closeSponsoredServiceAlert = () =>
    seterrCreatingSponsoredService({ isError: false, errMsg: "" });
  const closeSponsoredServiceHomeAlert = () => {
    settoppickReqErr({ isError: false, errMsg: "" });
  };

  //const setErrorToastMsg = (params: any) => dispatch(requestActions.setErrorToastMsg(params));
  const isSitedown = `${process.env.REACT_APP_SITEDOWN}`;
  useEffect(() => {
    PageViewGA();
    setShowLoaderService(true);
    if (
      localStorage.getItem("authHeader") &&
      localStorage.getItem("authHeader") != "" &&
      isSitedown == "false"
    ) {
      setTimeout(() => {
        fetchHomePageData();
        fetchMasterShippingAddress();
      }, 50);
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {}, [masterData]);
  const selectService = (event: any, serviceNameParam: string) => {
    setServiceName(serviceNameParam);
  };
  const fetchHomePageData = async () => {
    let homeApiData = await homePageData();
    // console.log("fetchHomePageData", homeApiData);

    let serviceObj = homeApiData.tabs.find(
      (obj: any) => obj.tab === "SERVICES"
    );

    let arr = Array<string>();
    if (homeApiData.tabs && homeApiData.tabs.length > 0) {
      homeApiData.tabs.map((item: any, index: number) => {
        if (item.tab === "SERVICES") {
          item.subTabs.map((item1: any, index1: number) => {
            arr.push(item1.subTab);
          });
        }
      });
    }
    setListOfReqType(arr);
    dispatch(requestActions.setServiceInfo(serviceObj));
    setMasterData(homeApiData);
    setShowLoaderService(false);
  };

  async function fetchMasterShippingAddress() {
    const response: any = await getShippingAddressMasterData();
    if (response && response.length) {
      dispatch(requestActions.setShippingAddressMaster(response));
    }
  }

  const homeCounterFn = (type: string) => {
    if (type === "decrement") {
      if (topPicksCounter < 5 % 4 && topPicksCounter != 0) {
        setTopPicksCounter(topPicksCounter + 1);
      }
    } else if (type === "increment") {
      if (topPicksCounter > -5 % 4) {
        setTopPicksCounter(topPicksCounter - 1);
      }
    }
  };

  //Top pick download

  useEffect(() => {
    fetchOneLinerServiceDesc();
    fetTopPickData();
    fetchBannerImages();
  }, []);
  const [serviceDesc, setServiceDesc] = useState([]);
  const fetchOneLinerServiceDesc = async () => {
    const sampleOptionApi =
      baseServiceUrl + `${process.env.REACT_APP_GETSERVICEDESC}`;
    let brandOptionvalue = await makeGetRequest(sampleOptionApi);
    setServiceDesc(brandOptionvalue.data);
  };

  // Banner Start
  const fetchBannerImages = async () => {
    try {
      let response = await fetchPushMarketingContent();
      if (response && response.status == "200") {
        let bannerContent = response.data
          ? response.data.lstContentWrapper
          : "";
        //console.log(response);
        setbannerContent(bannerContent);
      } else {
        clearSessionData();
      }
    } catch (e) {
      console.log(e, "error occured");
    }
  };

  const handlePushContentClick = async (fileName?: any) => {
    // window.open("/pushMarketing");
    //EXISTING FLOW
    const pushContentsUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS}`;
    const pushContentsFullUrl = baseServiceUrl + pushContentsUrl;
    setShowLoaderBanner(true);
    let pushContentResult = await makePostRequest(
      pushContentsFullUrl,
      new RequestParams({
        imagebanner: fileName,
        legacycode: userInfo ? userInfo.legacycode : "",
      })
    );

    if (
      pushContentResult &&
      pushContentResult.data &&
      pushContentResult.data.lstContentWrapper &&
      pushContentResult.data.lstContentWrapper.length
    ) {
      if (
        pushContentResult.data.lstContentWrapper[0].top10article == null &&
        pushContentResult.data.lstContentWrapper[0].type !== null &&
        pushContentResult.data.lstContentWrapper[0].filename &&
        pushContentResult.data.lstContentWrapper[0].filename.length > 0
      ) {
        setReadMoreDownloadType(
          pushContentResult.data.lstContentWrapper[0].type
        );
        setreadMoreFileName(fileName);
        setContentAnalyticsFileName(
          pushContentResult.data.lstContentWrapper[0].filename
        );
        setReadMoreDownload(
          pushContentResult.data.lstContentWrapper[0].strContentVersionId
        );
        //audio MP4

        let downloadType = handleDownloadType(
          pushContentResult.data.lstContentWrapper[0].type
        );
        let fetchContentUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS_FETCH_CONTENT}`;
        const fetchContentsFullUrl = baseServiceUrl + fetchContentUrl;

        let topPicksResult = await axios.get(
          `${fetchContentsFullUrl}${pushContentResult.data.lstContentWrapper[0].strContentVersionId}/VersionData`,
          { responseType: "blob" }
        );
        sendContentAnalyticsData();
        var file = new Blob([topPicksResult.data], { type: downloadType });
        var fileURL = URL.createObjectURL(file);
        // let anchorElement = document.createElement("a");
        // anchorElement.href = fileURL;
        // anchorElement.download = readMoreFileName;
        // anchorElement.click();
        window.open(fileURL);
        setShowLoaderBanner(false);
      } else {
        if (
          pushContentResult.data.lstContentWrapper[0].top10article &&
          pushContentResult.data.lstContentWrapper[0].top10article.Images &&
          pushContentResult.data.lstContentWrapper[0].top10article.Images
            .length > 0
        ) {
          const top10list =
            pushContentResult.data.lstContentWrapper[0].top10article;
          dispatch(loginActions.settop10Article(top10list));
          localStorage.setItem("topratedcontent", JSON.stringify(top10list));
          window.open("/pushMarketing");
          setShowLoaderBanner(false);
        } else {
          dispatch(
            requestActions.seterrCreatingSponsoredService({
              isError: true,
              errMsg: "No data found",
            })
          );
          setShowLoaderBanner(false);
        }
      }
    } else {
      if (
        pushContentResult &&
        pushContentResult.data.status == "Failure" &&
        pushContentResult.data.error == "No File found"
      ) {
        dispatch(
          requestActions.seterrCreatingSponsoredService({
            isError: true,
            errMsg: "No data found",
          })
        );
        setShowLoaderBanner(false);
      }
    }
  };

  //Banner End
  const fetTopPickData = async () => {
    try {
      let response = await fetchTopPickContent();
      if (response && response.status == 200) {
        let topPickContent = response.data.lstContentWrapper;
        settopPicks(topPickContent);
      } else clearSessionData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleTopPicksClick = async (fileName: any, event: any) => {
    setSelectedTopPickProgramType(fileName);
    const topPicksUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS}`;
    const topPicksUrlFullUrl = baseServiceUrl + topPicksUrl;
    let topPicksResult = await makePostRequest(
      topPicksUrlFullUrl,
      new RequestParams({ imagebanner: fileName })
    );
    setOpenTopPicksPopup(true);
    // console.log(topPicksResult, 'topPicksResult.data.lstContentWrapper !== undefined');
    if (
      topPicksResult.data.lstContentWrapper !== undefined &&
      topPicksResult.data.lstContentWrapper !== null &&
      topPicksResult.data.lstContentWrapper.length > 0
    ) {
      setReadMoreDownloadType(topPicksResult.data.lstContentWrapper[0].type);
      setreadMoreFileName(fileName);
      setContentAnalyticsFileName(
        topPicksResult.data.lstContentWrapper[0].filename
      );
      setReadMoreDownload(
        topPicksResult.data.lstContentWrapper[0].strContentVersionId
      );
      EventGA("TOP PICKS", fileName, "Home Page");
    }
  };

  // const handleCloseCancelPopUp = () => {
  //   setcreateReqTopPick(false);
  //   setOpenConfirmPopup(false);
  //   setReadMoreDownload('');
  //   setreadMoreFileName('');
  // };

  const handleReadMoreDownload = async (ProgramType: any) => {
    setOpenTopPicksPopup(false);
    let downloadType = handleDownloadType(readMoreDownloadType);
    let fetchContentUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS_FETCH_CONTENT}`;
    const fetchContentsFullUrl = baseServiceUrl + fetchContentUrl;
    let topPicksResult = await axios.get(
      `${fetchContentsFullUrl}${readMoreDownload}/VersionData`,
      { responseType: "blob" }
    );

    const pdfBlob = new Blob([topPicksResult.data], { type: downloadType });
    var fileURL = window.URL.createObjectURL(pdfBlob);
    window.open(fileURL);
    sendContentAnalyticsData();
  };

  const sendContentAnalyticsData = async () => {
    let contentAnalyticsUrl = `${process.env.REACT_APP_CONTENTANALYTICS_DEV}`;
    const fetchContentsFullUrl = baseServiceUrl + contentAnalyticsUrl;
    const contentAnalyticsResult = await makePostRequest(
      fetchContentsFullUrl,
      new RequestParams({
        USV_Content_PDF_Name__c: contentAnalyticsFileName,
        USV_HCP__r: { USV_Legacy_Code__c: userInfo ? userInfo.legacycode : "" },
        USV_View_Timestamp__c: new Date().toISOString(),
      })
    );
  };
  //Top pick download
  const [toppickReqErr, settoppickReqErr] = useState({
    isError: false,
    errMsg: "",
  });
  const createReqForTopPick = async (ProgramType: any) => {
    //history.push('/request', { selectedServiceHome: 'Patient Awareness & Support', selectedReqHome: 'Patient Support Program', selectedFromHome: true });
    const programType = getValidProgramType(ProgramType);
    dispatch(requestActions.setProgramType(programType));
    // alert(ProgramType);
    // alert(programType);
    let reqObject = {
      servicetype: "Patient Awareness & Support",
      requesttype: "Patient Support Program",
      legacycode: userInfo ? userInfo.legacycode : "",
      programType: programType,
      status: "Submitted",
    };
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      if (output.data.status === "failure") {
        setcreateReqTopPick(false);
        // setOpenConfirmPopup(false);
        setOpenTopPicksPopup(false);
        settoppickReqErr({ isError: true, errMsg: output.data.errormessage });
      } else {
        setcreateReqTopPick(true);
        // setOpenConfirmPopup(true);
        setOpenTopPicksPopup(false);
      }
    } catch (e) {
      console.log(e, "error in creating request");
    }
  };

  const getValidProgramType = (programType: string) => {
    if (programType.includes("Spandan") || programType.includes("spandan")) {
      return "Spandan";
    } else if (
      programType.includes("Aarambh") ||
      programType.includes("aarambh")
    ) {
      return "Aarambh";
    } else if (programType.includes("GGBPC")) {
      return "Ghar Ghar BP Care -  Awareness and Education";
    } else if (programType.includes("IDE")) {
      return "IDEJ";
    } else if (
      programType.includes("Beat-Diabetes") ||
      programType.includes("Beat Diabetes") ||
      programType.includes("Beat Diabetes")
    ) {
      return "Beat Diabetes";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="home-mainContainer mobileWrapperContentPages container-fluid">
        <div
          id="carouselExampleCaptions"
          className="carousel slide pullMarketingContent mt-2"
          data-bs-ride="carousel"
          data-interval="9000"
        >
          {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" ></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></button>
          </div> */}
          <div className="carousel-inner">
            {bannerContent !== undefined &&
            bannerContent !== null &&
            bannerContent.length > 0 ? (
              bannerContent.map(({ filename, content }, i) => {
                return (
                  <div
                    className={`carousel-item bannerimages cimg${i + 1}  ${
                      i + 1 === 1 ? "active" : ""
                    }`}
                    onClick={(e: any) => {
                      handlePushContentClick(filename);
                    }}
                    key={`bannerImage${i + 1}`}
                    style={{
                      backgroundImage: `url(data:image/png;base64,${
                        content !== null ? content : ""
                      })`,
                    }}
                  ></div>
                );
              })
            ) : (
              <>
                <div className="carousel-item bannerimages cimg1 active"></div>
                {/* <div className="carousel-item cimg2"></div>
                <div className="carousel-item cimg1"></div>{" "} */}
              </>
            )}
          </div>

          {showLoaderBanner ? (
            <div className="loader banner-loader">
              <CircularProgress />
            </div>
          ) : null}

          {bannerContent !== undefined &&
          bannerContent !== null &&
          bannerContent.length > 1 ? (
            <>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </>
          ) : null}
        </div>
        {/* //This snackbar is for sponsored service -- kindly refrain from using it  */}
        <Snackbar
          className="sponsorServiceErr"
          open={errCreatingSponsoredService.isError}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          onClose={closeSponsoredServiceAlert}
        >
          <Alert variant="filled" severity="warning">
            {errCreatingSponsoredService.errMsg.includes("Found Similar Record")
              ? "Please wait same service type request is under process."
              : errCreatingSponsoredService.errMsg.includes("No data found")
              ? "No data found"
              : "Error Occurred in Submitting The Request.\nPlease email us on info@usv.in or call us on 1800-123-4567"}
          </Alert>
        </Snackbar>

        {/* //This snackbar is for top picks service -- kindly refrain from using it  */}
        <Snackbar
          className="sponsorServiceErr"
          open={toppickReqErr.isError}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          onClose={closeSponsoredServiceHomeAlert}
        >
          <Alert variant="filled" severity="warning">
            {toppickReqErr.isError
              ? toppickReqErr.errMsg.includes("Found Similar Record")
                ? "Please wait same service type request is under process."
                : toppickReqErr.errMsg.includes("No data found")
                ? "No data found"
                : "Error Occurred in Submitting The Request.\nPlease email us on info@usv.in or call us on 1800-123-4567"
              : ""}
          </Alert>
        </Snackbar>

        {mobileScreen() ? null : (
          <section className="user-activityPanel">
            {
              showLoaderService ? (
                <div className="loader">
                  <div className="spinner-grow text-muted"></div>
                  <div className="spinner-grow text-muted"></div>
                  <div className="spinner-grow text-muted"></div>
                </div>
              ) : masterData ? (
                <UserServicePanel
                  masterData={masterData}
                  serviceName={serviceName}
                  selectService={selectService}
                  listOfReqType={listOfReqType}
                ></UserServicePanel>
              ) : null
              // {
              //   masterData ? (<UserServicePanel masterData={masterData} serviceName={serviceName} selectService={selectService}></UserServicePanel>) : null
              // }
            }
          </section>
        )}
        <section
          className={
            (serviceName != "" ? "usvAboutUsMargin " : "") + "topPicksHome"
          }
        >
          <article className="toppicks_heading">
            Our Patient Support Initiatives
          </article>
          <section
            className={mobileScreen() ? "toppicks_section_mobile-root" : ""}
          >
            {!mobileScreen() ? (
              <div
                className={
                  topPicks && topPicks.length > 4
                    ? "visibilityVisible"
                    : "visibilityHidden"
                }
                onClick={() => homeCounterFn("decrement")}
              >
                <ArrowBackIosIcon style={{ cursor: "pointer", fill: "#fff" }} />
              </div>
            ) : null}

            <div className={mobileScreen() ? "toppicks_section_mobile" : ""}>
              {topPicks &&
                topPicks.map(({ filename, content }, i) => {
                  return !mobileScreen() ? (
                    <article
                      className="homeTopPicksBg"
                      key={`topPicks${i + 1}`}
                      data-name={filename}
                      onClick={(e: any) => {
                        handleTopPicksClick(filename, e);
                      }}
                      style={{
                        left: (topPicksCounter + i) * 25 + "%",
                        transition: "all 200ms",
                      }}
                    >
                      {!mobileScreen() ? <TopPicksAnimation /> : null}

                      <section
                        style={{
                          backgroundImage: `url(data:image/png;base64,${content})`,
                        }}
                      ></section>
                    </article>
                  ) : (
                    <article
                      className="homeTopPicksBg"
                      key={`topPicks${i + 1}`}
                      data-name={filename}
                      onClick={(e: any) => {
                        handleTopPicksClick(filename, e);
                      }}
                      style={{ left: i * 10 + "%", transition: "all 200ms" }}
                    >
                      {!mobileScreen() ? <TopPicksAnimation /> : null}

                      <section
                        style={{
                          backgroundImage: `url(data:image/png;base64,${content})`,
                        }}
                      ></section>
                    </article>
                  );
                })}
            </div>

            {!mobileScreen() ? (
              <div
                className={
                  topPicks && topPicks.length > 4
                    ? "visibilityVisible"
                    : "visibilityHidden"
                }
                onClick={() => homeCounterFn("increment")}
              >
                <ArrowForwardIosIcon
                  style={{ cursor: "pointer", fill: "#fff" }}
                />
              </div>
            ) : null}

            <Dialog
              open={openTopPicksPopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableScrollLock={true}
              className="MobileHomePage-popover"
            >
              <section className="sponsoredPopupDetails">
                <div>
                  <Close
                    className="closeSponsoredPopup"
                    onClick={() => setOpenTopPicksPopup(false)}
                  />
                </div>
                <h5>Thank you for your interest</h5>
                {selectedTopPickProgramType !== "IDE LOGO" ? (
                  <p className="sponsoredPopUptextDesc">
                    Please select one of the below options to proceed
                  </p>
                ) : (
                  <p className="sponsoredPopUptextDesc">
                    Please select the below option to proceed
                  </p>
                )}
              </section>
              <DialogActions className="btnContainer_sponsored">
                <Button
                  variant="contained"
                  onClick={(e) => {
                    handleReadMoreDownload(selectedTopPickProgramType);
                  }}
                  className="confirmBtn"
                >
                  Read More
                </Button>
                {selectedTopPickProgramType !== "IDE LOGO" ? (
                  <Button
                    variant="contained"
                    className="sponsoredpopUpBtn"
                    onClick={(e) => {
                      createReqForTopPick(selectedTopPickProgramType);
                    }}
                  >
                    Raise a Request
                  </Button>
                ) : (
                  <></>
                )}
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirmPopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="MobileHomePage-popover"
            >
              <section className="sponsoredPopupDetails sponsoredPopup2">
                {/* <div><Close className="closeSponsoredPopup" onClick={handleClosePopUp2} /></div> */}
                <h5>Thank you for your interest</h5>
                <div>
                  {userInfo != null && userInfo.rbmdetails ? (
                    <div className="pocDetails">
                      <p className="pocName">
                        <strong>
                          {userInfo.rbmdetails.name} will connect with you
                          shortly
                        </strong>
                      </p>
                      <p className="contactDetail">
                        {userInfo.rbmdetails.email
                          ? `Email Id: ${userInfo.rbmdetails.email}`
                          : ""}
                      </p>
                      <p className="contactDetail">
                        {userInfo.rbmdetails.mobilephone
                          ? `Mobile: ${userInfo.rbmdetails.mobilephone}`
                          : ""}
                      </p>
                    </div>
                  ) : (
                    <p className="sampleTextMsg">
                      {"Our executive will get back to you shortly"}
                    </p>
                  )}
                </div>
              </section>
              <DialogActions className="btnContainer_sponsored">
                <Button
                  onClick={() => setOpenConfirmPopup(false)}
                  className="confirmBtn"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </section>
        </section>
        <section className="container-fluid homeServicesWrapper">
          <div className="row h-100">
            <article className="col-md-5 col-sm-12 homeServiceParagraph">
              <article>We are providing the top services</article>
              <p>
                {/* USV - Your reliable healthcare partner.
                <br /> */}
                <strong>USVConnect</strong> is a unique portal developed
                exclusively for <br></br> "Medical Fraternity" to facilitate
                various academic & patient centric initiatives.
              </p>
            </article>
            <section className="col-md-6 col-sm-12 homeServInfo">
              <div className="row 100 homeServRowClass">
                {listOfReqType &&
                listOfReqType.includes("Academic & Education") ? (
                  <article className="col-md-4 col-6">
                    <div>
                      <section>
                        <img src={home_academics} />
                      </section>
                      <label>{`Academic & Education`}</label>
                      <p>
                        {serviceDesc
                          .filter(
                            (x: any) => x.servicename === "Academic & Education"
                          )
                          .map((y: any) => y.description)}
                      </p>
                      {/* <p> Articles, references, slide presentations and training program for staff</p> */}
                    </div>
                  </article>
                ) : null}

                {listOfReqType &&
                listOfReqType.includes("Patient Awareness & Support") ? (
                  <article className="col-md-4 col-6">
                    <div>
                      <section>
                        <img src={home_patient_awareness} />
                      </section>
                      <label>{`Patient Awareness & Support`}</label>
                      <p>
                        {serviceDesc
                          .filter(
                            (x: any) =>
                              x.servicename === "Patient Awareness & Support"
                          )
                          .map((y: any) => y.description)}
                      </p>
                      {/* <p> Disease awareness leaflets, patient counselling, disease screening & patient support programs  </p> */}
                    </div>
                  </article>
                ) : null}

                {listOfReqType &&
                listOfReqType.includes("Practice Enhancement") ? (
                  <article className="col-md-4 col-6">
                    <div>
                      <section>
                        <img src={home_practice} />
                      </section>
                      <label>{`Practice Enhancement`}</label>
                      <p>
                        {serviceDesc
                          .filter(
                            (x: any) => x.servicename === "Practice Enhancement"
                          )
                          .map((y: any) => y.description)}
                      </p>
                      {/* <p> Skill development programs, social media management & virtual consultation platform </p> */}
                    </div>
                  </article>
                ) : null}

                {listOfReqType &&
                listOfReqType.includes("Research Publication") ? (
                  <article className="col-md-4 col-6">
                    <div>
                      <section>
                        <img src={home_research} />
                      </section>
                      <label>{`Research Publication`}</label>
                      <p>
                        {serviceDesc
                          .filter(
                            (x: any) => x.servicename === "Research Publication"
                          )
                          .map((y: any) => y.description)}
                      </p>
                      {/* <p>Poster presentation support, publications & medical writing </p> */}
                    </div>
                  </article>
                ) : null}

                {listOfReqType && listOfReqType.includes("Patient Care") ? (
                  <article className="col-md-4 col-6">
                    <div>
                      <section>
                        <img src={home_patient_care} />
                      </section>
                      <label>{`Patient Care`}</label>
                      <p>
                        {serviceDesc
                          .filter((x: any) => x.servicename === "Patient Care")
                          .map((y: any) => y.description)}
                      </p>
                      {/* <p>Connect with experts, Safety kits & samples </p> */}
                    </div>
                  </article>
                ) : null}
              </div>
            </section>
          </div>
        </section>
      </div>
      {mobileScreen() ? null : (
        <section className="hcpFooterContainer w-100">
          <Footer />
        </section>
      )}
      <Dialog
        open={createReqTopPick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="MobileHomePage-popover"
      >
        <section className="sponsoredPopupDetails sponsoredPopup2">
          <h5>Thank you for your interest</h5>
          <div>
            <p className="sampleTextMsg">
              {"Our executive will get back to you shortly"}
            </p>
          </div>
        </section>
        <DialogActions className="btnContainer_sponsored">
          <Button
            onClick={() => setcreateReqTopPick(false)}
            className="confirmBtn"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Home;
