import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showShippingAddressPopup: false,
};

const slice = createSlice({
  name: "shippingAddressAction",
  initialState: initialState,
  reducers: {
    setshowShippingAddressPopup(state: any, action: any) {
      state.showShippingAddressPopup = action.payload;
    },
  },
});

export const shippingAddressAction = slice.actions;

export default slice.reducer;
