import React from 'react';
import './MobileWrapperComponent.scss';
import MobileTopImage from '../../assets/images/MobileAssets/mobileTopImage.svg';
import MobileBottomLeftImage from '../../assets/images/MobileAssets/mobileBottomLeftImage.svg';
import MobileBottomRightImage from '../../assets/images/MobileAssets/mobileBottomRightImage.svg';
export interface MobileLayoutProps  { 
  children: React.ReactNode
}

const MobileWrapper = (props: MobileLayoutProps) => {  
  return (

      <div className='MobileWraper'>
        {props.children}
        <div className="mobile-topImage"><img src={MobileTopImage} alt="mobile-topImage" /></div>
        <div className="mobile-bottomLeftImage"><img src={MobileBottomLeftImage} alt="mobile-bottomLeftImage" /></div>
        <div className="mobile-bottomRightImage"><img src={MobileBottomRightImage} alt="mobile-bottomRightImage" /></div>
      </div>
    
  )
}

export default MobileWrapper;


