const TopPicksAnimation = () => {
    return (
        <>
            <a className='reflection-grid-cell reflection-grid-cell-1' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-2' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-3' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-4' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-5' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-6' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-7' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-8' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-9' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-10' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-11' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-12' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-13' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-14' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-15' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-16' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-17' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-18' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-19' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-20' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-21' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-22' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-23' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-24' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-25' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-26' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-27' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-28' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-29' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-30' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-31' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-32' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-33' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-34' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-35' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-36' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-37' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-38' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-39' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-40' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-41' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-42' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-43' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-44' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-45' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-46' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-47' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-48' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-49' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-50' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-51' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-52' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-53' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-54' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-55' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-56' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-57' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-58' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-59' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-60' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-61' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-62' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-63' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-64' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-65' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-66' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-67' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-68' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-69' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-70' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-71' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-72' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-73' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-74' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-75' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-76' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-77' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-78' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-79' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-80' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-81' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-82' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-83' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-84' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-85' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-86' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-87' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-88' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-89' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-90' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-91' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-92' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-93' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-94' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-95' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-96' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-97' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-98' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-99' href="#" onClick={(e) => { e.preventDefault()}}></a>
            <a className='reflection-grid-cell reflection-grid-cell-100' href="#" onClick={(e) => { e.preventDefault()}}></a>
        </>
    )
}

export default TopPicksAnimation