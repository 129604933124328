import React, { useState, useEffect, useRef } from "react";
import "./DraftRequest.scss";
import "./DraftRequestMobile.scss";
import moment from "moment-timezone";
import { CircularProgress } from "@material-ui/core";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../..//Class-Interfaces/httpRequestParams";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestActions } from "../../../store/request";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  Snackbar,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Popover,
} from "@material-ui/core";
import { fetchDraftDetails } from "../../..//utils/home_axios";

import FilterListSharpIcon from "@material-ui/icons/FilterListSharp";
import { mobileScreen } from "../../..//Mobile_utils/GlobalUtils";

const DraftRequest = (props: any) => {
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [draftDetails, setdraftDetails] = useState([]);
  const [draftDetailsFiltered, setdraftDetailsFiltered] = useState<any[]>([]);
  const cancelReqRef: any = useRef(null);
  const [openCancelToastMsg, setOpenCancelToastMsg] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [requestNumber, setrequestNumber] = useState("");
  const [requesttype, setrequesttype] = useState("");
  const [requestServiceName, setrequestServiceName] = useState("");
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const draftInfoUrl = `${process.env.REACT_APP_DRAFT_REQUEST}`;
  const fetchServiceApi = baseServiceUrl + serviceUrl;
  const draftServiceApi = baseServiceUrl + draftInfoUrl;
  const serviceUrlCancel = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const cancelReqServiceApi = baseServiceUrl + serviceUrlCancel;
  const [showCategoryFilter, setshowCategoryFilter] = useState(false);
  const [categoryFilter, setcategoryFilter] = useState("");
  const [showFilterOption, setshowFilterOption] = useState(false);
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const fetchDraftData = async (categoryFilterSelecetd?: string) => {
    setShowLoader(true);
    try {
      let response = await fetchDraftDetails();
      let lstServiceRequests = response.data.lstServiceRequest;
      let draftDataCount =
        lstServiceRequests !== null && lstServiceRequests !== undefined
          ? lstServiceRequests.length
          : 0;
      setbadgeCount(draftDataCount);
      let DraftDetails = lstServiceRequests.filter(
        (lstServiceRequest: any) => lstServiceRequest.requeststatus === "Draft"
      );
      setdraftDetails(DraftDetails);
      const completeDraftReqList: any = getDraftReqList(
        DraftDetails,
        categoryFilterSelecetd
      );
      setdraftDetailsFiltered(completeDraftReqList);
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      if ("indexedDB" in window && !navigator.onLine) {
        let response = await fetchDraftDetails();
        //console.log("in indexedDB response************", response);
        let lstServiceRequests = response.lstServiceRequest;
        let draftDataCount =
          lstServiceRequests !== null && lstServiceRequests !== undefined
            ? lstServiceRequests.length
            : 0;
        setbadgeCount(draftDataCount);
        let DraftDetails = lstServiceRequests.filter(
          (lstServiceRequest: any) =>
            lstServiceRequest.requeststatus === "Draft"
        );
        setdraftDetails(DraftDetails);
        const completeDraftReqList: any = getDraftReqList(
          DraftDetails,
          categoryFilterSelecetd
        );
        setdraftDetailsFiltered(completeDraftReqList);
        setShowLoader(false);
      }
    }
  };

  const categoryFilterOption = getAllFilterCategoryOption();
  function getAllFilterCategoryOption() {
    let resultArr = Array<string>();
    if (draftDetails !== null) {
      draftDetails.map((item: any, index: number) => {
        resultArr.push(item.servicetype);
      });
    }
    return Array.from(new Set(resultArr));
  }

  const filteredReq1 = (listOfDraftReq: any, selectedFilterVal: any) =>
    listOfDraftReq
      ? listOfDraftReq.filter(function (item: any) {
          if (selectedFilterVal.length > 0) {
            return item.servicetype == selectedFilterVal;
          }
        })
      : null;

  function getDraftReqList(listOfDraftReq: any, selectedFilterVal?: any) {
    if (selectedFilterVal !== undefined && selectedFilterVal.length > 0) {
      let filteredList = filteredReq1(listOfDraftReq, selectedFilterVal);
      return filteredList
        ? filteredList
            .slice()
            .sort(
              (a: any, b: any) =>
                +new Date(b.createdon) - +new Date(a.createdon)
            )
        : "";
    } else {
      return listOfDraftReq
        ? listOfDraftReq
            .slice()
            .sort(
              (a: any, b: any) =>
                +new Date(b.createdon) - +new Date(a.createdon)
            )
        : "";
    }
  }
  function handleFilterChange(selectedFilter: string, type: string) {
    setcategoryFilter(selectedFilter);
    fetchDraftData(selectedFilter);
  }

  const setSelectedReqService = (
    requesttype: any,
    servicetype: any,
    requestnumber: any
  ) => {
    fetchDraftedValue(requesttype, servicetype, requestnumber);
  };

  const fetchDraftedValue = async (
    requesttype: any,
    servicetype: any,
    requestnumber: any
  ) => {
    try {
      let response = await makePostRequest(
        draftServiceApi,
        new RequestParams({
          servicereqno: requestnumber,
          servicetype: servicetype,
          requesttype: requesttype,
        })
      );

      let draftValues = response.data.lstDetails;
      if (draftValues && draftValues.length > 0) {
        let draftdata = draftValues.map((draftValue: any) => {
          if (draftValue.value === null) {
            draftValue.value = "";
          }
          return draftValue;
        });
        // console.log(draftdata);
        history.push("/request", {
          selectedServiceHome: servicetype,
          selectedReqHome: requesttype,
        });
        props.handleTabs(0);
        dispatch(requestActions.setrequestTab(true));
        dispatch(requestActions.seteditDraft(true));
        dispatch(requestActions.setCaseNumber(requestnumber));
        switch (servicetype) {
          case "Patient Awareness & Support":
            handleDraftValuePatientAwareness(requesttype, draftdata);
            break;
          case "Academic & Education":
            handleDraftValueAcademicEducation(requesttype, draftdata);
            break;
          case "Patient Care":
            handleDraftValuePatientCare(requesttype, draftdata);
            break;
          case "Practice Enhancement":
            handleDraftValuePatientEnhancement(requesttype, draftdata);
            break;
          case "Research Publication":
            handleDraftValueResearchPublication(requesttype, draftdata);
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDraftValuePatientEnhancement = (
    selectedReq: string,
    draftValues: any
  ) => {
    switch (selectedReq) {
      case "Skill Development Programme":
        const setskillDevelopmentDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setSessionTypeValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Type_of_Sessions__c"
        );

        const setskillDevelopmentDesc = setskillDevelopmentDescValue[0].value;
        const setSessionType = setSessionTypeValue[0].value;
        draftValueSkillDev(setskillDevelopmentDesc, setSessionType);
        break;

      case "Social Media Management":
        const setReqOfOtherServiceValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Requirement_of_Service_If_Other__c"
        );
        const setReqOfServiceValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Requirement_of_Service__c"
        );
        const parasetSocialMediaReqDescmsValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );

        const setReqOfOtherService = setReqOfOtherServiceValue[0].value;
        const setReqOfService = setReqOfServiceValue[0].value;
        const parasetSocialMediaReqDescms =
          parasetSocialMediaReqDescmsValue[0].value;
        draftValueSocialMedia(
          setReqOfOtherService,
          setReqOfService,
          parasetSocialMediaReqDescms
        );
        break;
      // case "Virtual Consultation Platform":
      //     const [setSampleTabletCount,setSampleDesc,setUsvBrandName,setsampleBCP]= draftValue;
      //     draftValueVirtualConsultant(setSampleTabletCount,setSampleDesc,setUsvBrandName);
      //     break;
    }
  };

  const handleDraftValuePatientCare = (
    selectedReq: string,
    draftValues: any
  ) => {
    switch (selectedReq) {
      case "Expert Doctor Connect":
        const setExpertDocConnectBriefDescValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Brief_description_of_Case__c"
        );
        const setExpertDocConnectDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setExpertDocConnectSpecialityValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Specialty__c"
        );

        const setExpertDocConnectBriefDesc =
          setExpertDocConnectBriefDescValue[0].value;
        const setExpertDocConnectDesc = setExpertDocConnectDescValue[0].value;
        const setExpertDocConnectSpeciality =
          setExpertDocConnectSpecialityValue[0].value;

        draftValueDocterConnect(
          setExpertDocConnectBriefDesc,
          setExpertDocConnectDesc,
          setExpertDocConnectSpeciality
        );
        break;

      case "Safety Kit":
        const setSafetyKitDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );

        const setSafetyKitDesc = setSafetyKitDescValue[0].value;
        draftValueSafetyKit(setSafetyKitDesc);
        break;
      case "Samples":
        const setSampleTabletCountValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Number_of_tablets_month__c"
        );
        const setSampleDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setUsvBrandNameValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Name_of_USV_brand__c"
        );

        const setSampleTabletCount = setSampleTabletCountValue[0].value;
        const setSampleDesc = setSampleDescValue[0].value;
        const setUsvBrandName = setUsvBrandNameValue[0].value;
        draftValueSample(setSampleTabletCount, setSampleDesc, setUsvBrandName);
        break;
    }
  };

  const handleDraftValueResearchPublication = (
    selectedReq: string,
    draftValues: any
  ) => {
    switch (selectedReq) {
      case "Poster Preparation Support":
        const setConferenceDateValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Month_Of_Confrence__c"
        );
        const setConferenceNameValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Name_of_Conference__c"
        );
        const setPosterTitleValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Title_of_Poster__c"
        );

        const setConferenceDate = setConferenceDateValue[0].value;
        const setConferenceName = setConferenceNameValue[0].value;
        const setPosterTitle = setPosterTitleValue[0].value;
        draftValuePosterPrep(
          setConferenceDate,
          setConferenceName,
          setPosterTitle
        );
        break;

      case "Publication/ Medical Writing":
        const setRequiredResearchServiceValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setOtherResearchServiceValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Type_of_Sessions__c"
        );
        const setResearchTitleValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Title_of_Poster__c"
        );

        const setRequiredResearchService =
          setRequiredResearchServiceValue[0].value;
        const setOtherResearchService = setOtherResearchServiceValue[0].value;
        const setResearchTitle = setResearchTitleValue[0].value;
        draftValuePosterPub(
          setRequiredResearchService,
          setOtherResearchService,
          setResearchTitle
        );
        break;
    }
  };
  const handleDraftValuePatientAwareness = (
    selectedReq: string,
    draftValues: any
  ) => {
    switch (selectedReq) {
      case "Patient Screening":
        const setScreeningSupportValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Support_for_Screening__c"
        );
        const setScreeningLanguageValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Language__c"
        );
        const setscreeningTherapyTopicValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Selection_of_Topic_therapy__c"
        );
        const setEducationMaterialNeededValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename ===
            "USV_Patient_Education_Material_required__c"
        );
        const setpatientEducationTypeValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Type_of_patient_education__c"
        );
        const setScreeningInputCountValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_No_of_inputs_required__c"
        );
        const setScreeningDeliveryAddressValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const setScreeningDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );

        const setScreeningSupport = setScreeningSupportValue.value;
        const setScreeningLanguage = setScreeningLanguageValue[0].value;
        const setscreeningTherapyTopic = setscreeningTherapyTopicValue.length
          ? setscreeningTherapyTopicValue[0].value
          : "";
        const setEducationMaterialNeeded =
          setEducationMaterialNeededValue.length
            ? setEducationMaterialNeededValue[0].value
            : "";
        const setpatientEducationType = setpatientEducationTypeValue.length
          ? setpatientEducationTypeValue[0].value
          : "";
        const setScreeningInputCount = setScreeningInputCountValue.length
          ? setScreeningInputCountValue[0].value
          : "";
        const setScreeningDeliveryAddress =
          setScreeningDeliveryAddressValue.length
            ? setScreeningDeliveryAddressValue[0].value
            : "";
        const setScreeningDesc = setScreeningDescValue.length
          ? setScreeningDescValue[0].value
          : "";

        draftValuePatientScreening(
          // setscreeningTherapyTopic,
          setScreeningLanguage,
          setEducationMaterialNeeded,
          setpatientEducationType,
          setScreeningDesc,
          setScreeningDeliveryAddress
          // setScreeningSupport,
          // setScreeningInputCount
        );
        break;

      case "Patient Support Program":
        const setPatientSupportDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setPatientSupportProgramTypeValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Program_type__c"
        );

        const setPatientSupportDesc = setPatientSupportDescValue[0].value;
        const setPatientSupportProgramType =
          setPatientSupportProgramTypeValue[0].value;

        draftValuePatientProgram(
          setPatientSupportDesc,
          setPatientSupportProgramType
        );
        break;

      case "Patient Counselling":
        const setServiceForCounsellingValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const setCounsellingDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const setCounsellingPatientCountValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const setCounsellingFreqPerMonthValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );

        const setServiceForCounselling = setServiceForCounsellingValue[0].value;
        const setCounsellingDesc = setCounsellingDescValue[0].value;
        const setCounsellingPatientCount =
          setCounsellingPatientCountValue[0].value;
        const setCounsellingFreqPerMonth =
          setCounsellingFreqPerMonthValue[0].value;

        draftValueDigitalPatientCounselling(
          setServiceForCounselling,
          setCounsellingDesc,
          setCounsellingPatientCount,
          setCounsellingFreqPerMonth
        );
        break;

      case "Patient Awareness Material":
        const setpatientAwarenessDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const setpatientAwarenessInputTypeValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Input_type__c"
        );
        const setpatientAwarenessLangValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Language__c"
        );
        // const setpatientAwarenessCopiesCountValue = draftValues.filter((draftValue:any) =>draftValue.uniquename === "");
        const setTherapyTopicSelectionValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Selection_of_Topic_therapy__c"
        );
        const setOtherTherapyTopicValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename ===
            "USV_Selection_of_Topic_If_other_selected__c"
        );
        // const setpatientAwarenessAddressValue = draftValues.filter((draftValue:any) =>draftValue.uniquename === "");

        const setpatientAwarenessDesc = setpatientAwarenessDescValue[0].value;
        const setpatientAwarenessInputType =
          setpatientAwarenessInputTypeValue[0].value;
        const setpatientAwarenessLang = setpatientAwarenessLangValue[0].value;
        // const setpatientAwarenessCopiesCount = setpatientAwarenessCopiesCountValue[0].value;
        const setTherapyTopicSelection = setTherapyTopicSelectionValue[0].value;
        const setOtherTherapyTopic = setOtherTherapyTopicValue[0].value;
        // const setpatientAwarenessAddress = setpatientAwarenessAddressValue[0].value;

        draftValuePatientAwareness(
          setpatientAwarenessDesc,
          setpatientAwarenessInputType,
          setpatientAwarenessLang,
          setOtherTherapyTopic,
          setTherapyTopicSelection
        );
        break;
    }
  };

  const handleDraftValueAcademicEducation = (
    selectedReq: string,
    draftValues: any
  ) => {
    switch (selectedReq) {
      case "Slides Presentation":
        const topicNameValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Name_of_Topic__c"
        );
        const specialCommentsValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Special_comments_if_any__c"
        );
        const meetingTypeValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Type_of_Meeting__c"
        );
        const targetAudienceValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Target_audience__c"
        );
        const durationValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Duration_of_talk_in_mins__c"
        );
        const RequestingHCPSlideValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename ===
            "USV_Reason_For_Requesting_On_Behalf_HCP__c"
        );

        const topicName = topicNameValue[0].value;
        const specialComments = specialCommentsValue[0].value;
        const meetingType = meetingTypeValue[0].value;
        const targetAudience = targetAudienceValue[0].value;
        const duration = durationValue[0].value;
        const RequestingHCPSlide = RequestingHCPSlideValue[0].value;

        draftValueSlidePresentation(
          topicName,
          specialComments,
          meetingType,
          RequestingHCPSlide,
          targetAudience,
          duration
        );
        break;

      case "Article/ Reference":
        const ArticleDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "Description"
        );
        const ArticleTitleValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Title_of_the_Article__c"
        );
        const JournalNameValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === "USV_Journal_Name__c"
        );

        const ArticleDesc = ArticleDescValue[0].value;
        const ArticleTitle = ArticleTitleValue[0].value;
        const JournalName = JournalNameValue[0].value;
        draftValueArticle(ArticleDesc, ArticleTitle, JournalName);
        break;

      case "Digital Meeting Platform":
        const digitalAudienceTypeValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const digitalMeetingDateTimeValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const digitalMeetingNameValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const digitalReqDescValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const optionalRequirementValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const loginRequirementValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );
        const otherSpecificReqValue = draftValues.filter(
          (draftValue: any) => draftValue.uniquename === ""
        );

        const digitalAudienceType = digitalAudienceTypeValue[0].value;
        const digitalMeetingDateTime = digitalMeetingDateTimeValue[0].value;
        const digitalMeetingName = digitalMeetingNameValue[0].value;
        const digitalReqDesc = digitalReqDescValue[0].value;
        const optionalRequirement = optionalRequirementValue[0].value;
        const loginRequirement = loginRequirementValue[0].value;
        const otherSpecificReq = otherSpecificReqValue[0].value;
        draftValueDigitalPlatform(
          digitalAudienceType,
          digitalMeetingDateTime,
          digitalMeetingName,
          digitalReqDesc,
          loginRequirement,
          optionalRequirement,
          otherSpecificReq
        );
        break;

      case "Training Required For Staff":
        const trainingStaffCountValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename ===
            "USV_Number_of_Staff_members_for_training__c"
        );
        const trainingNameValue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Name_of_Training_Program__c"
        );
        const trainingDescvalue = draftValues.filter(
          (draftValue: any) =>
            draftValue.uniquename === "USV_Training_Program_Description__c"
        );

        const trainingStaffCount = trainingStaffCountValue[0].value;
        const trainingName = trainingNameValue[0].value;
        const trainingDesc = trainingDescvalue[0].value;
        draftValueTrainingStaffRequired(
          trainingStaffCount,
          trainingName,
          trainingDesc
        );
        break;
    }
  };

  const draftValueSkillDev = (
    setskillDevelopmentDesc: any,
    setSessionType: any
  ) => {
    dispatch(requestActions.setskillDevelopmentDesc(setskillDevelopmentDesc));
    dispatch(requestActions.setSessionType(setSessionType));
  };
  const draftValueSocialMedia = (
    setReqOfOtherService: any,
    setReqOfService: any,
    parasetSocialMediaReqDescms: any
  ) => {
    dispatch(requestActions.setReqOfOtherService(setReqOfOtherService));
    dispatch(requestActions.setReqOfService(setReqOfService));
    dispatch(requestActions.setSocialMediaReqDesc(parasetSocialMediaReqDescms));
  };
  const draftValuePosterPub = (
    setRequiredResearchService: any,
    setOtherResearchService: any,
    setResearchTitle: any
  ) => {
    dispatch(requestActions.setOtherResearchService(setOtherResearchService));
    dispatch(
      requestActions.setRequiredResearchService(setRequiredResearchService)
    );
    dispatch(requestActions.setResearchTitle(setResearchTitle));
  };

  const draftValuePosterPrep = (
    setConferenceDate: any,
    setConferenceName: any,
    setPosterTitle: any
  ) => {
    dispatch(requestActions.setConferenceDate(setConferenceDate));
    dispatch(requestActions.setConferenceName(setConferenceName));
    dispatch(requestActions.setPosterTitle(setPosterTitle));
  };

  const draftValueDocterConnect = (
    setExpertDocConnectBriefDesc: any,
    setExpertDocConnectDesc: any,
    setExpertDocConnectSpeciality: any
  ) => {
    dispatch(requestActions.setExpertDocConnectDesc(setExpertDocConnectDesc));
    dispatch(
      requestActions.setExpertDocConnectBriefDesc(setExpertDocConnectBriefDesc)
    );
    dispatch(
      requestActions.setExpertDocConnectSpeciality(
        setExpertDocConnectSpeciality
      )
    );
  };
  const draftValueSafetyKit = (setSafetyKitDesc: any) => {
    dispatch(requestActions.setSafetyKitDesc(setSafetyKitDesc));
  };
  const draftValueSample = (
    setSampleTabletCount: any,
    setSampleDesc: any,
    setUsvBrandName: any
  ) => {
    dispatch(requestActions.setSampleDesc(setSampleDesc));
    dispatch(requestActions.setSampleTabletCount(setSampleTabletCount));
    dispatch(requestActions.setUsvBrandName(setUsvBrandName));
  };

  const draftValuePatientScreening = (
    // screeningTherapyTopic: any,
    ScreeningLanguage: any,
    EducationMaterialNeeded: any,
    patientEducationType: any,
    ScreeningDesc: any,
    ScreeningDeliveryAddress: any
    // ScreeningSupport: any,
    // ScreeningInputCount: any
  ) => {
    // dispatch(requestActions.setscreeningTherapyTopic([screeningTherapyTopic]));
    dispatch(requestActions.setScreeningDesc(ScreeningDesc));
    dispatch(
      requestActions.setScreeningDeliveryAddress(ScreeningDeliveryAddress)
    );
    dispatch(
      requestActions.setEducationMaterialNeeded(EducationMaterialNeeded)
    );
    dispatch(requestActions.setpatientEducationType(patientEducationType));
    dispatch(requestActions.setScreeningLanguage(ScreeningLanguage));
    // dispatch(requestActions.setScreeningSupport([ScreeningSupport]));
    // dispatch(requestActions.setScreeningInputCount(ScreeningInputCount));
  };

  const draftValuePatientAwareness = (
    setpatientAwarenessDesc: any,
    setpatientAwarenessInputType: any,
    setpatientAwarenessLang: any,
    setTherapyTopicSelection: any,
    setOtherTherapyTopic: any
  ) => {
    dispatch(requestActions.setpatientAwarenessDesc(setpatientAwarenessDesc));
    //dispatch(requestActions.setOtherTherapyTopic(setOtherTherapyTopic));
    dispatch(
      requestActions.setpatientAwarenessInputType(setpatientAwarenessInputType)
    );
    dispatch(requestActions.setpatientAwarenessLang(setpatientAwarenessLang));
    //dispatch(requestActions.setTherapyTopicSelection(setTherapyTopicSelection));
  };
  const draftValuePatientProgram = (
    setPatientSupportDesc: any,
    setPatientSupportProgramType: any
  ) => {
    dispatch(requestActions.setProgramType(setPatientSupportProgramType));
    dispatch(requestActions.setPatientSupportDesc(setPatientSupportDesc));
  };
  const draftValueDigitalPatientCounselling = (
    setServiceForCounselling: any,
    setCounsellingDesc: any,
    setCounsellingPatientCount: any,
    setCounsellingFreqPerMonth: any
  ) => {
    dispatch(requestActions.setCounsellingDesc(setCounsellingDesc));
    dispatch(
      requestActions.setCounsellingFreqPerMonth(setCounsellingFreqPerMonth)
    );
    dispatch(
      requestActions.setCounsellingPatientCount(setCounsellingPatientCount)
    );
    dispatch(requestActions.setServiceForCounselling(setServiceForCounselling));
  };

  const draftValueArticle = (
    ArticleDesc: any,
    ArticleTitle: any,
    JournalName: any
  ) => {
    dispatch(requestActions.setArticleDesc(ArticleDesc));
    dispatch(requestActions.setArticleTitle(ArticleTitle));
    dispatch(requestActions.setJournalName(JournalName));
  };
  const draftValueSlidePresentation = (
    TopicName: any,
    SpecialComments: any,
    MeetingType: any,
    RequestingHCPSlide: any,
    TargetAudience: any,
    Duration: any
  ) => {
    dispatch(requestActions.setDuration(Duration));
    dispatch(requestActions.setMeetingType(MeetingType));
    dispatch(requestActions.setSpecialComments(SpecialComments));
    dispatch(requestActions.setTargetAudience([TargetAudience]));
    dispatch(requestActions.setTopicName(TopicName));
  };
  const draftValueDigitalPlatform = (
    digitalAudienceType: any,
    digitalMeetingDateTime: any,
    digitalMeetingName: any,
    digitalReqDesc: any,
    loginRequirement: any,
    optionalRequirement: any,
    otherSpecificReq: any
  ) => {
    dispatch(requestActions.setDigitalAudienceType(digitalAudienceType));
    dispatch(requestActions.setDigitalMeetingDateTime(digitalMeetingDateTime));
    dispatch(requestActions.setDigitalMeetingName(digitalMeetingName));
    dispatch(requestActions.setDigitalReqDesc(digitalReqDesc));
    dispatch(requestActions.setLoginRequirement(loginRequirement));
    dispatch(requestActions.setOptionalRequirement(optionalRequirement));
    dispatch(requestActions.setotherSpecificReq(otherSpecificReq));
  };
  const draftValueTrainingStaffRequired = (
    trainingStaffCount: any,
    trainingName: any,
    trainingDesc: any
  ) => {
    dispatch(requestActions.setTrainingDesc(trainingDesc));
    dispatch(requestActions.setTrainingName(trainingName));
    dispatch(requestActions.setTrainingStaffCount(trainingStaffCount));
  };

  const getReqDate = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dd = moment(reqDate).tz(moment.tz.guess()).format("DD");
      var mm = moment(reqDate).tz(moment.tz.guess()).format("MM");
      var yyyy = moment(reqDate).tz(moment.tz.guess()).format("YYYY");
      return dd + "/" + mm + "/" + yyyy;
    }
  };

  const getReqTime = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var time = moment(reqDate).tz(moment.tz.guess()).format("hh:mm a");
      return time;
    }
  };
  const handleCancelDraft = (
    event: any,
    requesttype: any,
    requestnumber: any,
    servicetype: any
  ) => {
    event.stopPropagation();
    setOpenCancelPopup(true);
    setrequestNumber(requestnumber);
    setrequesttype(requesttype);
    setrequestServiceName(servicetype);
  };
  const handleCloseCancelPopUp = () => {
    setOpenCancelPopup(false);
  };
  const sendCancelReq = async (reqObject: any) => {
    try {
      const response = await makePostRequest(
        cancelReqServiceApi,
        new RequestParams(reqObject)
      );
      if (response.data.status === "failure") {
        setOpenCancelToastMsg(true);
        setShowSuccessAlert(false);
      } else {
        setOpenCancelToastMsg(true);
        setShowSuccessAlert(true);
        setShowLoader(true);
        setTimeout(() => {
          fetchDraftData();
        }, 3000);
      }
    } catch (e) {
      console.log(e, "error in cancelling request");
    }
  };
  const handleCloseCancelToast = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenCancelToastMsg(false);
    //setAnchorEl(null);
  };
  const cancelReq = () => {
    handleCloseCancelPopUp();
    let reqObject = {
      servicetype: requestServiceName !== undefined ? requestServiceName : "",
      requesttype: requesttype !== undefined ? requesttype : "",
      legacycode: userInfo ? userInfo.legacycode : "",
      caseNumber: requestNumber !== undefined ? requestNumber : "",
      cancelfromdraft: true,
      status: "Cancelled",
    };
    sendCancelReq(reqObject);
  };

  useEffect(() => {
    fetchDraftData();
  }, []);

  const renderDraftDetail = draftDetailsFiltered ? (
    draftDetailsFiltered.map(
      ({
        requestnumber,
        createdon,
        requesttype,
        servicetype,
        requeststatus,
        draftValue,
      }) => {
        return (
          <>
            <section
              className="reqDetailsCard"
              data-reqtype={requesttype}
              data-reqid={requestnumber}
              data-reqname={servicetype}
              ref={cancelReqRef}
              onClick={() => {
                setSelectedReqService(requesttype, servicetype, requestnumber);
              }}
            >
              <div
                className="removeDraft"
                onClick={(e) => {
                  handleCancelDraft(e, requesttype, requestnumber, servicetype);
                }}
              >
                <HighlightOffOutlinedIcon />
              </div>
              <div className="firstRow">
                <div
                  className={
                    "viewMyRequest-ReqImg " +
                    (servicetype === "Academic & Education"
                      ? "academicReqImage"
                      : servicetype === "Patient Awareness"
                      ? "patientAwarenessReqImg"
                      : servicetype === "Patient Care"
                      ? "patientCareReqImg"
                      : servicetype === "Practice Enhancement"
                      ? "practiceEnhancement"
                      : "research")
                  }
                ></div>
                <div className="reqId-Name ms-3">
                  <div data-testid="DraftRequestID" className="reqId">
                    {requestnumber}
                  </div>
                  <div className="reqName">{servicetype}</div>
                </div>
              </div>
              <div className="secondRow mt-1">
                <span>{requesttype}</span>
              </div>
              <div className="thirdRow mt-1">
                <div className="reqRaised-dateTime">
                  <div className="dateOfReqRaised">{getReqDate(createdon)}</div>
                  <div className="timeOfReqRaised">{getReqTime(createdon)}</div>
                </div>
                <div>
                  <button
                    data-testid="draft-button"
                    className="draftButton reqStatusBtn"
                  >
                    {requeststatus}
                  </button>
                </div>
              </div>
            </section>
          </>
        );
      }
    )
  ) : (
    <></>
  );

  const getReqDateMobile = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dayMonth = moment(reqDate).format("DD MMM YYYY");
      return dayMonth;
    }
  };

  function renderDraftDetailMobile() {
    //console.log(draftDetailsFiltered, 'draftDetailsFiltered draftDetailsFiltered');

    return (
      <div className="allReq-gridViewMobile">
        <div className="reqListContainer-mobile">
          {draftDetailsFiltered && draftDetailsFiltered.length > 0 ? (
            draftDetailsFiltered.map((value: any, index: number) => {
              return (
                <>
                  {index > 0 &&
                  getReqDate(value.createdon) ==
                    getReqDate(draftDetailsFiltered[index - 1].createdon) ? (
                    <></>
                  ) : index == 0 ? (
                    <div className="dateOfReqHeader">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  ) : (
                    <div className="dateOfReqHeader">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  )}

                  <section
                    className="reqDetailsCardMobile"
                    data-reqtype={value.requesttype}
                    data-reqid={value.requestnumber}
                    data-reqname={value.servicetype}
                    ref={cancelReqRef}
                    onClick={() => {
                      setSelectedReqService(
                        value.requesttype,
                        value.servicetype,
                        value.requestnumber
                      );
                    }}
                  >
                    <div
                      className="removeDraft"
                      onClick={(e) => {
                        handleCancelDraft(
                          e,
                          value.requesttype,
                          value.requestnumber,
                          value.servicetype
                        );
                      }}
                    >
                      <HighlightOffOutlinedIcon />
                    </div>
                    <div className="firstRowDraftMobile">
                      <div
                        className={
                          "viewMyRequest-ReqImg " +
                          (value.servicetype === "Academic & Education"
                            ? "academicReqImage"
                            : value.servicetype === "Patient Awareness"
                            ? "patientAwarenessReqImg"
                            : value.servicetype === "Patient Care"
                            ? "patientCareReqImg"
                            : value.servicetype === "Practice Enhancement"
                            ? "practiceEnhancement"
                            : "research")
                        }
                      ></div>
                      <div className="reqId-Name ms-3">
                        <div data-testid="DraftRequestID" className="reqId">
                          {value.requestnumber}
                        </div>
                        <div className="reqName">{value.servicetype}</div>
                      </div>
                    </div>
                    <div className="secondRowDraftMobile">
                      <span>{value.requesttype}</span>
                    </div>
                    <div className="thirdRowDraftMobile mt-3">
                      <div className="reqRaised-dateTime">
                        <div className="timeOfReqCreation">
                          {getReqTime(value.createdon)}
                        </div>
                      </div>
                      <div>
                        <div
                          data-testid="draft-button"
                          className="draftButton reqStatusBtn"
                        >
                          {value.requeststatus}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              );
            })
          ) : (
            <div className="noRequestAvailable">No Requests Available</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div data-testid="draft" className="MuiBox-root jss28">
        <section className="myReqList-mainContainer">
          <div className="draftReqList">
            <Snackbar
              className="cancelReqToast"
              open={openCancelToastMsg}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              autoHideDuration={2000}
              onClose={handleCloseCancelToast}
            >
              {showSuccessAlert ? (
                <Alert severity="success">
                  Draft Request Cancelled Successfully
                </Alert>
              ) : (
                <Alert severity="error">
                  Draft Request Cancellation Failed
                </Alert>
              )}
            </Snackbar>

            <Dialog
              open={openCancelPopup}
              onClose={handleCloseCancelPopUp}
              aria-labelledby="form-dialog-title"
              className="cancelDraftDialog"
            >
              <DialogTitle id="form-dialog-title">
                <ErrorOutlineIcon></ErrorOutlineIcon>
                <label className="confirmCancelText">
                  Are you sure you want to cancel the draft request
                </label>
              </DialogTitle>
              <DialogActions className="cancelBtnContainer">
                <Button
                  className="cancelPopUpBtn doNotCancel"
                  onClick={handleCloseCancelPopUp}
                  color="primary"
                  variant="contained"
                >
                  No
                </Button>
                <Button
                  className="cancelPopUpBtn cancelClickedReq"
                  onClick={cancelReq}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            {/* Here we need to give Sorting and Filter component ..... End Here*/}

            {draftDetails && draftDetails.length > 0 ? (
              <div className="FilterReqList">
                <div
                  className={
                    "filterByOptions " +
                    (showCategoryFilter
                      ? "visibilityVisible"
                      : "visibilityHidden")
                  }
                >
                  <div className="categoryFilterContainer">
                    <FormControl className="">
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={categoryFilter}
                        onChange={(e: any) =>
                          handleFilterChange(e.target.value, "category")
                        }
                      >
                        <MenuItem value="">Reset</MenuItem>
                        {categoryFilterOption.map(
                          (item1: any, index1: number) => (
                            <MenuItem key={item1} value={item1}>
                              {item1}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <section className="filterIcon 2">
                  <div
                    onClick={() => {
                      setshowCategoryFilter(!showCategoryFilter);
                    }}
                  >
                    <IconButton>
                      <FilterListSharpIcon></FilterListSharpIcon>
                    </IconButton>
                  </div>
                </section>
              </div>
            ) : null}
            <div className="reqListContainer">
              {showLoader ? (
                <div className="loader draftLoader">
                  <CircularProgress />
                </div>
              ) : draftDetails && draftDetails.length > 0 ? (
                mobileScreen() ? (
                  renderDraftDetailMobile()
                ) : (
                  renderDraftDetail
                )
              ) : (
                <div className="noRequestAvailable">No Requests Available</div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DraftRequest;
