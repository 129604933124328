import { useState, ChangeEvent, useRef } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SERV001 from '../../assets/images/academic.svg';
import SERV001W from '../../assets/images/academicWhite.svg';
import SERV002 from '../../assets/images/personalReq.svg';
import SERV002W from '../../assets/images/patientCareSelected.svg';
import SERV003 from '../../assets/images/researchPublication.svg';
import SERV003W from '../../assets/images/researchReqSelected.svg';
import SERV004 from '../../assets/images/practiceReq.svg';
import SERV004W from '../../assets/images/practiceReqSelected.svg';
import SERV005 from '../../assets/images/patient_awareness_support.svg';
import SERV005W from '../../assets/images/selectedPatientAwareness.svg';
import top_picks_3 from '../../assets/images/top_picks_3.png';
import top_picks_2 from '../../assets/images/top_picks_2.png';
import top_picks_1 from '../../assets/images/top_picks_1.png';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import './UserServicePanel.scss';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/HighlightOff';
//import 'antd/dist/antd.css';
// import { Tooltip } from 'antd';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import { RequestCategoryInterface } from '../../Class-Interfaces/RequestCategoryInterface'
import home_clock from '../../assets/images/home_clock.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { requestActions } from '../../store/request';
import InfoIcon from '@material-ui/icons/Info';
import {
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import { homeRequests, homeEvents } from '../../utils/home_axios'
import { homeRequestReducer, homeEventReducer } from '../../utils/home-utils'

function TabPanel(props: any) {
    const { children, value, index } = props;
    return (
        <div role="tabpanel">
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function UserServicePanel(props: RequestCategoryInterface) {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const targetRef = useRef(null);
    const history = useHistory();
    const [showCloseIcon, setCloseIcon] = useState(false);

    const dispatch = useDispatch();
    const showSponsoredPopup1 = useSelector((state: RootState) => state.request.showSponsoredPopup1);
    const showSponsoredPopup2 = useSelector((state: RootState) => state.request.showSponsoredPopup2);

    const [userpanelReq, setUserPanelReq] = useState([]);
    const [userpanelEvent, setUserPanelEvent] = useState([]);
    const [showLoader, setShowLoader] = useState(false);


    const ReqDescriptionTooltip = withStyles({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        }
    })(Tooltip);

    const setSelectedReqService = (reqname: any, servicename: any, isSponsored: boolean) => {
        //console.log(servicename,'service',reqname,'req');
        if (isSponsored) {
            dispatch(requestActions.setSponsoredPopup1({ "showPopup": true, "fromHome": true, "clickedSponsoredReq": reqname, "clickedSponsoredService": servicename }));
        } else {
            history.push('/request', { selectedServiceHome: servicename, selectedReqHome: reqname, selectedFromHome: true });
        }
    };

    const handleTabs = (event: ChangeEvent<{}>, val: number) => {
        event.stopPropagation();
        setShowLoader(true);
        setValue(val);
        focusElement();
        if ([1, 2].includes(val)) {
            setCloseIcon(true);
        }

        if (val == 1) {
            loadRequestSection();
        } else if (val == 2) {
            loadEventSection();
        }
       
    };


    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    const [noRequests, setNoRequests] = useState(false);
    const loadRequestSection = async () => {
        let requestFullData = await homeRequests()
        let requestParsedData = homeRequestReducer(requestFullData);
        setUserPanelReq(requestParsedData);
        setTimeout(() => {
            (requestParsedData.length == 0) ? setNoRequests(true) : setNoRequests(false);
        }, 500);
         setShowLoader(false);
    }

    const [noEvents, setNoEvents] = useState(false);
    const loadEventSection = async () => {
        let eventsFullData = await homeEvents();
        let eventParsedData = homeEventReducer(eventsFullData)
        setUserPanelEvent(eventParsedData);
        (eventParsedData.length == 0) ? setNoEvents(true) : setNoEvents(false);
        setShowLoader(false);
    }

    const focusElement = () => {
        if (targetRef && targetRef.current) {
            // @ts-ignore: Object is possibly 'null'.
            targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    const resetTabs = () => {
        props.selectService(null, '');
        setValue(0);
        focusElement();
        setCloseIcon(false);
    };
    const [selectedReqBucketOffsetLeft, setselectedReqBucketOffsetLeft] = useState('0px');
    function checkOffset(e: any) {
        let offsetLeft = (e.target.tagName !== "ARTICLE") ? e.target.closest('article').offsetLeft : e.target.offsetLeft;
        setselectedReqBucketOffsetLeft((offsetLeft - 10) + 'px');
        setCloseIcon(true);
    }

    return (
        <div className="allService-reqContainer" ref={targetRef}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleTabs}
                >
                    {
                        props.masterData && props.masterData.tabs ? (props.masterData.tabs.map((obj: any) =>
                        (
                            <Tab key={obj.tab} label={obj.tab} />
                        )
                        )) : null
                    }
                    <CancelIcon className="closeBtnHomePage" onClick={resetTabs} style={showCloseIcon ? { display: 'block' } : { display: 'none' }} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {props.masterData && props.masterData.tabs ? (props.masterData.tabs.map((mainObj: any, index: number) => (
                    <TabPanel key={mainObj.tab} value={value} index={index}>
                        {
                            props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'SERVICES' ?
                                (<div className="categoryOption-mainContainer pt-3">
                                    <div className="categoryOptionContent-service">
                                        {(props.masterData.tabs[index].subTabs.map((tabObj: any, subIndex: number) => (
                                            <article key={subIndex} className="academicContent" onClick={(e) => { props.selectService(e, tabObj.subTab); focusElement(); checkOffset(e) }}>
                                                <div>
                                                    <img className="serviceImgClass" src={tabObj.subTab === 'Academic & Education' ? props.serviceName === 'Academic & Education' ? SERV001W : SERV001 : tabObj.subTab === 'Patient Awareness & Support' ? props.serviceName === 'Patient Awareness & Support' ? SERV005W : SERV005 : tabObj.subTab === 'Practice Enhancement' ? props.serviceName === 'Practice Enhancement' ? SERV004W : SERV004 : tabObj.subTab === 'Research Publication' ? props.serviceName === 'Research Publication' ? SERV003W : SERV003 : tabObj.subTab === 'Patient Care' ? props.serviceName === 'Patient Care' ? SERV002W : SERV002 : ''} ></img>
                                                </div>
                                                <p style={{ "transition": "500ms all", "color": props.serviceName === tabObj.subTab ? 'white' : 'inherit' }}>{tabObj.subTab}</p>
                                            </article>
                                        )
                                        ))}
                                        {/* <section style={{
                                            "transition": "500ms all", "left": props.serviceName === 'Academic & Education' ? '0%' : props.serviceName === 'Patient Awareness & Support' ? "20%" : props.serviceName === 'Patient Care' ? "40%" : props.serviceName === 'Practice Enhancement' ? "60%" :
                                                props.serviceName === 'Research Publication' ? "80%" : 0, display: props.serviceName === '' ? 'none' : 'block'
                                        }}>
                                        </section> */}
                                        <section style={{
                                            "transition": "500ms all",
                                            "left": selectedReqBucketOffsetLeft,
                                            //"left": props.serviceName === props.listOfReqType[0] ? '0%' : props.serviceName === props.listOfReqType[1] ? ((100/(props.listOfReqType.length))*1)+'%' : props.serviceName === props.listOfReqType[2] ? ((100/(props.listOfReqType.length))*2)+'%': props.serviceName === props.listOfReqType[3] ? ((100/(props.listOfReqType.length))*3)+'%':
                                            //      props.serviceName === props.listOfReqType[4] ? ((100/(props.listOfReqType.length))*4)+'%' : 0, 
                                            "display": props.serviceName === '' ? 'none' : 'block'
                                        }}>
                                        </section>
                                    </div>

                                    <div className="typeOfReq-mainContainer"
                                        style={{
                                            "height": props.serviceName !== '' ? '11.7em' : '0',
                                            "transition": "all 300ms"
                                        }}
                                    >
                                        {(() => {

                                            switch (props.serviceName) {
                                                case 'Academic & Education':
                                                    return (
                                                        <div className="academicReq" >
                                                            {props.masterData && props.masterData.tabs && props.masterData.tabs[index].subTabs.length > 0 && props.masterData.tabs[index].subTabs.find((elem: any) => elem.subTab === 'Academic & Education').requestTypes.map((reqObj: any, index: number) => (
                                                                <div className="reqTypeDetails" key={index}>
                                                                    <label data-issponsored={reqObj.sponsored} onClick={() => { setSelectedReqService(reqObj.requestType, props.serviceName, reqObj.sponsored) }}>{reqObj.requestType}</label>
                                                                    <ReqDescriptionTooltip placement="right" title={reqObj.description}>
                                                                        <label className="serviceInfo hoverForTT" >i</label>
                                                                    </ReqDescriptionTooltip>
                                                                </div>
                                                            ))
                                                            }

                                                        </div>
                                                    )

                                                case 'Patient Awareness & Support':
                                                    return (
                                                        <div className="patientSupport">

                                                            {props.masterData && props.masterData.tabs && props.masterData.tabs[index].subTabs.length > 0 && props.masterData.tabs[index].subTabs.find((elem: any) => elem.subTab === 'Patient Awareness & Support').requestTypes.map((reqObj: any, index: number) => (
                                                                <div className="reqTypeDetails" key={index}>
                                                                    <label data-issponsored={reqObj.sponsored} onClick={() => { setSelectedReqService(reqObj.requestType, props.serviceName, reqObj.sponsored) }}>{reqObj.requestType}</label>
                                                                    <ReqDescriptionTooltip placement="right" title={reqObj.description}>
                                                                        <label className="serviceInfo hoverForTT" >i</label>
                                                                    </ReqDescriptionTooltip>
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    )

                                                case 'Practice Enhancement':
                                                    return (
                                                        <div className="patientSupport">
                                                            {props.masterData && props.masterData.tabs && props.masterData.tabs[index].subTabs.length > 0 && props.masterData.tabs[index].subTabs.find((elem: any) => elem.subTab === 'Practice Enhancement').requestTypes.map((reqObj: any, index: number) => (
                                                                <div className="reqTypeDetails" key={index}>
                                                                    <label data-issponsored={reqObj.sponsored} onClick={() => { setSelectedReqService(reqObj.requestType, props.serviceName, reqObj.sponsored) }}>{reqObj.requestType}</label>
                                                                    <ReqDescriptionTooltip placement="right" title={reqObj.description}>
                                                                        <label className="serviceInfo hoverForTT" >i</label>
                                                                    </ReqDescriptionTooltip>
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    )

                                                case 'Research Publication':
                                                    return (
                                                        <div className="patientSupport">
                                                            {props.masterData && props.masterData.tabs && props.masterData.tabs[index].subTabs.length > 0 && props.masterData.tabs[index].subTabs.find((elem: any) => elem.subTab === 'Research Publication').requestTypes.map((reqObj: any, index: number) => (
                                                                <div className="reqTypeDetails" key={index}>
                                                                    <label data-issponsored={reqObj.sponsored} onClick={() => { setSelectedReqService(reqObj.requestType, props.serviceName, reqObj.sponsored) }}>{reqObj.requestType}</label>
                                                                    <ReqDescriptionTooltip placement="right" title={reqObj.description}>
                                                                        <label className="serviceInfo hoverForTT" >i</label>
                                                                    </ReqDescriptionTooltip>

                                                                </div>
                                                            ))}

                                                        </div>
                                                    )

                                                case 'Patient Care':
                                                    return (
                                                        <div className="patientSupport">
                                                            {props.masterData && props.masterData.tabs && props.masterData.tabs[index].subTabs.length > 0 && props.masterData.tabs[index].subTabs.find((elem: any) => elem.subTab === 'Patient Care').requestTypes.map((reqObj: any, index: number) => (
                                                                <div className="reqTypeDetails" key={index}>
                                                                    <label data-issponsored={reqObj.sponsored} onClick={() => { setSelectedReqService(reqObj.requestType, props.serviceName, reqObj.sponsored) }}>{reqObj.requestType}</label>
                                                                    <ReqDescriptionTooltip placement="right" title={reqObj.description}>
                                                                        <label className="serviceInfo hoverForTT" >i</label>
                                                                    </ReqDescriptionTooltip>
                                                                </div>
                                                            ))
                                                            }

<p className="sampleNotificationText-HomePage">
                                 **Platform is undergoing some development.
                                 Sample requests will not be available. Apologies for the inconvenience caused.
                             </p>
                                                        </div>
                                                    )

                                            }

                                        })()}
                                    </div>

                                </div>) : null
                        }
                        {
                            props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'MY REQUESTS' ? (
                                <section className="panelRequestConatiner">
                                    <section className="panelRequestDetails">
                                        {/* {
                                            (props.masterData.tabs[value].subTabs.map((tabObj: any, subIndex: number) => (
                                                <>
                                                    <div key={subIndex} className="hcpRequestTracking row pt-3">
                                                        <section >
                                                            <div>{moment(tabObj.date).format('ddd')}</div>
                                                            <div>
                                                                <span>{moment(tabObj.date).format('DD')}</span>
                                                                <span> {moment(tabObj.date).format('MMM')}</span>
                                                            </div>
                                                            <div>{moment(tabObj.date).format('YYYY')}</div>
                                                        </section>
                                                        <section className="requestContentDetail">
                                                            {
                                                                tabObj.requestArray ? (tabObj.requestArray.map((subTabObj: any, subTabIndex: number) => (
                                                                    <article key={subTabIndex}>
                                                                        <div>
                                                                            <section className={("requestImgBackgroud mx-3 ") + (subTabObj.requestCat === 'Academics and Education' ? ' academics' : subTabObj.requestCat === 'Patient Awareness and Support' ? 'patientCare' : '')} >
                                                                            </section>
                                                                            {subTabObj.requestCat}</div>
                                                                        <div>Id: {subTabObj.requestId}</div>
                                                                        <div style={{ color: subTabObj.status === 'Completed' ? '#419268' : subTabObj.status === 'Submitted' ? '#0a358e ' : subTabObj.status === 'In Progress' ? 'yellow' : 'red' }}>
                                                                            {subTabObj.status}</div>
                                                                    </article>
                                                                ))) : null
                                                            }
                                                        </section>
                                                    </div>
                                                </>
                                            )
                                            ))
                                        } */}
                                        {/* after API OPTIMISATION IN UI */}

                                        {
                                            (showLoader) ? <div className="loader">
                                                <div className="spinner-grow text-muted"></div>
                                                <div className="spinner-grow text-muted"></div>
                                                <div className="spinner-grow text-muted"></div>
                                            </div>
                                                :
                                                (userpanelReq && userpanelReq.map((tabObj: any, subIndex: number) => (
                                                    <>
                                                        <div key={subIndex} className="hcpRequestTracking row pt-3">
                                                            <section >
                                                                <div>{moment(tabObj.date).format('ddd')}</div>
                                                                <div>
                                                                    <span>{moment(tabObj.date).format('DD')}</span>
                                                                    <span> {moment(tabObj.date).format('MMM')}</span>
                                                                </div>
                                                                <div>{moment(tabObj.date).format('YYYY')}</div>
                                                            </section>
                                                            <section className="requestContentDetail">
                                                                {
                                                                    tabObj.requestArray ? (tabObj.requestArray.map((subTabObj: any, subTabIndex: number) => (
                                                                        <article key={subTabIndex}>
                                                                            <div>
                                                                                <section className={("requestImgBackgroud mx-3 ") + (subTabObj.requestCat === 'Academics and Education' ? ' academics' : subTabObj.requestCat === 'Patient Awareness and Support' ? 'patientCare' : '')} >
                                                                                </section>
                                                                                {subTabObj.requestCat}</div>
                                                                            <div>Id: {subTabObj.requestId}</div>
                                                                            <div style={{ color: subTabObj.status === 'Completed' ? '#419268' : subTabObj.status === 'Submitted' ? '#0a358e ' : subTabObj.status === 'In Progress' ? 'yellow' : 'red' }}>
                                                                                {subTabObj.status}</div>
                                                                        </article>
                                                                    ))) : null
                                                                }
                                                            </section>
                                                        </div>
                                                    </>
                                                )
                                                ))
                                        }

                                    </section>
                                    {/* {(props.masterData.tabs[value].subTabs.length > 0) && <div className="seeMore" onClick={() => { history.push('/request', { fromHome: true }) }}>{`See More >> `}</div>} */}

                                    {props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'MY REQUESTS' && (noRequests) && (showLoader == false) && <div className="noUpcomingEvents">{`You do not have any Requests`}</div>}
                                    {(userpanelReq.length > 0) && (showLoader == false) && <div className="seeMore" onClick={() => { history.push('/request', { fromHome: true }) }}>{`See More >> `}</div>}

                                </section>
                            )
                                : null
                        }


                        {
                            props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'UPCOMING EVENTS' ? (
                                <section className="panelEventsConatiner">
                                    <section className="panelEventsContText">
                                        {/* {
                                            (props.masterData.tabs[value].subTabs.map((tabObj: any, subIndex: number) => (
                                                <>
                                                    <div key={subIndex} className="hcpEventsHome row pt-3">
                                                        <section >
                                                            <div>{moment(tabObj.date).format('ddd')}</div>
                                                            <div>
                                                                <span>{moment(tabObj.date).format('DD')}</span>
                                                                <span> {moment(tabObj.date).format('MMM')}</span>
                                                            </div>
                                                            <div>{moment(tabObj.date).format('YYYY')}</div>
                                                        </section>
                                                        <section className="eventContentDetail">
                                                            {
                                                                tabObj.eventsArray && tabObj.eventsArray.map((subObj: any) => (
                                                                    <article key={subObj.speaker}>
                                                                        <div>{subObj.eventName}</div>
                                                                        <div>Speaker: {subObj.speaker}</div>
                                                                        <div>
                                                                            <img src={home_clock} alt={subObj.eventName} />
                                                                            {subObj.time}</div>
                                                                        <div>Venue:  {subObj.venue}</div>
                                                                    </article>
                                                                ))
                                                            }
                                                        </section>

                                                    </div>

                                                </>
                                            )
                                            ))
                                        } */}



                                        {/* AFTER API OPTIMISATION */}

                                        {
                                            // (props.masterData.tabs[value].subTabs.map((tabObj: any, subIndex: number) => (
                                            (showLoader) ? <div className="loader">
                                                <div className="spinner-grow text-muted"></div>
                                                <div className="spinner-grow text-muted"></div>
                                                <div className="spinner-grow text-muted"></div>
                                            </div>
                                                : (userpanelEvent && userpanelEvent.map((tabObj: any, subIndex: number) => (
                                                    <>
                                                        <div key={subIndex} className="hcpEventsHome row pt-3">
                                                            <section >
                                                                <div>{moment(tabObj.date).format('ddd')}</div>
                                                                <div>
                                                                    <span>{moment(tabObj.date).format('DD')}</span>
                                                                    <span> {moment(tabObj.date).format('MMM')}</span>
                                                                </div>
                                                                <div>{moment(tabObj.date).format('YYYY')}</div>
                                                            </section>
                                                            <section className="eventContentDetail">
                                                                {
                                                                    tabObj.eventsArray && tabObj.eventsArray.map((subObj: any) => (
                                                                        <article key={subObj.speaker}>
                                                                            <div>{subObj.eventName}</div>
                                                                            <div>Speaker: {subObj.speaker}</div>
                                                                            <div>
                                                                                <img src={home_clock} alt={subObj.eventName} />
                                                                                {subObj.time}</div>
                                                                            <div>Venue:  {subObj.venue}</div>
                                                                        </article>
                                                                    ))
                                                                }
                                                            </section>

                                                        </div>

                                                    </>
                                                )
                                                ))
                                        }
                                    </section>
                                    {/* {props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'UPCOMING EVENTS' && (props.masterData.tabs[value].subTabs.length == 0) && <div className="noUpcomingEvents">{`You do not have any upcoming events `}</div>}
                                    {props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'UPCOMING EVENTS' && (props.masterData.tabs[value].subTabs.length > 0) && <div className="seeMore" onClick={() => { history.push('/events') }}>{`See More >> `}</div>} */}

                                    {props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'UPCOMING EVENTS' && (noEvents) && (showLoader == false) && <div className="noUpcomingEvents">{`You do not have any upcoming events `}</div>}
                                    {props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'UPCOMING EVENTS' && (showLoader == false) && (userpanelEvent.length > 0) && <div className="seeMore" onClick={() => { history.push('/events') }}>{`See More >> `}</div>}
                                </section>
                            )
                                : <></>
                        }









                        {
                            props.masterData && props.masterData.tabs && props.masterData.tabs[value].tab === 'TOP PICKS' ?
                                (<div className="topPicksHome">
                                    <img src={top_picks_1} alt="top picks 1" />
                                    <img src={top_picks_2} alt="top picks 2" />
                                    <img src={top_picks_3} alt="toop picks 3" />
                                    <img src={top_picks_2} alt="top picks 4" />
                                    <img src={top_picks_1} alt="top picks 5" />
                                </div>) : null
                        }
                    </TabPanel>
                ))) : null}
            </SwipeableViews>
        </div>
    );
}