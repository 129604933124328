import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "./CreateNewRequest.scss";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { shippingAddressAction } from "../../../store/shippingAddress";
const PatientAwarenessRequest = () => {
  const dispatch = useDispatch();
  // const userInfo = useSelector((state: RootState) => state.login.userInfo);
  const editDraft = useSelector((state: RootState) => state.request.editDraft);
  const caseNumber = useSelector(
    (state: RootState) => state.request.caseNumber
  );

  const [patientSupportCreatedPopup, setpatientSupportCreatedPopup] =
    useState(false);

  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const serviceName = "Patient Awareness & Support";

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  const [listOfService, setListOfService] = useState([]);

  const selectedPatientAwarenessReqRef = useRef("");
  const selectedPatientAwarenessReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );

  const setPatientAwarenessReq = (params: any) => {
    selectedPatientAwarenessReqRef.current = params;
    dispatch(requestActions.setSelectedReq(params));
  };

  const shipToAddressRecordID = useSelector(
    (state: RootState) => state.request.shipToAddressRecordID
  );

  function validateEmail(emailAdress: string) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const [
    submitPatientAwarenessMaterialEnabled,
    setSubmitPatientAwarenessMaterialEnabled,
  ] = useState(true);
  const [submitPatientCounsellingEnabled, setSubmitPatientCounsellingEnabled] =
    useState(true);
  const [submitPatientScreeningEnabled, setSubmitPatientScreeningEnabled] =
    useState(true);
  const [submitPatientSupportEnabled, setSubmitPatientSupportEnabled] =
    useState(true);

  useEffect(() => {
    fetchListOfAccessbileServices();
    //resetPrevSelections(selectedPatientAwarenessReq);
    resetPrevSelectedFieldValues(selectedPatientAwarenessReq);
    resetFieldErrors(selectedPatientAwarenessReq);
    resetSubmitButtons(selectedPatientAwarenessReq);
    return () => {
      //  console.log("cleanup")
      debouncedChangeHandler();
    };
  }, []);

  function debounce(callback: any, delay: any) {
    //console.log("debounce")
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }

  const debouncedChangeHandler = () => {
    //console.log("debouncedChangeHandler")
    let text = selectedPatientAwarenessReqRef.current; //(localStorage.getItem('selectedService'));
    debounce(() => saveAsDraftOnTabChange(text), 1000);
  };

  const saveAsDraftOnTabChange = (selectedPatientAwarenessReq1: any) => {
    //console.log(selectedPatientAwarenessReq1, 'selectedPatientAwarenessReq1')
    switch (selectedPatientAwarenessReq1) {
      case "Patient Support Program":
        submitPatientSupportRequest(true, true);
        break;

      case "Patient Screening":
        submitPatientScreeningRequest(true, true);
        break;

      case "Patient Counselling":
        submitPatientCounsellingRequest(true, true);
        break;

      case "Patient Awareness Material":
        submitPatientAwarenessRequest(true, true);
        break;
    }
  };

  const patientAwarenessRequestTypeArray = listOfService.filter(function (
    item: any
  ) {
    return item.serviceName == serviceName;
  });

  patientAwarenessRequestTypeArray.map((item1: any, index1: number) => {
    const sortedlist = item1.lstRequestType.sort((a: any, b: any) =>
      a.requestName < b.requestName ? -1 : a.requestName > b.requestName ? 1 : 0
    );
    item1.lstRequestType = sortedlist;
  });

  const fetchListOfAccessbileServices = async () => {
    const serviceUrl1 = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
    const srviceAccUrl1 = baseServiceUrl + serviceUrl1;
    let params: any = {
      speciality: userInfo ? userInfo.speciality : "",
      category: userInfo ? userInfo.category : "",
    };
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl1,
      new RequestParams(params)
    );
    setListOfService(serviceAccessibleData.data.lstServiceType);
  };

  const setErrorToastMsg = (params: any) => {
    dispatch(requestActions.setErrorToastMsg(params));
  };

  const setSuccessToastMsg = (params: any) => {
    dispatch(requestActions.setSuccessToastMsg(params));
  };

  // const serviceList = useSelector((state: RootState) => state.request.serviceInfo.subTabs);
  // const patientAwarenessRequestTypeArray = serviceList.filter(function (item: any) {
  //     return item.subTab == 'Patient Awareness & Support';
  //   })

  const patientAwarenessEmailRef = useRef("");
  const patientAwarenessEmail = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial.email
  );
  const setpatientAwarenessEmail = (params: any) => {
    patientAwarenessEmailRef.current = params;
    dispatch(requestActions.setpatientAwarenessEmail(params));
  };

  const patientAwarenessLangRef = useRef("");
  const patientAwarenessLang = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial.language
  );
  const setpatientAwarenessLang = (params: any) => {
    patientAwarenessLangRef.current = params;
    dispatch(requestActions.setpatientAwarenessLang(params));
  };

  const patientAwarenessDeliveryAddressRef = useRef("");
  const patientAwarenessDeliveryAddress = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .deliveryAddr
  );
  const setpatientAwarenessDeliveryAddress = (params: any) => {
    patientAwarenessDeliveryAddressRef.current = params;
    dispatch(requestActions.setpatientAwarenessDeliveryAddress(params));
  };

  const patientAwarenessCopiesCountRef = useRef("");
  const patientAwarenessCopiesCount = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .noOfCopies
  );
  const setpatientAwarenessCopiesCount = (params: any) => {
    patientAwarenessCopiesCountRef.current = params;
    dispatch(requestActions.setpatientAwarenessCopiesCount(params));
  };

  const patientAwarenessInputTypeRef = useRef("");
  const patientAwarenessInputType = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .inputType
  );
  const setpatientAwarenessInputType = (params: any) => {
    patientAwarenessInputTypeRef.current = params;
    dispatch(requestActions.setpatientAwarenessInputType(params));
  };

  const therapyTopicSelectionRef = useRef("");
  const therapyTopicSelection = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .selectTopicTherapy
  );
  const setTherapyTopicSelection = (params: any) => {
    therapyTopicSelectionRef.current = params;
    dispatch(requestActions.setTherapyTopicSelection(params));
  };

  const otherTherapyTopicRef = useRef("");
  const otherTherapyTopic = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .selectTopicOther
  );
  const setOtherTherapyTopic = (params: any) => {
    otherTherapyTopicRef.current = params;
    dispatch(requestActions.setOtherTherapyTopic(params));
  };

  const papersizeRef = useRef("");
  const papersize = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .papersize
  );
  const setPaperSize = (params: any) => {
    papersizeRef.current = params;
    dispatch(requestActions.setPaperSize(params));
  };

  const patientAwarenessDescRef = useRef("");
  const patientAwarenessDesc = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientAwarenessMaterial
        .descriptionPatientAwareness
  );
  const setpatientAwarenessDesc = (params: any) => {
    patientAwarenessDescRef.current = params;
    dispatch(requestActions.setpatientAwarenessDesc(params));
  };

  const [therapyTopicSelectionErr, setTherapyTopicSelectionErr] =
    useState("UD");
  const [otherTherapyTopicErr, setOtherTherapyTopicErr] = useState("UD");
  const [papersizeErr, setpapersizeErr] = useState("UD");
  const [patientAwarenessDescErr, setpatientAwarenessDescErr] = useState("UD");
  const [patientAwarenessInputTypeErr, setpatientAwarenessInputTypeErr] =
    useState("UD");
  const [patientAwarenessCopiesCountErr, setpatientAwarenessCopiesCountErr] =
    useState("UD");
  const [
    patientAwarenessDeliveryAddressErr,
    setpatientAwarenessDeliveryAddressErr,
  ] = useState("UD");
  const [patientAwarenessLangErr, setpatientAwarenessLangErr] = useState("UD");
  const [patientAwarenessEmailErr, setpatientAwarenessEmailErr] =
    useState("UD");
  const [patientAwarenessReqClicked, setPatientAwarenessReqClicked] =
    useState(false);

  useEffect(() => {
    if (patientAwarenessReqClicked) {
      validatePatientAwarenessMaterialReq();
    }
  }, [patientAwarenessReqClicked]);

  useEffect(() => {
    if (
      therapyTopicSelectionErr === "N" &&
      patientAwarenessInputTypeErr === "N" &&
      patientAwarenessLangErr === "N" &&
      patientAwarenessDescErr === "N" &&
      ((patientAwarenessInputType == "Print" &&
        patientAwarenessDeliveryAddressErr == "N" &&
        patientAwarenessCopiesCountErr === "N") ||
        (patientAwarenessInputType == "Digital" &&
          patientAwarenessEmailErr == "N"))
    ) {
      submitPatientAwarenessRequest();
    } else if (
      therapyTopicSelectionErr === "Y" ||
      patientAwarenessInputTypeErr === "Y" ||
      patientAwarenessDescErr === "Y" ||
      patientAwarenessLangErr === "Y" ||
      (patientAwarenessInputType == "Print" &&
        (patientAwarenessDeliveryAddressErr == "Y" ||
          patientAwarenessCopiesCountErr === "Y")) ||
      (patientAwarenessInputType == "Digital" &&
        patientAwarenessEmailErr == "Y")
    ) {
      setPatientAwarenessReqClicked(false);
    }
  }, [
    therapyTopicSelectionErr,
    patientAwarenessInputTypeErr,
    patientAwarenessCopiesCountErr,
    patientAwarenessLangErr,
    patientAwarenessDeliveryAddressErr,
    patientAwarenessEmailErr,
    patientAwarenessDescErr
  ]);

  const resetOnInputTypeChange = () => {
    setTherapyTopicSelectionErr("UD");
    setOtherTherapyTopicErr("UD");
    // setpatientAwarenessDescErr('UD');
    setpatientAwarenessInputTypeErr("UD");
    setpatientAwarenessLangErr("UD");
    setpatientAwarenessEmailErr("UD");
    setpatientAwarenessDeliveryAddressErr("UD");
    setpatientAwarenessCopiesCountErr("UD");
    setpatientAwarenessCopiesCount("");
    setpatientAwarenessDeliveryAddress("");
    setpatientAwarenessEmail("");
    setPatientAwarenessReqClicked(false);
  };

  const validatePatientAwarenessMaterialReq = () => {
    setTherapyTopicSelectionErr(therapyTopicSelection.length == 0 ? "Y" : "N");
    setOtherTherapyTopicErr(otherTherapyTopic.length == 0 ? "Y" : "N");
    setpapersizeErr(papersize.length == 0 ? "Y" : "N");
    setpatientAwarenessDescErr(patientAwarenessDesc.trim().length == 0 ? "Y" : "N");
    setpatientAwarenessInputTypeErr(
      patientAwarenessInputType.length == 0 ? "Y" : "N"
    );
    setpatientAwarenessLangErr(patientAwarenessLang.length == 0 ? "Y" : "N");

    if (patientAwarenessInputType == "Print") {
      setpatientAwarenessDeliveryAddressErr(
        patientAwarenessDeliveryAddress.length == 0 ? "Y" : "N"
      );
      setpatientAwarenessCopiesCountErr(
        patientAwarenessCopiesCount.length == 0 ? "Y" : "N"
      );
      setpatientAwarenessEmailErr("UD");
    } else {
      setpatientAwarenessEmailErr(
        validateEmail(patientAwarenessEmail) ? "N" : "Y"
      );
      setpatientAwarenessDeliveryAddressErr("UD");
      setpatientAwarenessCopiesCountErr("UD");
    }
  };

  const submitPatientAwarenessRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPatientAwarenessMaterialEnabled(false);
    try {
      // if (patientAwarenessReqClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPatientAwarenessReqRef.current
          : selectedPatientAwarenessReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        selectTopicTherapy: unmounted
          ? therapyTopicSelectionRef.current
          : therapyTopicSelection,
        selectTopicOther: unmounted
          ? otherTherapyTopicRef.current
          : otherTherapyTopic,
        papersize: unmounted ? papersizeRef.current : papersize,
        inputType: unmounted
          ? patientAwarenessInputTypeRef.current
          : patientAwarenessInputType,
        noOfCopies: unmounted
          ? patientAwarenessCopiesCountRef.current
          : patientAwarenessCopiesCount,
        language: unmounted
          ? patientAwarenessLangRef.current
          : patientAwarenessLang,
        deliveryAddr: unmounted
          ? patientAwarenessDeliveryAddressRef.current
          : patientAwarenessDeliveryAddress,
        description: unmounted
          ? patientAwarenessDescRef.current
          : patientAwarenessDesc,
        emailid: unmounted
          ? patientAwarenessEmailRef.current
          : patientAwarenessEmail,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
        addressRecordId: shipToAddressRecordID,
      };
      if (
        fromSaveAsDraft &&
        (therapyTopicSelectionRef.current.length > 0 ||
          patientAwarenessDescRef.current.length > 0 ||
          patientAwarenessLangRef.current.length > 0 ||
          patientAwarenessInputTypeRef.current.length > 0)
      ) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (patientAwarenessReqClicked) {
        sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPatientAwarenessReqClicked(false);
      setSubmitPatientAwarenessMaterialEnabled(true);
    }
  };

  const screeningEmailRef = useRef("");
  const screeningEmail = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.emailPS
  );
  const setScreeningEmail = (params: any) => {
    screeningEmailRef.current = params;
    dispatch(requestActions.setScreeningEmail(params));
  };

  const screeningTherapyTopicRef = useRef([]);
  const screeningTherapyTopic = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening
        .selectTopicTherapyPS
  );
  const setscreeningTherapyTopic = (params: any) => {
    screeningTherapyTopicRef.current = params;
    dispatch(requestActions.setscreeningTherapyTopic(params));
  };

  const screeningTherapyTopicOtherRef = useRef([]);
  const screeningTherapyTopicOther = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening
        .selectTopicOtherPS
  );
  const setScreeningTherapyTopicOther = (params: any) => {
    screeningTherapyTopicOtherRef.current = params;
    dispatch(requestActions.setScreeningTherapyTopicOther(params));
  };

  const screeningSupportRef = useRef(new Array());
  const screeningSupport = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening
        .supportScreeningPS
  );
  const setScreeningSupport = (params: any) => {
    screeningSupportRef.current = params;
    dispatch(requestActions.setScreeningSupport(params));
  };

  const screeningInputCountRef = useRef([]);
  const screeningInputCount = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.noOfInputPS
  );
  const setScreeningInputCount = (params: any) => {
    screeningInputCountRef.current = params;
    dispatch(requestActions.setScreeningInputCount(params));
  };

  const educationMaterialNeededRef = useRef("");
  const educationMaterialNeeded = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening
        .patientEduMaterialPS
  );
  const setEducationMaterialNeeded = (params: any) => {
    educationMaterialNeededRef.current = params;
    dispatch(requestActions.setEducationMaterialNeeded(params));
  };

  const patientEducationTypeRef = useRef("");
  const patientEducationType = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.patientEduTypePS
  );
  const setpatientEducationType = (params: any) => {
    patientEducationTypeRef.current = params;
    dispatch(requestActions.setpatientEducationType(params));
  };

  const patientscreeningLanguageRef = useRef("");
  const patientscreeningLanguage = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.languagePS
  );
  const setPatientScreeningLanguage = (params: any) => {
    patientscreeningLanguageRef.current = params;
    dispatch(requestActions.setScreeningLanguage(params));
  };

  const screeningDeliveryAddressRef = useRef("");
  const screeningDeliveryAddress = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.deliveryAddrPS
  );
  const setScreeningDeliveryAddress = (params: any) => {
    screeningDeliveryAddressRef.current = params;
    dispatch(requestActions.setScreeningDeliveryAddress(params));
  };

  const screeningCopiesCountRef = useRef("");
  const screeningCopiesCount = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.copiesCountPS
  );
  const setScreeningCopiesCount = (params: any) => {
    screeningCopiesCountRef.current = params;
    dispatch(requestActions.setScreeningCopiesCount(params));
  };

  const screeningDescRef = useRef("");
  const screeningDesc = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientScreening.serviceDescPS
  );
  const setScreeningDesc = (params: any) => {
    screeningDescRef.current = params;
    dispatch(requestActions.setScreeningDesc(params));
  };

  const [screeningTherapyTopicErr, setscreeningTherapyTopicErr] =
    useState("UD");
  const [screeningTherapyTopicOtherErr, setscreeningTherapyTopicOtherErr] =
    useState("UD");
  const [screeningSupportErr, setScreeningSupportErr] = useState("UD");
  const [screeningInputCountErr, setScreeningInputCountErr] = useState("UD");
  const [educationMaterialNeededErr, setEducationMaterialNeededErr] =
    useState("UD");
  const [patientEducationTypeErr, setpatientEducationTypeErr] = useState("UD");
  const [screeningLanguageErr, setScreeningLanguageErr] = useState("UD");
  const [screeningDeliveryAddressErr, setScreeningDeliveryAddressErr] =
    useState("UD");
  const [screeningCopiesCountErr, setScreeningCopiesCountErr] = useState("UD");
  const [screeningEmailErr, setScreeningEmailErr] = useState("UD");
  const [screeningRequestClicked, setScreeningRequestClicked] = useState(false);
  const [screeningDescErr, setscreeningDescErr] = useState("UD");

  useEffect(() => {
    if (screeningRequestClicked) {
      validateScreeningReq();
    }
  }, [screeningRequestClicked]);

  useEffect(() => {
    if (
      screeningTherapyTopicErr === "N" &&
      screeningSupportErr === "N" &&
      screeningDescErr === "N" &&
      educationMaterialNeededErr === "N" &&
      screeningInputCountErr === "N" &&
      patientEducationTypeErr === "N" &&
      screeningLanguageErr === "N" &&
      screeningSupportRef.current.length ==
        screeningInputCountRef.current.length &&
      ((patientEducationType == "Print" &&
        screeningDeliveryAddressErr === "N") ||
        (patientEducationType == "Digital Version" &&
          screeningEmailErr === "N"))
    ) {
      submitPatientScreeningRequest();
    } else if (
      screeningTherapyTopicErr === "Y" ||
      screeningSupportErr === "Y" ||
      educationMaterialNeededErr === "Y" ||
      screeningInputCountErr === "Y" ||
      patientEducationTypeErr === "Y" ||
      screeningLanguageErr === "Y" ||
      screeningDeliveryAddressErr === "Y" ||
      screeningDescErr === "Y" ||
      (patientEducationType == "Print" &&
        screeningDeliveryAddressErr === "Y") ||
      (patientEducationType == "Digital Version" &&
        screeningEmailErr === "Y") ||
      screeningSupportRef.current.length !==
        screeningInputCountRef.current.length
    ) {
      if (
        screeningSupportRef.current.length !==
        screeningInputCountRef.current.length
      ) {
        setErrorToastMsg({
          isError: true,
          errMsg:
            "Support screening and number of input selection should be same",
        });
      }

      setScreeningRequestClicked(false);
    }
  }, [
    screeningEmailErr,
    screeningTherapyTopicErr,
    screeningSupportErr,
    screeningInputCountErr,
    educationMaterialNeededErr,
    screeningLanguageErr,
    screeningDeliveryAddressErr,
    screeningEmailErr,
    screeningDescErr,
  ]);

  const resetOnPatientEducationTypeChange = () => {
    setscreeningTherapyTopicErr("UD");
    setScreeningLanguageErr("UD");
    setpatientEducationTypeErr("UD");
    setEducationMaterialNeededErr("UD");
    setEducationMaterialNeededErr("UD");
    setScreeningDeliveryAddressErr("UD");
    setScreeningCopiesCountErr("UD");
    setScreeningInputCountErr("UD");
    setScreeningEmailErr("UD");
    // setScreeningSupportErr('UD');
    setscreeningDescErr("UD");

    setScreeningDeliveryAddress("");
    setScreeningCopiesCount("");
    setScreeningEmail("");
    setScreeningRequestClicked(false);
  };

  const validateScreeningReq = () => {
    setscreeningTherapyTopicErr(screeningTherapyTopic.length == 0 ? "Y" : "N");
    setScreeningSupportErr(
      screeningInputCount.length !== screeningSupport.length ||
        screeningSupport.length == 0
        ? "Y"
        : "N"
    );
    setScreeningLanguageErr(patientscreeningLanguage.length == 0 ? "Y" : "N");
    setpatientEducationTypeErr(patientEducationType.length == 0 ? "Y" : "N");
    setEducationMaterialNeededErr(
      educationMaterialNeeded.length == 0 ? "Y" : "N"
    );
    setScreeningInputCountErr(screeningInputCount.length == 0 ? "Y" : "N");
    setscreeningDescErr(screeningDesc.trim().length == 0 ? "Y" : "N");
    if (patientEducationType == "Print") {
      setScreeningDeliveryAddressErr(
        screeningDeliveryAddress.length == 0 ? "Y" : "N"
      );
      setScreeningCopiesCountErr(screeningCopiesCount.length == 0 ? "Y" : "N");
      setScreeningEmailErr("N");
    } else {
      setScreeningEmailErr(validateEmail(screeningEmail) ? "N" : "Y");
      setScreeningDeliveryAddressErr("N");
      setScreeningCopiesCountErr("N");
    }
  };

  const submitPatientScreeningRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPatientScreeningEnabled(false);
    try {
      //if (screeningRequestClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPatientAwarenessReqRef.current
          : selectedPatientAwarenessReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        selecttopicscreening: unmounted
          ? screeningTherapyTopicRef.current.join(";")
          : screeningTherapyTopic.join(";"),
        selectTopicOther: unmounted
          ? screeningTherapyTopicOtherRef.current
          : screeningTherapyTopicOther,
        supportScreening: unmounted
          ? screeningSupportRef.current.join(";")
          : screeningSupport.join(";"),
        noOfInput: unmounted
          ? screeningInputCountRef.current.join(";")
          : screeningInputCount.join(";"),
        patientEduMaterial: unmounted
          ? educationMaterialNeededRef.current
          : educationMaterialNeeded,
        patientEduType: unmounted
          ? patientEducationTypeRef.current
          : patientEducationType,
        language: unmounted
          ? patientscreeningLanguageRef.current
          : patientscreeningLanguage,
        deliveryAddr: unmounted
          ? screeningDeliveryAddressRef.current
          : screeningDeliveryAddress,
        description: unmounted ? screeningDescRef.current : screeningDesc,
        emailid: unmounted ? screeningEmailRef.current : screeningEmail,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        noOfCopies: unmounted
          ? screeningCopiesCountRef.current
          : screeningCopiesCount,
        caseNumber: editDraft ? caseNumber : "",
        addressRecordId: shipToAddressRecordID,
      };
      if (
        fromSaveAsDraft &&
        (screeningDescRef.current.length > 0 ||
          screeningDeliveryAddressRef.current.length > 0 ||
          patientscreeningLanguageRef.current.length > 0 ||
          patientEducationTypeRef.current.length > 0 ||
          educationMaterialNeededRef.current.length > 0 ||
          screeningInputCountRef.current.length > 0 ||
          screeningSupportRef.current.length > 0 ||
          screeningTherapyTopicRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (screeningRequestClicked) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
      // else{
      //     setErrorToastMsg({ 'isError': true, 'errMsg': 'Error' });
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setScreeningRequestClicked(false);
      setSubmitPatientScreeningEnabled(true);
    }
  };

  const [serviceForCounsellingErr, setServiceForCounsellingErr] =
    useState("UD");
  const [counsellingDescErr, setcounsellingDescErr] = useState("UD");
  const [counsellingPatientCountErr, setCounsellingPatientCountErr] =
    useState("UD");
  const [counsellingFreqPerMonthErr, setCounsellingFreqPerMonthErr] =
    useState("UD");
  const [patientCounsellingReqClicked, setPatientCounsellingReqClicked] =
    useState(false);

  const counsellingMode = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientCounselling
        .counsellingMode
  );

  const serviceForCounsellingRef = useRef("");
  const serviceForCounselling = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientCounselling
        .selectServicePC
  );
  const setServiceForCounselling = (params: any) => {
    serviceForCounsellingRef.current = params;
    dispatch(requestActions.setServiceForCounselling(params));
  };

  const counsellingPatientCountRef = useRef("");
  const counsellingPatientCount = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientCounselling
        .noOfPatientMonthPC
  );
  const setCounsellingPatientCount = (params: any) => {
    counsellingPatientCountRef.current = params;
    dispatch(requestActions.setCounsellingPatientCount(params));
  };

  const counsellingFreqPerMonthRef = useRef("");
  const counsellingFreqPerMonth = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientCounselling.freqPerMonth
  );
  const setCounsellingFreqPerMonth = (params: any) => {
    counsellingFreqPerMonthRef.current = params;
    dispatch(requestActions.setCounsellingFreqPerMonth(params));
  };

  const counsellingDescRef = useRef("");
  const counsellingDesc = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientCounselling.serviceDescPC
  );
  const setCounsellingDesc = (params: any) => {
    counsellingDescRef.current = params;
    dispatch(requestActions.setCounsellingDesc(params));
  };

  useEffect(() => {
    if (patientCounsellingReqClicked) {
      validateCounsellingReq();
    }
  }, [patientCounsellingReqClicked]);

  useEffect(() => {
    if (
      serviceForCounsellingErr === "N" &&
      counsellingDescErr === "N" &&
      counsellingPatientCountErr === "N" &&
      counsellingFreqPerMonthErr === "N"
    ) {
      submitPatientCounsellingRequest();
    } else if (
      serviceForCounsellingErr === "Y" ||
      counsellingDescErr === "Y" ||
      counsellingPatientCountErr === "Y" ||
      counsellingFreqPerMonthErr === "Y"
    ) {
      setPatientCounsellingReqClicked(false);
    }
  }, [
    serviceForCounsellingErr,
    counsellingDescErr,
    counsellingPatientCountErr,
    counsellingFreqPerMonthErr,
  ]);

  const validateCounsellingReq = () => {
    setServiceForCounsellingErr(serviceForCounselling.length == 0 ? "Y" : "N");
    setCounsellingPatientCountErr(
      counsellingPatientCount.length == 0 ? "Y" : "N"
    );
    setCounsellingFreqPerMonthErr(
      counsellingFreqPerMonth.length == 0 ? "Y" : "N"
    );
    setcounsellingDescErr(counsellingDesc.trim().length == 0 ? "Y" : "N");
  };

  const submitPatientCounsellingRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPatientCounsellingEnabled(false);
    try {
      // if (patientCounsellingReqClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPatientAwarenessReqRef.current
          : selectedPatientAwarenessReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        selectService: unmounted
          ? serviceForCounsellingRef.current
          : serviceForCounselling,
        noOfPatientMonth: unmounted
          ? counsellingPatientCountRef.current
          : counsellingPatientCount,
        mode: counsellingMode,
        freqMonth: unmounted
          ? counsellingFreqPerMonthRef.current
          : counsellingFreqPerMonth,
        description: unmounted ? counsellingDescRef.current : counsellingDesc,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (serviceForCounsellingRef.current.length > 0 ||
          counsellingPatientCountRef.current.length > 0 ||
          counsellingMode.length > 0 ||
          counsellingFreqPerMonthRef.current.length > 0 ||
          counsellingDescRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject);
      } else if (patientCounsellingReqClicked) {
        await sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPatientCounsellingReqClicked(false);
      setSubmitPatientCounsellingEnabled(true);
    }
  };

  const patientSupportProgramTypeRef = useRef("");
  const patientSupportProgramType = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientSupport.programType
  );
  const setPatientSupportProgramType = (params: any) => {
    patientSupportProgramTypeRef.current = params;
    dispatch(requestActions.setProgramType(params));
  };

  const patientSupportProgramDescRef = useRef("");
  const patientSupportProgramDesc = useSelector(
    (state: RootState) =>
      state.request.patientAwarenessSupportReq.patientSupport
        .descriptionPatientSupport
  );
  const setPatientSupportProgramDesc = (params: any) => {
    patientSupportProgramDescRef.current = params;
    dispatch(requestActions.setPatientSupportDesc(params));
  };

  const [patientSupportProgramTypeErr, setPatientSupportProgramTypeErr] =
    useState("UD");
  const [patientSupportDescErr, setPatientSupportDescErr] = useState("UD");

  const [patientSupportReqClicked, setPatientSupportReqClicked] =
    useState(false);

  useEffect(() => {
    if (patientSupportReqClicked) {
      validatePatientSupportReq();
    }
  }, [patientSupportReqClicked]);

  useEffect(() => {
    if (patientSupportProgramTypeErr === "N" && patientSupportDescErr === "N") {
      submitPatientSupportRequest();
    } else if (
      patientSupportProgramTypeErr === "Y" ||
      patientSupportDescErr === "Y"
    ) {
      setPatientSupportReqClicked(false);
    }
  }, [patientSupportProgramTypeErr, patientSupportDescErr]);

  const validatePatientSupportReq = () => {
    setPatientSupportProgramTypeErr(
      patientSupportProgramType.length == 0 ? "Y" : "N"
    );
    setPatientSupportDescErr(patientSupportProgramDesc.trim().length == 0 ? "Y" : "N");
  };

  const submitPatientSupportRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitPatientSupportEnabled(false);
    try {
      // if (patientSupportReqClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPatientAwarenessReqRef.current
          : selectedPatientAwarenessReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        programType: unmounted
          ? patientSupportProgramTypeRef.current
          : patientSupportProgramType,
        description: unmounted
          ? patientSupportProgramDescRef.current
          : patientSupportProgramDesc,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (patientSupportProgramDescRef.current.length > 0 ||
          patientSupportProgramTypeRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else if (patientSupportReqClicked) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPatientSupportReqClicked(false);
      setSubmitPatientSupportEnabled(true);
    }
  };

  const therapyTopic = ["Hypertension", "Diabetes"];

  const screeningMachine = ["Glucometer", "Glucometer Strips", "BP Machine"];

  // const screeningMachineInputCount = [
  //   "Glucometer(1)",
  //   "Gluco Strips(20)",
  //   "BP Machine(1)",
  // ];
  const screeningMachineInputCount = getFilteredInputCount();

  function getFilteredInputCount() {
    const result: string[] = [];
    if (screeningSupportRef.current.includes("Glucometer")) {
      // result.push("1 to 5 ( Glucometer)");
      result.push("Glucometer-1");
    }
    if (screeningSupportRef.current.includes("Glucometer Strips")) {
      // result.push("10 to 200 (gluco strips)");
      result.push("Strips-25");
    }
    if (screeningSupportRef.current.includes("BP Machine")) {
      // result.push("1 to 5 (BP Machine)");
      result.push("BP Machine-1");
    }
    return result;
  }

  const languages = [
    "Assamese",
    "Bengali",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Odia",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Tamil",
    "Telugu",
    "Urdu",
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleReqTypeChange = (clickedElement: any) => {
    let isSponsoredService =
      clickedElement.currentTarget.getAttribute("data-issponsored");
    dispatch(requestActions.seteditDraft(false));
    if (isSponsoredService == "true") {
      dispatch(
        requestActions.setSponsoredPopup1({
          showPopup: true,
          fromHome: false,
          clickedSponsoredReq: clickedElement.target.value,
          clickedSponsoredService: serviceName,
        })
      );
      setPatientAwarenessReq("");
    } else {
      setPatientAwarenessReq(clickedElement.target.value);
      //resetPrevSelections(clickedElement.target.value);
      resetPrevSelectedFieldValues(clickedElement.target.value);
      resetFieldErrors(clickedElement.target.value);
    }
  };

  const sendReqObjectForNewReq = async (
    reqObject: any,
    unmounted?: boolean,
    saveAsDraft?: boolean
  ) => {
    if (!editDraft) {
      delete (reqObject as any).caseNumber;
    }
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      // console.log(output.data);
      dispatch(requestActions.seteditDraft(false));
      if (output.data.status == "failure") {
        if (!unmounted) {
          resetFieldErrors(selectedPatientAwarenessReq);
          setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
        }
      } else {
        if (!unmounted) {
          if (
            selectedPatientAwarenessReq == "Patient Support Program" &&
            !saveAsDraft
          ) {
            setpatientSupportCreatedPopup(true);
          } else if (
            selectedPatientAwarenessReq !== "Patient Support Program"
          ) {
            setSuccessToastMsg({
              isSuccess: true,
              newReqId: output.data.requestnumber,
            });
          }
          //resetPrevSelections(selectedPatientAwarenessReq);
          resetPrevSelectedFieldValues(selectedPatientAwarenessReq);
          resetFieldErrors(selectedPatientAwarenessReq);
        }
        if (
          caseNumber !== undefined &&
          caseNumber.trim() == "" &&
          (saveAsDraft || unmounted)
        ) {
          setbadgeCount(badgeCount + 1);
        }
      }
    } catch (e: any) {
      console.log("send Req Msg");
    }
    resetSubmitButtons(selectedPatientAwarenessReq);
  };

  function resetPrevSelectedFieldValues(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Patient Awareness Material":
            setTherapyTopicSelection("");
            setOtherTherapyTopic("");
            setpatientAwarenessDesc("");
            setpatientAwarenessInputType("");
            setpatientAwarenessCopiesCount("");
            setpatientAwarenessLang("");
            setpatientAwarenessDeliveryAddress("");
            setpatientAwarenessDesc("");
            setpatientAwarenessEmail("");
            setPaperSize("");

            patientAwarenessEmailRef.current = "";
            patientAwarenessDeliveryAddressRef.current = "";
            patientAwarenessLangRef.current = "";
            patientAwarenessCopiesCountRef.current = "";
            patientAwarenessInputTypeRef.current = "";
            therapyTopicSelectionRef.current = "";
            otherTherapyTopicRef.current = "";
            patientAwarenessDescRef.current = "";

            break;

          case "Patient Screening":
            setscreeningTherapyTopic([]);
            setScreeningSupport([]);
            setScreeningInputCount([]);
            setEducationMaterialNeeded("");
            setpatientEducationType("");
            setPatientScreeningLanguage("");
            setScreeningDeliveryAddress("");
            setScreeningCopiesCount("");
            setScreeningDesc("");
            setScreeningTherapyTopicOther("");
            setScreeningEmail("");

            // screeningEmailRef.current = '';
            // screeningTherapyTopicRef.current = new Array();
            // screeningTherapyTopicOtherRef.current = new Array();
            // // screeningSupportRef.current = new Array();
            // screeningInputCountRef.current = new Array();
            // educationMaterialNeededRef.current = '';
            // patientEducationTypeRef.current = '';
            // patientscreeningLanguageRef.current = '';
            // screeningDeliveryAddressRef.current = '';
            // screeningCopiesCountRef.current = '';
            // screeningDescRef.current = '';
            break;

          case "Patient Counselling":
            setCounsellingDesc("");
            setCounsellingFreqPerMonth("");
            setCounsellingPatientCount("");
            setServiceForCounselling("");

            counsellingDescRef.current = "";
            counsellingFreqPerMonthRef.current = "";
            counsellingPatientCountRef.current = "";
            serviceForCounsellingRef.current = "";
            break;

          case "Patient Support Program":
            setPatientSupportProgramType("");
            setPatientSupportProgramDesc("");

            patientSupportProgramDescRef.current = "";
            patientSupportProgramTypeRef.current = "";
            break;
        }
      })();
    }
  }

  function resetFieldErrors(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Patient Awareness Material":
            setTherapyTopicSelectionErr("UD");
            setOtherTherapyTopicErr("UD");
            setpatientAwarenessDescErr("UD");
            setpapersizeErr("UD");
            setpatientAwarenessInputTypeErr("UD");
            setpatientAwarenessCopiesCountErr("UD");
            setpatientAwarenessLangErr("UD");
            setpatientAwarenessDeliveryAddressErr("UD");
            setpatientAwarenessEmailErr("UD");
            break;

          case "Patient Screening":
            setscreeningTherapyTopicErr("UD");
            setScreeningSupportErr("UD");
            setScreeningInputCountErr("UD");
            setEducationMaterialNeededErr("UD");
            setpatientEducationTypeErr("UD");
            setScreeningLanguageErr("UD");
            setScreeningDeliveryAddressErr("UD");
            setScreeningCopiesCountErr("UD");
            break;

          case "Patient Counselling":
            setServiceForCounsellingErr("UD");
            setCounsellingPatientCountErr("UD");
            setCounsellingFreqPerMonthErr("UD");
            break;

          case "Patient Support Program":
            setPatientSupportProgramTypeErr("UD");
            setPatientSupportDescErr("UD");
            break;
        }
      })();
    }
  }

  function resetSubmitButtons(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Patient Awareness Material":
            setSubmitPatientAwarenessMaterialEnabled(true);
            break;

          case "Patient Screening":
            setSubmitPatientScreeningEnabled(true);
            break;

          case "Patient Counselling":
            setSubmitPatientCounsellingEnabled(true);
            break;

          case "Patient Support Program":
            setSubmitPatientSupportEnabled(true);
            break;
        }
      })();
    }
  }

  const handleScreeningTherapyTopic = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (!screeningTherapyTopic.includes("Other")) {
      setScreeningTherapyTopicOther("");
    }
  };

  const autoPopulatePatientSupportDesc = (selectedProgramType: string) => {
    switch (selectedProgramType) {
      case "Aarambh":
        setPatientSupportProgramDesc(
          "Aarambh is an unique diabetes management Patient support program, focusing on adherence to therapy along with lifestyle modifications, thus helping Type 2 Diabetes Mellitus patients to achieve their A1c goals."
        );
        break;

      case "IDEJ":
        setPatientSupportProgramDesc(
          "Longest Running Indian Diabetes Educator Journal.To Empower Diabetes Educators and Physicians Managing Diabetes"
        );
        break;

      case "Beat Diabetes":
        setPatientSupportProgramDesc(
          "Assuring normal life for People with Diabetes in India with Unique Initiatives via HCPs, there by aiming to achieve 90.90.90"
        );
        break;

      case "Spandan":
        setPatientSupportProgramDesc(
          "To provide effective enhancements and value additions to the current scope of work in Spandan Patient support program."
        );
        break;

      case "Ghar Ghar BP Care -  Awareness and Education":
        setPatientSupportProgramDesc(
          "India’s one of the largest patient education initiative, designed to educate patients with hypertension and people who are at the risk of hypertension."
        );
        break;
      default:
        break;
    }
  };

  function displayShippingAddress(params: any) {
    dispatch(shippingAddressAction.setshowShippingAddressPopup(params));
  }

  return (
    <section
      className="root-container container-fluid mt-3 reqCategoryContentContainer"
      style={{
        height:
          selectedPatientAwarenessReq == "Patient Screening" &&
          patientEducationType == "Print"
            ? "30em"
            : "",
      }}
    >
      <section className="userFilledEntity RequestSubtypeDD">
        <FormControl className="requestEntityContainer">
          <InputLabel id="demo-simple-select-required-label">
            Request Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedPatientAwarenessReq}
            onChange={(e) => handleReqTypeChange(e)}
            className="selectReqDD-Container createReqDropdown selectRequestTypeBucket"
            MenuProps={MenuProps}
          >
            <MenuItem value="" disabled>
              Select Request
            </MenuItem>
            {patientAwarenessRequestTypeArray.map((item: any, index: number) =>
              item.lstRequestType.map((item1: any, index1: number) => (
                <MenuItem
                  key={index1}
                  value={item1.requestName}
                  data-issponsored={item1.sponsored}
                >
                  {item1.requestName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </section>
      {(() => {
        switch (selectedPatientAwarenessReq) {
          case "Patient Awareness Material":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={therapyTopicSelectionErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Selection of Topic - Therapy
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={therapyTopicSelection}
                          onChange={(e: any) => {
                            setTherapyTopicSelection(e.target.value);
                          }}
                          className="createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Topic{" "}
                          </MenuItem>
                          <MenuItem value="Hypertention">Hypertention</MenuItem>
                          <MenuItem value="Diabetes">Diabetes</MenuItem>
                          <MenuItem value="ACS">ACS</MenuItem>
                          <MenuItem value="Heart Failure">
                            Heart Failure
                          </MenuItem>
                          <MenuItem value="Dyslipidemia">Dyslipidemia</MenuItem>
                          <MenuItem value="CKD">CKD</MenuItem>
                          <MenuItem value="ANC">ANC</MenuItem>
                          <MenuItem value="PCOS">PCOS</MenuItem>
                          <MenuItem value="Gynaecological Infections">
                            Gynaecological Infections
                          </MenuItem>
                          <MenuItem value="Acne">Acne</MenuItem>
                          <MenuItem value="Diabetes">Diabetes</MenuItem>
                          <MenuItem value="Fungal Infection">
                            Fungal Infection
                          </MenuItem>
                          <MenuItem value="Cosmetology">Cosmetology</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        <FormHelperText>
                          {therapyTopicSelectionErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          therapyTopicSelection == "Other" ? "block" : "none",
                      }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Other Therapy Topic"
                        type="text"
                        autoComplete="off"
                        //required
                        // error={otherTherapyTopicErr === 'Y'}
                        // helperText={otherTherapyTopicErr === 'Y' ? "Please enter a valid value" : ""}
                        value={otherTherapyTopic}
                        onChange={(e: any) => {
                          setOtherTherapyTopic(e.target.value);
                        }}
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={papersizeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Paper Size
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={papersize}
                          onChange={(e: any) => {
                            setPaperSize(e.target.value);
                            resetOnInputTypeChange();
                          }}
                          className="createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Paper Size
                          </MenuItem>
                          <MenuItem value="A3">A3</MenuItem>
                          <MenuItem value="A4">A4</MenuItem>
                          <MenuItem value="A5">A5</MenuItem>
                          <MenuItem value="B4">B4</MenuItem>
                          <MenuItem value="B5">B5</MenuItem>
                          <MenuItem value="Legal">Legal</MenuItem>
                          <MenuItem value="Letter">Letter</MenuItem>
                        </Select>
                        <FormHelperText>
                          {patientAwarenessInputTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        required
                        error={patientAwarenessDescErr === "Y"}
                        helperText={
                          patientAwarenessDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={patientAwarenessDesc}
                        onChange={(e: any) => {
                          setpatientAwarenessDesc(e.target.value);
                        }}
                      />
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientAwarenessInputType == "Digital"
                            ? "block"
                            : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientAwarenessInputType == "Digital" ? true : false
                        }
                        error={patientAwarenessEmailErr === "Y"}
                        helperText={
                          patientAwarenessEmailErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Email"
                        type="text"
                        value={patientAwarenessEmail}
                        onChange={(e: any) => {
                          setpatientAwarenessEmail(e.target.value);
                        }}
                      />
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientAwarenessInputType == "Print"
                            ? "block"
                            : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientAwarenessInputType == "Print" ? true : false
                        }
                        error={patientAwarenessDeliveryAddressErr === "Y"}
                        helperText={
                          patientAwarenessDeliveryAddressErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Delivery Address"
                        type="text"
                        value={patientAwarenessDeliveryAddress}
                        // onChange={(e: any) => {
                        //   setpatientAwarenessDeliveryAddress(e.target.value);
                        // }}
                        onClick={() => displayShippingAddress(true)}
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          patientAwarenessInputTypeErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Input Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={patientAwarenessInputType}
                          onChange={(e: any) => {
                            setpatientAwarenessInputType(e.target.value);
                            resetOnInputTypeChange();
                          }}
                          className="createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Input Type
                          </MenuItem>
                          <MenuItem value="Digital">Digital</MenuItem>
                          <MenuItem value="Print">Print</MenuItem>
                        </Select>
                        <FormHelperText>
                          {patientAwarenessInputTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientAwarenessInputType == "Print"
                            ? "block"
                            : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientAwarenessInputType == "Print" ? true : false
                        }
                        autoComplete="off"
                        error={patientAwarenessCopiesCountErr === "Y"}
                        helperText={
                          patientAwarenessCopiesCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Number Of Copies"
                        type="number"
                        value={patientAwarenessCopiesCount}
                        onChange={(e: any) => {
                          setpatientAwarenessCopiesCount(e.target.value);
                        }}
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={patientAwarenessLangErr === "Y" ? true : false}
                      >
                        {/* <div className="reqFormHeader ">Language<span className="mandatoryTab">*</span></div> */}
                        <InputLabel id="demo-simple-select-required-label">
                          Language
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={patientAwarenessLang}
                          onChange={(e: any) => {
                            setpatientAwarenessLang(e.target.value);
                          }}
                          className="createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Your Language
                          </MenuItem>
                          {languages.map((itemL: string, indexL: number) => (
                            <MenuItem key={itemL} value={itemL}>
                              {itemL}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText>
                          {patientAwarenessLangErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setPatientAwarenessReqClicked(true);
                    }}
                    disabled={
                      submitPatientAwarenessMaterialEnabled ? false : true
                    }
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitPatientAwarenessRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitPatientAwarenessRequest(true);
                    }}
                    disabled={
                      submitPatientAwarenessMaterialEnabled ? false : true
                    }
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Patient Screening":
            return (
              <>
                <div
                  className={`row selectedReqFieldContainer d-flex justify-content-center text-center  ${
                    screeningTherapyTopic.includes("Other")
                      ? " otherScreeningTopic-enabled"
                      : ""
                  }`}
                >
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={screeningTherapyTopicErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Selection for Topic (Therapy)
                        </InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={screeningTherapyTopic}
                          onChange={(e: any) => {
                            setscreeningTherapyTopic(e.target.value);
                            handleScreeningTherapyTopic(e.target.value);
                            // setscreeningTherapyTopicErr((e.target.value).length == 0 ? 'Y' : 'N');
                          }}
                          className="audienceTypeDD createReqDropdown "
                          renderValue={(selected) => {
                            if ((selected as string[]).length === 0) {
                              return <>Select Topic for Therapy</>;
                            }
                            return (selected as string[]).join(",");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem disabled value="">
                            Select Topic Therapy
                          </MenuItem>
                          {therapyTopic.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                className="hcpCheckbox"
                                checked={
                                  screeningTherapyTopic.indexOf(name) > -1
                                }
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {screeningTherapyTopicErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display: screeningTherapyTopic.includes("Other")
                          ? "block"
                          : "none",
                      }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Other Therapy Topic"
                        type="text"
                        autoComplete="off"
                        //required
                        // error={otherTherapyTopicErr === 'Y'}
                        // helperText={otherTherapyTopicErr === 'Y' ? "Please enter a valid value" : ""}
                        value={screeningTherapyTopicOther}
                        onChange={(e: any) => {
                          setScreeningTherapyTopicOther(e.target.value);
                        }}
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={screeningSupportErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Support for Screening
                        </InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={screeningSupport}
                          onChange={(e: any) => {
                            setScreeningSupport(e.target.value);
                            // setScreeningSupportErr((e.target.value).length == 0 ? 'Y' : 'N');
                          }}
                          className="screeningSupport createReqDropdown"
                          renderValue={(selected) => {
                            if ((selected as string[]).length === 0) {
                              return <>Support for Screening</>;
                            }
                            return (selected as string[]).join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem disabled value="">
                            <>Support for Screening</>
                          </MenuItem>
                          {screeningMachine.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              className="screeningSupport"
                            >
                              <Checkbox
                                className="hcpCheckbox"
                                checked={screeningSupport.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {screeningSupportErr === "Y"
                            ? screeningInputCount.length !==
                              screeningSupport.length
                              ? "Note:Support screening and input count selection should be equal"
                              : "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={screeningInputCountErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Number Of Inputs
                        </InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={screeningInputCount}
                          onChange={(e: any) => {
                            setScreeningInputCount(e.target.value);
                            // setScreeningInputCountErr((e.target.value).length == 0 ? 'Y' : 'N');
                          }}
                          className="selectReqDD-Container createReqDropdown"
                          renderValue={(selected) => {
                            if ((selected as string[]).length === 0) {
                              return <>Select Quantity</>;
                            }
                            return (selected as string[]).join(", ");
                          }}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Quantity
                          </MenuItem>
                          {screeningMachineInputCount.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              className="screeningInputCount"
                            >
                              <Checkbox
                                className="hcpCheckbox"
                                checked={screeningInputCount.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {screeningInputCountErr === "Y"
                            ? screeningInputCount.length !==
                              screeningSupport.length
                              ? "Note:Support screening and input count selection should be equal"
                              : "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          educationMaterialNeededErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Patient Education Material Needed
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={educationMaterialNeeded}
                          onChange={(e: any) => {
                            setEducationMaterialNeeded(e.target.value);
                            setEducationMaterialNeededErr(
                              e.target.value.length == 0 ? "Y" : "N"
                            );
                          }}
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Your Choice
                          </MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        <FormHelperText>
                          {educationMaterialNeededErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>

                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={patientEducationTypeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Type of Patient Education
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={patientEducationType}
                          onChange={(e: any) => {
                            setpatientEducationType(e.target.value);
                            setpatientEducationTypeErr(
                              e.target.value.length == 0 ? "Y" : "N"
                            );
                            resetOnPatientEducationTypeChange();
                          }}
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Your Choice
                          </MenuItem>
                          <MenuItem value="Digital Version">
                            Digital Version
                          </MenuItem>
                          <MenuItem value="Print">Print</MenuItem>
                        </Select>
                        <FormHelperText>
                          {patientEducationTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={screeningLanguageErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Language
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={patientscreeningLanguage}
                          onChange={(e: any) => {
                            setPatientScreeningLanguage(e.target.value);
                            // setScreeningLanguageErr((e.target.value).length == 0 ? 'Y' : 'N');
                          }}
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Your Language
                          </MenuItem>
                          {languages.map((itemL: string, indexL: number) => (
                            <MenuItem key={itemL} value={itemL}>
                              {itemL}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {screeningLanguageErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientEducationType == "Digital Version"
                            ? "block"
                            : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientEducationType == "Digital Version"
                            ? true
                            : false
                        }
                        error={screeningEmailErr === "Y"}
                        helperText={
                          screeningEmailErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Email"
                        type="text"
                        value={screeningEmail}
                        onChange={(e: any) => {
                          setScreeningEmail(e.target.value);
                          // setScreeningEmailErr(validateEmail(e.target.value) ? 'N' : 'Y');
                        }}
                      />
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientEducationType == "Print" ? "block" : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientEducationType == "Print" ? true : false
                        }
                        error={screeningDeliveryAddressErr === "Y"}
                        helperText={
                          screeningDeliveryAddressErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Delivery Address"
                        type="text"
                        value={screeningDeliveryAddress}
                        // onChange={(e: any) => {
                        //   setScreeningDeliveryAddress(e.target.value);
                        // }}
                        onClick={() => displayShippingAddress(true)}
                      />
                    </section>

                    <section
                      className="userFilledEntity mt-2"
                      style={{
                        display:
                          patientEducationType == "Print" ? "block" : "none",
                      }}
                    >
                      <TextField
                        required={
                          patientEducationType == "Print" ? true : false
                        }
                        autoComplete="off"
                        error={screeningCopiesCountErr === "Y"}
                        helperText={
                          screeningCopiesCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        id="standard-basic"
                        label="Number Of Copies"
                        type="number"
                        value={screeningCopiesCount}
                        onChange={(e: any) => {
                          setScreeningCopiesCount(e.target.value);
                        }}
                      />
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={screeningDescErr === "Y"}
                        helperText={
                          screeningDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={screeningDesc}
                        onChange={(e: any) => setScreeningDesc(e.target.value)}
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setScreeningRequestClicked(true);
                    }}
                    disabled={submitPatientScreeningEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitPatientScreeningRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitPatientScreeningRequest(true);
                    }}
                    disabled={submitPatientScreeningEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Patient Counselling":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={serviceForCounsellingErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Selection of Service
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={serviceForCounselling}
                          onChange={(e: any) =>
                            setServiceForCounselling(e.target.value)
                          }
                          className="serviceTypeDD-Container createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Your Service
                          </MenuItem>
                          <MenuItem value="Dietician">Dietician</MenuItem>
                          <MenuItem value="Cardiac Rehabilitation">
                            Cardiac Rehabilitation
                          </MenuItem>
                          <MenuItem value="Diabetes Educator">
                            Diabetes Educator
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {serviceForCounsellingErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          counsellingPatientCountErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Number of Patients Per Month
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={counsellingPatientCount}
                          onChange={(e: any) =>
                            setCounsellingPatientCount(e.target.value)
                          }
                          className="meetingTypeReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Patient Count
                          </MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="15">15</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="25">25</MenuItem>
                          <MenuItem value="30">30</MenuItem>
                          <MenuItem value="35">35</MenuItem>
                          <MenuItem value="40">40</MenuItem>
                          <MenuItem value="45">45</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                        </Select>
                        <FormHelperText>
                          {counsellingPatientCountErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      {/* <TextField
                                            label="Mode" 
                                            className="underline"
                                             type="text" 
                                             value="Virtual" 
                                             disabled /> */}

                      <FormControl className="requestEntityContainer" required>
                        <InputLabel id="demo-simple-select-required-label">
                          Mode
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={counsellingMode}
                          // onChange={(e: any) => setCounsellingPatientCount(e.target.value)}
                          className="meetingTypeReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Patient Count
                          </MenuItem>
                          <MenuItem value="Virtual">Virtual</MenuItem>
                        </Select>
                      </FormControl>
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          counsellingFreqPerMonthErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Frequency/Month
                        </InputLabel>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={counsellingFreqPerMonth}
                          onChange={(e: any) =>
                            setCounsellingFreqPerMonth(e.target.value)
                          }
                          className="meetingTypeReqDD-Container createReqDropdown"
                        >
                          <MenuItem value="" disabled>
                            Select Frequency per Month
                          </MenuItem>
                          <MenuItem value="Once a week">
                            Once a week for 3 hours
                          </MenuItem>
                          <MenuItem value="Twice a week">
                            Twice a week for 3 hours
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {counsellingFreqPerMonthErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <TextField
                        label="Description"
                        type="text"
                        value={counsellingDesc}
                        onChange={(e: any) =>
                          setCounsellingDesc(e.target.value)
                        }
                        error={counsellingDescErr === "Y"}
                        helperText={
                          counsellingDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setPatientCounsellingReqClicked(true);
                    }}
                    disabled={submitPatientCounsellingEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitPatientCounsellingRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitPatientCounsellingRequest(true);
                    }}
                    disabled={submitPatientCounsellingEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );

          case "Patient Support Program":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          patientSupportProgramTypeErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Program Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={patientSupportProgramType}
                          onChange={(e: any) => {
                            setPatientSupportProgramType(e.target.value);
                            autoPopulatePatientSupportDesc(e.target.value);
                          }}
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Select Program Type
                          </MenuItem>
                          {/* <MenuItem value="Spandan">Spandan</MenuItem> */}
                          <MenuItem value="Aarambh">Aarambh</MenuItem>
                          <MenuItem value="Beat Diabetes">
                            Beat Diabetes
                          </MenuItem>
                          {/* <MenuItem value="IDEJ">IDEJ</MenuItem> */}
                          {/* <MenuItem value="BG-Monitor">BG-Monitor</MenuItem>
                                                    <MenuItem value="Discount Coupon for BP">Discount Coupon for BP</MenuItem> */}
                          <MenuItem value="Ghar Ghar BP Care -  Awareness and Education">
                            Ghar Ghar BP Care - Awareness and Education
                          </MenuItem>
                          {/* <MenuItem value="Ghar Ghar BP Care -  Discount Coupon">Ghar Ghar BP Care -  Discount Coupon</MenuItem> */}
                        </Select>
                        <FormHelperText>
                          {patientSupportProgramTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity mt-2">
                      <TextField
                        error={patientSupportDescErr === "Y"}
                        helperText={
                          patientSupportDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Description"
                        type="text"
                        title={patientSupportProgramDesc}
                        value={patientSupportProgramDesc}
                        multiline
                        rows={1}
                        rowsMax={5}
                        disabled
                        // onChange={(e: any) => setPatientSupportProgramDesc(e.target.value)}
                      />
                    </section>
                  </div>
                </div>
                <div
                  className={`${
                    patientSupportProgramType == "Aarambh"
                      ? "arambhProgramType "
                      : " "
                  } text-center reqActionBtnContainer`}
                >
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setPatientSupportReqClicked(true);
                    }}
                    disabled={submitPatientSupportEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitPatientSupportRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitPatientSupportRequest(true);
                    }}
                    disabled={submitPatientSupportEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
        }
      })()}

      <Dialog
        open={patientSupportCreatedPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="MobileHomePage-popover"
      >
        <section className="sponsoredPopupDetails sponsoredPopup2">
          <h5>Thank you for your interest</h5>
          <div>
            <p className="sampleTextMsg">
              {"Our executive will get back to you shortly"}
            </p>
          </div>
        </section>
        <DialogActions className="btnContainer_sponsored">
          <Button
            onClick={() => setpatientSupportCreatedPopup(false)}
            className="confirmBtn"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default PatientAwarenessRequest;
