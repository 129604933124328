import { RequestParams } from "../Class-Interfaces/httpRequestParams";
const axios = require("axios");

export const makePostRequest = (url: string, payload?: RequestParams) => {
  // console.log(payload)
  if (payload) {
    return axios.post(url, payload).catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  } else {
    return axios.post(url);
  }
};
export const makeGetRequest = (url: string) => {
  return axios.get(url);
};
export const makePutRequest = (url: string, payload: RequestParams) => {
  return axios.put(url, payload);
};
export const makeDeleteRequest = (url: string, payload?: RequestParams) => {
  return axios.delete(url, payload);
};
export const makePatchRequest = (url: string, payload?: RequestParams) => {
  return axios.patch(url, payload);
};
