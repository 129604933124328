import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./CreateNewRequest.scss";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { requestActions } from "../../../store/request";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import { APiUrls } from "../../../HcpHelper";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
const PracticeEnhancementRequest = () => {
  const dispatch = useDispatch();
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const serviceName = "Practice Enhancement";
  const [listOfService, setListOfService] = useState([]);

  const [
    submitVirtualConsultationEnabled,
    setSubmitVirtualConsultationEnabled,
  ] = useState(true);
  const [submitSkillDevEnabled, setSubmitSkillDevEnabled] = useState(true);
  const [submitSocialMediaEnabled, setSubmitSocialMediaEnabled] =
    useState(true);

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  const editDraft = useSelector((state: RootState) => state.request.editDraft);
  const caseNumber = useSelector(
    (state: RootState) => state.request.caseNumber
  );

  const badgeCount = useSelector(
    (state: RootState) => state.request.badgeCount
  );
  const setbadgeCount = (params: any) =>
    dispatch(requestActions.setbadgeCount(params));

  const names = [
    "Future ways of Improving clinical practice - Acquiring & Retaining Patients",
    "Diabetologist",
    "Endocrinologist",
    "Gynecologists",
    "PG",
    "Patients",
    "Dermatologists",
    "Chest Physician",
    "Orthopedition",
    "Family Physicians",
    "Nephrologists",
    "Neurologists",
    "Consulting Physicians",
    "Other",
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const setErrorToastMsg = (params: any) => {
    dispatch(requestActions.setErrorToastMsg(params));
  };
  const setSuccessToastMsg = (params: any) => {
    dispatch(requestActions.setSuccessToastMsg(params));
  };

  const handleReqTypeChange = (clickedElement: any) => {
    dispatch(requestActions.seteditDraft(false));
    let isSponsoredService =
      clickedElement.currentTarget.getAttribute("data-issponsored");
    if (isSponsoredService == "true") {
      dispatch(
        requestActions.setSponsoredPopup1({
          showPopup: true,
          fromHome: false,
          clickedSponsoredReq: clickedElement.target.value,
          clickedSponsoredService: serviceName,
        })
      );
      setselectedPracticeEReq("");
    } else {
      setselectedPracticeEReq(clickedElement.target.value);
      resetPrevSelectedFieldValues(clickedElement.target.value);
      resetFieldErrors(clickedElement.target.value);
    }
  };

  const selectedPracticeEReqRef = useRef("");
  const selectedPracticeEReq = useSelector(
    (state: RootState) => state.request.selectedReq
  );
  const setselectedPracticeEReq = (params: any) => {
    selectedPracticeEReqRef.current = params;
    dispatch(requestActions.setSelectedReq(params));
  };

  useEffect(() => {
    fetchListOfAccessbileServices();
    resetPrevSelectedFieldValues(selectedPracticeEReq);
    resetFieldErrors(selectedPracticeEReq);
    resetSubmitButtons(selectedPracticeEReq);
    fetchCancelOptions();
    return () => {
      debouncedChangeHandler();
    };
  }, []);

  function debounce(callback: any, delay: any) {
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }
  const debouncedChangeHandler = () => {
    let text = selectedPracticeEReqRef.current;
    debounce(() => saveAsDraftOnTabChange(text), 1000);
  };

  const saveAsDraftOnTabChange = (selectedRequest: any) => {
    switch (selectedRequest) {
      case "Virtual Consultation Platform":
        submitVirtualConsultationRequest(true, true);
        break;
      case "Social Media Management":
        submitSocialMediaRequest(true, true);
        break;
      case "Skill Development Programme":
        submitSkillDevelopmentRequest(true, true);
        break;
    }
  };

  const practiceEnhancementRequestArray = listOfService.filter(function (
    item: any
  ) {
    return item.serviceName == serviceName;
  });

  practiceEnhancementRequestArray.map((item1: any, index1: number) => {
    const sortedlist = item1.lstRequestType.sort((a: any, b: any) =>
      a.requestName < b.requestName ? -1 : a.requestName > b.requestName ? 1 : 0
    );
    item1.lstRequestType = sortedlist;
  });
  const fetchListOfAccessbileServices = async () => {
    const serviceUrl1 = `${process.env.REACT_APP_FETCH_SERVICE_ACCESSIBLE}`;
    const srviceAccUrl1 = baseServiceUrl + serviceUrl1;
    let params: any = {
      speciality: userInfo ? userInfo.speciality : "",
      category: userInfo ? userInfo.category : "",
    };
    let serviceAccessibleData = await makePostRequest(
      srviceAccUrl1,
      new RequestParams(params)
    );
    setListOfService(serviceAccessibleData.data.lstServiceType);
  };

  useEffect(() => {}, [selectedPracticeEReq]);
  const vcDescRef = useRef("");
  const virtualConsultationDesc = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.VirtualConsultation
        .virtualConsultationDesc
  );
  const setVirtualConsultationDesc = (params: any) => {
    vcDescRef.current = params;
    dispatch(requestActions.setVirtualConsultationDesc(params));
  };
  const [virtualConsultationDescErr, setVirtualConsultationDescErr] =
    useState("UD");
  const [
    virtualConsultationRequestSubmitClicked,
    setVirtualConsultationRequestSubmitClicked,
  ] = useState(false);

  useEffect(() => {
    if (virtualConsultationRequestSubmitClicked) {
      validateVCReq();
    }
  }, [virtualConsultationRequestSubmitClicked]);

  useEffect(() => {
    if (virtualConsultationDescErr === "N") {
      submitVirtualConsultationRequest();
    } else if (virtualConsultationDescErr === "Y") {
      setVirtualConsultationRequestSubmitClicked(false);
    }
  }, [virtualConsultationDescErr]);

  const validateVCReq = () => {
    setVirtualConsultationDescErr(
      virtualConsultationDesc.length == 0 ? "Y" : "N"
    );
  };

  const submitVirtualConsultationRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitVirtualConsultationEnabled(false);
    try {
      //if (virtualConsultationRequestSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPracticeEReqRef.current
          : selectedPracticeEReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        virtualConsultationDesc: unmounted
          ? vcDescRef.current
          : virtualConsultationDesc,
        servicetypevirualcon: "Virtual Consultation With EMR",
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (fromSaveAsDraft && vcDescRef.current.length > 0) {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      } else {
        await sendReqObjectForNewReq(reqObject, unmounted, fromSaveAsDraft);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setVirtualConsultationRequestSubmitClicked(false);
      setSubmitVirtualConsultationEnabled(true);
    }
  };

  const reqOfServiceRef = useRef("");
  const reqOfService = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SocialMediaReq.reqOfService
  );
  const setReqOfService = (params: any) => {
    reqOfServiceRef.current = params;
    dispatch(requestActions.setReqOfService(params));
  };

  const reqOfOtherServiceRef = useRef("");
  const reqOfOtherService = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SocialMediaReq
        .reqOfOtherService
  );
  const setReqOfOtherService = (params: any) => {
    reqOfOtherServiceRef.current = params;
    dispatch(requestActions.setReqOfOtherService(params));
  };

  const socialMediaReqDescRef = useRef("");
  const socialMediaReqDesc = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SocialMediaReq
        .socialMediaReqDesc
  );
  const setSocialMediaReqDesc = (params: any) => {
    socialMediaReqDescRef.current = params;
    dispatch(requestActions.setSocialMediaReqDesc(params));
  };

  const [socialMediaDescErr, setsocialMediaDescErr] = useState("UD");
  const [reqOfServiceErr, setReqOfServiceErr] = useState("UD");
  const [reqOfOtherServiceErr, setReqOfOtherServiceErr] = useState("UD");
  const [socialMediaReqSubmitClicked, setSocialMediaReqSubmitClicked] =
    useState(false);

  useEffect(() => {
    if (socialMediaReqSubmitClicked) validateSocialMediaReq();
  }, [socialMediaReqSubmitClicked]);

  useEffect(() => {
    if (
      reqOfServiceErr === "N" &&
      reqOfOtherServiceErr === "N" &&
      socialMediaDescErr === "N"
    ) {
      submitSocialMediaRequest();
    } else if (reqOfServiceErr === "Y" || reqOfOtherServiceErr === "Y") {
      setSocialMediaReqSubmitClicked(false);
    }
  }, [reqOfServiceErr, reqOfOtherServiceErr, socialMediaDescErr]);

  const validateSocialMediaReq = () => {
    setReqOfServiceErr(reqOfService.length == 0 ? "Y" : "N");
    setsocialMediaDescErr(socialMediaReqDesc.length == 0 ? "Y" : "N");
    reqOfService == "Others"
      ? setReqOfOtherServiceErr(reqOfOtherService.length == 0 ? "Y" : "N")
      : setReqOfOtherServiceErr("N");
  };

  const submitSocialMediaRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitSocialMediaEnabled(false);
    try {
      //if (socialMediaReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: serviceName,
        requesttype: unmounted
          ? selectedPracticeEReqRef.current
          : selectedPracticeEReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        reqOfService: unmounted ? reqOfServiceRef.current : reqOfService,
        reqOfServiceOther: unmounted
          ? reqOfOtherServiceRef.current
          : reqOfOtherService,
        socialMediaReqDesc: unmounted
          ? socialMediaReqDescRef.current
          : socialMediaReqDesc,
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (reqOfServiceRef.current.length > 0 ||
          reqOfOtherServiceRef.current.length > 0 ||
          socialMediaReqDescRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject);
      } else if (socialMediaReqSubmitClicked) {
        await sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSocialMediaReqSubmitClicked(false);
      setSubmitSocialMediaEnabled(true);
    }
  };

  /*************************************************************************/
  const skillDevelopmentProgramtypeRef = useRef("");
  const skillDevelopmentProgramtype = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SkillDevelopmentprogramme
        .programtype
  );
  const setskillDevelopmentProgramtype = (params: any) => {
    skillDevelopmentProgramtypeRef.current = params;
    dispatch(requestActions.setskillDevelopmentProgramType(params));
  };

  const sessionTypeRef: any = useRef([]);
  const sessionType: any = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SkillDevelopmentprogramme
        .sessionType
  );
  const setSessionType = (params: any) => {
    sessionTypeRef.current = params;
    dispatch(requestActions.setSessionType(params));
  };

  const skillDevelopmentDescRef = useRef("");
  const skillDevelopmentDesc = useSelector(
    (state: RootState) =>
      state.request.createPracticeEnhancmentRequest.SkillDevelopmentprogramme
        .skillDevelopmentDesc
  );
  const setskillDevelopmentDesc = (params: any) => {
    skillDevelopmentDescRef.current = params;
    dispatch(requestActions.setskillDevelopmentDesc(params));
  };

  const [skillDevelopmentProgramtypeErr, setSkillDevelopmentProgramtypeErr] =
    useState("UD");
  const [skillDevelopmentDescErr, setSkillDevelopmentDescErr] = useState("UD");
  const [sessionTypeErr, setsessionTypeErr] = useState("UD");
  const [skillDevReqSubmitClicked, setSkillDevslideReqSubmitClicked] =
    useState(false);

  const [sessiontypeList, setsessiontypeList] = useState([]);
  const fetchCancelOptions = () => {
    fetch("sessiontype.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        let res = myJson.sessiontypeOption;
        setsessiontypeList(res[0].sessiontypeList);
      });
  };

  useEffect(() => {
    if (skillDevReqSubmitClicked) {
      validateSkillDevelopmentRequest();
    }
  }, [skillDevReqSubmitClicked]);

  useEffect(() => {
    // skillDevelopmentDescErr === 'N'
    if (
      sessionTypeErr === "N" &&
      skillDevelopmentDescErr == "N" &&
      skillDevelopmentProgramtypeErr == "N"
    ) {
      submitSkillDevelopmentRequest();
    } else if (
      sessionTypeErr === "Y" ||
      skillDevelopmentDescErr == "Y" ||
      skillDevelopmentProgramtypeErr == "Y"
    ) {
      setSkillDevslideReqSubmitClicked(false);
    }
  }, [skillDevelopmentDescErr, sessionTypeErr, skillDevelopmentProgramtypeErr]);

  const validateSkillDevelopmentRequest = () => {
    setSkillDevelopmentDescErr(skillDevelopmentDesc.length == 0 ? "Y" : "N");
    setsessionTypeErr(sessionType.length == 0 ? "Y" : "N");
    setSkillDevelopmentProgramtypeErr(
      skillDevelopmentProgramtype.length == 0 ? "Y" : "N"
    );
  };

  const submitSkillDevelopmentRequest = async (
    fromSaveAsDraft?: boolean,
    unmounted?: boolean
  ) => {
    setSubmitSkillDevEnabled(false);
    try {
      //if (skillDevReqSubmitClicked || fromSaveAsDraft) {
      let reqObject = {
        servicetype: "Practice Enhancement",
        requesttype: unmounted
          ? selectedPracticeEReqRef.current
          : selectedPracticeEReq,
        legacycode: userInfo ? userInfo.legacycode : "",
        skilldevelopmentprogram: unmounted
          ? skillDevelopmentProgramtypeRef.current
          : skillDevelopmentProgramtype,
        description: unmounted ? skillDevelopmentDescRef : skillDevelopmentDesc,
        sessionType: unmounted
          ? sessionTypeRef.current.join(";")
          : sessionType.join(";"),
        status: fromSaveAsDraft ? "draft" : "Submitted",
        caseNumber: editDraft ? caseNumber : "",
      };
      if (
        fromSaveAsDraft &&
        (skillDevelopmentDescRef.current.length > 0 ||
          sessionTypeRef.current.length > 0)
      ) {
        await sendReqObjectForNewReq(reqObject);
      }
      // else if (skillDevReqSubmitClicked) {
      //     sendReqObjectForNewReq(reqObject);
      // }
      else if (!fromSaveAsDraft) {
        await sendReqObjectForNewReq(reqObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSkillDevslideReqSubmitClicked(false);
      setSubmitSkillDevEnabled(true);
    }
  };

  function resetPrevSelectedFieldValues(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Virtual Consultation Platform":
            setVirtualConsultationDesc("");
            vcDescRef.current = "";
            break;

          case "Skill Development Programme":
            setSessionType([]);
            setskillDevelopmentDesc("");
            setskillDevelopmentProgramtype("");

            sessionTypeRef.current = new Array();
            skillDevelopmentDescRef.current = "";
            skillDevelopmentProgramtypeRef.current = "";
            break;

          case "Social Media Management":
            setReqOfService("");
            setReqOfOtherService("");
            setSocialMediaReqDesc("");

            reqOfServiceRef.current = "";
            reqOfOtherServiceRef.current = "";
            socialMediaReqDescRef.current = "";
            break;
        }
      })();
    }
  }

  function resetFieldErrors(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Virtual Consultation Platform":
            setVirtualConsultationDescErr("UD");
            break;

          case "Skill Development Programme":
            setsessionTypeErr("UD");
            setSkillDevelopmentDescErr("UD");
            break;

          case "Social Media Management":
            setReqOfServiceErr("UD");
            setReqOfOtherServiceErr("UD");
            break;
        }
      })();
    }
  }

  function resetSubmitButtons(selectedReq: string) {
    {
      (() => {
        switch (selectedReq) {
          case "Virtual Consultation Platform":
            setSubmitVirtualConsultationEnabled(true);
            break;

          case "Skill Development Programme":
            setSubmitSkillDevEnabled(true);
            break;

          case "Social Media Management":
            setSubmitSocialMediaEnabled(true);
            break;
        }
      })();
    }
  }

  const sendReqObjectForNewReq = async (
    reqObject: any,
    unmounted?: boolean,
    saveAsDraft?: boolean
  ) => {
    if (!editDraft) {
      delete (reqObject as any).caseNumber;
    }
    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      // console.log(output.data);
      dispatch(requestActions.seteditDraft(false));
      if (output.data.status == "failure") {
        if (!unmounted) {
          setErrorToastMsg({ isError: true, errMsg: output.data.errormessage });
          resetFieldErrors(selectedPracticeEReq);
        }
      } else {
        if (!unmounted) {
          setSuccessToastMsg({
            isSuccess: true,
            newReqId: output.data.requestnumber,
          });
          resetPrevSelectedFieldValues(selectedPracticeEReq);
          resetFieldErrors(selectedPracticeEReq);
        }
        if (
          caseNumber !== undefined &&
          caseNumber.trim() == "" &&
          (saveAsDraft || unmounted)
        ) {
          setbadgeCount(badgeCount + 1);
        }
      }
    } catch (e) {
      console.log("send Req Msg");
    }
    resetSubmitButtons(selectedPracticeEReq);
  };

  return (
    <section className="root-container container-fluid mt-3 reqCategoryContentContainer">
      <section className="userFilledEntity RequestSubtypeDD">
        <FormControl className="requestEntityContainer">
          <InputLabel id="demo-simple-select-required-label">
            Request Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedPracticeEReq}
            onChange={(e: any) => {
              handleReqTypeChange(e);
            }}
            className="selectReqDD-Container createReqDropdown selectRequestTypeBucket"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value="" disabled>
              Select Request
            </MenuItem>
            {practiceEnhancementRequestArray.map((item: any, index: number) =>
              item.lstRequestType.map((item1: any, index1: number) => (
                <MenuItem
                  key={index1}
                  value={item1.requestName}
                  data-issponsored={item1.sponsored}
                >
                  {item1.requestName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </section>

      {(() => {
        switch (selectedPracticeEReq) {
          case "Virtual Consultation Platform":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity ">
                      <TextField
                        // error={virtualConsultationDescErr === 'Y'}
                        // helperText={virtualConsultationDescErr === 'Y' ? "Please enter a valid value" : ""}
                        autoComplete="off"
                        id="standard-basic"
                        label="Service Type"
                        required
                        type="text"
                        value={"Virtual Consultation With EMR"}
                      />
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity ">
                      <TextField
                        error={virtualConsultationDescErr === "Y"}
                        required
                        helperText={
                          virtualConsultationDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={virtualConsultationDesc}
                        onChange={(e: any) =>
                          setVirtualConsultationDesc(e.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setVirtualConsultationRequestSubmitClicked(true);
                    }}
                    disabled={submitVirtualConsultationEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitVirtualConsultationRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitVirtualConsultationRequest(true);
                    }}
                    disabled={submitVirtualConsultationEnabled ? false : true}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
          case "Skill Development Programme":
            return (
              <>
                <div className="row d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={
                          skillDevelopmentProgramtypeErr === "Y" ? true : false
                        }
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Program Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={skillDevelopmentProgramtype}
                          onChange={(e: any) => {
                            setskillDevelopmentProgramtype(e.target.value);
                            setskillDevelopmentDesc(
                              "Future Plus program: Designed with future ready practice ideas to enhance clinical practice"
                            );
                          }}
                          className="selectReqDD-Container createReqDropdown"
                        >
                          <MenuItem value="" disabled>
                            Select Program Type
                          </MenuItem>
                          <MenuItem value="Future Plus">Future Plus</MenuItem>
                        </Select>

                        <FormHelperText>
                          {skillDevelopmentProgramtypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>

                    <section className="userFilledEntity mt-2">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={sessionTypeErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Session Type
                        </InputLabel>
                        <Select
                          multiple
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          title={sessionType}
                          value={sessionType}
                          onChange={(e: any) =>
                            setSessionType(e.target.value as string[])
                          }
                          className="skilldevsessiontype createReqDropdown"
                          renderValue={(selected) => {
                            if ((selected as string[]).length === 0) {
                              return <>Select Session</>;
                            }
                            return (selected as string[]).join(",");
                          }}
                          MenuProps={MenuProps}
                        >
                          <MenuItem disabled value="">
                            Select Session
                          </MenuItem>
                          {sessiontypeList.map((name: any) => (
                            <MenuItem
                              key={name}
                              title={name}
                              value={name}
                              className="sessiontype"
                              disabled={
                                sessionType.length > 2 &&
                                sessionType.indexOf(`${name}`) == -1
                              }
                            >
                              <Checkbox
                                checked={sessionType.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {sessionTypeErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity ">
                      <TextField
                        disabled
                        multiline
                        rows={1}
                        rowsMax={5}
                        required
                        error={skillDevelopmentDescErr === "Y"}
                        helperText={
                          skillDevelopmentDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={skillDevelopmentDesc}
                        onChange={(e: any) =>
                          setskillDevelopmentDesc(e.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setSkillDevslideReqSubmitClicked(true);
                    }}
                    disabled={submitSkillDevEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  {/* <div className="saveAsDraft mt-2" onClick={() => submitSkillDevelopmentRequest(true)}>Save as Draft</div> */}
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitSkillDevelopmentRequest(true);
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
          case "Social Media Management":
            return (
              <>
                <div className="row selectedReqFieldContainer d-flex justify-content-center text-center">
                  <div className="leftCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity">
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={reqOfServiceErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Requirement of Service
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={reqOfService}
                          onChange={(e: any) => setReqOfService(e.target.value)}
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Requirement of Service
                          </MenuItem>
                          <MenuItem value="Facebook">Facebook</MenuItem>
                          <MenuItem value="Youtube">Youtube</MenuItem>
                          <MenuItem value="Twitter">Twitter</MenuItem>
                          <MenuItem value="Instagram">Instagram</MenuItem>
                          <MenuItem value="SEO">SEO</MenuItem>
                          <MenuItem value="Short Patient Education Video">
                            Short Patient Education Video
                          </MenuItem>
                          <MenuItem value="Promotion Of Event via SMS/ Whatsapp/ Facebook">
                            Promotion Of Event via SMS/ Whatsapp/ Facebook
                          </MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                        <FormHelperText>
                          {reqOfServiceErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                    <section
                      className="userFilledEntity"
                      style={{
                        display: reqOfService == "Others" ? "block" : "none",
                      }}
                    >
                      <FormControl
                        className="requestEntityContainer"
                        required
                        error={reqOfOtherServiceErr === "Y" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-required-label">
                          Requirement of Service (Others)
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={reqOfOtherService}
                          onChange={(e: any) =>
                            setReqOfOtherService(e.target.value)
                          }
                          className="selectReqDD-Container createReqDropdown"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Requirement of Service (Others)
                          </MenuItem>
                          <MenuItem value="ORM (Online Reputation Management)">
                            ORM (Online Reputation Management)
                          </MenuItem>
                          <MenuItem value="Wikipedia page">
                            Wikipedia page
                          </MenuItem>
                          <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                          <MenuItem value="Website Creation">
                            Website Creation
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {reqOfOtherServiceErr === "Y"
                            ? "Please enter a valid value"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </section>
                  </div>
                  <div className="rightCol col=lg-6 col-md-6 col-12 form-fieldsContainer">
                    <section className="userFilledEntity ">
                      <TextField
                        error={socialMediaDescErr === "Y"}
                        helperText={
                          socialMediaDescErr === "Y"
                            ? "Please enter a valid value"
                            : ""
                        }
                        autoComplete="off"
                        required
                        id="standard-basic"
                        label="Description"
                        type="text"
                        value={socialMediaReqDesc}
                        onChange={(e: any) =>
                          setSocialMediaReqDesc(e.target.value)
                        }
                      />
                    </section>
                  </div>
                </div>
                <div className="text-center reqActionBtnContainer">
                  <Button
                    variant="contained"
                    className="submitRequestBtn"
                    onClick={() => {
                      setSocialMediaReqSubmitClicked(true);
                    }}
                    disabled={submitSocialMediaEnabled ? false : true}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    className="saveAsDraft"
                    onClick={() => {
                      submitSocialMediaRequest(true);
                    }}
                  >
                    Save as Draft
                  </Button>
                </div>
              </>
            );
        }
      })()}
    </section>
  );
};

export default PracticeEnhancementRequest;
