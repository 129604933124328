import React,{useState,useEffect} from 'react';
import './MobileTopNavigationComponent.scss';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DehazeOutlinedIcon from '@material-ui/icons/DehazeOutlined';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { useHistory,useLocation } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import usvLogo from '../../assets/images/USV_Blue_Logo.svg';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import MobileToggle from '../MobileToggleComponent/MobileToggleComponent';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import MobileToggleIcon from '../../assets/images/MobileAssets/mobileToggleIcon.svg';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 250,
  },
});


const MobileTopNavigation =() =>{
  const classes = useStyles();
  const [showLogo,setShowLogo] = useState(true);
  const history = useHistory();
  let location = useLocation();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    let getpathName = location.pathname;
    handleLogoVisibility(getpathName);
  }, [location]);

  const handleLogoVisibility=(pathName:any)=>{
      pathName === '/Notification' ? setShowLogo(false) :setShowLogo(true);
  }

  const toggleDrawer = (anchor:any, open:any) => (event:any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleNotification =()=>{
    const redirectNotificationURL = '/Notification'
    history.push(redirectNotificationURL)
  }

  return (
    <div>
        <React.Fragment key={'left'}>
         <div className='row mobileNavigation'>
             {showLogo ?
             <>
             <div className='col-1 toggleIconWrapper'>
                <Button onClick={toggleDrawer('left', true)}><img src={MobileToggleIcon} alt='MobileToggleIcon' /></Button>
                    <Drawer anchor={'left'} open={state["left"]}  onClick={toggleDrawer('left', false)} onKeyDown={toggleDrawer('left', false)} onClose={toggleDrawer('left', false)}>
                        <MobileToggle/>
                    </Drawer>
             </div>
             <div className='col-9'>
                    <div className="usvIconDivMobile">
                        <Link to="/home"><img src={usvLogo} alt="usvLogo"></img></Link>
                    </div>
             </div>
             </>
             :
             <>
                <div className='col-1 back-arrow' onClick={history.goBack}>
                  <ArrowBackIosOutlinedIcon />
                </div>
                <div className='col-9'>
                      <div className="header-Title">
                          <h1>Notification</h1>
                      </div>
                </div>
            </>
             }
             {/* <div className='col-2 notification-icon-mobile' onClick={handleNotification}>
                    <Badge color="secondary" variant="dot">
                             <NotificationsNoneIcon fontSize='large' />
                    </Badge>
             </div> */}
         </div>
          
        </React.Fragment>
    </div>
  );
}

export default MobileTopNavigation;
