import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import "./ViewRequest.scss";
import "./ViewRequestMobile.scss";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import FilterListSharpIcon from "@material-ui/icons/FilterListSharp";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { requestActions } from "../../store/request";
import "bootstrap/dist/css/bootstrap.min.css";
import goBackArrow from "../../assets/images/viewReqBackIcon.svg";
import feedback1 from "../../assets/images/FeedbackPopUpImg2.svg";
import feedback2 from "../../assets/images/feedbackPopUpImg1.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  Snackbar,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  IconButton,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Popover,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import { handleDownloadType } from "../../utils/globalUtils";
import axios from "axios";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { readAllData } from "../../indexdb_utils";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router-dom";

const ViewRequest = () => {
  const [showGridView, setShowGridView] = useState(true);
  const [showListView, setShowListView] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [starvalueIndex, setStarValueIndex] = useState();
  const history = createBrowserHistory();
  const position = "request";
  const [userfeedback, setUserfeedback] = useState({
    servicerequestno: "",
    lstResponses: [
      {
        Question: "",
        Response: "",
        OrderNo: "",
      },
    ],
  });
  const starRating = ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"];

  const [showFilterOption, setshowFilterOption] = useState(false);
  const [categoryFilter, setcategoryFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [isSubmitFeedbackDisabled, setisSubmitFeedbackDisabled] =
    useState(true);

  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);
  const dispatch = useDispatch();
  const cancelReqRef: any = useRef(null);

  const [showLoader, setShowLoader] = useState(false);
  const [filteredArrayList, setFilteredArrayList] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonOther, setCancelReasonOther] = useState("");
  const [cancelReasonOptionAll, setCancelReasonOptionAll] = useState([]);
  const [cancelReasonFiltered, setCancelReasonFiltered] = useState([]);
  const [openfeedpack1Popup, setopenfeedpack1Popup] = useState(false);
  const [openfeedpack2Popup, setopenfeedpack2Popup] = useState(false);
  const [feedBackOptionFiltered, setFeedBackOptionFiltered] = useState<any[]>(
    []
  );
  const [isSubmitFeedbackErr, setisSubmitFeedbackErr] = useState(false);
  const [reqMappingSchemaList, setReqMappingSchemaList] = useState<any[]>([]);
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_FETCH_REQUEST}`;
  const fetchServiceApi = baseServiceUrl + serviceUrl;
  const steps = ["Submitted", "In Progress", "Completed"];
  const [viewSelectedReqVal, setviewSelectedReqVal] = useState([]);
  const [tentativedate, settentativeDate] = useState("");
  const [attachment, setAttachment] = useState([]);
  const myRequestList = useSelector(
    (state: RootState) => state.request.userReq.lstServiceRequest
  );
  const reqList =
    myRequestList &&
    myRequestList.filter(function (item: any) {
      return item.requeststatus !== "Draft";
    });
  // console.log('12345',reqList);
  const categoryFilterOption = getAllFilterCategoryOption();

  const statusFilterOption = getAllFilterStatusOption();

  function getAllFilterCategoryOption() {
    let resultArr = Array<string>();
    if (reqList) {
      reqList.map((item, index: number) => {
        resultArr.push(item.servicetype);
      });
    }
    return Array.from(new Set(resultArr));
  }

  function getAllFilterStatusOption() {
    let resultArr = Array<string>();
    if (reqList) {
      reqList.map((item, index: number) => {
        if (item.requeststatus == "Cancel") {
          resultArr.push("Cancelled");
        } else resultArr.push(item.requeststatus);
      });
    }
    return Array.from(new Set(resultArr));
  }

  const filteredReq = reqList
    ? reqList.filter(function (item: any) {
        return item.requestnumber == filteredArrayList;
      })
    : null;

  const filteredReq1 = reqList
    ? reqList.filter(function (item: any) {
        if (categoryFilter.length > 0 && statusFilter.length > 0) {
          return (
            item.servicetype == categoryFilter &&
            item.requeststatus == statusFilter
          );
        } else if (statusFilter.length > 0) {
          return item.requeststatus == statusFilter;
        } else {
          return item.servicetype == categoryFilter;
        }
      })
    : null;

  const sortedReqList = getSortedList();

  function getSortedList() {
    if (categoryFilter || statusFilter) {
      return filteredReq1
        ? filteredReq1
            .slice()
            .sort(
              (a: any, b: any) =>
                +new Date(b.createdon) - +new Date(a.createdon)
            )
        : "";
    } else {
      return reqList
        ? reqList
            .slice()
            .sort(
              (a: any, b: any) =>
                +new Date(b.createdon) - +new Date(a.createdon)
            )
        : "";
    }
  }

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: "8px 10px !important",
      fontSize: "0.82rem",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClickOpenCancelPopUp = () => setOpenCancelPopup(true);

  const handleCloseCancelPopUp = () => {
    setOpenCancelPopup(false);
    setAnchorEl(null);
    setCancelReason("");
    setCancelReasonOther("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const setUserRequestList = (params: any) =>
    dispatch(requestActions.setUserRequestList(params));

  const setViewDetailScreenClicked = (params: any) => {
    mobileScreen()
      ? dispatch(requestActions.setIsViewDetailScreenClicked(params))
      : dispatch(requestActions.setIsViewDetailScreenClicked(false));
  };

  function getReqDate(inputDate: string) {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dd = moment(reqDate).tz(moment.tz.guess()).format("DD");
      var mm = moment(reqDate).tz(moment.tz.guess()).format("MM");
      var yyyy = moment(reqDate).tz(moment.tz.guess()).format("YYYY");
      return dd + "/" + mm + "/" + yyyy;
    }
  }

  const getReqDateMobile = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dayMonth = moment(reqDate).format("DD MMM YYYY");
      return dayMonth;
    }
  };

  function getReqTime(inputDate: string) {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var time = moment(reqDate).tz(moment.tz.guess()).format("hh:mm a");
      return time;
    }
  }

  useEffect(() => {
    fetchHomePageData();
    fetchCancelOptions();
    fetchReqMappingList();
  }, []);

  const fetchHomePageData = async () => {
    let output;
    setShowLoader(true);
    try {
      output = await makePostRequest(
        fetchServiceApi,
        new RequestParams({
          legacycode: userInfo ? userInfo.legacycode : "",
          draftonly: false,
          supportTicketOnly: false,
        })
      );
      setUserRequestList(output.data);
    } catch (e: any) {
      //localStorage.removeItem('authHeader');
      console.log(e);
      // pwa-code-start
      if ("indexedDB" in window && e.message === "Network Error") {
        let userReqListIndexedDB = await readAllData("posts", fetchServiceApi);
        //console.log("in indexedDB userReqListIndexedDB", userReqListIndexedDB)
        setUserRequestList(userReqListIndexedDB);
      }
      // pwa-code-end
    }
    setShowLoader(false);
  };

  function showListViewSelectedReq(
    id: string,
    event: any,
    fromAccordianClick: boolean
  ) {
    // setShowListView(true);
    // setShowGridView(false);
    //window.history.pushState(null, document.title, window.location.href);
    //window.location.reload();

    setFilteredArrayList(id);
    let serviceName = event.currentTarget.getAttribute("data-reqname");
    let reqBucketName = event.currentTarget.getAttribute("data-servicename");
    fetchServiceReqValue(reqBucketName, serviceName, id);
  }
  const draftInfoUrl = `${process.env.REACT_APP_DRAFT_REQUEST}`;
  const draftServiceApi = baseServiceUrl + draftInfoUrl;

  const fetchServiceReqValue = async (
    requesttype: any,
    servicetype: any,
    requestnumber: any
  ) => {
    try {
      //    console.log(draftServiceApi);
      let response = await makePostRequest(
        draftServiceApi,
        new RequestParams({
          servicereqno: requestnumber,
          servicetype: servicetype,
          requesttype: requesttype,
        })
      );

      let draftValues = response.data.lstDetails;
      const draftdata = draftValues.map((draftValue: any) => draftValue.value);
      setviewSelectedReqVal(draftValues);
      settentativeDate(response.data.tentative);
      // console.log(draftValues);
      // console.log(draftdata);
      const selectedAttachment: any = reqList
        ? reqList.filter(function (item: any) {
            return item.requestnumber == requestnumber;
          })
        : null;
      setAttachment(selectedAttachment[0].lstcontent);
      setShowListView(true);
      setShowGridView(false);
    } catch (error) {
      console.log(error);
    }
  };

  const showCancelPopUp = async (e: any) => {
    handleClickOpenCancelPopUp();
    if (cancelReqRef && cancelReqRef.current) {
      let reqqtype = cancelReqRef.current.getAttribute("data-reqqtype");
      const requestOptions = {
        serviceRequestType: reqqtype,
        requestId: "",
      };

      const cancelReasonApi = `${process.env.REACT_APP_CANCELREASON}`;
      let response = await axios.post(cancelReasonApi, requestOptions);
      //console.log(response, 'cancel reason');

      if (
        cancelReasonOptionAll !== undefined &&
        cancelReasonOptionAll !== null
      ) {
        const filteredCancelReason = cancelReasonOptionAll.filter(
          (number: any) => {
            return number.reqCategoryId == reqqtype;
          }
        );
        setCancelReasonFiltered(filteredCancelReason);
      }
    }
  };

  const fetchCancelOptions = () => {
    fetch("cancelOption.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setCancelReasonOptionAll(myJson.cancelOption);
      });
  };

  const fetchReqMappingList = () => {
    fetch("reqMappingSchema.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        //console.log(myJson,'myJson')
        setReqMappingSchemaList(myJson[0]);
      });
  };

  //const baseServiceUrl = ((process.env.REACT_APP_ENV == 'QA') ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}` : ((process.env.REACT_APP_ENV == 'PROD') ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}` : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`));
  const serviceUrlCancel = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const cancelReqServiceApi = baseServiceUrl + serviceUrlCancel;

  function cancelReq() {
    handleCloseCancelPopUp();
    let reqObject = {
      servicetype: cancelReqRef.current.getAttribute("data-servicetype"),
      requesttype: cancelReqRef.current.getAttribute("data-reqqtype"),
      legacycode: userInfo ? userInfo.legacycode : "",
      caseNumber: cancelReqRef.current.getAttribute("id"),
      cancelreason: cancelReason,
      canceldesc: cancelReasonOther,
      cancelfromdraft: false,
      status: "Cancelled",
    };
    sendCancelReq(reqObject);
  }

  const [openCancelToastMsg, setOpenCancelToastMsg] = useState(false);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const sendCancelReq = async (reqObject: any) => {
    try {
      let output;
      output = await makePostRequest(
        cancelReqServiceApi,
        new RequestParams(reqObject)
      );
      if (output.data.status == "failure") {
        setOpenCancelToastMsg(true);
        setShowSuccessAlert(false);
      } else {
        setOpenCancelToastMsg(true);
        setShowSuccessAlert(true);
        setTimeout(() => {
          fetchHomePageData();
          setShowListView(false);
          setShowGridView(true);
        }, 3000);
      }
    } catch (e) {
      console.log(e, "error in cancelling request");
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseCancelToast = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenCancelToastMsg(false);
    // setAnchorEl(null);
    setCancelReason("");
    setCancelReasonOther("");
  };
  const [currentReqId, setCurrentReqId] = useState("");
  const showFeedbackPopUp = async (event: any) => {
    event.stopPropagation();
    let reqId = event.currentTarget.getAttribute("data-reqid");
    setCurrentReqId(reqId);
    const feedbackUrl = `${process.env.REACT_APP_FETCH_FEEDBACK}`;
    const feedbackQuestionUrl = baseServiceUrl + feedbackUrl;
    let params: any = {
      servicerequestno: reqId,
    };
    let serviceAccessibleData = await makePostRequest(
      feedbackQuestionUrl,
      new RequestParams(params)
    );
    // console.log('serviceAccessibleData serviceAccessibleData',serviceAccessibleData);
    setFeedBackOptionFiltered(serviceAccessibleData.data.lstQuestions);
    setopenfeedpack1Popup(true);
  };

  function closeFeedback1PopUp() {
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  }

  const sendFeedBack = async () => {
    const submitfeedbackUrl =
      baseServiceUrl + `${process.env.REACT_APP_SUBMIT_FEEDBACK}`;
    let serviceSubmitFeedback = await makePostRequest(
      submitfeedbackUrl,
      new RequestParams(userfeedback)
    );
    if (serviceSubmitFeedback.data.status == "Success") {
      setopenfeedpack2Popup(true);
      setopenfeedpack1Popup(false);
      fetchHomePageData();
    } else {
      setisSubmitFeedbackErr(true);
    }
    setisSubmitFeedbackDisabled(true);
  };

  function closeFeedback2PopUp() {
    setopenfeedpack2Popup(false);
  }

  function handleFilterChange(selectedFilter: string, type: string) {
    type == "category"
      ? setcategoryFilter(selectedFilter)
      : setStatusFilter(selectedFilter);
    fetchHomePageData();
  }
  // useOnClickOutside(filterRef, () => { setshowFilterOption(false) });

  function handleChangeUserFeedback(currentSelction: any, newval?: any) {
    // console.log(newval,'RRRR');
    let reqId = currentReqId;
    //let starValue = currentSelction.value;
    let quesName = currentSelction.getAttribute("name");
    const indexOfQues = feedBackOptionFiltered.findIndex(
      (element: any, index: number) => {
        if (element.Questions === quesName) {
          return true;
        }
      }
    );

    let otherQuesName =
      feedBackOptionFiltered[feedBackOptionFiltered.length - 1].Questions;
    let response = "";

    if (quesName == otherQuesName) {
      response = "";
    } else if (newval == null && newval !== undefined) {
      response = "";
    } else response = starRating[newval - 1];

    let copyOfcurrentFeedback = userfeedback.lstResponses
      ? userfeedback.lstResponses
      : [];
    // check if this question already exists
    let nonEmptyFeedback = copyOfcurrentFeedback.filter(
      (item) => item.Question !== ""
    );
    let filteredFeedback = nonEmptyFeedback.filter(
      (item) => item.Question !== quesName
    );

    filteredFeedback.push({
      Question: quesName,
      Response: quesName == otherQuesName ? currentSelction.value : response,
      OrderNo: indexOfQues.toString(),
    });

    var index = filteredFeedback.findIndex(function (o: any) {
      return o.Response === "";
    });
    if (index !== -1) filteredFeedback.splice(index, 1);
    // console.log(filteredFeedback,'filteredFeedback 123',feedBackOptionFiltered);
    const otherQuesfound = filteredFeedback.some(
      (item) =>
        item.Question ===
        feedBackOptionFiltered[feedBackOptionFiltered.length - 1].Questions
    );
    // alert(otherQuesfound)
    // const responseVal = filteredFeedback.find(({ Response }) => Response.length > 0);
    if (
      (otherQuesfound &&
        filteredFeedback.length == feedBackOptionFiltered.length) ||
      (!otherQuesfound &&
        filteredFeedback.length == feedBackOptionFiltered.length - 1)
    ) {
      setisSubmitFeedbackDisabled(false);
    } else {
      setisSubmitFeedbackDisabled(true);
    }

    setUserfeedback({
      servicerequestno: reqId,
      lstResponses: filteredFeedback,
    });
  }

  const closeSubmitFeedbackErr = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackErr(false);
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  };

  const downloadSelectedAttachment = async (event: any) => {
    let fileId = event.currentTarget.getAttribute("data-contentid");
    let fileType = event.currentTarget.getAttribute("data-filetype");
    let filename = event.currentTarget.getAttribute("data-title");
    //let blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
    let fetchContentUrl = `${process.env.REACT_APP_PUSH_MARKETING_AND_TOP_PICKS_FETCH_CONTENT}`;
    const fetchContentsFullUrl = baseServiceUrl + fetchContentUrl;

    let topPicksResult = await axios.get(
      `${fetchContentsFullUrl}${fileId}/VersionData`,
      { responseType: "blob" }
    );
    let downloadType = handleDownloadType(fileType);
    var file = new Blob([topPicksResult.data], { type: downloadType });
    var fileURL = URL.createObjectURL(file);
    let anchorElement = document.createElement("a");
    anchorElement.href = fileURL;
    anchorElement.download = filename;
    anchorElement.click();
  };

  return (
    <section className="myReqList-mainContainer-myreq">
      {showLoader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {showGridView && (
            <div className="allReq-gridView">
              {sortedReqList && sortedReqList.length > 0 ? (
                <div className="FilterReqList">
                  <div
                    className={
                      "filterByOptions " +
                      (showFilterOption
                        ? "visibilityVisible"
                        : "visibilityHidden")
                    }
                  >
                    <div className="categoryFilterContainer">
                      <FormControl className="">
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={categoryFilter}
                          onChange={(e: any) =>
                            handleFilterChange(e.target.value, "category")
                          }
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="">Reset</MenuItem>
                          {categoryFilterOption.map(
                            (item1: any, index1: number) => (
                              <MenuItem key={item1} value={item1}>
                                {item1}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="statusFilterContainer">
                      <FormControl className="">
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={statusFilter}
                          onChange={(e: any) =>
                            handleFilterChange(e.target.value, "status")
                          }
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          <MenuItem value="">Reset</MenuItem>
                          {statusFilterOption.map(
                            (item1: any, index1: number) => (
                              <MenuItem key={item1 + index1} value={item1}>
                                {item1}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <section className="filterIcon 1">
                    <div
                      onClick={() => {
                        setshowFilterOption(!showFilterOption);
                      }}
                    >
                      <IconButton>
                        <FilterListSharpIcon></FilterListSharpIcon>
                      </IconButton>
                    </div>
                  </section>
                </div>
              ) : null}

              <div
                className={
                  mobileScreen()
                    ? "reqListContainer-mobile"
                    : "reqListContainer"
                }
              >
                {mobileScreen() ? (
                  MobileGridViewScreen()
                ) : sortedReqList && sortedReqList.length > 0 ? (
                  sortedReqList.map((value, index) => {
                    return (
                      <section
                        className="reqDetailsCard"
                        data-reqid={value.requestnumber}
                        data-servicename={value.servicetype}
                        data-reqname={value.requesttype}
                        key={value.requestnumber}
                        onClick={(e: any) => {
                          showListViewSelectedReq(
                            value.requestnumber,
                            e,
                            false
                          );
                        }}
                      >
                        <div
                          className={
                            "feedbackIcon mt-1 " +
                            (value.requeststatus == "Completed" &&
                            value.feedbackgiven == false
                              ? "visibilityVisible"
                              : "visibilityHidden")
                          }
                        >
                          <div
                            data-reqid={value.requestnumber}
                            onClick={(event: any) => {
                              showFeedbackPopUp(event);
                            }}
                          >
                            <FeedbackIcon color="primary" />
                          </div>
                        </div>
                        <div className="firstRow">
                          <div
                            className={
                              "viewMyRequest-ReqImg " +
                              (value.servicetype == "Academic & Education"
                                ? "academicReqImage"
                                : value.servicetype ==
                                  "Patient Awareness & Support"
                                ? "patientAwarenessReqImg"
                                : value.servicetype == "Patient Care"
                                ? "patientCareReqImg"
                                : value.servicetype == "Practice Enhancement"
                                ? "practiceEnhancement"
                                : "research")
                            }
                          ></div>
                          <div className="reqId-Name">
                            <div className="reqId">{value.requestnumber}</div>
                            <div className="reqName">{value.servicetype}</div>
                          </div>
                        </div>
                        <div className="secondRow mt-1">
                          <span>{value.requesttype}</span>
                        </div>
                        <div className="thirdRow mt-1">
                          <div className="reqRaised-dateTime">
                            <div className="dateOfReqRaised">
                              {getReqDate(value.createdon)}
                            </div>
                            <div className="timeOfReqRaised">
                              {getReqTime(value.createdon)}
                            </div>
                          </div>
                          <div>
                            <button
                              className={
                                value.requeststatus == "Submitted"
                                  ? "submittedReq reqStatusBtn"
                                  : [
                                      "In-Progress",
                                      "Awaiting Approval",
                                    ].includes(value.requeststatus)
                                  ? "inProgressReq reqStatusBtn"
                                  : value.requeststatus == "Completed"
                                  ? "completedReq reqStatusBtn"
                                  : ["Cancel", "Cancelled"].includes(
                                      value.requeststatus
                                    )
                                  ? "cancelledReq reqStatusBtn"
                                  : value.requeststatus == "Concluded"
                                  ? "concludedReq reqStatusBtn"
                                  : ""
                              }
                            >
                              {value.requeststatus == "Submitted"
                                ? "Submitted"
                                : ["In-Progress", "Awaiting Approval"].includes(
                                    value.requeststatus
                                  )
                                ? "In Progress"
                                : value.requeststatus == "Completed"
                                ? "Completed"
                                : ["Cancel", "Cancelled"].includes(
                                    value.requeststatus
                                  )
                                ? "Cancelled"
                                : value.requeststatus == "Concluded"
                                ? "Concluded"
                                : ""}
                            </button>
                          </div>
                        </div>
                      </section>
                    );
                  })
                ) : (
                  <div className="noRequestAvailable">
                    No Requests Available
                  </div>
                )}
              </div>
            </div>
          )}

          {showListView && (
            <div className="allReq-listView_myReq rootContainer">
              <Snackbar
                className="cancelReqToast"
                open={openCancelToastMsg}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                autoHideDuration={2000}
                onClose={handleCloseCancelToast}
              >
                {showSuccessAlert ? (
                  <Alert severity="success">
                    Request Cancelled Successfully
                  </Alert>
                ) : (
                  <Alert severity="error">Cancel Request Failed</Alert>
                )}
              </Snackbar>
              {
                //mobileScreen() ?<></>:
                <div
                  className="goBackToGridView"
                  onClick={() => {
                    setShowListView(false);
                    setShowGridView(true);
                    setFilteredArrayList("");
                    setViewDetailScreenClicked(false);
                  }}
                >
                  <img src={goBackArrow}></img>
                  <label>Back</label>
                </div>
              }
              <section className="listView-allReq">
                {
                  //mobileScreen() ? MobileDetailViewScreen() :
                  filteredReq && filteredReq.length > 0 ? (
                    filteredReq.map((value, index) => {
                      return (
                        <>
                          <section
                            className="reqDetails-single mt-3 mb-3 pb-2 vh-80"
                            key={value.requestnumber}
                          >
                            {value.requeststatus == "Cancelled" ? (
                              <div className="cancelReqText">
                                <strong>Request Cancelled</strong>
                              </div>
                            ) : (
                              <></>
                            )}

                            <section
                              className="firstSection"
                              data-reqid={value.requestnumber}
                            >
                              <div
                                className={
                                  "viewSelectedRequest-ReqImg " +
                                  (value.servicetype == "Academic & Education"
                                    ? "academicReqImage"
                                    : value.servicetype ==
                                      "Patient Awareness & Support"
                                    ? "patientAwarenessReqImg"
                                    : value.servicetype == "Patient Care"
                                    ? "patientCareReqImg"
                                    : value.servicetype ==
                                      "Practice Enhancement"
                                    ? "practiceEnhancement"
                                    : "research")
                                }
                              ></div>
                              <div className="servicetype">
                                <section className="reqTypeDetail">
                                  <div className="serviceName mt-2">
                                    {value.servicetype}
                                  </div>
                                  <div className="requestType mt-1">
                                    {value.requesttype}
                                  </div>
                                </section>
                                <div
                                  className="requestId"
                                  ref={cancelReqRef}
                                  id={value.requestnumber}
                                  data-reqqtype={value.requesttype}
                                  data-servicetype={value.servicetype}
                                >
                                  Request Id - {value.requestnumber}
                                </div>
                              </div>
                              {![
                                "Completed",
                                "Cancelled",
                                "Concluded",
                              ].includes(value.requeststatus) &&
                              value.servicetype !== "Push Marketing" ? (
                                <div className="showCancelOption">
                                  <IconButton
                                    onClick={(e: any) => handleClick(e)}
                                  >
                                    {" "}
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <Typography
                                      data-id={value.requestnumber}
                                      className={classes.typography}
                                      onClick={(e: any) => showCancelPopUp(e)}
                                    >
                                      Cancel Request
                                    </Typography>
                                  </Popover>
                                  <Dialog
                                    open={openCancelPopup}
                                    aria-labelledby="form-dialog-title"
                                    className="CancelSelectedReqPopup"
                                  >
                                    <DialogTitle id="form-dialog-title">
                                      <ErrorOutlineIcon></ErrorOutlineIcon>
                                      <label className="confirmCancelText">
                                        Are you sure you want to cancel the
                                        request
                                      </label>
                                    </DialogTitle>
                                    <DialogContent>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Select reason for cancellation
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={cancelReason}
                                          title={cancelReason}
                                          onChange={(e: any) =>
                                            setCancelReason(e.target.value)
                                          }
                                        >
                                          <MenuItem value="" disabled>
                                            Select Your Reason
                                          </MenuItem>
                                          {cancelReasonFiltered.map(
                                            (item: any, index: number) =>
                                              item.cancelOptionList.map(
                                                (item1: any) => (
                                                  <MenuItem
                                                    key={item1}
                                                    value={item1}
                                                    title={item1}
                                                  >
                                                    {item1}
                                                  </MenuItem>
                                                )
                                              )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <TextField
                                        onChange={(event) =>
                                          setCancelReasonOther(
                                            event.target.value
                                          )
                                        }
                                        autoComplete="off"
                                        multiline
                                        rows={3}
                                        id="outlined-multiline-static"
                                        placeholder="Mention your reason"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        className={
                                          cancelReason.includes("Any other") ||
                                          cancelReason.includes(
                                            "My reason is not listed"
                                          )
                                            ? "displayBlock otherCancelReason"
                                            : "displayNone otherCancelReason"
                                        }
                                      />
                                    </DialogContent>
                                    <DialogActions className="cancelBtnContainer">
                                      <Button
                                        className="cancelPopUpBtn CancelReqNo"
                                        onClick={handleCloseCancelPopUp}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="cancelPopUpBtn CancelReqYes "
                                        onClick={cancelReq}
                                        disabled={
                                          cancelReason.length == 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Confirm
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              ) : (
                                <></>
                              )}
                            </section>
                            <section className="secondSection">
                              {!["Cancel", "Cancelled", "Concluded"].includes(
                                value.requeststatus
                              ) ? (
                                <>
                                  <Box className="reqStatusDateContainer mt-3">
                                    <Box
                                      p={1}
                                      className="reqStatusDate submittedOn"
                                    >
                                      {value.createdon != null
                                        ? getReqDate(value.createdon)
                                        : ""}
                                    </Box>
                                    <Box
                                      p={1}
                                      className="reqStatusDate inProgressDate"
                                    >
                                      {value.inprogresson !== null
                                        ? getReqDate(value.inprogresson)
                                        : ""}
                                    </Box>
                                    <Box
                                      p={1}
                                      className="reqStatusDate completedOn"
                                    >
                                      {value.createdon != null
                                        ? getReqDate(value.closedon)
                                        : ""}
                                    </Box>
                                  </Box>
                                  <Box className="reqStatusLiveTracker wrapper">
                                    <Stepper
                                      activeStep={parseInt(
                                        value.requeststatus == "Submitted"
                                          ? "1"
                                          : [
                                              "In-Progress",
                                              "Awaiting Approval",
                                            ].includes(value.requeststatus)
                                          ? "2"
                                          : value.requeststatus == "Completed"
                                          ? "3"
                                          : ""
                                      )}
                                      alternativeLabel
                                    >
                                      {steps.map((label) => (
                                        <Step key={label}>
                                          <StepLabel>{label}</StepLabel>
                                        </Step>
                                      ))}
                                    </Stepper>
                                  </Box>
                                </>
                              ) : (
                                <></>
                              )}
                            </section>
                            <section className="thirdSection mt-4">
                              {viewSelectedReqVal &&
                                viewSelectedReqVal.map(
                                  (item: any, index: any) => {
                                    let sampleBrandVal = item.arrValue;
                                    let key = item.uniquename;
                                    let headerVal = reqMappingSchemaList[key];
                                    let keyValue =
                                      item.value !== null &&
                                      item.value !== undefined
                                        ? item.value
                                        : "None";

                                    if (
                                      key == "USV_Sample_Request__c" &&
                                      sampleBrandVal &&
                                      sampleBrandVal.length
                                    ) {
                                      return (
                                        <div
                                          className="viewReqFilledVal"
                                          key={item + index}
                                        >
                                          <p className="titleHeader">
                                            {headerVal}
                                          </p>

                                          {sampleBrandVal.map(
                                            (brandName: any, idx: number) => {
                                              return (
                                                <p className="titleValue">
                                                  <strong>
                                                    {idx + 1}. {brandName}
                                                  </strong>
                                                </p>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    } else if (
                                      key !==
                                        "USV_Month_Physician_Sample_Not_for_Sale__c" &&
                                      key ==
                                        "USV_Reason_For_Requesting_On_Behalf_HCP__c" &&
                                      item.value !== null
                                    ) {
                                      return (
                                        <div
                                          className="viewReqFilledVal"
                                          key={item + index}
                                        >
                                          <p className="titleHeader">
                                            {headerVal}
                                          </p>
                                          <p className="titleValue">
                                            <strong>{keyValue}</strong>
                                          </p>
                                        </div>
                                      );
                                    } else if (
                                      ![
                                        "USV_Reason_For_Requesting_On_Behalf_HCP__c",
                                        "USV_Month_Physician_Sample_Not_for_Sale__c",
                                      ].includes(key)
                                    ) {
                                      return (
                                        <div
                                          className="viewReqFilledVal"
                                          key={item + index}
                                        >
                                          <p className="titleHeader">
                                            {headerVal}
                                          </p>
                                          <p className="titleValue">
                                            <strong>{keyValue}</strong>
                                          </p>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              {tentativedate !== null &&
                              !["Completed", "Cancelled", "Concluded"].includes(
                                value.requeststatus
                              ) &&
                              tentativedate.length > 0 ? (
                                <div
                                  className="viewReqFilledVal"
                                  key={tentativedate}
                                >
                                  <p className="titleHeader">{`Expected Tentative Date`}</p>
                                  <p className="titleValue">
                                    <strong>{getReqDate(tentativedate)}</strong>
                                  </p>
                                </div>
                              ) : null}
                              {attachment !== null && attachment.length > 0 ? (
                                <div className="displayAttachment">
                                  <div>
                                    <AttachFileOutlinedIcon></AttachFileOutlinedIcon>
                                    <label className="attachments">
                                      Attachment
                                    </label>
                                  </div>
                                  {attachment.map(
                                    (itema: any, attachmentIndex: number) => {
                                      return (
                                        <>
                                          <Link
                                            to="#"
                                            key={attachmentIndex}
                                            data-contentid={itema.content}
                                            data-filetype={itema.type}
                                            data-title={itema.title}
                                            onClick={(e: any) =>
                                              downloadSelectedAttachment(e)
                                            }
                                          >
                                            {itema.title}
                                          </Link>
                                          <br></br>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </section>
                          </section>
                        </>
                      );
                    })
                  ) : (
                    <div className="noRequestAvailable">
                      No Requests Available
                    </div>
                  )
                }
              </section>
            </div>
          )}
        </>
      )}
      {/* Feedback Dialog PopUp1 */}
      <Dialog className="feedBack-mainPopup" open={openfeedpack1Popup}>
        <DialogContent>
          <section className="feedback-mainContainer">
            <section className="leftContainer col-lg-6  col-md-6 col-12">
              <h2 id="simple-dialog-title">We value your feedback</h2>
              <div className="feedbackImg">
                <img src={feedback1} alt="img"></img>
              </div>
            </section>
            <section className="rightContainer col-lg-6  col-md-6 col-12">
              <div className="closeFeedbackIcon">
                <Close
                  className="closeFeedBackDialog"
                  onClick={closeFeedback1PopUp}
                />
              </div>
              <h4 id="simple-dialog-title">
                How satisfied are you with USV service ?
              </h4>
              {feedBackOptionFiltered &&
                feedBackOptionFiltered.map((fitem: any, findex: number) => {
                  return findex !== feedBackOptionFiltered.length - 1 ? (
                    // (!(fitem.inclDudes('Any other'))) ? (
                    <div key={fitem.Questions}>
                      <Box
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <Typography component="legend">
                          {fitem.Questions}
                          <label className="requiredFeedbckQuestion">*</label>
                        </Typography>
                        <Rating
                          name={fitem.Questions}
                          value={starvalueIndex}
                          onChange={(event: any, newVal: any) => {
                            handleChangeUserFeedback(event.target, newVal);
                          }}
                        />
                      </Box>
                    </div>
                  ) : (
                    <section className="otherFeedback mt-2">
                      <TextField
                        //id="standard-basic"
                        id="outlined-multiline-static"
                        placeholder={fitem.Questions}
                        name={fitem.Questions}
                        type="text"
                        multiline
                        rows={3}
                        variant="outlined"
                        value={starvalueIndex}
                        onChange={(event: any) => {
                          handleChangeUserFeedback(event.target);
                        }}
                        autoComplete="off"
                        fullWidth
                      />
                    </section>
                  );
                })}
              <Button
                variant="contained"
                disabled={isSubmitFeedbackDisabled}
                className={
                  "sendFeedBackBtn " +
                  (isSubmitFeedbackDisabled
                    ? "disabledFeedbackBtn"
                    : "enablefeedBackBtn")
                }
                onClick={sendFeedBack}
              >
                Send Feedback
              </Button>
            </section>
          </section>
        </DialogContent>
        <Snackbar
          open={isSubmitFeedbackErr}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          onClose={closeSubmitFeedbackErr}
        >
          <Alert severity="warning">
            Error Occurred in Submitting The Request.\nPlease email us on
            info@usv.in or call us on 1800-123-4567
          </Alert>
        </Snackbar>
      </Dialog>

      {/* FEEDBACK POPUP 2 */}
      <Dialog
        className="feedBack-ConfirmPopup"
        onClose={closeFeedback2PopUp}
        open={openfeedpack2Popup}
      >
        <div>
          <Close
            className="closeFeedBackDialog"
            onClick={closeFeedback2PopUp}
          />
        </div>
        <DialogContent>
          <div className="confirmFeedBackImg">
            <img src={feedback2} alt="confirmFeedback"></img>
          </div>
        </DialogContent>
        <h2 className="feedback-thankyou">Thank you!</h2>
        <DialogContentText className="confirmFeedBackText">
          Thank you for sharing your thoughts.
          <br /> We appreciate your feedback!
        </DialogContentText>
        <Button
          variant="contained"
          className="feedBackDoneBtn"
          onClick={() => {
            setopenfeedpack2Popup(false);
            fetchHomePageData();
          }}
        >
          Done
        </Button>
      </Dialog>
    </section>
  );

  function MobileGridViewScreen() {
    return (
      <div className="allReq-gridViewMobile ">
        <div className="reqListContainer-mobile">
          {sortedReqList && sortedReqList.length > 0 ? (
            sortedReqList.map((value, index: number) => {
              return (
                <>
                  {index > 0 &&
                  getReqDate(value.createdon) ==
                    getReqDate(sortedReqList[index - 1].createdon) ? (
                    <></>
                  ) : index == 0 ? (
                    <div className="dateOfReqHeader-viewReq">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  ) : (
                    <div className="dateOfReqHeader">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  )}

                  <section
                    className="reqDetailsCardMobile"
                    data-reqid={value.requestnumber}
                    data-servicename={value.servicetype}
                    data-reqname={value.requesttype}
                    key={value.requestnumber}
                    onClick={(e: any) => {
                      setViewDetailScreenClicked(true);
                      showListViewSelectedReq(value.requestnumber, e, false);
                    }}
                  >
                    <div
                      className={
                        "feedbackIcon mt-1 " +
                        (value.requeststatus == "Completed" &&
                        value.feedbackgiven == false
                          ? "visibilityVisible"
                          : "visibilityHidden")
                      }
                    >
                      <div
                        data-reqid={value.requestnumber}
                        onClick={(event: any) => {
                          showFeedbackPopUp(event);
                        }}
                      >
                        <FeedbackIcon color="primary" />
                      </div>
                    </div>
                    <div className="firstRowDraftMobile">
                      <div
                        className={
                          "viewMyRequest-ReqImg " +
                          (value.servicetype == "Academic & Education"
                            ? "academicReqImage"
                            : value.servicetype == "Patient Awareness & Support"
                            ? "patientAwarenessReqImg"
                            : value.servicetype == "Patient Care"
                            ? "patientCareReqImg"
                            : value.servicetype == "Practice Enhancement"
                            ? "practiceEnhancement"
                            : "research")
                        }
                      ></div>
                      <div className="reqId-Name">
                        <div className="reqId">{value.requestnumber}</div>
                        <div className="reqName">{value.servicetype}</div>
                      </div>
                    </div>
                    <div className="secondRowDraftMobile">
                      <span>{value.requesttype}</span>
                    </div>
                    <div className="thirdRowDraftMobile mt-2">
                      <div className="reqRaised-dateTime">
                        <div className="timeOfReqRaised">
                          {getReqTime(value.createdon)}
                        </div>
                      </div>
                      <div>
                        <button
                          className={
                            value.requeststatus == "Submitted"
                              ? "submittedReq reqStatusBtn"
                              : ["In-Progress", "Awaiting Approval"].includes(
                                  value.requeststatus
                                )
                              ? "inProgressReq reqStatusBtn"
                              : value.requeststatus == "Completed"
                              ? "completedReq reqStatusBtn"
                              : ["Cancel", "Cancelled"].includes(
                                  value.requeststatus
                                )
                              ? "cancelledReq reqStatusBtn"
                              : value.requeststatus == "Concluded"
                              ? "concludedReq reqStatusBtn"
                              : ""
                          }
                        >
                          {value.requeststatus == "Submitted"
                            ? "Submitted"
                            : ["In-Progress", "Awaiting Approval"].includes(
                                value.requeststatus
                              )
                            ? "In Progress"
                            : value.requeststatus == "Completed"
                            ? "Completed"
                            : ["Cancel", "Cancelled"].includes(
                                value.requeststatus
                              )
                            ? "Cancelled"
                            : value.requeststatus == "Concluded"
                            ? "Concluded"
                            : ""}
                        </button>
                      </div>
                    </div>
                  </section>
                </>
              );
            })
          ) : (
            <div className="noRequestAvailable">No Requests Available</div>
          )}
        </div>
      </div>
    );
  }
};

export default ViewRequest;
