import { Fragment, useState } from "react";
import { LayoutProps } from "../../Class-Interfaces/layoutProps";
import Navigation from "../Navigation/Navigation";
import SponsoredPopup from "../SponsoredPopup/SponsoredPopup";
import "./Layout.scss";
import { useLocation } from "react-router-dom";
import { useGlobalEvent } from "beautiful-react-hooks";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import MobileBottomNavigation from "../../mobile-Components/MobileBottomMenu/MobileButtomMenuComponent";
import whatsapp from "../../assets/images/whatsapp.svg";
import SiteDown from "../SiteDown/Sitedown";
interface stateType {
  from: { pathname: string };
}

const Layout = (props: LayoutProps) => {
  let location = useLocation();
  const whatsappLink = ` https://api.whatsapp.com/send?phone=918976991789&text=Dear Doctor,%0aWelcome to USVConnect!%0aFor support, please initiate a chat, by just saying "Hi" to us!%0aThank you&app_absent=0`;
  function openWatsapp() {
    window.open(whatsappLink, "_blank");
  }
  return (
    <Fragment>
      {!location.pathname.includes("sitedown") &&
        !location.pathname.includes("login") &&
        !location.pathname.includes("verifyUser") && <Navigation />}
      <main>{props.children}</main>
      {/* {!location.pathname.includes('login')&&!location.pathname.includes('verifyUser') && mobileScreen()  ? <div className="MobileBottomNavigationWrapper"><MobileBottomNavigation /></div>: <></>} */}
      {!location.pathname.includes("sitedown") &&
        !location.pathname.includes("login") &&
        !location.pathname.includes("verifyUser") && <SponsoredPopup />}
      {!location.pathname.includes("sitedown") &&
      !location.pathname.includes("login") &&
      !location.pathname.includes("verifyUser") &&
      mobileScreen() ? (
        <div className="MobileBottomNavigationWrapper">
          <MobileBottomNavigation />
        </div>
      ) : (
        <></>
      )}
      {!location.pathname.includes("sitedown") &&
      !location.pathname.includes("/login") &&
      !location.pathname.includes("verifyUser") ? (
        <div className="watsappIcon">
          {/* {" "}
          <a
            href="https://api.whatsapp.com/send/?phone=916204665565&text=Hello&app_absent=0"
            target="_blank"
          > */}
          <img
            src={whatsapp}
            alt="livechat"
            className="livechat"
            onClick={() => openWatsapp()}
          ></img>
          {/* </a> */}
        </div>
      ) : null}
    </Fragment>
  );
};

export default Layout;
