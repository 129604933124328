import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import "./App.scss";
import IdleTimer from "../src/utils/IdleTimer";
import Layout from "./components/Layout/Layout";
import Login from "./components/Login/Login";
import Request from "./components/Requests/Request";
import Events from "./components/Events/Events";
import NotFound from "./components/NotFound/NotFound";
import Home from "./components/Home/Home";
import Otp from "./components/Otp/Otp";
import UserProfile from "./components/UserProfile/UserProfile";
import Faq from "./components/Faq/Faq";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useGlobalEvent } from "beautiful-react-hooks";
import { mobileScreen } from "./Mobile_utils/GlobalUtils";
import MobileBottomNavigation from "./mobile-Components/MobileBottomMenu/MobileButtomMenuComponent";
import { useLocation } from "react-router-dom";
import MobileNotification from "./mobile-Components/MobileNotificationComponnent/MobileNotificationComponent";
import { PageViewGA, initGA } from "./utils/googleAnalyticsTracking";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../src/store";
import { Dialog, DialogActions, Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Sitedown from "./components/SiteDown/Sitedown";
import PushMarketing from "./components/PushMarketing";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import SupportTicket from "./components/Requests/SupportTicket/SupportTicket";
function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowheight, setWindowheight] = useState(window.innerHeight);
  const onWindowResize = useGlobalEvent("resize");
  const [autoLogout, setAutoLogout] = useState(false);
  const [openLoginPopup, setopenLoginPopup] = useState(false);
  const loginStatusCheck = useSelector(
    (state: RootState) => state.login.loginStatus
  );
  const location = window.location.href;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
    setWindowheight(window.innerHeight);
    mobileScreen();
  });

  //Auto Logout Feature
  const handlelogOut = () => {
    localStorage.removeItem("authHeader");
    localStorage.removeItem("_expiredTime");
    setopenLoginPopup(true);
  };

  const handleRediectLogin = () => {
    window.location.href = "/login";
  };

  useEffect(() => {
    if (loginStatusCheck) {
      const timer = new IdleTimer({
        onTimeout() {
          setAutoLogout(true);
        },
        onExpired() {
          setAutoLogout(true);
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [loginStatusCheck]);

  useEffect(() => {
    if (autoLogout) {
      handlelogOut();
    }
  }, [autoLogout]);

  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [currentUrl]);

  useEffect(() => {
    mobileScreen();
    //console.log('called');
    // if (windowheight < windowWidth) {
    //   let viewwidth = window.screen.width;
    //   let viewport = document.querySelector("meta[name=viewport]");
    //   viewport?.setAttribute("content", "height=" + 800 + "px, width=" +
    //     viewwidth + "px, initial-scale=1.0");
    // }
    // else {
    //   let viewwidth = window.screen.width;
    //   let viewheight = window.screen.height;
    //   let viewport = document.querySelector("meta[name=viewport]");
    //   viewport?.setAttribute("content", "height=" + viewheight + "px, width=" +
    //     viewwidth + "px, initial-scale=1.0");
    // }
    let viewwidth = window.screen.width;
    let viewheight = document.body.clientHeight;
    let viewport = document.querySelector("meta[name=viewport]");
    viewport?.setAttribute(
      "content",
      "height=" +
        viewheight +
        "px, width=" +
        viewwidth +
        "px, initial-scale=1.0"
    );
  }, []);

  const gatrackingID =
    process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_GA_TRACKINGID_PROD}`
      : `${process.env.REACT_APP_GA_TRACKINGID_DEV}`;
  initGA(gatrackingID);
  PageViewGA();

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
            {/* <Redirect to="/sitedown" /> */}
          </Route>

          <Route path="/login" exact>
            <Login />
            {/* <Sitedown /> */}
          </Route>

          <Route path="/verifyUser">
            <Otp />
          </Route>

          <Route path="/home" exact>
            <Home />
          </Route>

          <Route path="/request" exact>
            <Request />
          </Route>
          <Route path="/events" exact>
            <Events />
          </Route>

          <Route path="/userProfile" exact>
            <UserProfile />
          </Route>
          <Route path="/faq" exact>
            <Faq />
          </Route>

          <Route path="/notification" exact>
            <MobileNotification />
          </Route>

          <Route path="/sitedown" exact>
            <Sitedown />
          </Route>
          <Route path="/pushMarketing">
            <PushMarketing />
          </Route>

          <Route path="/disclaimer" exact>
            <Disclaimer />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>

        <Dialog
          open={openLoginPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableScrollLock={true}
          className="MobileHomePage-popover"
        >
          <section className="sponsoredPopupDetails">
            <div>
              <Close
                className="closeSponsoredPopup"
                onClick={() => setopenLoginPopup(false)}
              />
            </div>
            <h5>Session Timeout. Please Login Again </h5>
          </section>
          <DialogActions className="btnContainer_sponsored">
            <Button
              variant="contained"
              className="confirmBtn"
              onClick={handleRediectLogin}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
