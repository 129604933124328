import './Request.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect,useRef } from 'react';
import { CreateNewRequestInterface } from '../../Class-Interfaces/CreateNewRequestInterface';
import academic from '../../assets/images/academic.svg';
import personalReq from '../../assets/images/personalReq.svg';
import researchReq from '../../assets/images/researchPublication.svg';
import practiceReq from '../../assets/images/practiceReq.svg';
import patientAwareness from '../../assets/images/patient_awareness_support.svg';
import AcademicRequest from './CreateReqCategory/CreateAcademicRequest';
import PatientAwarenessRequest from './CreateReqCategory/CreatePatientAwarenessRequest';
import PracticeEnhancementRequest from './CreateReqCategory/CreatePracticeEnhancementRequest';
import ResearchPublicationRequest from './CreateReqCategory/CreateResearchPublicationRequest';
import PatientCareRequest from './CreateReqCategory/CreatePatientCareRequest'
import AcademicReqSelectedImg from '../../assets/images/academicWhite.svg';
import PracticeReqSelected from '../../assets/images/practiceReqSelected.svg';
import ResearchReqSelected from '../../assets/images/researchReqSelected.svg';
import PatientCareSelected from '../../assets/images/patientCareSelected.svg';
import selectedPatientAwareness from '../../assets/images/selectedPatientAwareness.svg';
import { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { requestActions } from '../../store/request';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {CircularProgress} from '@material-ui/core';
import {EventGA} from '../../utils/googleAnalyticsTracking';



function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateRequest = (props: CreateNewRequestInterface) => {
    const dispatch = useDispatch();
    const [showRequestContentPanel, setShowRequestContentPanel] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const homeSelection = location.state as any;
    const homeSelectedServiceName = homeSelection ? homeSelection.selectedServiceHome : '';
    const homeSelectedReqName = homeSelection ? homeSelection.selectedReqHome : '';
    const fromhomeSelected = homeSelection ? homeSelection.selectedFromHome : false;
const[showReqBucketLoader,setshowReqBucketLoader]= useState(false);


    const setSelectedReq = (params: any) => {
        dispatch(requestActions.setSelectedReq(params));
    };
  
    useEffect(() => {
        EventGA('Create Request','Create Request Page','REQUEST PAGE');
        setshowReqBucketLoader(true);
    }, []);

    useEffect(() => {
 setTimeout(() => {
    if (homeSelectedServiceName !== undefined && homeSelectedReqName.length > 0 ) {
        props.selectService(null, homeSelectedServiceName);
        setShowRequestContentPanel(true);
        setSelectedReq(homeSelectedReqName);
        history.replace({ pathname: '/request', state: { selectedServiceHome: '', selectedReqHome: '' } });
    } 
 }, (fromhomeSelected?3000:0));
    

    }, [showRequestContentPanel]);

    const showErrorToast = useSelector((state: RootState) => state.request.errorToastMsg);
    const showSuccessToast = useSelector((state: RootState) => state.request.successToastMsg);
    const showBucketLoader = useSelector((state: RootState) => state.request.showBucketLoader);

    const setErrorToastMsg = (params: any) => {
        dispatch(requestActions.setErrorToastMsg(params));
    };
    const setSuccessToastMsg = (params: any) => {
        dispatch(requestActions.setSuccessToastMsg(params));
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorToastMsg({ 'isError': false, 'errMsg': '' });
        setSuccessToastMsg({ 'isSuccess': false, 'newReqId': '' });

    };

    const resetPrevSelections = () => {
        setShowRequestContentPanel(true);
        setSelectedReq('');
    }
    // const [reqBucketOffsetLeft,setReqBucketOffsetLeft]=useState('0px');
    // function setOffsetToHighlight(e:any){
    //  let offsetLeft = (e.target.tagName !== "ARTICLE")?e.target.closest('article').offsetLeft:e.target.offsetLeft;
    //  setReqBucketOffsetLeft((offsetLeft-20)+'px');
    // }

    return (
        <div className="createReq-mainContainer">
            <section className="loader" style={{ display: (showBucketLoader=='true') ? "flex" : "none" }}><CircularProgress></CircularProgress></section>
            <Snackbar
                open={showErrorToast.isError}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert variant="filled" severity="warning">
                    {
                        (showErrorToast.isError)?
                        ((showErrorToast.errMsg.includes('Found Similar Record')) ?
                            'Please wait same service type request is under process.'
                            :'Error Occurred in Submitting The Request.\nPlease email us on info@usv.in or call us on 1800-123-4567'):''
                    }
                </Alert>
            </Snackbar>

            <Snackbar
                open={showSuccessToast.isSuccess}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000} onClose={handleClose}>
                <Alert variant="filled" severity="success">
                    {
                        (showSuccessToast.newReqId.length > 0) ?
                            ' Request ' + showSuccessToast.newReqId + ' has been created successfully'
                            : ' Request has been created successfully'
                    }
                </Alert>
            </Snackbar>

            <div className="createReq-categoryOption-mainContainer pt-1">
                <div className="categoryOptionContent-service" >
                    {
                        //props.masterData && props.masterData.tabs ? (props.masterData.tabs.map((mainObj: any, index: number) => (

                            // props.masterData && props.masterData.tabs && props.masterData.tabs[index].tab === 'SERVICES' ?
                            //     (
                                <>
                                    {
                                        // (props.masterData.tabs[index].subTabs.map((tabObj: any, subIndex: number) => (
                                            (props.masterData.map((subItem: any, subIndex: number) => (
                                     
                                        <article key={subIndex} data-reqbucketname={props.serviceName} className={'requestBuckets ' + (subItem.serviceName === 'Academic & Education' ? props.serviceName === 'Academic & Education' ? "academicContent whiteText" : "academicContent" : subItem.serviceName === 'Patient Awareness & Support' ? props.serviceName === 'Patient Awareness & Support' ? "patientReqContent whiteText" : "patientReqContent" : subItem.serviceName === 'Practice Enhancement' ? props.serviceName === 'Practice Enhancement' ? "practiceEnhancement whiteText" : "practiceEnhancement" : subItem.serviceName === 'Research Publication' ? props.serviceName === 'Research Publication' ? "researchContent whiteText" : "researchContent" : subItem.serviceName === 'Patient Care' ? props.serviceName === 'Patient Care' ? "patientCareContent whiteText" : "patientCareContent" : '')}onClick={(e) => { props.selectService(e, subItem.serviceName);resetPrevSelections(); dispatch(requestActions.seteditDraft(false)); }}>
                                        <div>
                                            <img src={subItem.serviceName === 'Academic & Education' ? props.serviceName === 'Academic & Education' ? AcademicReqSelectedImg : academic : subItem.serviceName === 'Patient Awareness & Support' ? props.serviceName === 'Patient Awareness & Support' ? selectedPatientAwareness : patientAwareness : subItem.serviceName === 'Practice Enhancement' ? props.serviceName === 'Practice Enhancement' ? PracticeReqSelected : practiceReq : subItem.serviceName === 'Research Publication' ? props.serviceName === 'Research Publication' ? ResearchReqSelected : researchReq : subItem.serviceName === 'Patient Care' ? props.serviceName === 'Patient Care' ? PatientCareSelected : personalReq : ''} ></img>
                                        </div>
                                        <p style={{"color": props.serviceName === subItem.serviceName ? '#fff' : '#0F1C49' }}>{subItem.serviceName}</p>
                                    </article>
                                                                             
                                    
                                    )

                                    ))}
                                    {/* <section style={{
                                        // "transition": "500ms all", 
                                        //  "left": props.serviceName === props.listOfReqType[0] ? '0%' : props.serviceName === props.listOfReqType[1] ? ((100/(props.listOfReqType.length))*1)+'%' : props.serviceName === props.listOfReqType[2] ? ((100/(props.listOfReqType.length))*2)+'%': props.serviceName === props.listOfReqType[3] ? ((100/(props.listOfReqType.length))*3)+'%':
                                        //      props.serviceName === props.listOfReqType[4] ? ((100/(props.listOfReqType.length))*4)+'%' : 0, 
                                        "left":reqBucketOffsetLeft,
                                        "display": props.serviceName === '' ? 'none' : 'block'
                                    }}>
                                    </section> */}
                                </>
                                // ) : null

                        //)
                        //)) : null
                    }
                </div>
            </div>
            <div className={"selectedRequestContent mb-1" + (showRequestContentPanel ? ' showAnimateOpacity' : ' hideAnimateOpacity')}
                style={showRequestContentPanel ? { display: 'block' } : { display: 'none' }}
            >
                {(() => {
                    switch (props.serviceName) {
                        case 'Academic & Education':
                            return (
                                <AcademicRequest ></AcademicRequest>
                            )
                        case 'Patient Awareness & Support':
                            return (
                                <PatientAwarenessRequest></PatientAwarenessRequest>
                            )

                        case 'Practice Enhancement':
                            return (
                                <PracticeEnhancementRequest></PracticeEnhancementRequest>
                            )
                        case 'Research Publication':
                            return (
                                <ResearchPublicationRequest></ResearchPublicationRequest>
                            )
                        case 'Patient Care':
                            return (
                                <>
                              <p className="sampleNotificationText">
                                  **Platform is undergoing some development.
                                  Sample requests will not be available. Apologies for the inconvenience caused.
                                </p>
                                 <PatientCareRequest></PatientCareRequest>
                              </>
                            )
                    }
                })()}
            </div>
        </div>
    )
}

export default CreateRequest;