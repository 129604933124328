import { configureStore } from "@reduxjs/toolkit";
import request from "./request";
import login from "./login";
import shippingAddress from "./shippingAddress";

import logger from "redux-logger";
const store = configureStore({
  reducer: { request, login, shippingAddress },
  // middleware: [logger],
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
