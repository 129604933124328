import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makePostRequest } from "../../services/httpServices";
import { RequestParams } from "../../Class-Interfaces/httpRequestParams";
import "./SponsoredPopup.scss";
import { requestActions } from "../../store/request";

const SponsoredPopup = () => {
  const userInfo: any = JSON.parse(localStorage.getItem("userDetails") as any);

  const dispatch = useDispatch();
  const showSponsoredPopup1 = useSelector(
    (state: RootState) => state.request.showSponsoredPopup1
  );
  const showSponsoredPopup2 = useSelector(
    (state: RootState) => state.request.showSponsoredPopup2
  );

  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const serviceUrl = `${process.env.REACT_APP_CREATE_REQUEST}`;
  const createReqServiceApi = baseServiceUrl + serviceUrl;

  const setSponsoredPopup2 = (params: any) =>
    dispatch(requestActions.setSponsoredPopup2(params));
  const handleOpenPopup2 = () => {
    dispatch(
      requestActions.setSponsoredPopup1({ showPopup: false, fromHome: false })
    );
    createSponsoredRequest();
  };
  const handleClosePopUp1 = () =>
    dispatch(
      requestActions.setSponsoredPopup1({
        showPopup: false,
        fromHome: false,
        clickedSponsoredReq: "",
        clickedSponsoredService: "",
      })
    );

  const fromHome = showSponsoredPopup1.fromHome;

  const handleClosePopUp2 = () => setSponsoredPopup2(false);

  const createSponsoredRequest = async () => {
    let reqObject: any = {
      servicetype: showSponsoredPopup1.clickedSponsoredService,
      requesttype: showSponsoredPopup1.clickedSponsoredReq,
      legacycode: userInfo ? userInfo.legacycode : "",
      status: "Submitted",
    };

    try {
      let output;
      output = await makePostRequest(
        createReqServiceApi,
        new RequestParams(reqObject)
      );
      if (output.data.status == "failure") {
        if (fromHome) {
          // window.scrollTo(0, 0);
          dispatch(
            requestActions.seterrCreatingSponsoredService({
              isError: true,
              errMsg: output.data.errormessage,
            })
          );
        } else {
          dispatch(
            requestActions.setErrorToastMsg({
              isError: true,
              errMsg: output.data.errormessage,
            })
          );
        }
      } else {
        setSponsoredPopup2(true);
      }
    } catch (e) {
      console.log(e, "error in creating request");
    }
  };

  return (
    <div>
      <Dialog
        open={showSponsoredPopup1.showPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="MobileHomePage-popover"
      >
        <section className="sponsoredPopupDetails">
          {/* <div><Close className="closeSponsoredPopup" onClick={handleClosePopUp1} /></div> */}
          <h5>This is a special service</h5>
          <p className="sponsoredPopUptextDesc">Are you sure/ interested?</p>
        </section>
        <DialogActions className="btnContainer_sponsored">
          <Button
            variant="contained"
            onClick={handleClosePopUp1}
            className={"sponsoredpopUpBtn"}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleOpenPopup2}
            className="confirmBtn"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSponsoredPopup2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <section className="sponsoredPopupDetails sponsoredPopup2">
          {/* <div><Close className="closeSponsoredPopup" onClick={handleClosePopUp2} /></div> */}
          <h5>Thank you for your interest</h5>
          <div>
            {userInfo != null && userInfo.rbmdetails ? (
              <div className="pocDetails">
                <p className="pocName">
                  <strong>
                    {userInfo.rbmdetails.name} will connect with you shortly
                  </strong>
                </p>
                <p className="contactDetail">
                  {userInfo.rbmdetails.email
                    ? `Email Id: ${userInfo.rbmdetails.email}`
                    : ""}
                </p>
                <p className="contactDetail">
                  {userInfo.rbmdetails.mobilephone
                    ? `Mobile: ${userInfo.rbmdetails.mobilephone}`
                    : ""}
                </p>
              </div>
            ) : (
              <p className="sampleTextMsg">
                {"Our executive will get back to you shortly"}
              </p>
            )}
          </div>
        </section>
        <DialogActions className="btnContainer_sponsored">
          <Button onClick={handleClosePopUp2} className="confirmBtn">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SponsoredPopup;
