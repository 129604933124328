import React from "react";
import "./MobileBottomMenuComponent.scss";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HomeMobileIcon from "../../assets/images/MobileAssets/HomeMobile.svg";
import HomeMobileIconDisable from "../../assets/images/MobileAssets/HomeDisable.svg";
import EventMobileIcon from "../../assets/images/MobileAssets/EventMobile.svg";
import EventMobileIconDisable from "../../assets/images/MobileAssets/EventDisable.svg";
import MobileNotification from "../../assets/images/MobileAssets/mobileNotification.svg";
import MobileNotificationDisabled from "../../assets/images/MobileAssets/mobileNotificationDisable.svg";
import MyRequestMobileIcon from "../../assets/images/MobileAssets/MyRequestMobile.svg";
import MyRequestMobileIconDisable from "../../assets/images/MobileAssets/MyRequestDisable.svg";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestActions } from "../../store/request";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../store";

const useStyles = makeStyles({
  root: {
    width: 800,
  },
});

const MobileBottomNavigation = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState("home");
  const [active, setActive] = React.useState(false);
  const dispatch = useDispatch();
  let location = useLocation();
  const requestTabs = useSelector(
    (state: RootState) => state.request.requestTab
  );
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    newValue === "Raise  Request"
      ? dispatch(requestActions.setrequestTab(true))
      : dispatch(requestActions.setrequestTab(false));
    newValue === "Raise  Request"
      ? dispatch(requestActions.setrequestTabIndex(0))
      : dispatch(requestActions.setrequestTabIndex(1));
    newValue === "Raise  Request" ? setActive(true) : setActive(false);
    newValue === "Raise  Request"
      ? setValue("Raise Request")
      : setValue("request");
  };

  React.useEffect(() => {
    let getpathName = location.pathname.split("/")[1];
    getpathName === "request"
      ? requestTabs
        ? setValue("Raise  Request")
        : setValue(getpathName)
      : setValue(getpathName);
  }, [location]);

  return (
    <BottomNavigation value={value} className={classes.root}>
      <Link to="/home">
        <BottomNavigationAction
          label="Home"
          value="home"
          onClick={(e) => {
            handleChange(e, "home");
          }}
          icon={
            value === "home" ? (
              <img src={HomeMobileIcon} alt="HomeIcon" className="active" />
            ) : (
              <img
                src={HomeMobileIconDisable}
                alt="HomeMobileIconDisable"
                className="disable"
              />
            )
          }
        />
      </Link>
      <Link to="/events">
        <BottomNavigationAction
          className="event-wrapper"
          label="Events"
          value="events"
          onClick={(e) => {
            handleChange(e, "events");
          }}
          icon={
            value === "events" ? (
              <img src={EventMobileIcon} alt="EventIcon" className="active" />
            ) : (
              <img
                src={EventMobileIconDisable}
                alt="EventMobileIconDisable"
                className="disable"
              />
            )
          }
        />
      </Link>
      <Link to="/request">
        <BottomNavigationAction
          label="Raise Request"
          onClick={(e) => {
            handleChange(e, "Raise  Request");
          }}
          value="Raise  Request"
          icon={
            <div className={`request-icon ${active ? "active" : null}`}>
              <span>+</span>
            </div>
          }
        />
      </Link>
      <Link to="/notification">
        <BottomNavigationAction
          className="notification-wrapper"
          label="Notification"
          onClick={(e) => {
            handleChange(e, "notification");
          }}
          value="notification"
          icon={
            value === "notification" ? (
              <img
                src={MobileNotification}
                className="notificon-icon-mobile active"
                alt="TopPicksMobileIcon"
              />
            ) : (
              <img
                src={MobileNotificationDisabled}
                className="notificon-icon-mobile disable"
                alt="TopPicksMobileIconDisable"
              />
            )
          }
        />
      </Link>
      <Link to="/request">
        <BottomNavigationAction
          label="My Requests"
          onClick={(e) => {
            handleChange(e, "request");
          }}
          value="request"
          icon={
            value === "request" ? (
              <img
                src={MyRequestMobileIcon}
                alt="MyRequestMobileIcon"
                className="active"
              />
            ) : (
              <img
                src={MyRequestMobileIconDisable}
                alt="MyRequestMobileIconDisable"
                className="disable"
              />
            )
          }
        />
      </Link>
    </BottomNavigation>
  );
};

export default MobileBottomNavigation;
