import "./SupportTicket.scss";
import goBackArrow from "../../../assets/images/viewReqBackIcon.svg";
import Close from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import feedback1 from "../../../assets/images/FeedbackPopUpImg2.svg";
import feedback2 from "../../../assets/images/feedbackPopUpImg1.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { mobileScreen } from "../../../Mobile_utils/GlobalUtils";
import {
  Snackbar,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  IconButton,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Popover,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { readAllData } from "../../../indexdb_utils";
import { makePostRequest } from "../../../services/httpServices";
import { RequestParams } from "../../../Class-Interfaces/httpRequestParams";
import { fetchSupportTicketDetails } from "../../..//utils/home_axios";
import FeedbackIcon from "@material-ui/icons/Feedback";
import moment from "moment-timezone";
const SupportTicket = () => {
  const [data, setdata] = useState<any[]>([]);

  const [showGridView, setShowGridView] = useState(true);
  const [showListView, setShowListView] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filteredArrayList, setFilteredArrayList] = useState("");
  const steps = ["New", "In Progress", "Closed"];
  const [viewSelectedReqVal, setviewSelectedReqVal] = useState([]);
  const [starvalueIndex, setStarValueIndex] = useState();
  const [reqMappingSchemaList, setReqMappingSchemaList] = useState<any[]>([]);
  useEffect(() => {
    fetchSupportTicketData();
    fetchReqMappingList();
  }, []);

  const fetchSupportTicketData = async () => {
    setShowLoader(true);
    try {
      let response = await fetchSupportTicketDetails();
      // console.log(
      //   response.data.lstServiceRequest,
      //   "response -- Support Ticket Details"
      // );
      if (
        response &&
        response.data &&
        response.data.status == "success" &&
        response.data.lstServiceRequest
      ) {
        setdata(response.data.lstServiceRequest);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error);
      if ("indexedDB" in window && !navigator.onLine) {
        let response = await fetchSupportTicketDetails();
      }
      setShowLoader(false);
    }
  };

  const fetchReqMappingList = () => {
    fetch("reqMappingSchema.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        //console.log(myJson,'myJson')
        setReqMappingSchemaList(myJson[0]);
      });
  };

  function getReqDate(inputDate: string) {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dd = moment(reqDate).tz(moment.tz.guess()).format("DD");
      var mm = moment(reqDate).tz(moment.tz.guess()).format("MM");
      var yyyy = moment(reqDate).tz(moment.tz.guess()).format("YYYY");
      return dd + "/" + mm + "/" + yyyy;
    }
  }
  const getReqDateMobile = (inputDate: string) => {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var dayMonth = moment(reqDate).format("DD MMM YYYY");
      return dayMonth;
    }
  };

  function getReqTime(inputDate: string) {
    if (inputDate != null) {
      var reqDate = Date.parse(inputDate);
      var time = moment(reqDate).tz(moment.tz.guess()).format("hh:mm a");
      return time;
    }
  }

  function showListViewSelectedReq(
    id: string,
    event: any,
    fromAccordianClick: boolean
  ) {
    setFilteredArrayList(id);
    let serviceName = event.currentTarget.getAttribute("data-reqname");
    let reqBucketName = event.currentTarget.getAttribute("data-servicename");
    fetchServiceReqValue(reqBucketName, serviceName, id);
  }
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const draftInfoUrl = `${process.env.REACT_APP_DRAFT_REQUEST}`;
  const draftServiceApi = baseServiceUrl + draftInfoUrl;
  const fetchServiceReqValue = async (
    requesttype: any,
    servicetype: any,
    requestnumber: any
  ) => {
    try {
      //    console.log(draftServiceApi);
      let response = await makePostRequest(
        draftServiceApi,
        new RequestParams({
          servicereqno: requestnumber,
          servicetype: servicetype,
          requesttype: requesttype,
        })
      );

      let draftValues = response.data.lstDetails;
      const draftdata = draftValues.map((draftValue: any) => draftValue.value);
      setviewSelectedReqVal(draftValues);
      // console.log(draftdata, "data");
      setShowListView(true);
      setShowGridView(false);
    } catch (error) {
      console.log(error);
    }
  };
  const filteredReq = data
    ? data.filter(function (item: any) {
        return item.requestnumber == filteredArrayList;
      })
    : null;

  const [currentReqId, setCurrentReqId] = useState("");
  const [openfeedpack1Popup, setopenfeedpack1Popup] = useState(false);
  const [openfeedpack2Popup, setopenfeedpack2Popup] = useState(false);
  const [feedBackOptionFiltered, setFeedBackOptionFiltered] = useState<any[]>(
    []
  );
  const [isSubmitFeedbackErr, setisSubmitFeedbackErr] = useState(false);
  const showFeedbackPopUp = async (event: any) => {
    event.stopPropagation();
    let reqId = event.currentTarget.getAttribute("data-reqid");
    setCurrentReqId(reqId);
    const feedbackUrl = `${process.env.REACT_APP_FETCH_FEEDBACK}`;
    const feedbackQuestionUrl = baseServiceUrl + feedbackUrl;
    let params: any = {
      servicerequestno: reqId,
    };
    let serviceAccessibleData = await makePostRequest(
      feedbackQuestionUrl,
      new RequestParams(params)
    );
    // console.log('serviceAccessibleData serviceAccessibleData',serviceAccessibleData);
    setFeedBackOptionFiltered(serviceAccessibleData.data.lstQuestions);
    setopenfeedpack1Popup(true);
  };
  const [userfeedback, setUserfeedback] = useState({
    servicerequestno: "",
    lstResponses: [
      {
        Question: "",
        Response: "",
        OrderNo: "",
      },
    ],
  });
  const starRating = ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"];
  const [isSubmitFeedbackDisabled, setisSubmitFeedbackDisabled] =
    useState(true);
  function closeFeedback1PopUp() {
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  }

  const sendFeedBack = async () => {
    const submitfeedbackUrl =
      baseServiceUrl + `${process.env.REACT_APP_SUBMIT_FEEDBACK}`;
    let serviceSubmitFeedback = await makePostRequest(
      submitfeedbackUrl,
      new RequestParams(userfeedback)
    );
    if (serviceSubmitFeedback.data.status == "Success") {
      setopenfeedpack2Popup(true);
      setopenfeedpack1Popup(false);
      fetchSupportTicketData();
    } else {
      setisSubmitFeedbackErr(true);
    }
    setisSubmitFeedbackDisabled(true);
  };

  function closeFeedback2PopUp() {
    setopenfeedpack2Popup(false);
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleChangeUserFeedback(currentSelction: any, newval?: any) {
    // console.log(newval,'RRRR');
    let reqId = currentReqId;
    //let starValue = currentSelction.value;
    let quesName = currentSelction.getAttribute("name");
    const indexOfQues = feedBackOptionFiltered.findIndex(
      (element: any, index: number) => {
        if (element.Questions === quesName) {
          return true;
        }
      }
    );

    let otherQuesName =
      feedBackOptionFiltered[feedBackOptionFiltered.length - 1].Questions;
    let response = "";

    if (quesName == otherQuesName) {
      response = "";
    } else if (newval == null && newval !== undefined) {
      response = "";
    } else response = starRating[newval - 1];

    let copyOfcurrentFeedback = userfeedback.lstResponses
      ? userfeedback.lstResponses
      : [];
    // check if this question already exists
    let nonEmptyFeedback = copyOfcurrentFeedback.filter(
      (item) => item.Question !== ""
    );
    let filteredFeedback = nonEmptyFeedback.filter(
      (item) => item.Question !== quesName
    );

    filteredFeedback.push({
      Question: quesName,
      Response: quesName == otherQuesName ? currentSelction.value : response,
      OrderNo: indexOfQues.toString(),
    });

    var index = filteredFeedback.findIndex(function (o: any) {
      return o.Response === "";
    });
    if (index !== -1) filteredFeedback.splice(index, 1);
    // console.log(filteredFeedback,'filteredFeedback 123',feedBackOptionFiltered);
    const otherQuesfound = filteredFeedback.some(
      (item) =>
        item.Question ===
        feedBackOptionFiltered[feedBackOptionFiltered.length - 1].Questions
    );
    // alert(otherQuesfound)
    // const responseVal = filteredFeedback.find(({ Response }) => Response.length > 0);
    if (
      (otherQuesfound &&
        filteredFeedback.length == feedBackOptionFiltered.length) ||
      (!otherQuesfound &&
        filteredFeedback.length == feedBackOptionFiltered.length - 1)
    ) {
      setisSubmitFeedbackDisabled(false);
    } else {
      setisSubmitFeedbackDisabled(true);
    }

    setUserfeedback({
      servicerequestno: reqId,
      lstResponses: filteredFeedback,
    });
  }

  const closeSubmitFeedbackErr = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setUserfeedback({
      servicerequestno: "",
      lstResponses: [
        {
          Question: "",
          Response: "",
          OrderNo: "",
        },
      ],
    });
    setisSubmitFeedbackErr(false);
    setisSubmitFeedbackDisabled(true);
    setopenfeedpack1Popup(false);
  };

  function MobileGridViewScreen() {
    return (
      <div className="allReq-gridViewMobile ">
        <div className="reqListContainer-mobile supportTicketMobile">
          {data && data.length > 0 ? (
            data.map((value: any, index: number) => {
              return (
                <>
                  {index > 0 &&
                  getReqDate(value.createdon) ==
                    getReqDate(data[index - 1].createdon) ? (
                    <></>
                  ) : index == 0 ? (
                    <div className="dateOfReqHeader-viewReq">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  ) : (
                    <div className="dateOfReqHeader">
                      {getReqDateMobile(value.createdon)}
                    </div>
                  )}

                  <section
                    className="reqDetailsCardMobile"
                    data-reqid={value.requestnumber}
                    data-servicename={value.servicetype}
                    data-reqname={value.requesttype}
                    key={value.requestnumber}
                    onClick={(e: any) => {
                      showListViewSelectedReq(value.requestnumber, e, false);
                    }}
                  >
                    <div
                      className={
                        "feedbackIcon mt-1 " +
                        (value.requeststatus == "Completed" &&
                        value.feedbackgiven == false
                          ? "visibilityVisible"
                          : "visibilityHidden")
                      }
                    >
                      <div
                        data-reqid={value.requestnumber}
                        onClick={(event: any) => {
                          showFeedbackPopUp(event);
                        }}
                      >
                        <FeedbackIcon color="primary" />
                      </div>
                    </div>
                    <div className="firstRowDraftMobile">
                      <div
                        className={
                          "viewMyRequest-ReqImg " +
                          (value.servicetype == "Academic & Education"
                            ? "academicReqImage"
                            : value.servicetype == "Patient Awareness & Support"
                            ? "patientAwarenessReqImg"
                            : value.servicetype == "Patient Care"
                            ? "patientCareReqImg"
                            : value.servicetype == "Practice Enhancement"
                            ? "practiceEnhancement"
                            : "research")
                        }
                      ></div>
                      <div className="reqId-Name">
                        <div className="reqId">{value.requestnumber}</div>
                        <div className="reqName">{value.servicetype}</div>
                      </div>
                    </div>
                    <div className="secondRowDraftMobile">
                      <span>{value.requesttype}</span>
                    </div>
                    <div className="thirdRowDraftMobile mt-2">
                      <div className="reqRaised-dateTime">
                        <div className="timeOfReqRaised">
                          {getReqTime(value.createdon)}
                        </div>
                      </div>
                      <div>
                        <button
                          className={
                            ["Submitted", "New"].includes(value.requeststatus)
                              ? "submittedReq reqStatusBtn"
                              : ["In-Progress", "Awaiting Approval"].includes(
                                  value.requeststatus
                                )
                              ? "inProgressReq reqStatusBtn"
                              : ["Closed", "Completed"].includes(
                                  value.requeststatus
                                )
                              ? "completedReq reqStatusBtn"
                              : ["Cancel", "Cancelled"].includes(
                                  value.requeststatus
                                )
                              ? "cancelledReq reqStatusBtn"
                              : value.requeststatus == "Concluded"
                              ? "concludedReq reqStatusBtn"
                              : ""
                          }
                        >
                          {["Submitted", "New"].includes(value.requeststatus)
                            ? "New"
                            : ["In-Progress", "Awaiting Approval"].includes(
                                value.requeststatus
                              )
                            ? "In Progress"
                            : ["Closed", "Completed"].includes(
                                value.requeststatus
                              )
                            ? "Completed"
                            : ["Cancel", "Cancelled"].includes(
                                value.requeststatus
                              )
                            ? "Cancelled"
                            : value.requeststatus == "Concluded"
                            ? "Concluded"
                            : ""}
                        </button>
                      </div>
                    </div>
                  </section>
                </>
              );
            })
          ) : (
            <div className="noRequestAvailable">No Tickets Available</div>
          )}
        </div>
      </div>
    );
  }
  return (
    <section className="supporticket-mainContainer container-fluid mt-2">
      {showLoader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {showGridView && (
            <div className="allReq-gridView">
              <div
                className={
                  mobileScreen()
                    ? "reqListContainer-mobile"
                    : "reqListContainer"
                }
              >
                {data && data.length > 0 ? (
                  mobileScreen() ? (
                    MobileGridViewScreen()
                  ) : (
                    data.map((value: any, index) => {
                      return (
                        <section
                          className="reqDetailsCard"
                          data-reqid={value.requestnumber}
                          data-servicename={value.servicetype}
                          data-reqname={value.requesttype}
                          key={value.requestnumber}
                          onClick={(e: any) => {
                            showListViewSelectedReq(
                              value.requestnumber,
                              e,
                              false
                            );
                          }}
                        >
                          <div
                            className={
                              "feedbackIcon mt-1 " +
                              (["Closed", "Completed"].includes(
                                value.requeststatus
                              ) && value.feedbackgiven == false
                                ? "visibilityVisible"
                                : "visibilityHidden")
                            }
                          >
                            <div
                              data-reqid={value.requestnumber}
                              onClick={(event: any) => {
                                showFeedbackPopUp(event);
                              }}
                            >
                              <FeedbackIcon color="primary" />
                            </div>
                          </div>
                          <div className="firstRow">
                            <div className="reqId-Name">
                              <div className="reqId">{value.requestnumber}</div>
                              <div className="reqName">{value.servicetype}</div>
                            </div>
                          </div>
                          <div className="secondRow mt-1">
                            <span>{value.requesttype}</span>
                          </div>
                          <div className="thirdRow mt-1">
                            <div className="reqRaised-dateTime">
                              <div className="dateOfReqRaised">
                                {getReqDate(value.createdon)}
                              </div>
                              <div className="timeOfReqRaised">
                                {getReqTime(value.createdon)}
                              </div>
                            </div>
                            {value.requeststatus ? (
                              <div>
                                <button
                                  className={
                                    ["Submitted", "New"].includes(
                                      value.requeststatus
                                    )
                                      ? "submittedReq reqStatusBtn"
                                      : [
                                          "In-Progress",
                                          "Awaiting Approval",
                                        ].includes(value.requeststatus)
                                      ? "inProgressReq reqStatusBtn"
                                      : ["Closed", "Completed"].includes(
                                          value.requeststatus
                                        )
                                      ? "completedReq reqStatusBtn"
                                      : ["Cancel", "Cancelled"].includes(
                                          value.requeststatus
                                        )
                                      ? "cancelledReq reqStatusBtn"
                                      : value.requeststatus == "Concluded"
                                      ? "concludedReq reqStatusBtn"
                                      : ""
                                  }
                                >
                                  {["Submitted", "New"].includes(
                                    value.requeststatus
                                  )
                                    ? "New"
                                    : [
                                        "In-Progress",
                                        "Awaiting Approval",
                                      ].includes(value.requeststatus)
                                    ? "In Progress"
                                    : ["Closed", "Completed"].includes(
                                        value.requeststatus
                                      )
                                    ? "Closed"
                                    : ["Cancel", "Cancelled"].includes(
                                        value.requeststatus
                                      )
                                    ? "Cancelled"
                                    : value.requeststatus == "Concluded"
                                    ? "Concluded"
                                    : ""}
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </section>
                      );
                    })
                  )
                ) : (
                  <div className="noRequestAvailable">No Tickets Available</div>
                )}
              </div>
            </div>
          )}

          {showListView && (
            <div className="allReq-listView_myReq rootContainer">
              <div
                className="goBackToGridView"
                onClick={() => {
                  setShowListView(false);
                  setShowGridView(true);
                  setFilteredArrayList("");
                }}
              >
                <img src={goBackArrow}></img>
                <label>Back</label>
              </div>

              <section className="listView-allReq">
                {filteredReq && filteredReq.length > 0 ? (
                  filteredReq.map((value: any, index) => {
                    return (
                      <>
                        <section
                          className="reqDetails-single mt-3 mb-3 pb-2 vh-80"
                          key={value.requestnumber}
                        >
                          <section
                            className="firstSection"
                            data-reqid={value.requestnumber}
                          >
                            <div className="servicetype">
                              <section className="reqTypeDetail">
                                <div className="serviceName mt-2">
                                  {value.servicetype}
                                </div>
                                <div className="requestType mt-1">
                                  {value.requesttype}
                                </div>
                              </section>
                              <div
                                className="requestId"
                                // ref={cancelReqRef}
                                id={value.requestnumber}
                                data-reqqtype={value.requesttype}
                                data-servicetype={value.servicetype}
                              >
                                Request Id - {value.requestnumber}
                              </div>
                            </div>
                          </section>
                          <section className="secondSection">
                            {!["Cancel", "Cancelled", "Concluded"].includes(
                              value.requeststatus
                            ) ? (
                              <>
                                <Box className="reqStatusDateContainer mt-3">
                                  <Box
                                    p={1}
                                    className="reqStatusDate submittedOn"
                                  >
                                    {value.createdon != null
                                      ? getReqDate(value.createdon)
                                      : ""}
                                  </Box>
                                  <Box
                                    p={1}
                                    className="reqStatusDate inProgressDate"
                                  >
                                    {value.inprogresson !== null
                                      ? getReqDate(value.inprogresson)
                                      : ""}
                                  </Box>
                                  <Box
                                    p={1}
                                    className="reqStatusDate completedOn"
                                  >
                                    {value.createdon != null
                                      ? getReqDate(value.closedon)
                                      : ""}
                                  </Box>
                                </Box>
                                <Box className="reqStatusLiveTracker wrapper">
                                  <Stepper
                                    activeStep={parseInt(
                                      ["Submitted", "New"].includes(
                                        value.requeststatus
                                      )
                                        ? "1"
                                        : [
                                            "In-Progress",
                                            "Awaiting Approval",
                                          ].includes(value.requeststatus)
                                        ? "2"
                                        : ["Closed", "Completed"].includes(
                                            value.requeststatus
                                          )
                                        ? "3"
                                        : ""
                                    )}
                                    alternativeLabel
                                  >
                                    {steps.map((label) => (
                                      <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </Box>
                              </>
                            ) : (
                              <></>
                            )}
                          </section>
                          <section className="thirdSection mt-4">
                            {viewSelectedReqVal &&
                              viewSelectedReqVal.map(
                                (item: any, index: any) => {
                                  let key = item.uniquename;
                                  let headerVal = reqMappingSchemaList[key];
                                  let keyValue =
                                    item.value !== null &&
                                    item.value !== undefined
                                      ? item.value
                                      : "None";
                                  if (
                                    key ==
                                      "USV_Reason_For_Requesting_On_Behalf_HCP__c" &&
                                    item.value !== null
                                  ) {
                                    return (
                                      <div
                                        className="viewReqFilledVal"
                                        key={item + index}
                                      >
                                        <p className="titleHeader">
                                          {headerVal}
                                        </p>
                                        <p className="titleValue">
                                          <strong>{keyValue}</strong>
                                        </p>
                                      </div>
                                    );
                                  } else if (
                                    key !==
                                    "USV_Reason_For_Requesting_On_Behalf_HCP__c"
                                  ) {
                                    return (
                                      <div
                                        className="viewReqFilledVal"
                                        key={item + index}
                                      >
                                        <p className="titleHeader">
                                          {headerVal}
                                        </p>
                                        <p className="titleValue">
                                          <strong>{keyValue}</strong>
                                        </p>
                                      </div>
                                    );
                                  }
                                }
                              )}
                          </section>
                        </section>
                      </>
                    );
                  })
                ) : (
                  <div className="noRequestAvailable">
                    No Requests Available
                  </div>
                )}
              </section>
            </div>
          )}
        </>
      )}
      {/* Feedback Dialog PopUp1 */}
      <Dialog className="feedBack-mainPopup" open={openfeedpack1Popup}>
        <DialogContent>
          <section className="feedback-mainContainer">
            <section className="leftContainer col-lg-6  col-md-6 col-12">
              <h2 id="simple-dialog-title">We value your feedback</h2>
              <div className="feedbackImg">
                <img src={feedback1} alt="img"></img>
              </div>
            </section>
            <section className="rightContainer col-lg-6  col-md-6 col-12">
              <div className="closeFeedbackIcon">
                <Close
                  className="closeFeedBackDialog"
                  onClick={closeFeedback1PopUp}
                />
              </div>
              <h4 id="simple-dialog-title">
                How satisfied are you with USV service ?
              </h4>
              {feedBackOptionFiltered &&
                feedBackOptionFiltered.map((fitem: any, findex: number) => {
                  return findex !== feedBackOptionFiltered.length - 1 ? (
                    // (!(fitem.inclDudes('Any other'))) ? (
                    <div key={fitem.Questions}>
                      <Box
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <Typography component="legend">
                          {fitem.Questions}
                          <label className="requiredFeedbckQuestion">*</label>
                        </Typography>
                        <Rating
                          name={fitem.Questions}
                          value={starvalueIndex}
                          onChange={(event: any, newVal: any) => {
                            handleChangeUserFeedback(event.target, newVal);
                          }}
                        />
                      </Box>
                    </div>
                  ) : (
                    <section className="otherFeedback mt-2">
                      <TextField
                        //id="standard-basic"
                        id="outlined-multiline-static"
                        placeholder={fitem.Questions}
                        name={fitem.Questions}
                        type="text"
                        multiline
                        rows={3}
                        variant="outlined"
                        value={starvalueIndex}
                        onChange={(event: any) => {
                          handleChangeUserFeedback(event.target);
                        }}
                        autoComplete="off"
                        fullWidth
                      />
                    </section>
                  );
                })}
              <Button
                variant="contained"
                disabled={isSubmitFeedbackDisabled}
                className={
                  "sendFeedBackBtn " +
                  (isSubmitFeedbackDisabled
                    ? "disabledFeedbackBtn"
                    : "enablefeedBackBtn")
                }
                onClick={sendFeedBack}
              >
                Send Feedback
              </Button>
            </section>
          </section>
        </DialogContent>
        <Snackbar
          open={isSubmitFeedbackErr}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          onClose={closeSubmitFeedbackErr}
        >
          <Alert severity="warning">
            Error Occurred in Submitting The Request.\nPlease email us on
            info@usv.in or call us on 1800-123-4567
          </Alert>
        </Snackbar>
      </Dialog>

      {/* FEEDBACK POPUP 2 */}
      <Dialog
        className="feedBack-ConfirmPopup"
        onClose={closeFeedback2PopUp}
        open={openfeedpack2Popup}
      >
        <div>
          <Close
            className="closeFeedBackDialog"
            onClick={closeFeedback2PopUp}
          />
        </div>
        <DialogContent>
          <div className="confirmFeedBackImg">
            <img src={feedback2} alt="confirmFeedback"></img>
          </div>
        </DialogContent>
        <h2 className="feedback-thankyou">Thank you!</h2>
        <DialogContentText className="confirmFeedBackText">
          Thank you for sharing your thoughts.
          <br /> We appreciate your feedback!
        </DialogContentText>
        <Button
          variant="contained"
          className="feedBackDoneBtn"
          onClick={() => {
            setopenfeedpack2Popup(false);
            fetchSupportTicketData();
          }}
        >
          Done
        </Button>
      </Dialog>
    </section>
  );
};

export default SupportTicket;
