import React, { Component, useState, useEffect } from "react";
import "./Faq.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeGetRequest } from "../../services/httpServices";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import faqImg from "../../assets/images/faqImg.svg";
import { useHistory } from "react-router-dom";
import { mobileScreen } from "../../Mobile_utils/GlobalUtils";
import { PageViewGA } from "../../utils/googleAnalyticsTracking";
import { Link, NavLink } from "react-router-dom";

const Faq = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const baseServiceUrl =
    process.env.REACT_APP_ENV == "QA"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_QA}`
      : process.env.REACT_APP_ENV == "UAT"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_UAT}`
      : process.env.REACT_APP_ENV == "PROD"
      ? `${process.env.REACT_APP_BASE_SERVICEURL_PROD}`
      : `${process.env.REACT_APP_BASE_SERVICEURL_DEV}`;
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
      },
    })
  );

  const [faqs, setfaqs] = useState([
    {
      question: "What is USVConnect?",
      answer:
        "USVConnect is a unique portal developed exclusively for Medical Fraternity to facilitate various academic & patient centric services",
    },
    {
      question: "How can I check the different services offered by USVConnect?",
      answer: "Click here to know difference services offered by us",
    },
    {
      question: "How can I raise a request for service?",
      answer:
        "Laptop Users\nStep1- Click on the Request Service button or the request from the service panel displayed on home page\nStep2- Select the type of request and fill details.\nStep3- Click Submit\n\nMobile Users\nStep1- Click on the plus blue sign displayed on home page\nStep2- Select the respective request type.\nStep3- Select the service request and fill required details\nStep4- Click Submit\n",
    },
    {
      question: "How can I check the status of my raised service request?",
      answer:
        "Laptop Users\nStep1- Click on the Request Service button\nStep2- Click on My Request tab.\nStep3- You can view all your request here\n\nMobile Users\nStep1- Click on the My Request Service button from Homepage.\nStep2- You can view all your requests here.\nStep3- Click on respective service request & open for more relevant details.\n",
    },
    {
      question: "How can I connect with USV support team?",
      answer:
        "Please email us on usvconnect@usv.in or call us on 022-3515-5050",
    },
  ]);
  // {
  //   question: "How can I connect with USV support team?",
  //   answer:
  //     "Please email us on info@usv.in or call us on 1800-123-4567\nYou can also reach us on 022-3515-5050 for any support.",
  // },
  // const [faqs, setfaqs] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const isSitedown = `${process.env.REACT_APP_SITEDOWN}`;
  useEffect(() => {
    PageViewGA();
    if (
      localStorage.getItem("authHeader") &&
      localStorage.getItem("authHeader") != "" &&
      isSitedown == "false"
    ) {
      // loadUSVBrandDropdownOptions();
    } else {
      history.push("/login");
    }
  }, []);

  const loadUSVBrandDropdownOptions = async () => {
    const sampleOptionApi = baseServiceUrl + `${process.env.REACT_APP_GETFAQ}`;
    let brandOptionvalue = await makeGetRequest(sampleOptionApi);
    setfaqs(brandOptionvalue.data);
  };

  return (
    <section className="faq-rootContainer">
      {mobileScreen() ? (
        <h3 className="mobile-FAQHeader"> FAQ</h3>
      ) : (
        <section className="titleSection">
          <section className="col-lg-6 col-md-6 col-12 headingPage">
            <h2>Frequently Asked Questions</h2>
          </section>
          <section className="col-lg-6 col-md-6 col-12">
            <div className="faqImgContainer">
              <img src={faqImg} alt="faqimg"></img>
            </div>
          </section>
        </section>
      )}
      <div className="faq-mainContainer mt-5 pb-5">
        {faqs.map((item: any, index) => {
          return (
            <Accordion
              expanded={expanded === "panel" + index}
              onChange={handleChange("panel" + index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.question.includes(
                  "How can I check the different services offered by USVConnect?"
                ) ? (
                  <Typography>
                    <Link to="/home" className="goToHomeFaq">
                      Click here
                    </Link>
                    <label className="faqToHome">
                      to know different services offered by us
                    </label>
                  </Typography>
                ) : (
                  <Typography>{item.answer}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </section>
  );
};

export default Faq;
